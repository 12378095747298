import React from "react";
import jQuery from "jquery";
import FetchAllApi from "../api_links/fetch_all_api";
import { Link } from "react-router-dom";
// for title change
import { Helmet } from 'react-helmet'
// for title change
require("jquery-mousewheel");
// require("malihu-custom-scrollbar-plugin");
//

class left_sidebar extends React.Component {
  constructor(props) {
    super(props);
    //const { history } = this.props;
    this.state = {
      initialActiveInbox: "active",
      logged_user_id: localStorage.getItem("logged_user_id"),
      logged_client_id: localStorage.getItem("logged_client_id"),
      logged_role_id: localStorage.getItem("logged_role_id"),
      logged_user_name: localStorage.getItem("logged_user_name"),
      logged_user_email: localStorage.getItem("logged_user_email"),
      logged_user_phone: localStorage.getItem("logged_user_phone"),
      logged_user_image: localStorage.getItem("logged_user_image"),
      logged_company_name: localStorage.getItem("logged_company_name"),

      AllClientMail: localStorage.getItem("AllClientMail"),


      dropdown: "",
      inbox_list: [],
      response_stus: 0,
      response_msg: "No data found",
      item_details: "",
      item_file_path: "",

      role_permissions:
        JSON.parse(localStorage.getItem("role_permissions")) || [],
      user_layer_role: localStorage.getItem("user_layer_role"),
      layer: localStorage.getItem("layer"),
    };
  }

  // componentDidMount() {
  //   jQuery(".mscroll-y").mCustomScrollbar(
  //     {

  //       axis: "y",
  //       scrollEasing: "linear",
  //       scrollInertia: 600,
  //       autoHideScrollbar: "true",
  //       autoExpandScrollbar: "true"
  //     });

  //   if(this.state.currentLocation) {
  //     let cl = this.state.currentLocation.replace('/','');
  //     cl = '#'+cl;
  //     jQuery(".mscroll-y").mCustomScrollbar(
  //     'scrollTo',cl,{
  //       scrollInertia:1000
  //   });
  //   }
  // }

  // componentDidMount() {

  //   // console.log("role_permissions", role_permissions);
  //   // require("jquery-mousewheel");
  //   // require("malihu-custom-scrollbar-plugin");

  //   jQuery(".mscroll-y").mCustomScrollbar(
  //     //'scrollTo','#create_purchaseorder',
  //     // 'scrollTo',['','50'],
  //     //'scrollTo',{y:"450",x:"250"},
  //     {

  //       axis: "y",
  //       scrollEasing: "linear",
  //       scrollInertia: 600,
  //       autoHideScrollbar: "true",
  //       autoExpandScrollbar: "true",
  //       //'scrollTo:'50%
  //     });
  //   // setTimeout(function () {
  //   //   jQuery('#mCSB_1_container').css({ top: '-400px' });
  //   // }, 1000);
  //   //   setTimeout(function() { 
  //   //     jQuery(".mscroll-y").mCustomScrollbar('scrollTo','.active');
  //   //   //jQuery(".mscroll-y").mCustomScrollbar('scrollTo',document.getElementById(window.location.pathname.replace('/','')));
  //   // }, 1000);

  //   if(this.state.currentLocation) {
  //     let cl = this.state.currentLocation.replace('/','');
  //     cl = '#'+cl;
  //     jQuery(".mscroll-y").mCustomScrollbar(
  //     'scrollTo',cl,{
  //       scrollInertia:1000
  //   });
  //   }
  // }

  componentDidMount() {
    // Scroll to the active route when the component mounts
    this.scrollToActiveRoute();
}

// componentDidUpdate(prevProps, prevState) {
//     // Scroll to the active route when the component updates
//     if (prevProps.location.pathname !== this.props.location.pathname) {
//         this.scrollToActiveRoute();
//     }
// }

scrollToActiveRoute() {
    // Find the active route element
    const activeRouteElement = document.querySelector('.left-navmenu .active');
    if (activeRouteElement) {
        // Scroll to the top of the active route element
        activeRouteElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
}

  routeedChange(parameter) {
    localStorage.setItem("customer_id", '');
    localStorage.setItem("search", '');
    localStorage.setItem("edit_customer_receive_payment", '');
    localStorage.setItem("cheque_id", '');
    localStorage.setItem("deposit_id", '');
    localStorage.setItem('batchid', 0)

    // No need routing here
    // this.props.history.push('/' + parameter);
  }
  componentWillMount() {
    console.log('left', this.props)
    var currentLocation = window.location.pathname;
    this.setState({
      currentLocation: currentLocation,
    });

    // if(currentLocation) {
    //   let cl = currentLocation.replace('/','');
    //   console.log("currentLocation........",currentLocation);
    //   cl = '#'+cl;
    //   console.log("cl........",cl);
    //   jQuery(".mscroll-y").mCustomScrollbar(
    //   'scrollTo',cl
    //   );
    // }

  }

  // routedChange(parameter){
  //     this.props.history.push('/'+parameter);
  //     window.scrollTo(0,0);

  // }

  routedChange(page_slug) {
    // this.props.pageSubmit(page_slug);
    // window.location.href = "/" + page_slug;
    this.props.history.push('/' + page_slug);
  }

  pageChange(page) {
    window.open("/" + page)
  }

  render() {
    let roleid = this.state.logged_role_id;
    //console.log('role_id', roleid);

    if (this.state.AllClientMail == 'yes') {
      return (
        <>
          <div className="left-navbar" >
            <div className="" >
              <div className="nav-brand text-center hidden-xs" onClick={() => window.location.href = "/landing_page"}>
                <img src="../../images/nav-brand-transparent.png" alt="Genie" />
              </div>
              <ul className="left-navmenu list-unstyled" style={{overflowY: 'scroll', height:'100vh', paddingBottom: '20rem'}}>

                <li id='client_selection'>{this.state.currentLocation == "/client_selection" &&
                  <Helmet>
                    <title>{`GBSC | Client selection`}</title>
                  </Helmet>}
                  <Link to="/client_selection" className={
                    this.state.currentLocation == "/client_selection"
                      ? "active"
                      : ""
                  }>Client Selection</Link>
                </li>

                <li id='user_inbox'>{this.state.currentLocation == "/user_inbox" &&
                  <Helmet>
                    <title>{`GBSC | Accountant Inbox`}</title>
                  </Helmet>}
                  <Link to="/user_inbox" className={
                    this.state.currentLocation == "/user_inbox"
                      ? "active"
                      : ""
                  }>Accountant Inbox</Link>
                </li>

                <li id='sent_items'>{this.state.currentLocation == "/sent_items" &&
                  <Helmet>
                    <title>{`GBSC | Sent Items`}</title>
                  </Helmet>}
                  <Link to="/sent_items" className={
                    this.state.currentLocation == "/sent_items"
                      ? "active"
                      : ""
                  }>Sent Items</Link>
                </li>

                <li id='reviewed_items'>{this.state.currentLocation == "/reviewed_items" &&
                  <Helmet>
                    <title>{`GBSC | Reviewed Items`}</title>
                  </Helmet>}
                  <Link to="/reviewed_items" className={
                    this.state.currentLocation == "/reviewed_items"
                      ? "active"
                      : ""
                  }>Reviewed</Link>
                </li>

                <li id='pending_items' onClick={()=>{
                  localStorage.setItem("vendor_bill", "");
                }}>{this.state.currentLocation == "/pending_items" &&
                  <Helmet>
                    <title>{`GBSC | Pending Items`}</title>
                  </Helmet>}
                  <Link to="/data_tagging/1/1" className={
                    this.state.currentLocation == "/pending_items"
                      ? "active"
                      : ""
                  }>Pending Documents</Link>
                </li>

                <li id='list_of_pending_items'>{this.state.currentLocation == "/list_of_pending_items" &&
                  <Helmet>
                    <title>{`GBSC | Pending Documents List`}</title>
                  </Helmet>}
                  <Link to="/list_of_pending_items" className={
                    this.state.currentLocation == "/list_of_pending_items"
                      ? "active"
                      : ""
                  }>Pending Documents List</Link>
                </li>
                
              </ul>
            </div>
          </div>
        </>
      )
    }
    if (this.state.AllClientMail !== 'yes') {

      return (
        <div>

          <div
            className="left-navbar" 
            data-user-id={this.state.logged_user_id}
            data-client-id={this.state.logged_client_id}
          >
            <div className="">
              <div className="nav-brand text-center hidden-xs" onClick={() => window.location.href = "/landing_page"}>
                <img src="../../images/nav-brand-transparent.png" alt="Genie" />
              </div>
              <ul className="left-navmenu list-unstyled" style={{overflowY: 'scroll', height:'100vh', paddingBottom: '6rem'}}>
                {this.state.role_permissions.includes(1) ? (
                  <li id='landing_page'>{this.state.currentLocation == "/landing_page" &&
                    <Helmet>
                      <title>{`GBSC | Dashboard`}</title>
                    </Helmet>}
                    <Link to="/landing_page" className={
                      this.state.currentLocation == "/landing_page"
                        ? "active"
                        : ""
                    }>Dashboard</Link>
                  </li>
                ) : (
                  <li id='landing_page'>{this.state.currentLocation == "/landing_page" &&
                    <Helmet>
                      <title>{`GBSC | Dashboard`}</title>
                    </Helmet>}
                    <Link to="/landing_page" className={
                      this.state.currentLocation == "/landing_page"
                        ? "active"
                        : ""
                    }>Dashboard</Link>
                  </li>
                )}

                {/* No need in left menu */}
                {/* {this.state.user_layer_role == 1 &&
                  this.state.logged_role_id == 1 &&
                  this.state.layer == 1 ? (
                  <li  id='add_roles'>{this.state.currentLocation == "/add_roles" &&
                    <Helmet>
                      <title>{`GBSC | Roles And Permissions`}</title>
                    </Helmet>}                    
                    <Link to="/add_roles"  className={
                      this.state.currentLocation == "/add_roles"
                        ? "active"
                        : ""
                    }>Roles And Permissions</Link>
                  </li>
                ) : (
                  ""
                )} */}

                {/* No need in left menu */}
                {/* {this.state.user_layer_role == 1 &&
                  this.state.logged_role_id == 1 &&
                  this.state.layer == 2 ? (
                  <li id='roles_permissions'>{this.state.currentLocation == "/roles_permissions" &&
                    <Helmet>
                      <title>{`GBSC | Roles And Permissions`}</title>
                    </Helmet>}
                    <Link to="/roles_permissions"  className={
                      this.state.currentLocation == "/roles_permissions"
                        ? "active"
                        : ""
                    }>Roles And Permissions</Link>
                  </li>
                ) : (
                  ""
                )} */}

                {/* No need in left menu */}
                {/* {this.state.user_layer_role == 1 &&
                  this.state.logged_role_id == 2 &&
                  this.state.layer == 1 ? (
                  <li id='add_roles'>{this.state.currentLocation == "/add_roles" &&
                    <Helmet>
                      <title>{`GBSC | Roles And Permissions`}</title>
                    </Helmet>}
                    <Link to="/add_roles"  className={
                      this.state.currentLocation == "/add_roles"
                        ? "active"
                        : ""
                    }>Roles And Permissions</Link>
                  </li>
                ) : (
                  ""
                )} */}

                {/* No need in left menu */}
                {/* {this.state.user_layer_role == 1 &&
                  this.state.logged_role_id == 2 &&
                  this.state.layer == 2 ? (
                  <li id='roles_permissions'>{this.state.currentLocation == "/roles_permissions" &&
                    <Helmet>
                      <title>{`GBSC | Roles And Permissions`}</title>
                    </Helmet>}
                    <Link to="/roles_permissions"  className={
                      this.state.currentLocation == "/roles_permissions"
                        ? "active"
                        : ""
                    }>Roles And Permissions</Link>
                  </li>
                ) : (
                  ""
                )} */}

                {/* <li>
                  <span className="item-head">Recently Used</span>
                </li> */}
                {/* 
                <li>
                 <a href="javascript:;"
                    // onClick={this.routedChange.bind(this, "enter_batch_transaction ")}
                    className={
                      this.state.currentLocation == "/enter_batch_transaction"
                        ? "active"
                        : ""
                    }
                  >
                    Enter Batch Transaction{" "}
                  </a>
                </li>
  
                <li>
                 <a href="javascript:;"
                    // onClick={this.routedChange.bind(this, "trial_balance ")}
                    className={
                      this.state.currentLocation == "/trial_balance"
                        ? "active"
                        : ""
                    }
                  >
                    Trial Balance{" "}
                  </a>
                </li>
                <li>
                 <a href="javascript:;"
                    // onClick={this.routedChange.bind(this, "journal ")}
                    className={
                      this.state.currentLocation == "/journal"
                        ? "active"
                        : ""
                    }
                  >
                    journal{" "}
                  </a>
                </li>
                <li>
                 <a href="javascript:;"
                    // onClick={this.routedChange.bind(this, "prior_sales_tax_return ")}
                    className={
                      this.state.currentLocation == "/prior_sales_tax_return"
                        ? "active"
                        : ""
                    }
                  >
                    Prior Sales Tax Return{" "}
                  </a>
                </li>
                <li>
                 <a href="javascript:;"
                    // onClick={this.routedChange.bind(this, "sequence_check ")}
                    className={
                      this.state.currentLocation == "/sequence_check"
                        ? "active"
                        : ""
                    }
                  >
                    Sequence check{" "}
                  </a>
                </li>
  
  
  
  
  
                <li>
                 <a href="javascript:;"
                    // onClick={this.routedChange.bind(this, "make_deposit ")}
                    className={
                      this.state.currentLocation == "/make_deposit"
                        ? "active"
                        : ""
                    }
                  >
                    Make Deposit{" "}
                  </a>
                </li>
                <li>
                 <a href="javascript:;"
                    // onClick={this.routedChange.bind(this, "add_bank_account")}
                    className={
                      this.state.currentLocation == "/add_bank_account"
                        ? "active"
                        : ""
                    }
                  >
                    Add Bank Account{" "}
                  </a>
                </li>
                <li>
                 <a href="javascript:;"
                    // onClick={this.routedChange.bind(this, "add_bank_detail")}
                    className={
                      this.state.currentLocation == "/add_bank_detail"
                        ? "active"
                        : ""
                    }
                  >
                    Add Bank Details{" "}
                  </a>
                </li>
                <li>
                 <a href="javascript:;"
                    // onClick={this.routedChange.bind(this, "add_bank_internet")}
                    className={
                      this.state.currentLocation == "/add_bank_internet"
                        ? "active"
                        : ""
                    }
                  >
                    Add Bank Internet{" "}
                  </a>
                </li>
                <li>
                 <a href="javascript:;"
                    // onClick={this.routedChange.bind(this, "add_bank_login")}
                    className={
                      this.state.currentLocation == "/add_bank_login"
                        ? "active"
                        : ""
                    }
                  >
                    Add Bank Login{" "}
                  </a>
                </li>
                
                */}

                {this.state.role_permissions.includes(14) ? (
                  <li>
                    <span className="item-head">Create Company</span>
                  </li>
                ) : (
                  ""
                )}

                {this.state.role_permissions.includes(14) ? (
                  <li id='create_invoice'>{this.state.currentLocation == "/create_invoice" &&
                    <Helmet>
                      <title>{`GBSC | Create Invoice`}</title>
                    </Helmet>}
                    <Link to="/create_invoice" className={
                      this.state.currentLocation == "/create_invoice"
                        ? "active"
                        : ""
                    }>Create Invoice</Link>
                  </li>
                ) : (
                  ""
                )}

                {this.state.role_permissions.includes(14) ? (
                  <li id='create_creditmemo'>
                    {this.state.currentLocation == "/create_creditmemo" &&
                      <Helmet>
                        <title>{`GBSC | Create Credit memo`}</title>
                      </Helmet>}
                    <Link to="/create_creditmemo" onClick={this.routeedChange.bind(this, 'create_creditmemo')} className={
                      this.state.currentLocation == "/create_creditmemo"
                        ? "active"
                        : ""
                    }>Create Credit memo{" "}</Link>
                  </li>
                ) : (
                  ""
                )}

                {this.state.role_permissions.includes(14) ? (
                  <li id='create_estimate'>
                    {this.state.currentLocation == "/create_estimate" &&
                      <Helmet>
                        <title>{`GBSC | Create Quotation`}</title>
                      </Helmet>
                    }
                    <Link to="/create_estimate" onClick={this.routeedChange.bind(this, 'create_estimate')} className={
                      this.state.currentLocation == "/create_estimate"
                        ? "active"
                        : ""
                    }> Create Quotation{" "}</Link>
                  </li>
                ) : (
                  ""
                )}

                {this.state.role_permissions.includes(14) ? (
                  <li id='create_salesorder'>
                    {this.state.currentLocation == "/create_salesorder" &&
                      <Helmet>
                        <title>{`GBSC | Create Sales Order`}</title>
                      </Helmet>
                    }
                    <Link to="/create_salesorder" onClick={this.routeedChange.bind(this, 'create_salesorder')} className={
                      this.state.currentLocation == "/create_salesorder"
                        ? "active"
                        : ""
                    }> Create Sales Order{" "}</Link>
                  </li>
                ) : (
                  ""
                )}

                {this.state.role_permissions.includes(14) ? (
                  <li id='create_purchaseorder'>{this.state.currentLocation == "/create_purchaseorder" &&
                    <Helmet>
                      <title>{`GBSC | Create Purchase order`}</title>
                    </Helmet>}
                    <Link to="/create_purchaseorder" onClick={this.routeedChange.bind(this, 'create_purchaseorder')} className={
                      this.state.currentLocation == "/create_purchaseorder"
                        ? "active"
                        : ""
                    }>Create Purchase order</Link>
                  </li>
                ) : (
                  ""
                )}

                {this.state.role_permissions.includes(14) ? (
                  <li id='invoice_templates'>{this.state.currentLocation == "/invoice_templates" &&
                    <Helmet>
                      <title>{`GBSC | Templates`}</title>
                    </Helmet>}
                    <Link to="/invoice_templates" className={
                      this.state.currentLocation == "/invoice_templates"
                        ? "active"
                        : ""
                    }>Invoice Templates</Link>
                  </li>
                ) : (
                  ""
                )}

                {/* Dont delete in any case-used for edit invoice template purpose */}
                {/* {this.state.role_permissions.includes(14) ? (
                  <li id='template_edit_page'>{this.state.currentLocation == "/template_edit_page" &&
                    <Helmet>
                      <title>{`GBSC | Edit Template`}</title>
                    </Helmet>} */}
                {/* <a href="/template_edit_page"
                      // onClick={this.routedChange.bind(this, "template_edit_page")}
                      className={
                        this.state.currentLocation == "/template_edit_page"
                          ? "active"
                          : ""
                      }
                    >
                      Edit Template 1
                    </a> */}
                {/* <Link to="/template_edit_page"  className={
                      this.state.currentLocation == "/template_edit_page"
                        ? "active"
                        : ""
                    }>Edit Template 1</Link>
                  </li>
                ) : (
                  ""
                )} */}

                {/* Don't delete in any case- used for edit invoice tempalte */}
                {/* {this.state.role_permissions.includes(14) ? (
                  <li id='template_edit_page_2'>{this.state.currentLocation == "/template_edit_page_2" &&
                    <Helmet>
                      <title>{`GBSC | Edit Template`}</title>
                    </Helmet>} */}
                {/* <a href="/template_edit_page_2"
                      // onClick={this.routedChange.bind(this, "template_edit_page")}
                      className={
                        this.state.currentLocation == "/template_edit_page_2"
                          ? "active"
                          : ""
                      }
                    >
                      Edit Template 2
                    </a> */}
                {/* <Link to="/template_edit_page_2"  className={
                      this.state.currentLocation == "/template_edit_page_2"
                        ? "active"
                        : ""
                    }>Edit Template 2</Link>
                  </li>
                ) : (
                  ""
                )} */}

                {/* Dont delete in any case - Used for edit template purpose*/}
                {/* {this.state.role_permissions.includes(14) ? (
                  <li  id='template_edit_page_3'>{this.state.currentLocation == "/template_edit_page_3" &&
                    <Helmet>
                      <title>{`GBSC | Edit Template`}</title>
                    </Helmet>} */}
                {/* <a href="/template_edit_page_3"
                      // onClick={this.routedChange.bind(this, "template_edit_page")}
                      className={
                        this.state.currentLocation == "/template_edit_page_3"
                          ? "active"
                          : ""
                      }
                    >
                      Edit Template 3
                    </a> */}
                {/* <Link to="/template_edit_page_3"  className={
                      this.state.currentLocation == "/template_edit_page_3"
                        ? "active"
                        : ""
                    }>Edit Template 3</Link>
                  </li>
                ) : (
                  ""
                )} */}





                {this.state.role_permissions.includes(26) ? (
                  <li>
                    <span className="item-head">Group Accounting</span>
                  </li>
                ) : null}
                {this.state.role_permissions.includes(26) ? (
                  <li id='add_business_contact'>{this.state.currentLocation == "/add_business_contact" &&
                    <Helmet>
                      <title>{`GBSC | Add Business Contact`}</title>
                    </Helmet>}
                    <Link to="/add_business_contact" className={
                      this.state.currentLocation == "/add_business_contact"
                        ? "active"
                        : ""
                    }>Add Business Contact</Link>
                  </li>
                ) : (
                  ""
                )}{" "}
                {this.state.role_permissions.includes(26) ? (
                  <li id='requests'>{this.state.currentLocation == "/requests" &&
                    <Helmet>
                      <title>{`GBSC | Requests`}</title>
                    </Helmet>}
                    <Link to="/requests" className={
                      this.state.currentLocation == "/requests"
                        ? "active"
                        : ""
                    }>Requests</Link>
                  </li>
                ) : (
                  ""
                )}

                {this.state.role_permissions.includes(17) ? (
                  <li>
                    <span className="item-head">List</span>
                  </li>
                ) : (
                  ""
                )}

                {this.state.role_permissions.includes(17) ? (
                  <li id='customers-list'>{this.state.currentLocation == "/customers-list" &&
                    <Helmet>
                      <title>{`GBSC | Customers list`}</title>
                    </Helmet>}
                    <Link to="/customers-list" className={
                      this.state.currentLocation == "/customers-list"
                        ? "active"
                        : ""
                    }>Customers list</Link>
                  </li>
                ) : null}
                {
                  this.state.role_permissions.includes(20) ? (
                    <li id='Vendors_list'>{this.state.currentLocation == "/Vendors_list" &&
                      <Helmet>
                        <title>{`GBSC | Vendor list`}</title>
                      </Helmet>}
                      <Link to="/Vendors_list" className={
                        this.state.currentLocation == "/Vendors_list"
                          ? "active"
                          : ""
                      }>Vendor list</Link>
                    </li>
                  ) : (
                    ""
                  )
                }
                {this.state.role_permissions.includes(23) ? (
                  <li id='employee-list'>{this.state.currentLocation == "/" &&
                    <Helmet>
                      <title>{`GBSC |  Employee List`}</title>
                    </Helmet>}
                    <Link to="/employee-list" className={
                      this.state.currentLocation == "/employee-list"
                        ? "active"
                        : ""
                    }> Employee List</Link>
                  </li>
                ) : (
                  ""
                )}

                {this.state.role_permissions.includes(14) ? (
                  <li id='all_lists'>{this.state.currentLocation == "/all_lists" &&
                    <Helmet>
                      <title>{`GBSC | All Lists`}</title>
                    </Helmet>}
                    <Link to="/all_lists" className={
                      this.state.currentLocation == "/all_lists"
                        ? "active"
                        : ""
                    }>All Lists (list of transactions)</Link>
                  </li>
                ) : (
                  ""
                )}

                {this.state.role_permissions.includes(20) ? (
                  <li>
                    <span className="item-head">Bank</span>
                  </li>
                ) : (
                  ""
                )}

                {this.state.role_permissions.includes(20) ? (
                  <li id='vendor_bill_payment'>{this.state.currentLocation == "/vendor_bill_payment" &&
                    <Helmet>
                      <title>{`GBSC | Vendor Bill Payment`}</title>
                    </Helmet>}
                    <Link to="/vendor_bill_payment" onClick={this.routeedChange.bind(
                      this,
                      "vendor_bill_payment"
                    )} className={
                      this.state.currentLocation == "/vendor_bill_payment"
                        ? "active"
                        : ""
                    }>Vendor Bill Payment</Link>
                  </li>
                ) : (
                  ""
                )}

                {this.state.role_permissions.includes(17) ? (
                  <li id='Customer_receive_payment'>{this.state.currentLocation == "/Customer_receive_payment" &&
                    <Helmet>
                      <title>{`GBSC | Customer Receive Payment`}</title>
                    </Helmet>}
                    <Link to="/Customer_receive_payment" onClick={this.routeedChange.bind(
                      this,
                      "Customer_receive_payment"
                    )} className={
                      this.state.currentLocation == "/Customer_receive_payment"
                        ? "active"
                        : ""
                    }>Customer Receive Payment</Link>
                  </li>
                ) : (
                  ""
                )}

                {this.state.role_permissions.includes(17) ? (
                  <li id='transfer_funds'>{this.state.currentLocation == "/transfer_funds" &&
                    <Helmet>
                      <title>{`GBSC | Transfer Funds`}</title>
                    </Helmet>}
                    <Link to="/transfer_funds" className={
                      this.state.currentLocation == "/transfer_funds"
                        ? "active"
                        : ""
                    }>Transfer Funds</Link>
                  </li>
                ) : (
                  ""
                )}

                {this.state.role_permissions.includes(17) ? (
                  <li id='make_deposit'>{this.state.currentLocation == "/make_deposit" &&
                    <Helmet>
                      <title>{`GBSC | Make Deposit`}</title>
                    </Helmet>}
                    <Link to="/make_deposit" onClick={this.routeedChange.bind(
                      this,
                      "make_deposit"
                    )} className={
                      this.state.currentLocation == "/make_deposit"
                        ? "active"
                        : ""
                    }>Make Deposit</Link>
                  </li>
                ) : (
                  ""
                )}

                {this.state.role_permissions.includes(17) ? (
                  <li id='write_cheque'>{this.state.currentLocation == "/write_cheque" &&
                    <Helmet>
                      <title>{`GBSC | Write Cheque`}</title>
                    </Helmet>}
                    <Link to="/write_cheque" onClick={this.routeedChange.bind(
                      this,
                      "write_cheque"
                    )} className={
                      this.state.currentLocation == "/write_cheque"
                        ? "active"
                        : ""
                    }>Write Cheque</Link>
                  </li>
                ) : (
                  ""
                )}

                {this.state.role_permissions.includes(26) ? (
                  <li id='bank_reconcile_match'>
                    {this.state.currentLocation == "/bank_reconcile_match" &&
                      <Helmet>
                        <title>{`GBSC | Bank Reconcile Match`}</title>
                      </Helmet>
                    }
                    <Link to="/bank_reconcile_match" className={
                      this.state.currentLocation == "/bank_reconcile_match"
                        ? "active"
                        : ""
                    } onClick={()=> sessionStorage.removeItem('currentBank')}>Bank Reconcile Match{" "}</Link>
                  </li>
                ) : (
                  ""
                )}
                {this.state.role_permissions.includes(26) ? (
                  <li id='bank_reconcile_summary'>
                    {this.state.currentLocation == "/bank_reconcile_summary" &&
                      <Helmet>
                        <title>{`GBSC | Bank Reconcile Report`}</title>
                      </Helmet>
                    }
                    <Link to="/bank_reconcile_summary" className={
                      this.state.currentLocation == "/bank_reconcile_summary"
                        ? "active"
                        : ""
                    } onClick={()=> sessionStorage.removeItem('currentBank')}>Bank Reconcile Report{" "}</Link>
                  </li>
                ) : (
                  ""
                )}

                {this.state.role_permissions.includes(26) ? (
                  <li id='create-bank-rule'>
                    {this.state.currentLocation == "/create-bank-rule" &&
                      <Helmet>
                        <title>{`GBSC | Create Bank Rule`}</title>
                      </Helmet>
                    }
                    <Link to="/create-bank-rule" className={
                      this.state.currentLocation == "/create-bank-rule"
                        ? "active"
                        : ""
                    }>Create Bank Rule{" "}</Link>
                  </li>
                ) : (
                  ""
                )}

              {this.state.role_permissions.includes(26) ? (
                  <li id='bank_rule_list'>
                    {this.state.currentLocation == "/bank_rule_list" &&
                      <Helmet>
                        <title>{`GBSC | Bank Rule List`}</title>
                      </Helmet>
                    }
                    <Link to="/bank_rule_list" className={
                      this.state.currentLocation == "/bank_rule_list"
                        ? "active"
                        : ""
                    }>Bank Rule List{" "}</Link>
                  </li>
                ) : (
                  ""
                )}

                {this.state.role_permissions.includes(14) ? (
                  <li>
                    <span className="item-head">Batch Posting</span>
                  </li>
                ) : (
                  ""
                )}

                {this.state.role_permissions.includes(26) ? (
                  <li id='batch_transaction_list'>
                    {this.state.currentLocation == "/batch_transaction_list" &&
                      <Helmet>
                        <title>{`GBSC | Batch Transaction List`}</title>
                      </Helmet>
                    }
                    <Link to="/batch_transaction_list" onClick={this.routeedChange.bind(
                      this,
                      "batch_transaction_list"
                    )} className={
                      this.state.currentLocation == "/batch_transaction_list"
                        ? "active"
                        : ""
                    }> Batch Transaction List{" "}</Link>
                  </li>
                ) : (
                  ""
                )}

                {this.state.role_permissions.includes(26) ? (
                  <li id='enter_batch_transaction'>
                    {this.state.currentLocation == "/enter_batch_transaction" &&
                      <Helmet>
                        <title>{`GBSC | Enter Batch Transaction`}</title>
                      </Helmet>
                    }
                    <Link to="/enter_batch_transaction" className={
                      this.state.currentLocation == "/enter_batch_transaction"
                        ? "active"
                        : ""
                    }>Enter Batch Transaction{" "}</Link>
                  </li>
                ) : (
                  ""
                )}

                {this.state.role_permissions.includes(26) ? (
                  <li id='client_data_review'>
                    {this.state.currentLocation == "/client_data_review" &&
                      <Helmet>
                        <title>{`GBSC | Client Data Review`}</title>
                      </Helmet>
                    }
                    <Link to="/client_data_review" className={
                      this.state.currentLocation == "/client_data_review"
                        ? "active"
                        : ""
                    }>Client Data Review{" "}</Link>
                  </li>
                ) : (
                  ""
                )}


                {/* 
                {this.state.role_permissions.includes(26) ? (
                  <li>
                   <a href="javascript:;"
                      // onClick={this.routedChange.bind(this, 'bank-reconcile-match')}
                      className={
                        this.state.currentLocation == '/bank-reconcile-match'
                          ? 'active'
                          : ''
                      }
                    >
  Reconciliation Items                  </a>
                  </li>
                ) : (
                  ''
                )} */}
                {/* 
  {this.state.role_permissions.includes(26) ? (
                  <li>
                   <a href="javascript:;"
                      // onClick={this.routedChange.bind(this, 'bank-reconcile-create')}
                      className={
                        this.state.currentLocation == '/bank-reconcile-create'
                          ? 'active'
                          : ''
                      }
                    >
  Reconcile Create Match                  </a>
                  </li>
                ) : (
                  ''
                )} */}
                {/* <li><a
                //  onClick={this.routedChange.bind(this, 'roles_permissions')}
                 className={
                   this.state.currentLocation == '/roles_permissions'
                     ? 'active'
                     : ''
                 }
                href='javascript:;' className='has-sub'>
                      Roles And Permissions
                    </a></li> */}


                {/* {this.state.role_permissions.includes(14) ? (
                  <li>{this.state.currentLocation == "/add_business_contact" &&
                    <Helmet>
                      <title>{`GBSC | Group Accounting`}</title>
                    </Helmet>}
                    <a href="/add_business_contact"
                      className={
                        this.state.currentLocation == "/add_business_contact"
                          ? "active"
                          : ""
                      }
                    >
                      Group Accounting
                    </a>
                  </li>
                ) : (
                  ""
                )} */}
                {/* {this.state.role_permissions.includes(14) ? (
                  <li>{this.state.currentLocation == "/invoice_templates" &&
                    <Helmet>
                      <title>{`GBSC | Invoice templates`}</title>
                    </Helmet>}
                   <a href="javascript:;"
                      // onClick={this.routedChange.bind(this, "invoice_templates")}
                      className={
                        this.state.currentLocation == "/invoice_templates"
                          ? "active"
                          : ""
                      }
                    >
                      Invoice templates
                    </a>
                  </li>
                ) : (
                    ""
                  )} */}




                {/* {this.state.role_permissions.includes(17) ? (
                  <li>
                    <span className='item-head'>Accounting</span>
                  </li>
                ) : (
                  ''
                )} */}
                {this.state.role_permissions.includes(1) ? (
                  <li>
                    <span className="item-head">Documents</span>
                  </li>
                ) : (
                  ""
                )}
                {this.state.role_permissions.includes(29) ? (
                  <li id='user_inbox'>{this.state.currentLocation == "/user_inbox" &&
                    <Helmet>
                      <title>{`GBSC | Accountant Inbox`}</title>
                    </Helmet>}
                    <Link to="/user_inbox" className={
                      this.state.currentLocation == "/user_inbox"
                        ? "active"
                        : ""
                    }>Accountant Inbox</Link>
                  </li>
                ) : (
                  ""
                )}
                {this.state.role_permissions.includes(8) ? (
                  <li id='inbox'>{this.state.currentLocation == "/inbox" &&
                    <Helmet>
                      <title>{`GBSC | Inbox`}</title>
                    </Helmet>}
                    <Link to="/inbox" className={
                      this.state.currentLocation == "/inbox"
                        ? "active"
                        : ""
                    }>Inbox</Link>
                  </li>
                ) : (
                  ""
                )}
                {this.state.role_permissions.includes(8) || this.state.role_permissions.includes(29) ? (
                  <li id='sent_items'>{this.state.currentLocation == "/sent_items" &&
                    <Helmet>
                      <title>{`GBSC | Sent Items`}</title>
                    </Helmet>}
                    <Link to="/sent_items" className={
                      this.state.currentLocation == "/sent_items"
                        ? "active"
                        : ""
                    }>  Sent Items</Link>
                  </li>
                ) : (
                  ""
                )}
                {this.state.role_permissions.includes(8) ||
                  this.state.role_permissions.includes(29) ? (
                  <li id='reviewed_items'>{this.state.currentLocation == "/reviewed_items" &&
                    <Helmet>
                      <title>{`GBSC | Reviewed Items`}</title>
                    </Helmet>}
                    <Link to="/reviewed_items" className={
                      this.state.currentLocation == "/reviewed_items"
                        ? "active"
                        : ""
                    }>Reviewed</Link>
                  </li>
                ) : (
                  ""
                )}

                {this.state.role_permissions.includes(8) ||
                  this.state.role_permissions.includes(29) ? (
                    <li id='pending_items'  onClick={()=>{
                      localStorage.setItem("vendor_bill", "");
                    }}>{this.state.currentLocation == "/pending_items" &&
                  <Helmet>
                    <title>{`GBSC | Pending Items`}</title>
                  </Helmet>}
                  <Link to="/data_tagging/1/1" className={
                    this.state.currentLocation == "/pending_items"
                      ? "active"
                      : ""
                  }>Pending Documents</Link>
                </li>
                  ) : (
                    ""
                  )}

            {this.state.role_permissions.includes(8) ||
                  this.state.role_permissions.includes(29) ? (
                <li id='list_of_pending_items'>{this.state.currentLocation == "/list_of_pending_items" &&
                  <Helmet>
                    <title>{`GBSC | Pending Documents List`}</title>
                  </Helmet>}
                  <Link to="/list_of_pending_items" className={
                    this.state.currentLocation == "/list_of_pending_items"
                      ? "active"
                      : ""
                  }>Pending Documents List</Link>
                </li>
              ) : (
                    ""
                  )}
  
                {this.state.role_permissions.includes(11) ? (
                  <li>
                    <span className="item-head">Accountant</span>
                  </li>
                ) : null}
                {/* {parseInt(this.state.layer) === 1 ? (
                  <li>{this.state.currentLocation == "/profile" &&
                    <Helmet>
                      <title>{`GBSC | Profile & Settings`}</title>
                    </Helmet>}
                    <a
                      //  href="/profile"
                      onClick={this.pageChange.bind(this, "profile")}
                      className={
                        this.state.currentLocation == "/profile" ? "active" : ""
                      }
                    >
                      Profile&settings
                    </a>
                  </li>
                ) : (
                  <li>{this.state.currentLocation == "/user_profile" &&
                    <Helmet>
                      <title>{`GBSC | Profile & Settings`}</title>
                    </Helmet>}
                    <a
                      //  href="/user_profile"
                      onClick={this.pageChange.bind(this, "user_profile")}
                      className={
                        this.state.currentLocation == "/user_profile"
                          ? "active"
                          : ""
                      }
                    >
                      Profile&settings
                    </a>
                  </li>
                )} */}
                {this.state.role_permissions.includes(11) ? (
                  <li id='all-report'>{this.state.currentLocation == "/all-report" &&
                    <Helmet>
                      <title>{`GBSC | All Reports`}</title>
                    </Helmet>}
                    <Link to="/all-report" className={
                      this.state.currentLocation == "/all-report"
                        ? "active"
                        : ""
                    }>All Reports</Link>
                  </li>
                ) : (
                  ""
                )}

                {this.state.role_permissions.includes(11) ? (
                  <li id='trial_balance'>{this.state.currentLocation == "/trial_balance" &&
                    <Helmet>
                      <title>{`GBSC | Trial Balance`}</title>
                    </Helmet>}
                    <Link to="/trial_balance" className={
                      this.state.currentLocation == "/trial_balance"
                        ? "active"
                        : ""
                    }>Trial Balance</Link>
                  </li>
                ) : (
                  ""
                )}

                {this.state.role_permissions.includes(11) ? (
                  <li id='sequence_check'>{this.state.currentLocation == "/sequence_check" &&
                    <Helmet>
                      <title>{`GBSC | Sequence Check`}</title>
                    </Helmet>}
                    <Link to="/sequence_check" className={
                      this.state.currentLocation == "/sequence_check"
                        ? "active"
                        : ""
                    }>Sequence Check</Link>
                  </li>
                ) : (
                  ""
                )}
                {this.state.role_permissions.includes(11) ? (
                  <li id='sequence_check'>{this.state.currentLocation == "/journal_report" &&
                    <Helmet>
                      <title>{`GBSC | Journal Report`}</title>
                    </Helmet>}
                    <Link to="/journal_report" className={
                      this.state.currentLocation == "/journal_report"
                        ? "active"
                        : ""
                    }>Journal Report</Link>
                  </li>
                ) : (
                  ""
                )}

                {this.state.role_permissions.includes(11) ? (
                  <li id='profit_loss_report'>{this.state.currentLocation == "/profit_loss_report" &&
                    <Helmet>
                      <title>{`GBSC | Profit & Loss`}</title>
                    </Helmet>}
                    <Link to="/profit_loss_report" className={
                      this.state.currentLocation == "/profit_loss_report"
                        ? "active"
                        : ""
                    }> Profit & Loss</Link>
                  </li>
                ) : (
                  ""
                )}
                {this.state.role_permissions.includes(11) ? (
                  <li id='balance_sheet'>{this.state.currentLocation == "/balance_sheet" &&
                    <Helmet>
                      <title>{`GBSC | Balance Sheet`}</title>
                    </Helmet>}
                    <Link to="/balance_sheet" className={
                      this.state.currentLocation == "/balance_sheet"
                        ? "active"
                        : ""
                    }>Balance Sheet</Link>
                  </li>
                ) : (
                  ""
                )}{" "}
                {this.state.role_permissions.includes(11) ? (
                  <li id='general_ledger'>{this.state.currentLocation == "/general_ledger" &&
                    <Helmet>
                      <title>{`GBSC | General Ledger`}</title>
                    </Helmet>}
                    <Link to="/general_ledger" className={
                      this.state.currentLocation == "/general_ledger"
                        ? "active"
                        : ""
                    }> General Ledger{" "}</Link>
                  </li>
                ) : (
                  ""
                )}

                {/* setting side bar */}

                {this.state.role_permissions.includes(11) ? (
                  <li id='find_recode'>{this.state.currentLocation == "/find_recode" &&
                    <Helmet>
                      <title>{`GBSC | Find And Recode `}</title>
                    </Helmet>}
                    <Link to="/find_recode" onClick={this.routeedChange.bind(this, 'find_recode')} className={
                      this.state.currentLocation == "/find_recode"
                        ? "active"
                        : ""
                    }> Find &amp; Recode{" "}</Link>
                  </li>
                ) : (
                  ""
                )}

                {this.state.role_permissions.includes(11) ? (
                  <li id='home_currency_adjustment'>{this.state.currentLocation == "/home_currency_adjustment" &&
                    <Helmet>
                      <title>{`GBSC | Home Currency Adjustment`}</title>
                    </Helmet>}
                    <Link to="/home_currency_adjustment" target="_blank" className={
                      this.state.currentLocation == "/home_currency_adjustment"
                        ? "active"
                        : ""
                    }> Home Currency Adjustment{" "}</Link>
                  </li>
                ) : (
                  ""
                )}

                {this.state.role_permissions.includes(11) ? (
                  <li id='to_do'>{this.state.currentLocation == "/to_do" &&
                    <Helmet>
                      <title>{`GBSC | Todo`}</title>
                    </Helmet>}
                    <Link to="/to_do" target="_blank" className={
                      this.state.currentLocation == "/to_do"
                        ? "active"
                        : ""
                    }> Todo{" "}</Link>
                  </li>
                ) : (
                  ""
                )}


                {this.state.role_permissions.includes(11) ? (
                  <li id='manual_journal'>{this.state.currentLocation == "/manual_journal" &&
                    <Helmet>
                      <title>{`GBSC | Manual Journal`}</title>
                    </Helmet>}
                    <Link to="/manual_journal" target="_blank" className={
                      this.state.currentLocation == "/manual_journal"
                        ? "active"
                        : ""
                    }> Manual Journal{" "}</Link>
                  </li>
                ) : (
                  ""
                )}


                {this.state.role_permissions.includes(11) ? (
                  <li id='fixed_assests'>{this.state.currentLocation == "/fixed_assests" &&
                    <Helmet>
                      <title>{`GBSC | Fixed Assets & Settings`}</title>
                    </Helmet>}
                    <Link to="/fixed_assests" target="_blank" className={
                      this.state.currentLocation == "/fixed_assests"
                        ? "active"
                        : ""
                    }>Fixed Assets & Settings{" "}</Link>
                  </li>
                ) : (
                  ""
                )}


                {this.state.role_permissions.includes(11) ? (
                  <li id='chart'>{this.state.currentLocation == "/chart" &&
                    <Helmet>
                      <title>{`GBSC | Chart of Accounts`}</title>
                    </Helmet>}
                    <Link to="/chart" target="_blank" className={
                      this.state.currentLocation == "/chart"
                        ? "active"
                        : ""
                    }> Chart of Accounts{" "}</Link>
                  </li>
                ) : (
                  ""
                )}


                {this.state.role_permissions.includes(11) ? (
                  <li id='summary_added_type_items'>{this.state.currentLocation == "/summary_added_type_items" &&
                    <Helmet>
                      <title>{`GBSC | Summary of Added Items`}</title>
                    </Helmet>}
                    <Link to="/summary_added_type_items" target="_blank" className={
                      this.state.currentLocation == "/summary_added_type_items"
                        ? "active"
                        : ""
                    }>Summary of Added Items{" "}</Link>
                  </li>
                ) : (
                  ""
                )}


                {this.state.role_permissions.includes(11) ? (
                  <li id='history'>{this.state.currentLocation == "/history" &&
                    <Helmet>
                      <title>{`GBSC | History & Notes`}</title>
                    </Helmet>}
                    <Link to="/history" target="_blank" className={
                      this.state.currentLocation == "/history"
                        ? "active"
                        : ""
                    }> History & Notes{" "}</Link>
                  </li>
                ) : (
                  ""
                )}


                {/* settings side bar */}


                {this.state.role_permissions.includes(11) ? (
                  <li>
                    <span className="item-head">Customer</span>
                  </li>
                ) : null}
                {this.state.role_permissions.includes(11) ? (
                  <li id='open_invoice'>{this.state.currentLocation == "/open_invoice" &&
                    <Helmet>
                      <title>{`GBSC | Customer Open Invoice`}</title>
                    </Helmet>}
                    <Link to="/open_invoice" className={
                      this.state.currentLocation == "/open_invoice"
                        ? "active"
                        : ""
                    }> Customer Open Invoice</Link>
                  </li>
                ) : (
                  ""
                )}
                {this.state.role_permissions.includes(11) ? (
                  <li id='ar_aging_summary'>{this.state.currentLocation == "/ar_aging_summary" &&
                    <Helmet>
                      <title>{`GBSC | Customer Aging Summary`}</title>
                    </Helmet>}
                    <Link to="/ar_aging_summary" className={
                      this.state.currentLocation == "/ar_aging_summary"
                        ? "active"
                        : ""
                    }> Customer Aging Summary</Link>
                  </li>
                ) : (
                  ""
                )}
                {this.state.role_permissions.includes(11) ? (
                  <li id='customer_module'>{this.state.currentLocation == "/customer_module" &&
                    <Helmet>
                      <title>{`GBSC | Customer Balance Detail`}</title>
                    </Helmet>}
                    <Link to="/customer_module" className={
                      this.state.currentLocation == "/customer_module"
                        ? "active"
                        : ""
                    }>Customer Balance Detail</Link>
                  </li>
                ) : (
                  ""
                )}
                {this.state.role_permissions.includes(11) ? (
                  <li id='customer_balance_summary'>{this.state.currentLocation == "/customer_balance_summary" &&
                    <Helmet>
                      <title>{`GBSC | Customer Balance Summary`}</title>
                    </Helmet>}
                    <Link to="/customer_balance_summary" className={
                      this.state.currentLocation == "/customer_balance_summary"
                        ? "active"
                        : ""
                    }>Customer Balance Summary</Link>
                  </li>
                ) : (
                  ""
                )}
                {/* {roleid === "1" ? (
                      // <li><a onClick={this.routedChange.bind(this,'customer_balance_detail')} className={(this.state.currentLocation == '/customer_balance_detail' ? 'active' : '')}>Customer Balance Detail</a></li>
                      ) : ''} */}
                {this.state.role_permissions.includes(11) ? (
                  <li>
                    <span className="item-head">Vendor</span>
                  </li>
                ) : null}
                {this.state.role_permissions.includes(11) ? (
                  <li id='vendor_balance_detail'>{this.state.currentLocation == "/vendor_balance_detail" &&
                    <Helmet>
                      <title>{`GBSC | Vendor Balance Detail`}</title>
                    </Helmet>}
                    <Link to="/vendor_balance_detail" className={
                      this.state.currentLocation == "/vendor_balance_detail"
                        ? "active"
                        : ""
                    }>Vendor Balance Details</Link>
                  </li>
                ) : (
                  ""
                )}
                {this.state.role_permissions.includes(11) ? (
                  <li id='vendor_balance_summary'>{this.state.currentLocation == "/vendor_balance_summary" &&
                    <Helmet>
                      <title>{`GBSC | Vendor Balance Summary`}</title>
                    </Helmet>}
                    <Link to="/vendor_balance_summary" className={
                      this.state.currentLocation == "/vendor_balance_summary"
                        ? "active"
                        : ""
                    }>Vendor Balance Summary</Link>
                  </li>
                ) : (
                  ""
                )}
                {this.state.role_permissions.includes(11) ? (
                  <li id='ap_aging_summary'>{this.state.currentLocation == "/ap_aging_summary" &&
                    <Helmet>
                      <title>{`GBSC | Vendor Aging Summary`}</title>
                    </Helmet>}
                    <Link to="/ap_aging_summary" className={
                      this.state.currentLocation == "/ap_aging_summary"
                        ? "active"
                        : ""
                    }>Vendor Aging Summary</Link>
                  </li>
                ) : (
                  ""
                )}
                {this.state.role_permissions.includes(11) ? (
                  <li id='unpaid_bills'>{this.state.currentLocation == "/unpaid_bills" &&
                    <Helmet>
                      <title>{`GBSC | Vendor Unpaid Bills`}</title>
                    </Helmet>}
                    <Link to="/unpaid_bills" className={
                      this.state.currentLocation == "/unpaid_bills"
                        ? "active"
                        : ""
                    }>Vendor Unpaid Bills</Link>
                  </li>
                ) : (
                  ""
                )}
                {this.state.role_permissions.includes(35) ? (
                  <li>
                    <span className="item-head">Taxes</span>
                  </li>
                ) : null}
                {this.state.role_permissions.includes(35) ? (
                  <li id='GST_detail_report'>{this.state.currentLocation == "/GST_detail_report" &&
                    <Helmet>
                      <title>{`GBSC | GST Detail Report`}</title>
                    </Helmet>}
                    <Link to="/GST_detail_report" className={
                      this.state.currentLocation == "/GST_detail_report"
                        ? "active"
                        : ""
                    }>GST Detail Report</Link>
                  </li>
                ) : (
                  ""
                )}{" "}
                {this.state.role_permissions.includes(35) ? (
                  <li id='Gst_report_summary'>{this.state.currentLocation == "/Gst_report_summary" &&
                    <Helmet>
                      <title>{`GBSC | GST Report Summary`}</title>
                    </Helmet>}
                    <Link to="/Gst_report_summary" className={
                      this.state.currentLocation == "/Gst_report_summary"
                        ? "active"
                        : ""
                    }> GST Report Summary</Link>
                  </li>
                ) : (
                  ""
                )}

                {this.state.role_permissions.includes(35) ? (
                  <li id='prior_sales_tax_return' style={{marginBottom: '40rem'}}>{this.state.currentLocation == "/prior_sales_tax_return" &&
                    <Helmet>
                      <title>{`GBSC | Prior Sales Tax Return`}</title>
                    </Helmet>}
                    <Link to="/prior_sales_tax_return" className={
                      this.state.currentLocation == "/prior_sales_tax_return"
                        ? "active"
                        : ""
                    }>Prior Sales Tax Return</Link>
                  </li>
                ) : (
                  ""
                )}






                {/* {this.state.role_permissions.includes(17) ? (
                  <li>
                    <a href='javascript:;'>General Ledger</a>
                  </li>
                ) : (
                  ''
                )}
  
                {this.state.role_permissions.includes(17) ? (
                  <li>
                    <a href='javascript:;'>Balance Sheet</a>
                  </li>
                ) : (
                  ''
                )}
  
                {this.state.role_permissions.includes(17) ? (
                  <li>
                    <a href='javascript:;'>Customers</a>
                  </li>
                ) : (
                  ''
                )} */}
                {/* {this.state.role_permissions.includes(17) ? (
                  <li>
                    <span className='item-head'>Documents</span>
                  </li>
                ) : (
                  ''
                )} */}
                {/* {this.state.role_permissions.includes(1) ? (
                  <li>
                    <a href="javascript:;" className="has-sub">
                      Lists
                    </a>
                    <ul className="list-unstyled sub-menu">
                      <li>
                        <a href="javascript:;">Chart of Accounts</a>
                      </li>
                      <li>
                        <a href="javascript:;">Item List</a>
                      </li>
                      <li>
                        <a href="javascript:;">Fixed Asset Item List</a>
                      </li>
                      <li>
                        <a href="javascript:;">Currency List</a>
                      </li>
                    </ul>
                  </li>
                ) : (
                  ""
                )} */}
                {/* {this.state.role_permissions.includes(17) ? (
                  <li>
                    <a href='javascript:;' className='has-sub'>
                      Lists
                    </a>
                    <ul className='list-unstyled sub-menu'>
                      <li>
                        <a href='javascript:;'>Chart of Accounts</a>
                      </li>
                      <li>
                        <a href='javascript:;'>Item List</a>
                      </li>
                      <li>
                        <a href='javascript:;'>Fixed Asset Item List</a>
                      </li>
                      <li>
                        <a href='javascript:;'>Currency List</a>
                      </li>
                    </ul>
                  </li>
                ) : (
                  ''
                )}
  
                {this.state.role_permissions.includes(17) ? (
                  <li>
                    <a href='javascript:;'>Accountant</a>
                  </li>
                ) : (
                  ''
                )}
  
                {this.state.role_permissions.includes(17) ? (
                  <li>
                    <a href='javascript:;'>Company</a>
                  </li>
                ) : (
                  ''
                )}
  
                {this.state.role_permissions.includes(17) ? (
                  <li>
                    <a href='javascript:;'>Sales Tax</a>
                  </li>
                ) : (
                  ''
                )}
  
                {this.state.role_permissions.includes(17) ? (
                  <li>
                    <a href='javascript:;'>Vendors</a>
                  </li>
                ) : (
                  ''
                )}
  
                {this.state.role_permissions.includes(17) ? (
                  <li>
                    <a href='javascript:;'>Employees</a>
                  </li>
                ) : (
                  ''
                )}
  
                {this.state.role_permissions.includes(17) ? (
                  <li>
                    <a href='javascript:;'>Banking</a>
                  </li>
                ) : (
                  ''
                )}
  
                {this.state.role_permissions.includes(17) ? (
                  <li>
                    <a href='javascript:;'>Reports</a>
                  </li>
                ) : (
                  ''
                )} */}
              </ul>
            </div>
          </div>
          <div className="menu-close visible-xs">&nbsp;</div>
        </div>
      );
    }

  }
}
export default left_sidebar;
