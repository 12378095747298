import React from 'react';
import LeftSidebar from './../left_sidebar';
import Footer from './../footer';
import Topbar from './../topbar';
import FetchAllApi from '../../api_links/fetch_all_api';
import jQuery from 'jquery';
import { ToWords } from 'to-words';
import config from './../../api_links/api_links';
import moment from 'moment';
import writtenNumber from 'written-number';
import Swal from 'sweetalert2';
import { PDFtoIMG } from 'react-pdf-to-image';
import NumberFormat from 'react-number-format';
const toWords = new ToWords();
var _ = require('lodash');
writtenNumber.defaults.lang = 'en-US';

class MakeDeposit extends React.Component {
  constructor(props) {
    super(props);
    //const { history } = this.props;
    this.state = {
      logged_user_id: localStorage.getItem('logged_user_id'),
      logged_client_id: localStorage.getItem('logged_client_id'),
      logged_role_id: localStorage.getItem('logged_role_id'),
      logged_user_name: localStorage.getItem('logged_user_name'),
      logged_user_email: localStorage.getItem('logged_user_email'),
      logged_user_phone: localStorage.getItem('logged_user_phone'),
      logged_user_image: localStorage.getItem('logged_user_image'),
      logged_company_name: localStorage.getItem('logged_company_name'),
      lock_date: localStorage.getItem('lock_date'),
      role_permissions: JSON.parse(localStorage.getItem('role_permissions')) || [],
      banks: [],
      bankCurency: '',
      payee_names: [],
      payor_name: '',
      clientHomeCurrency: 'SGD',
      currency: '',
      // currencies: [],
      currencies: config.all_currency_list,
      currency_clone: [],
      selected_bank: '',
      amount: '',
      amountPlainValue: '',
      rows: ['row 1'],
      coulmns: [],
      myarray: [],
      initial_value: 0,
      isAdd: false,
      gst_list: [],
      exchange_rate: 0,
      item_array: [
        {
          id: 1,
          catagory_id: '',
          third_party_acc_id: '',
          memo: '',
          amount: '',
        },
      ],
      bank_balance: 0,
      error: false,
      message: false,
      sourceNameOptions: [],
      errormessage: '',
      reference_no: '',
      isThirdPartyName: false,
      deposit_id: localStorage.getItem('deposit_id') && localStorage.getItem('deposit_id'),
      customerID: localStorage.getItem('customer_id') && localStorage.getItem('customer_id'),
      is_create_copy_deposit: localStorage.getItem('is_create_copy_deposit') && localStorage.getItem('is_create_copy_deposit'),
      is_add_from_table_list: false,
      table_index_to_add_category: -1,

      is_invalid_bank_account: false,
      is_invalid_payer: false,
      is_invalid_currency: false,
      is_invalid_reference_no: false,
      is_invalid_transaction_date: false,
      is_invalid_transaction_amount: false,
      isSuccessful: false,
      isFailed: false,
      saveType: 'save',

      selectedFile: '',
      succ_msg: '',
      attachment_file: [],
      attachment_file_length: 0,
      attachment_fileName: [],
      imgThumb: '',
      pages: [],
      list_of_attachments: [],
      is_invalid_subject: false,
      is_invalid_message: false,
      is_files_not_selected: false,
      old_transaction_date: '',
      jobList: [],
      buttonLoading: false,
      isVoided: false,
      aedButtonDisabled: false,
    };
    this.loadFile = this.loadFile.bind(this);
    this.sortableRef = React.createRef();
  }

  removeImage = (e, index) => {
    const updated = this.state.attachment_file.filter(item => item.name !== e);
    this.setState(
      {
        attachment_file: updated,
      },
      () => {
        if (this.state.attachment_file.length === 0) {
          // Reset file input value when no files are selected
          document.getElementById('imgInp').value = '';
          // jQuery('.upload-space').removeClass('hide')
          jQuery('.upload-space').css({
            opacity: 1,
          });
          jQuery('.upload-thumb').addClass('hide');
        }
      },
    );
  };

  loadFile(e) {
    var files = e.target.files;
    this.setState({ attachment_file_length: files.length });
    console.log(files, 'res prev');
    if (files.length > 0) {
      // jQuery('.upload-space').addClass('hide')
      jQuery('.upload-space').css({
        opacity: 0,
      });
      jQuery('.upload-thumb').removeClass('hide');
      var fileArra = this.state.attachment_file;
      //var fileThumbArra = this.state.imgThumb;
      for (var i = 0; i < files.length; i++) {
        // let fname = e.target.files[i].name.toLowerCase();
        let fname = files[i].name.toLowerCase();
        if (
          fname.endsWith('.pdf') ||
          fname.endsWith('.png') ||
          fname.endsWith('.jpg') ||
          fname.endsWith('.jpeg') ||
          fname.endsWith('.xls') ||
          fname.endsWith('.xlsx')
        ) {
          // let previous_attachments = this.state.attachment_file.filter(item => item.name.toLowerCase() == e.target.files[i].name.toLowerCase());
          let previous_attachments = this.state.attachment_file.filter(item => item.name.toLowerCase() === fname);
          console.log(this.state.attachment_file, previous_attachments, 'res prev', fname);

          if (previous_attachments.length === 0) {
            // fileArra.push(e.target.files[i]);
            fileArra.push(files[i]);
            this.setState(
              {
                // selectedFile: URL.createObjectURL(e.target.files[i]),
                selectedFile: URL.createObjectURL(files[i]),
                attachment_file: fileArra,
                is_files_not_selected: false,
              },
              () => {},
            );

            // e.target.files = null;
            // e.target.value = '';
          } else {
            e.target.files = null;
            e.target.value = '';
            e.preventDefault();
            this.setState({
              isFailed: true,
              errormessage: 'Cannot select same file again',
            });

            setTimeout(() => {
              this.setState({ isFailed: false, errormessage: '' });
            }, 3000);
          }
        } else {
          e.target.files = null;
          e.target.value = '';
          e.preventDefault();
          this.setState({
            isFailed: true,
            errormessage: 'Unsupported file Format',
          });

          setTimeout(() => {
            this.setState({ isFailed: false, errormessage: '' });
          }, 3000);
        }

        if (i == files.length - 1) {
          if (this.state.attachment_file.length == 0) {
            // jQuery('.upload-space').removeClass('hide')
            jQuery('.upload-space').css({
              opacity: 1,
            });
            jQuery('.upload-thumb').addClass('hide');
          }
        }
      }
    }
  }

  save_attachment = () => {
    const client_id = this.state.logged_client_id;
    const type = 'make_deposit';
    const source_attachments = this.state.attachment_file;
    FetchAllApi.save_source_attachements(client_id, type, source_attachments, (err, response) => {
      if (response.status === 1) {
        this.setState(
          {
            attachment_file: [],
            list_of_attachments: [...this.state.list_of_attachments, ...response.data],
          },
          () => {
            jQuery('.previewImg').remove();
            window.jQuery('#upload_doc_modal').modal('hide');
            window.jQuery('#attachment_modal').modal('show');
            // jQuery('.upload-space').removeClass('hide')
            jQuery('.upload-space').css({
              opacity: 1,
            });

            jQuery('.upload-thumb').addClass('hide');
          },
        );
      } else {
        alert(response.message);
      }
    });
  };

  rename = (obj, curr) => {
    let a = {};
    Object.keys(obj).map(key => {
      let newKey = key.replace(curr, '');
      Object.assign(a, { [newKey]: obj[key] });
    });
    return a;
  };

  get_bank_balance = id => {
    let coreData = {
      client_id: this.state.logged_client_id,
      selectedbank: id,
    };
    FetchAllApi.get_bank_balance(coreData, (err, response) => {
      if (response.status === 1) {
        this.setState({
          bank_balance: response.totalAmount,
        });
      } else {
        this.setState({
          bank_balance: 0,
        });
      }
    });
  };

  bankSelected = e => {
    this.state.banks.map((item, i) => {
      if (item.id == e.target.value) {
        this.get_bank_balance(item.id);
        this.get_currencies(item.currency);
        // console.log(this.state.currency_clone[item.currency],this.state.currency_clone)
        this.setState(
          {
            selected_bank: e.target.value,
            bankCurency: item.currency,
            is_invalid_bank_account: false,
            is_invalid_currency: false,
            // exchange_rate: Number(this.state.currency_clone[item.currency].toFixed(4)),
          },
          () => {
            this.loadExchangeRate(false);
          },
        );
      }
    });
  };

  loadExchangeRate = is_date_changed => {
    let date_formated = this.state.transaction_date
      ? moment(this.state.transaction_date).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD');
    let todayDate = new Date();
    todayDate.setHours(23, 59, 59, 998);
    if (new Date(date_formated).getTime() > todayDate.getTime()) {
      if (is_date_changed == true) {
        if (this.state.bankCurency == this.state.clientHomeCurrency) {
          Swal.fire('You are entering a transaction for Future Date!');
        } else {
          Swal.fire(
            'You are entering a transaction for Future Date!',
            "Since you are entering a transaction for future date, The exchange rate will follow today's date And you must change the exchange rate manually for the Transaction",
          );
        }
      }
      date_formated = moment().format('YYYY-MM-DD');
    }
    fetch(
      `https://api.currencylayer.com/timeframe?access_key=${config.api_key}&start_date=${date_formated}&end_date=${date_formated}&currencies=${this.state.clientHomeCurrency}&source=${this.state.bankCurency}`,
    )
      .then((response, err) => response.json())
      .then(data => {
        if (data.success == true) {
          let newObj = this.rename(data.quotes, this.state.clientHomeCurrency);

          let currencyAr = [];
          let first = newObj;
          currencyAr.push({
            date: data.start_date,
            // 'rate': isNaN(first[data.start_date][this.state.bankCurency + this.state.clientHomeCurrency])? 0.0000 :(first[data.start_date][this.state.bankCurency + this.state.clientHomeCurrency]).toFixed(4)
            rate:
              first[data.start_date] && first[data.start_date][this.state.bankCurency + this.state.clientHomeCurrency]
                ? first[data.start_date][this.state.bankCurency + this.state.clientHomeCurrency].toFixed(4)
                : parseFloat(1).toFixed(4),
          });
          let val = currencyAr[0].rate;
          newObj[this.state.clientHomeCurrency] = parseFloat(val);

          this.setState({
            exchange_rate: Number(parseFloat(newObj[this.state.clientHomeCurrency]).toFixed(4)),
          });
        } else {
          console.log('Failed to get exchange rate');
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  get_client_home_currency = () => {
    let client_id = this.state.logged_client_id;

    FetchAllApi.get_client_home_currency(client_id, (err, response) => {
      if (response.status === 1) {
        this.get_currencies(response.currency);
        console.log('Basio state', response);
        this.setState({
          clientHomeCurrency: response.currency,
        });
      } else {
      }
    });
  };

  total = () => {
    let total = 0;
    this.state.item_array &&
      this.state.item_array.map((a, b) => {
        total = total + Number(a.amount);
      });
    // console.log("hdya", total);

    var num = total.toFixed(2);
    return num;
  };
  sourceNameOption = () => {
    let client_Id = this.state.logged_client_id;
    let filter_id = 2;
    FetchAllApi.settings_defaultNamelist(client_Id, filter_id, (err, response) => {
      // alert(response.country_id)
      if (response.status === 1) {
        this.setState({ sourceNameOptions: response.list });
      }
    });
  };

  catagory_list = () => {
    let search_key = '';
    var client_id = this.state.logged_client_id;
    let filter_id = 2;
    //alert(search_key)
    FetchAllApi.cashcoding_category_list(client_id, filter_id, (err, response) => {
      console.log('defaultcategorylist', response);
      if (response.status === 1) {
        // alert('k')
        this.setState({
          balancesheetlist: response.list,
        });
      } else {
        this.setState({
          balancesheetlist: [],
        });
      }
    });
  };

  fetchThirdPartyNames = (payment_account_id, row) => {
    let client_id = this.state.logged_client_id;
    FetchAllApi.third_party_account_list(client_id, payment_account_id, (err, response) => {
      console.log('vendor_names', response);
      if (response.status === 1) {
        console.log('hiiiii==========iiiiiii', response);
        this.setState({ [`third_party_account_list${row}`]: response.data });

        localStorage.setItem('third_party_customer_id', '');
      } else {
      }
    });
  };

  handleTable = idx => e => {
    const { name, value } = e.target;
    var myarray = this.state.item_array;
    myarray[idx][name] = value;
    this.setState({ item_array: myarray });
  };

  copyRow = item_id => {
    if (
      this.state.item_array.length > this.state.rows.length - 1 &&
      this.state.item_array[this.state.item_array.length - 1].catagory_id !== '' &&
      this.state.item_array[this.state.item_array.length - 1].memo !== ''
    ) {
      jQuery('.error_tbl_fld').css('display', 'none');
      var arrayList = this.state.item_array;
      var rowToCopy = { id: this.state.item_array.length + 1, ...this.state.item_array[item_id] };
      var rows = this.state.rows;
      rows.push('row' + (parseInt(this.state.initial_value) + 1));
      // arrayList.push({...rowToCopy});

      this.setState(
        {
          item_array: [...arrayList, rowToCopy],
          isAdd: false,
          initial_value: parseInt(this.state.initial_value) + 1,
        },
        () => {
          var myarray = this.state.item_array;

          this.setState({ rows: rows, isTablefilled: false }, () => {
            console.log('t2');
            this.setState({
              item_array: myarray,
            });
            this.handleTable(this.state.item_array.length + 1);
          });
        },
      );

      // jQuery(
      //   `#third_account_id${item_id}`
      // ).attr("disabled", true);
    } else {
      alert('`fill last row');
    }
  };

  // to check lock password

  checkPassword = () => {
    let Input = {
      client_id: this.state.logged_client_id,
      password: this.state.old_password,
    };

    FetchAllApi.verify_lock_date_password(Input, (err, response) => {
      if (response.status === 1) {
        let temp_date = this.state.temp_date;
        jQuery('#invoice_date').val(temp_date);

        window.jQuery('.modal-backdrop').css('display', 'none');
        window.jQuery('#asking_password_for_delete').modal('hide');
        window.jQuery('#lock_date').modal('hide');

        // setTimeout(() => {
        //   this.setState({
        //     cus_rate_rate: this.state.exchange_value_ref,
        //   });
        // }, 500);

        // setTimeout(() => {
        // this.handleChangeItems(0, this.state.rows.length - 1);
        // }, 500);
        this.save(this.state.saveType);
        this.loadExchangeRate(true);
      } else {
        alert(response.message);
        this.setState({ deleteMsg: true });
      }
    });
  };

  deleteRow = row => {
    let array = this.state.item_array;
    if (array.length > 1) {
      array.splice(row, 1);
      console.log('ddsgdusyf', array);
      this.setState({ item_array: array });
      var rows = this.state.rows;
      rows.pop('row' + row);
    }
  };

  totalTable = () => {
    let total = 0;
    this.stateitem_array &&
      this.state.item_array.map((a, b) => {
        total = total + Number(a.item_amount);
      });
    console.log('hdya', total);
    return total.toFixed(2);
  };

  addRow = row => {
    let array = this.state.item_array;
    array.push({
      id: row,
      catagory_id: '',
      third_party_acc_id: '',
      memo: '',
      amount: '',
    });
    this.setState({
      item_array: array,
    });
  };
  clear = () => {
    window.location.reload();
  };

  save = btn_type => {
    let payorname = this.state.payor_name;
    let payordet = this.state.payee_names.filter(item => item.id == payorname);
    const source_attachment_ids = this.state.list_of_attachments.map(obj => obj.source_id);
    let data = {
      client_id: this.state.logged_client_id,
      item_total_home_currency: (Number(this.total()) * Number(this.state.exchange_rate)).toFixed(2),
      grand_total_home_currency: (Number(this.total()) * Number(this.state.exchange_rate)).toFixed(2),
      invoice_date: moment(this.state.transaction_date).format('YYYY/MM/DD'),
      company_name:
        payordet && payordet.length > 0
          ? payordet[0].type == 2
            ? payordet[0].id.replace('v', '')
            : payordet[0].type == 3
            ? payordet[0].id.replace('e', '')
            : payordet[0].type == 4
            ? payordet[0].id.replace('O', '')
            : payordet[0].id
          : 0,
      source_attachments: source_attachment_ids.join(','),
      payor_type: payordet && payordet.length > 0 ? payordet[0].type : 0,
      currency: this.state.bankCurency,
      item_total_foreign_currency: this.total(),
      grand_total_foreign_currency: this.total(),
      exchange_rate: Number(this.state.exchange_rate.toFixed(4)),
      customer_id:
        payordet && payordet.length > 0
          ? payordet[0].type == 2
            ? payordet[0].id.replace('v', '')
            : payordet[0].type == 3
            ? payordet[0].id.replace('e', '')
            : payordet[0].type == 4
            ? payordet[0].id.replace('O', '')
            : payordet[0].id
          : 0,
      account: this.state.selected_bank,
      transaction_date: this.state.transaction_date,
      deposit_id: this.state.deposit_id,
      amount_in_word: isNaN(Number(this.state.amount))
        ? ''
        : config.currencies_using_word_lakh.indexOf(this.state.bankCurency) >= 0
        ? toWords.convert(Number(this.state.amount))
        : _.startCase(_.toLower(writtenNumber(this.state.amount))),
      reference_no: this.state.reference_no,
      memo: this.state.memo,
      item_list: [],
    };
    console.log(data, 'res', payorname, payordet);
    let is_invalid_bank_account = this.state.selected_bank ? false : true;
    let is_invalid_payer = data.customer_id ? false : true;
    let is_invalid_currency = this.state.bankCurency ? false : true;
    let is_invalid_reference_no = this.state.reference_no ? false : true;
    let is_invalid_transaction_date = this.state.transaction_date ? false : true;
    let is_invalid_transaction_amount = this.state.amount ? false : true;

    if (
      is_invalid_bank_account ||
      is_invalid_payer ||
      is_invalid_currency ||
      is_invalid_reference_no ||
      is_invalid_transaction_date ||
      is_invalid_transaction_amount
    ) {
      this.setState({
        is_invalid_bank_account: is_invalid_bank_account,
        is_invalid_payer: is_invalid_payer,
        is_invalid_currency: is_invalid_currency,
        is_invalid_reference_no: is_invalid_reference_no,
        is_invalid_transaction_date: is_invalid_transaction_date,
        is_invalid_transaction_amount: is_invalid_transaction_amount,
      });

      this.setState({
        error: true,
        errormessage: 'Mandatory fields must be filled!',
      });
      setTimeout(() => {
        this.setState({ error: false });
      }, 4000);
    } else {
      this.setState({
        is_invalid_bank_account: is_invalid_bank_account,
        is_invalid_payer: is_invalid_payer,
        is_invalid_currency: is_invalid_currency,
        is_invalid_reference_no: is_invalid_reference_no,
        is_invalid_transaction_date: is_invalid_transaction_date,
        is_invalid_transaction_amount: is_invalid_transaction_amount,
      });
      let invalid_table_list = true;
      this.state.item_array.map((item, idx) => {
        if (item.catagory_id == '' || item.amount == '') {
          invalid_table_list = false;
        }
      });

      if (invalid_table_list == false) {
        this.setState({
          message: true,
          error: true,
          errormessage: 'Mandatory fields must be filled!',
        });
        setTimeout(() => {
          this.setState({ error: false });
        }, 4000);
      } else {
        this.setState({ message: false });
        let item_list = [];
        this.state.item_array &&
          this.state.item_array.map((item, i) => {
            let thirdparty = item.third_party_acc_id && JSON.parse(item.third_party_acc_id);
            let thirdaccount = thirdparty && this.state.sourceNameOptions.filter(item => item.id == thirdparty);
            console.log(thirdaccount, thirdparty);
            let items = {
              item_name: 'make deposit transaction',
              input_type: 'make_deposit_transaction',
              tax_code: '',
              descr: item.memo,
              quantity: 1,
              price: 0,
              unit_price: item.amount,
              category_id: item.catagory_id,
              tax_name: '',
              tax_rate: '',
              tax_type: '',
              item_tax: '0',
              item_total: item.amount,
              home_item_total: Number(item.amount) * Number(this.state.exchange_rate),
              credit: item.amount,
              debit: 0,
              selected_user_type: thirdaccount && thirdaccount[0].type ? thirdaccount[0].type : 1,
              selected_user_id: thirdaccount && thirdaccount[0].id ? thirdaccount[0].id : 0,
              selected_job_id: item.selected_job_id ? item.selected_job_id : 0,
            };
            item_list.push(items);
          });

        this.setState({ old_transaction_date: this.state.transaction_date });

        data['item_list'] = item_list;
        if (this.state.deposit_id && this.state.deposit_id != undefined && this.state.deposit_id != 0) {
          this.setState({ buttonLoading: true });
          FetchAllApi.updatedeposit(data, (err, response) => {
            if (response.status === 1) {
              //  alert(response.message);

              this.setState({ isSuccessful: true, buttonLoading: false });

              setTimeout(() => {
                this.setState({
                  isSuccessful: false,
                  errormessage: response.message,
                });
              }, 3000);

              if (btn_type == 'savenew') {
                this.setState({
                  item_array: [
                    {
                      id: 1,
                      catagory_id: '',
                      third_party_acc_id: '',
                      memo: '',
                      amount: '',
                    },
                  ],
                  transaction_date: '',
                  exchange_rate: '',
                  grand_total_foreign_currency: '',
                  bankCurency: '',
                  payor_name: '',
                  reference_no: '',
                  amount: '',
                  amountPlainValue: '',
                  bank_balance: 0,
                  selected_bank: '',
                });
                jQuery('#transaction_date').val('');
              } else {
                this.setState({ deposit_id: response.deposit_id });
              }
              // this.props.history.push("/loading", ["/make_deposit"]);
              // this.setState({ banks: response.data });
            } else {
              // this.setState({ error: true ,errormessage:response.message})
              // setTimeout(() => {
              //   this.setState({ error: false })
              // }, 4000)

              this.setState({ isFailed: true, errormessage: response.message, buttonLoading: false });
              setTimeout(() => {
                this.setState({
                  isFailed: false,
                  errormessage: response.message,
                });
              }, 3000);

              // this.setState({ banks: [] });
            }
          });
        } else {
          this.setState({ buttonLoading: true });
          FetchAllApi.make_deposit(data, (err, response) => {
            if (response.status === 1) {
              // alert(response.message);

              this.setState({ isSuccessful: true, buttonLoading: false });
              setTimeout(() => {
                this.setState({
                  isSuccessful: false,
                  errormessage: response.message,
                });
              }, 3000);

              if (btn_type == 'savenew') {
                this.setState({
                  item_array: [
                    {
                      id: 1,
                      catagory_id: '',
                      third_party_acc_id: '',
                      memo: '',
                      amount: '',
                    },
                  ],
                  transaction_date: '',
                  exchange_rate: '',
                  grand_total_foreign_currency: '',
                  bankCurency: '',
                  payor_name: '',
                  reference_no: '',
                  amount: '',
                  amountPlainValue: '',
                  bank_balance: 0,
                  selected_bank: '',
                });
                jQuery('#transaction_date').val('');
              } else {
                this.setState({ deposit_id: response.deposit_id });
              }
              // this.props.history.push("/loading", ["/make_deposit"]);
              // this.setState({ banks: response.data });
            } else {
              // this.setState({ error: true ,errormessage:response.message})
              // setTimeout(() => {
              //   this.setState({ error: false })
              // }, 4000)

              this.setState({ isFailed: true, errormessage: response.message, buttonLoading: false });
              setTimeout(() => {
                this.setState({
                  isFailed: false,
                  errormessage: response.message,
                });
              }, 3000);

              // this.setState({ banks: [] });
            }
          });
        }
      }
    }
  };

  // 1 for bank account list dropdown

  getAllbanks = () => {
    let filter_id = 2;
    FetchAllApi.getAllbanks(this.state.logged_client_id, filter_id, (err, response) => {
      if (response.status === 1) {
        this.setState({ banks: response.data }, () => {
          var urlParams = new URLSearchParams(window.location.search);
          if (urlParams.size > 0) {
            response.data.map((item, i) => {
              if (item.id == urlParams.get('selected_bank')) {
                var date = urlParams.get('date') || moment().format('YYYY-MM-DD');
                jQuery('#transaction_date').val(date);
                this.get_bank_balance(item.id);
                this.setState(
                  {
                    selected_bank: urlParams.get('selected_bank'),
                    bankCurency: item.currency,
                    transaction_date: date,
                    // exchange_rate: Number(this.state.currency_clone[item.currency].toFixed(4)),
                    payor_name: urlParams.get('payor_name'),
                    amount: urlParams.get('amount'),
                    amountPlainValue: urlParams.get('amount'),
                  },
                  () => this.loadExchangeRate(false),
                );
              }
            });
          }
        });
      } else {
        this.setState({ banks: [] });
      }
    });
  };

  customer_vendor_list = () => {
    let client_id = this.state.logged_client_id;
    let filter_id = 2;
    FetchAllApi.customer_vendor_list(client_id, filter_id, (err, response) => {
      if (response.status === 1) {
        this.setState({ payee_names: response.list });
      } else {
        this.setState({ payee_names: [] });
      }
    });
  };

  allCustomerJobs = () => {
    let client_id = this.state.logged_client_id;
    FetchAllApi.allCustomerJobs(client_id, (err, response) => {
      if (response.status === 1) {
        this.setState({ jobList: response.list });
      } else {
        this.setState({ jobList: [] });
      }
    });
  };

  get_currencies = home_currency => {
    // fetch(
    //   // `https://api.exchangerate-api.com/v4/latest/${home_currency}`
    //   `https://api.currencylayer.com/live?access_key=${config.api_key}&source=${home_currency}`

    // )
    //   .then((response) => response.json())
    //   .then((data) => {
    //     let newObj = this.rename(data.quotes, home_currency)

    //     const currencyAr = [];
    //     let first = newObj;
    //     for (const key in first) {
    //       currencyAr.push(key);
    //     }
    //     this.setState({ currencies: currencyAr, currency_clone: first }, this.getAllbanks());
    //   });
    this.getAllbanks();
  };

  changeDate = fromdate => {
    setTimeout(() => {
      let date = jQuery('#transaction_date').val();
      if (date && date != undefined) {
        var array = date.split('/');
        var date_formated = array[2] + '-' + array[1] + '-' + array[0];
        if (date_formated != this.state.transaction_date) {
          this.setState(
            {
              transaction_date: date_formated,
              is_invalid_transaction_date: false,
            },
            () => {
              this.loadExchangeRate(true);
            },
          );
        }
        // alert(date_formated);
      }
    }, 1000);
  };

  componentDidUpdate() {
    window.jQuery('.input-group.date').datepicker({ format: 'dd/mm/yyyy', autoclose: true });
    window.jQuery('.selectpicker').selectpicker('refresh');
    // jQuery("#currency_selected").val(4);
    // console.log(this.state.item_array, 'res', this.state.balancesheetlist, jQuery(
    //   `#categry_id${0}`
    // ).val())
  }

  UNSAFE_componentWillMount() {
    jQuery(document.body).removeClass('minimize_leftbar');
    //console.log("logged_user_id", this.state.logged_user_id);

    jQuery('title').html('Customer | GBSC');

    if (this.state.logged_user_id === '' || this.state.logged_user_id === 'null' || this.state.logged_user_id === 'undefined') {
      this.props.history.push('/');
    }
  }

  routedChange(parameter) {
    this.props.history.push('/' + parameter);
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    // Initialize sortable
    window.jQuery(this.sortableRef.current).sortable({
      handle: '.drag-icon',
      update: (event, ui) => {
        const sortedArray = window.jQuery(this.sortableRef.current).sortable('toArray');
        console.log('Sorted array:', sortedArray);
      },
    });

    jQuery('#entername').hide();
    jQuery(function () {
      jQuery('.cus').on('hidden.bs.dropdown', function () {
        jQuery('#changeTableProps').css('height', 'auto');
      });
    });

    jQuery(function () {
      jQuery('.cus').on('show.bs.dropdown', function () {
        jQuery('#changeTableProps').css('height', '1000px');
      });
    });

    jQuery('#changeTableProps').click('.bootstrap-select', function (e) {
      jQuery('.table-responsive').css('overflow', 'visible');
    });
    this.get_client_home_currency();
    this.catagory_list();
    this.sourceNameOption();
    //this.customer_vendor_list();
    this.allCustomerJobs();
    this.fetchNames();
    window.jQuery('.input-group.date').datepicker({ format: 'dd/mm/yyyy', autoclose: true });
    window.jQuery('.selectpicker').selectpicker('refresh');

    console.log(this.state.payee_names, 'res from', this.props.location.state);

    // this.getSpecificPage(1, 10);

    jQuery(window).on('load', function () {
      window.jQuery('.mscroll-y').mCustomScrollbar({
        axis: 'y',
        scrollEasing: 'linear',
        scrollInertia: 600,
        autoHideScrollbar: 'true',
        autoExpandScrollbar: 'true',
      });

      window.jQuery('.ib-scroll').mCustomScrollbar({
        scrollEasing: 'linear',
        scrollInertia: 600,
        scrollbarPosition: 'outside',
      });
    });

    jQuery(document).ready(function () {
      jQuery('.left-navmenu .has-sub').click(function () {
        jQuery('.left-navmenu li a').removeClass('active');
        jQuery(this).addClass('active');
        jQuery('.left-navmenu li a:not(.active)').siblings('.sub-menu').slideUp();
        jQuery(this).siblings('.sub-menu').slideToggle();
      });
      jQuery('.left-navmenu .sub-menu li a').click(function () {
        jQuery('.left-navmenu .sub-menu li a').removeClass('active');
        jQuery(this).addClass('active');
      });
      jQuery('.search-btn').click(function () {
        jQuery('.hdr-search').addClass('active');
      });
      jQuery('.hdr-search .close-icon').click(function () {
        jQuery('.hdr-search').removeClass('active');
      });
      window.jQuery('.select-picker').selectpicker();
      jQuery('.label-enclose .label').click(function () {
        jQuery(this).toggleClass('active');
      });
      jQuery('.nav-brand-res').click(function () {
        jQuery('.left-navbar').addClass('active');
      });
      jQuery('.menu-close').click(function () {
        jQuery('.left-navbar').removeClass('active');
      });
    });
    if (this.state.deposit_id != '' && this.state.deposit_id != null && this.state.deposit_id != undefined) {
      this.get_deposit_details();
    }
    this.watchIsCustomerAdded();
    this.defaultcategorylist_onchange();
    if (this.state.customerID != 0) {
      this.setState(
        {
          selectCustomer: this.state.customerID,
          payor_name: this.state.customerID,
        },
        () => {
          // alert(this.state.selectCustomer)
        },
      );
    }
  }

  createCopy = () => {
    localStorage.setItem('deposit_id', this.state.deposit_id);
    localStorage.setItem('is_create_copy_deposit', 1);
    let win = window.open('/make_deposit', '_blank');
    win.focus();
  };

  get_deposit_details = () => {
    console.log(this.state.payee_names);
    let input = {
      client_id: this.state.logged_client_id,
      deposit_id: this.state.deposit_id,
    };
    FetchAllApi.get_deposit_details(input, (err, response) => {
      localStorage.setItem('deposit_id', '');
      localStorage.setItem('is_create_copy_deposit', '');

      if (this.state.is_create_copy_deposit == 1) {
        this.setState({
          deposit_id: '',
          is_create_copy_deposit: 0,
        });
      }
      if (response.status === 1) {
        console.log('res', response);
        let data = response.details;
        let detail = response.details.paydetails;
        this.get_bank_balance(data.account);
        let date = data.invoice_date.split('-');
        var date_formated = date[2] + '/' + date[1] + '/' + date[0];
        jQuery('#transaction_date').val(date_formated);
        // jQuery("#payor_name").val(data.customer_id)
        var items = [];
        for (let i = 0; i < data.item_list.length; i++) {
          var item_list = {
            catagory_id: data.item_list[i].category_id,
            third_party_acc_id: data.item_list[i].selected_user_id,
            unit_price: data.item_list[i].unit_price,
            memo: data.item_list[i].descr,
            amount: data.item_list[i].item_total,
            selected_job_id: data.item_list[i].selected_job_id ? data.item_list[i].selected_job_id : 0,
          };
          items.push(item_list);
        }
        this.setState(
          {
            isEdit: true,
            selected_bank: data.account,
            isVoided: data?.status === 11 ? true : false,
            transaction_date: data.invoice_date,
            old_transaction_date: data.invoice_date,
            payor_name:
              data.payor_type == 2
                ? 'v' + data.customer_id
                : data.payor_type == 3
                ? 'e' + data.customer_id
                : data.payor_type == 4
                ? 'O' + data.customer_id
                : data.customer_id,
            bankCurency: data.currency,
            item_array: items,
            reference_no: data.reference_no,
            amount: data.grand_total_foreign_currency,
            amountPlainValue: data.grand_total_foreign_currency,
            memo: data.memo,
            list_of_attachments: data.source_attachments ? data.source_attachments : [],
            //newly added
            exchange_rate: data.exchange_rate,
          },
          () => {
            setTimeout(() => {
              console.log(this.state.payee_names, this.state.item_array, this.state.payor_name, this.state.transaction_date);
            }, 1000);
          },
        );
      } else {
        alert(response.message);
      }
    });
  };
  getSpecificPage = (pageNumber, limitvalue, searchkey) => {
    let client_id = this.state.logged_client_id;
    let page = pageNumber;
    let limit = this.state.selectedLimit;

    let search = searchkey;
    // alert(this.state.logged_client_id)
    FetchAllApi.customer_list(client_id, page, limit, search, parseInt(this.state.selected_filter_id), (err, response) => {
      console.log('Customer_list', response);
      if (response.status === 1) {
        let customerListArray = response.list;
        //  let totalPagesCount = new Array(parseInt( response.TotalPages))
        var totalPagesCount = [];
        for (var i = 1; i <= response.TotalPages; i++) {
          totalPagesCount.push(i);
        }
        this.setState({
          customerListArray: customerListArray,
          TotalPages: totalPagesCount,
          selectedLimit: this.state.selectedLimit,
          totalPagesCount: response.TotalPages,
        });
      } else {
        this.setState({
          customerListArray: [],
          TotalPages: '',
          pgNo: '',
          totalPagesCount: '',
        });
      }
    });
  };
  watchIsCustomerAdded = () => {
    setInterval(() => {
      var checkMe = localStorage.getItem('customer_added');
      if (checkMe !== undefined && checkMe !== '' && checkMe !== null && checkMe === 'yes') {
        let newlyCreatedID = localStorage.getItem('customer_added_id');

        if (this.state.radio == 'Vendor') {
          newlyCreatedID = 'v' + newlyCreatedID;
        }
        this.setState({ selectCustomer: newlyCreatedID });

        this.handleChange(newlyCreatedID);
        let client_id = this.state.logged_client_id;
        let filter_id = 2;
        //  FetchAllApi.customer_vendor_list(client_id, (err, response) => {
        FetchAllApi.customer_and_vendor_list_for_journal(client_id, filter_id, (err, response) => {
          if (response.status === 1) {
            let myArray = response.list;

            this.setState(
              { payee_names: response.list },

              () => {
                myArray.map(item => {
                  if (item.id == newlyCreatedID) {
                    jQuery('#variable_pay_type').val(item.id);
                  }
                });

                window.jQuery('#variable_pay_type').selectpicker('refresh');
                localStorage.setItem('customer_added_id', null);
                localStorage.setItem('customer_added', null);
              },
            );
            let variable_pay_type = newlyCreatedID;
            localStorage.setItem('customer', newlyCreatedID + '=' + variable_pay_type);

            this.setState(
              {
                selectCustomer: variable_pay_type,
                payor_name: jQuery('#variable_pay_type').val(),
              },
              () => {
                // alert(this.state.selectCustomer)
              },
            );
          } else {
            localStorage.setItem('customer_added_id', null);
            localStorage.setItem('customer_added', null);
          }
        });
      }
    }, 3000);
  };
  fetchNames = (a, id) => {
    let client_Id = this.state.logged_client_id;
    let filter_id = 2;
    FetchAllApi.customer_and_vendor_list_for_journal(client_Id, filter_id, (err, response) => {
      if (response.status === 1) {
        this.setState({ payee_names: response.list });
        if (a == 'added') {
          // this.changeName(this.state.idx, id)
          this.setState({
            payor_name: id,
          });
        }
      }
    });
  };
  changeName = (idx, e) => {
    let obj = this.state.payee_names.find(itm => e == itm.id);
    // let table = [...this.state.data_table]
    // table[idx].name = obj.id
    // table[idx].name_text =  obj.name
    // table[idx].name_type = obj.type
    // this.setState({ data_table: table })
  };
  saveEmp = () => {
    let input = {
      client_id: this.state.logged_client_id,
      employee_name: this.state.new_name,
    };
    FetchAllApi.add_employee_with_name(input, (input, response) => {
      if (response.status === 1) {
        this.fetchNames('added', 'e' + response.id);
        // setTimeout(() => {
        // this.changeName(this.state.idx, id)
        // }, 1000);
      } else if (response.message) {
        alert(response.message);
      }
    });
  };

  saveOthers = () => {
    let input = {
      client_id: this.state.logged_client_id,
      name: this.state.new_name,
    };
    FetchAllApi.add_other_staff(input, (err, response) => {
      if (response.status === 1) {
        this.fetchNames('added', 'O' + response.id);
        // setTimeout(() => {
        // this.changeName(this.state.idx, id)
        // }, 1000);
      } else if (response.message) {
        alert(response.message);
      }
    });
  };
  handleChange = selected_option => {
    if (selected_option == 'Create New') {
      jQuery('#variable_pay_type option').prop('selected', false).trigger('change');
      // this.props.history.push('/add-new-customer')
      window.jQuery('#add-new-modal').modal('show');
      // let win = window.open("/add-new-customer", "_blank");
      // win.focus();
    } else {
      let variable_pay_type = jQuery('#variable_pay_type option:selected').data('status');
      localStorage.setItem('customer', selected_option + '=' + variable_pay_type);

      this.setState(
        {
          payor_name: jQuery('#variable_pay_type').val(),
          is_invalid_payer: false,
        },
        () => {
          // alert(this.state.selectCustomer)
        },
      );
      this.fetchNames();
      //this.customer_vendor_list()
    }
  };
  logoutLink() {
    localStorage.clear();

    this.props.history.push('/');
  }

  defaultcategorylist_onchange = (x, y, z, new_currency) => {
    let keyy = '';
    let from_create_invoice = 1;
    var client_id = this.state.logged_client_id;
    let filter_id = 2;

    FetchAllApi.defaultcategorylist_onchange2(keyy, from_create_invoice, client_id, filter_id, (err, response) => {
      // console.log("defaultcat9999egorylist", response);
      if (response.status === 1) {
        if (x == 'added') {
          this.setState({
            selectNeedIndex: response.list.length - 1,
            nameFilter: y,
          });
        }
        this.setState(
          {
            default_category_list: response.list,
          },
          () => {
            window.jQuery('#balanceSheetCategeory').selectpicker('refresh');
          },
        );
      } else {
        this.setState({
          default_category_list: [],
        });
      }
    });
    this.onChange_filter_balancesheet();
    // No need to change selected bank if category added from table list
    if (this.state.is_add_from_table_list == false) {
      this.setState({ selected_bank: z, bankCurency: new_currency });
    } else {
      //  this.setState({ is_add_from_table_list:false });
      if (this.state.table_index_to_add_category >= 0) {
        var myarray = this.state.item_array;
        myarray[this.state.table_index_to_add_category]['catagory_id'] = z;
        this.setState({
          item_array: myarray,
          is_add_from_table_list: false,
          table_index_to_add_category: -1,
        });
      } else {
        this.setState({ is_add_from_table_list: false });
      }
    }
  };

  formatNumber = num => {
    return num.toLocaleString();
  };

  onChange_filter_balancesheet = () => {
    let search_key = '';
    var client_id = this.state.logged_client_id;

    // FetchAllApi.balancesheetlist_onchange(search_key,
    //   client_id,
    //   (err, response) => {
    //     // console.log("defaultcategorylist", response);
    //     if (response.status === 1) {
    //       // alert('k')
    //       this.setState({
    //         banks: response.list,
    //       });
    //     } else {
    //       this.setState({
    //         banks: [],
    //       });
    //     }
    //   }
    // );

    let filter_id = 2;
    FetchAllApi.cashcoding_category_list(client_id, filter_id, (err, response) => {
      if (response.status === 1) {
        this.setState({
          balancesheetlist: response.list,
        });
      } else {
        this.setState({
          balancesheetlist: [],
        });
      }
    });

    let bank_filter_id = 2;
    FetchAllApi.getAllbanks(client_id, bank_filter_id, (err, response) => {
      if (response.status === 1) {
        this.setState({
          banks: response.data,
        });
      } else {
        this.setState({
          banks: [],
        });
      }
    });
  };
  pageLink(page_slug) {
    this.props.history.push('/' + page_slug);
  }

  delete_or_void_deposit = val => {
    let input = {
      client_id: this.state.logged_client_id,
      deposit_id: this.state.deposit_id,
      status_to_set: val,
    };

    FetchAllApi.delete_or_void_deposit(input, (err, response) => {
      if (response.status === 1) {
        alert(response.message);
        window.jQuery('#modal_void_invoice').modal('hide');
        this.get_deposit_details();
        // window.jQuery('#modal_delete_invoice').modal('hide');
      } else if (response.status === 2) {
        this.setState({ delete_alert_msg: response.message });
        setTimeout(() => {
          // if (val == 10) {
          //   window.jQuery('#modal_delete_invoice').modal('hide')
          //   window.jQuery('#paid_invoice_delete_modal').show('show')
          // }
          if (val == 11) {
            window.jQuery('#modal_void_invoice').modal('hide');
            // window.jQuery('#paid_invoice_void_modal').show('show')
          }
        }, 500);
      } else {
        alert(response.message);
      }
    });
  };

  render() {
    return (
      <div>
        {/* Main Wrapper Starts here */}

        {this.state.isVoided && (
          <div
            style={{
              position: 'fixed',
              display: 'none',
              width: ' 100%',
              height: '100%',
              top: '0',
              left: ' 0',
              right: '0',
              bottom: ' 0',
              backgroundColor: 'rgba(0,0,0,0.2)',
              zIndex: '99999',
              cursor: 'pointer',
              display: 'block',
            }}
          >
            <h1 className="watermark">Voided</h1>
          </div>
        )}
        <div className="container-fluid">
          <div className="row">
            {/* left-navbar Starts here */}
            <LeftSidebar history={this.props.history} pageSubmit={e => this.pageLink(e)} />

            {/* left-navbar Ends here */}
            {/* MainContent Wrapper Starts here */}
            <div className="main-wrap col-md-12 col-xs-12 pad-r-no">
              {/* Top bar Starts here */}
              <div className="top-bar col-md-12 col-xs-12 pad-r-no">
                <form className="hdr-search">
                  <input type="text" className="form-control" name="search" placeholder="Search..." />
                  {/* <button type="submit" class="btn btn-green">Search</button> */}
                  <a href="javascript:;" className="close-icon">
                    <img src="images/close-icon-red.svg" alt="Close" />
                  </a>
                </form>
                <div className="nav-brand-res visible-xs">
                  <img className="img-responsive" src="images/logo-icon.png" alt="LogoIcon" />
                </div>
                <a href="javascript:;" className="back hidden-xs" onClick={() => this.props.history.goBack()}>
                  <img src="images/back-arrow-blue.svg" />
                </a>
                {/* <span class="page-title hidden-xs">Preference</span> */}
                <ul className="list-unstyled breadcrumb page-title hidden-xs">
                  <li>
                    <a href="javascript:;">Banking</a>
                  </li>
                  <li>Make Deposit</li>
                </ul>
                <Topbar history={this.props.history} logoutSubmit={e => this.logoutLink()} />
              </div>
              {/* Top bar Ends here */}
              <div className="col-md-12 col-xs-12 mar-top visible-xs">
                <a href="javascript:;" className="back">
                  <img src="images/back-arrow-blue.svg" />
                </a>
                <span className="page-title">Make Deposit</span>
              </div>
              {/* content-top Starts here */}
              <div className="content-top col-md-12 col-xs-12">
                <form className="custom-form row">
                  <div className="form-group col-md-4 mar-b-no">
                    <label>
                      Bank Account<span className="astrick">*</span>
                    </label>
                    <div>
                      <select
                        className="selectpicker form-control add-new"
                        // id='bank_account'
                        data-live-search="true"
                        title="Choose"
                        value={this.state.selected_bank}
                        id="balanceSheetCategeory"
                        onChange={e => {
                          if (e.target.value == '1e') {
                            jQuery('#balanceSheetCategeory option').prop('selected', false).trigger('change');
                            jQuery('#balanceSheetCategeory').val('');
                            window.jQuery('#pop-modal').modal('show');
                          } else {
                            this.bankSelected(e);
                          }
                        }}
                      >
                        <option value="">choose...</option>
                        <option value="1e"> Add New </option>

                        {this.state.banks.length > 0 &&
                          this.state.banks.map((item, i) => {
                            // if (
                            //   this.props.location.state !=
                            //     undefined &&
                            //   this.props.location.state.finance
                            //     .sales_default_account === item.id
                            // ) {
                            //   var selected = 'selected'
                            // }
                            return (
                              <option
                                value={item.id}
                                // selected={selected}
                                // dataStatus={item.currency}
                                disabled={item.status == 2 ? true : false}
                                style={{
                                  display: item.status == 2 ? 'none' : 'block',
                                }}
                              >
                                {item.name}
                              </option>
                            );
                          })}
                      </select>
                      {this.state.is_invalid_bank_account ? (
                        <div>
                          <small style={{ color: 'red' }}>Required.</small>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <div className="form-group col-md-4 mar-b-no">
                    <label className="bal-txt wc">
                      Ending Balance:{' '}
                      <strong>
                        {this.state.bankCurency} {''} {this.state.bank_balance}
                      </strong>
                    </label>
                  </div>
                </form>
              </div>
              {/* content-top Starts here */}
              {/* Main Content Starts here */}
              <div className="main-content col-md-12 col-xs-12 pad-t-no">
                <div className="content-sec col-md-12 col-xs-12 pad-no">
                  <form className="custom-form invoice-form">
                    <div className="row">
                      <div className="form-group col-md-4">
                        <label>
                          Payer Name<span className="astrick">*</span>
                        </label>
                        <div>
                          <select
                            className="selectpicker form-control add-new"
                            // id='payor_name'
                            data-live-search="true"
                            title="Choose"
                            value={this.state.payor_name}
                            id="variable_pay_type"
                            onChange={e => {
                              let value = e.target.value;
                              this.handleChange(value);
                            }}
                          >
                            <option value="1qw">Choose...</option>
                            <option>Create New </option>
                            {this.state.payee_names.length > 0 &&
                              this.state.payee_names.map(val => {
                                return (
                                  <option
                                    value={val.id}
                                    disabled={val.status == 2 ? true : false}
                                    style={{
                                      display: val.status == 2 ? 'none' : 'block',
                                    }}
                                  >
                                    {val.name}
                                  </option>
                                );
                              })}
                            {/* {this.state.payee_names.length > 0 &&
                              this.state.payee_names.map((item, i) => {
                                // if (
                                //   this.props.location.state != undefined &&
                                //   this.props.location.state.finance
                                //     .sales_default_account === item.id
                                // ) {
                                //   var selected = "selected";
                                // }
                                return (
                                  <option
                                    value={item}
                                    // selected={selected}
                                    data-status={item.id}
                                  >
                                    {item.name}
                                  </option>
                                );
                              })} */}
                          </select>
                          {this.state.is_invalid_payer ? (
                            <div>
                              <small style={{ color: 'red' }}>Required.</small>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                      <div className="form-group col-md-4">
                        <label>
                          Currency<span className="astrick">*</span>
                        </label>
                        <div>
                          <select
                            className="selectpicker form-control add-new"
                            id="bank_account"
                            data-live-search="true"
                            title="Choose"
                            value={this.state.bankCurency}
                            onChange={e => {
                              this.setState({
                                currency: e.target.value,
                              });
                              alert('bank currency and payment currency should be same ');
                              // this.setState({
                              //   currency: e.target.value,
                              //   exchange_rate: this.state.currency_clone[
                              //     e.target.value
                              //   ],
                              // });
                            }}
                          >
                            <option value="1qw">Choose...</option>

                            {this.state.currencies.length > 0 &&
                              this.state.currencies.map((item, i) => {
                                // if (
                                //   this.props.location.state !=
                                //     undefined &&
                                //   this.props.location.state.finance
                                //     .sales_default_account === item.id
                                // ) {
                                //   var selected = 'selected'
                                // }
                                return (
                                  <option
                                    value={item}

                                    // selected={selected}
                                    // data-status={item.id}
                                  >
                                    {item}
                                  </option>
                                );
                              })}
                          </select>
                          {this.state.is_invalid_currency ? (
                            <div>
                              <small style={{ color: 'red' }}>Required.</small>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                      <div className="form-group col-md-4">
                        <label>
                          Reference No#<span className="astrick">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name
                          value={this.state.reference_no}
                          autoComplete="off"
                          onChange={e => this.setState({ reference_no: e.target.value })}
                          onBlur={e => {
                            if (e.target.value != '') {
                              this.setState({ is_invalid_reference_no: false });
                            }
                          }}
                          required
                        />
                        {this.state.is_invalid_reference_no ? (
                          <div>
                            <small style={{ color: 'red' }}>Required.</small>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-4">
                        <label>
                          Transaction Date<span className="astrick">*</span>
                        </label>
                        <div className="input-group date mar-t-no" data-date-format="dd/mm/yyyy">
                          <input
                            type="text"
                            className="form-control"
                            id="transaction_date"
                            autoComplete="off"
                            onBlur={() => this.changeDate()}
                          />
                          <div className="input-group-addon" onClick={() => jQuery('#transaction_date').focus()}>
                            <img src="images/calendar-icon.svg" alt="icon" />
                          </div>
                        </div>
                        {this.state.is_invalid_transaction_date ? (
                          <div>
                            <small style={{ color: 'red' }}>Required.</small>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className="form-group col-md-4">
                        <label>
                          Amount<span className="astrick">*</span>
                        </label>
                        <NumberFormat
                          value={this.state.amountPlainValue}
                          thousandSeparator={true}
                          autoComplete="off"
                          className="form-control"
                          placeholder={0}
                          // onKeyPress={(event) => {
                          //   if (!/[0-9]/.test(event.key)) {
                          //     event.preventDefault();
                          //   }
                          // }}
                          onBlur={e => {
                            if (e.target.value != '' && e.target.value != 0) {
                              this.setState({ is_invalid_transaction_amount: false });
                            }
                          }}
                          onChange={e => this.setState({ amount: e.target.value.split(',').join('') })}
                          onValueChange={values => {
                            const { formattedValue, value } = values;
                            this.setState({ amount: value });
                            this.setState({ amountPlainValue: formattedValue });
                          }}
                        />
                        {this.state.is_invalid_transaction_amount ? (
                          <div>
                            <small style={{ color: 'red' }}>Required.</small>
                          </div>
                        ) : (
                          ''
                        )}
                        {/* <input
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          className="form-control"
                          name
                          onChange={(e) =>
                            this.setState({ amount: e.target.value })
                          }
                          onBlur={(e) =>{
                            if(e.target.value != '' && e.target.value != 0) {
                              this.setState({ is_invalid_transaction_amount: false })
                            }
                          }
                          }
                          value={this.state.amount}
                          autoComplete="off"
                          placeholder={0}
                        />
                          {this.state.is_invalid_transaction_amount ? (
                          <div >
                            <small style={{ color: "red" }}>Required.</small>
                          </div>
                        ) : (
                          ""
                        )} */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-4">
                        <label>
                          Total Amount in Words
                          <span className="astrick">*</span>
                        </label>
                        <textarea
                          className="form-control"
                          defaultValue={''}
                          // value={toWords.convert(
                          //   isNaN(Number(this.state.amount))
                          //     ? 0
                          //     : Number(this.state.amount)
                          // )}
                          value={`${
                            isNaN(Number(this.state.amount))
                              ? ''
                              : config.currencies_using_word_lakh.indexOf(this.state.bankCurency) >= 0
                              ? toWords.convert(Number(this.state.amount))
                              : _.startCase(_.toLower(writtenNumber(this.state.amount)))
                          } only`}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label>Memo</label>
                        <textarea
                          className="form-control"
                          defaultValue={''}
                          onChange={e => this.setState({ memo: e.target.value })}
                          value={this.state.memo}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-12">
                        <div className="table-responsive col-md-12" id="changeTableProps">
                          <table className="invoice-item-table">
                            <thead>
                              <tr>
                                <th>No</th>
                                <th>
                                  Category<span className="astrick">*</span>
                                </th>
                                <th>Third Party Name</th>
                                <th>Job</th>
                                <th>Memo</th>
                                <th className="text-right">
                                  Amount<span className="astrick">*</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody className="ui-sortable" ref={this.sortableRef}>
                              {this.state.item_array &&
                                this.state.item_array.map((item, j) => {
                                  let itemid = j;
                                  return (
                                    <tr key={item.id}>
                                      <td>
                                        <span className="drag-icon">
                                          <img src="images/dots-menu.svg" alt="icon" />
                                        </span>
                                        <span>{j + 1}</span>
                                      </td>
                                      <td style={{ width: '30%' }}>
                                        <select
                                          className="selectpicker form-control add-new hello"
                                          data-live-search="true"
                                          title="Choose "
                                          name="catagory_id"
                                          id={'catagory_id'}
                                          value={item.catagory_id}
                                          onChange={e => {
                                            const { name, value } = e.target;
                                            var myarray = this.state.item_array;
                                            myarray[j][name] = value;

                                            myarray[j]['third_party_acc_id'] = 0;
                                            myarray[j]['selected_job_id'] = 0;
                                            this.setState({
                                              item_array: myarray,
                                            });
                                            if (e.target.value == '1e') {
                                              jQuery(`#categry_id${itemid} option`).prop('selected', false).trigger('change');

                                              window.jQuery('#pop-modal').modal('show');
                                              this.setState({ is_add_from_table_list: true, table_index_to_add_category: j });
                                            }

                                            this.handleTable(itemid);

                                            var selectAcct = this.state.balancesheetlist.filter(
                                              item => item.id === Number(e.target.value),
                                            )[0];

                                            // account_type_id ===5
                                            if (selectAcct.account_type_id === 5) {
                                              // jQuery("#third_account_id").attr("disabled", false);
                                              this.setState({
                                                isThirdPartyName: true,
                                              });
                                              this.setState({
                                                thrird_party_type: 2,
                                              });
                                              this.fetchThirdPartyNames(5, j);
                                            } else if (selectAcct.account_type_id === 2) {
                                              // jQuery("#third_account_id").attr("disabled", false);
                                              this.setState({
                                                isThirdPartyName: true,
                                              });
                                              this.setState({
                                                thrird_party_type: 1,
                                              });
                                              this.fetchThirdPartyNames(2, j);
                                            } else {
                                              // jQuery("#third_account_id").attr("disabled", true);
                                              this.setState({
                                                isThirdPartyName: false,
                                              });
                                            }
                                          }}
                                        >
                                          <option value="1e">Create New </option>

                                          {this.state.balancesheetlist &&
                                            this.state.balancesheetlist.map(item => {
                                              return (
                                                <option
                                                  value={item.id}
                                                  data-status={item.name}
                                                  disabled={item.status == 2 ? true : false}
                                                  style={{
                                                    display: item.status == 2 ? 'none' : 'block',
                                                  }}
                                                >
                                                  {item.name}
                                                </option>
                                              );
                                            })}
                                        </select>
                                      </td>
                                      <td>
                                        <select
                                          className="selectpicker form-control add-new"
                                          data-live-search="true"
                                          title="Choose..."
                                          id="third_account_id"
                                          name="third_party_acc_id"
                                          value={item.third_party_acc_id}
                                          disabled={
                                            this.state?.balancesheetlist?.filter(
                                              item => `${item.id}` === this.state.item_array[j].catagory_id,
                                            )[0]?.account_type_id === 5
                                              ? false
                                              : this.state?.balancesheetlist?.filter(
                                                  item => `${item.id}` === this.state.item_array[j].catagory_id,
                                                )[0]?.account_type_id === 2
                                              ? false
                                              : true
                                          }
                                          onChange={e => {
                                            const { name, value } = e.target;
                                            var myarray = this.state.item_array;
                                            myarray[j][name] = value;
                                            myarray[j]['selected_job_id'] = 0;
                                            this.setState({
                                              item_array: myarray,
                                            });

                                            // alert( jQuery("#third_account_id").val())
                                            // if (
                                            //   jQuery(
                                            //     "#third_account_id"
                                            //   ).val() == "new"
                                            // ) {
                                            //   console.log(
                                            //     "1111",
                                            //     this.state.thrird_party_type
                                            //   );
                                            //   if (
                                            //     this.state.thrird_party_type ==
                                            //     1
                                            //   ) {
                                            //     localStorage.setItem(
                                            //       "comes_from",
                                            //       "third_party"
                                            //     );
                                            //     let win = window.open(
                                            //       "/add-new-customer",
                                            //       "_blank"
                                            //     );
                                            //     win.focus();
                                            //   }
                                            //   if (
                                            //     this.state.thrird_party_type ==
                                            //     2
                                            //   ) {
                                            //     localStorage.setItem(
                                            //       "comes_from",
                                            //       "third_party"
                                            //     );
                                            //     let win = window.open(
                                            //       "/add_new_vendor",
                                            //       "_blank"
                                            //     );
                                            //     win.focus();
                                            //   }
                                            //   // alert( 'in')
                                            // }
                                          }}
                                        >
                                          {/* <option>Create New </option> */}
                                          <option value="new">Choose...</option>
                                          {/* {this.state[
                                            `third_party_account_list${j}`
                                          ] &&
                                            this.state[
                                              `third_party_account_list${j}`
                                            ].map((item) => {
                                              return (
                                                <option
                                                  value={item.id}
                                                  data-status={item.name}
                                                >
                                                  {item.name}
                                                </option>
                                              );
                                            })} */}
                                          {this.state.sourceNameOptions.map(val => {
                                            return (
                                              <option
                                                value={JSON.stringify(val.id)}
                                                disabled={val.status === 2 ? true : false}
                                                style={{
                                                  display: val.status === 2 ? 'none' : 'block',
                                                }}
                                              >
                                                {val.name}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </td>

                                      <td>
                                        <select
                                          className="selectpicker form-control add-new"
                                          data-live-search="true"
                                          title="Choose..."
                                          id="selected_job_id"
                                          name="selected_job_id"
                                          value={item.selected_job_id}
                                          disabled={
                                            this.state?.balancesheetlist?.filter(
                                              item => `${item.id}` === this.state.item_array[j].catagory_id,
                                            )[0]?.account_type_id === 2
                                              ? false
                                              : true
                                          }
                                          onChange={e => {
                                            const { name, value } = e.target;
                                            let table_items_array = this.state.item_array;
                                            table_items_array[j]['selected_job_id'] = value;
                                            this.setState({
                                              item_array: table_items_array,
                                            });
                                          }}
                                        >
                                          <option value="new">Choose...</option>
                                          {this.state.jobList.map(val => {
                                            return (
                                              <option
                                                value={JSON.stringify(val.job_id)}
                                                disabled={val.status === 2 ? true : false}
                                                style={{
                                                  display: val.status === 2 ? 'none' : 'block',
                                                }}
                                              >
                                                {val.job_name}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </td>

                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder=""
                                          id="memo"
                                          name="memo"
                                          value={item.memo}
                                          autoComplete="off"
                                          onChange={this.handleTable(j)}
                                        />
                                      </td>
                                      <td className="text-right">
                                        <input
                                          type="text"
                                          name="amount"
                                          id="totalAmount"
                                          className="form-control"
                                          placeholder="00.00"
                                          value={item.amount}
                                          autoComplete="off"
                                          onChange={this.handleTable(j)}
                                        />

                                        <div className="action-wrap">
                                          <a href="javascript:;" className="clone-row" onClick={() => this.copyRow(j)}>
                                            <img src="images/clone-icon.svg" alt="icon" />
                                          </a>
                                          {j > 0 ? (
                                            <a href="javascript:;" className="del-row" onClick={() => this.deleteRow(j)}>
                                              <img src="images/delete-icon.svg" alt="icon" />
                                            </a>
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                          <div className="form-group col-md-6 mar-b-no">
                            {this.state.message == true ? (
                              <div style={{ float: 'left' }}>
                                <small style={{ color: 'red' }}>Please fill out mandatory fields in Table items.</small>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className="form-group col-md-6 mar-b-no">
                            <a
                              href="javascript:;"
                              className="add-input"
                              onClick={() => {
                                this.addRow(this.state.item_array.length + 1);
                              }}
                            >
                              ADD ROW
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 total-row">
                        <div className="row">
                          <div className="form-group exchange-col col-md-5 col-xs-12">
                            <label className="fw-sbold">
                              Exchange Rate 1 {''} {this.state.currency}
                            </label>
                            <div>
                              <input
                                type="text"
                                name="exchangeRate"
                                className="form-control"
                                defaultValue="0.00"
                                value={this.state.exchange_rate}
                                onChange={e => {
                                  this.setState({ exchange_rate: parseFloat(e.target.value) });
                                }}
                              />
                              <span className="label">{this.state.clientHomeCurrency}</span>
                            </div>
                          </div>
                          <div className="form-group col-md-7 col-xs-12 total-table">
                            <table className="pull-right">
                              <thead>
                                <tr>
                                  <th>&nbsp;</th>
                                  <th className="text-center">
                                    Foreign Currency
                                    <br />({this.state.bankCurency})
                                  </th>
                                  <th className="text-center">
                                    Home Currency
                                    <br />({this.state.clientHomeCurrency})
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="text-right">Sub Total</td>
                                  <td className="text-center">
                                    {' '}
                                    {this.total()
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  </td>
                                  <td className="text-center">
                                    {(Number(this.total()) * Number(this.state.exchange_rate))
                                      .toFixed(2)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-right">Tax</td>
                                  <td className="text-center">00.00</td>
                                  <td className="text-center">00.00</td>
                                </tr>
                                <tr>
                                  <td className="text-right">Grand Total</td>
                                  <td className="text-center">
                                    {this.total()
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  </td>
                                  <td className="text-center">
                                    {' '}
                                    {(Number(this.total()) * Number(this.state.exchange_rate))
                                      .toFixed(2)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {/* Main Content Ends here */}
              <div className="invoice-form">
                <div className="pf-btm-wrap xs-pad-all">
                  <button
                    className="btn btn-empty ico"
                    // onClick={this.convertHtmlToPdf.bind(this)}
                    type="button"
                    onClick={() => window.jQuery('#attachment_modal').modal('show')}
                  >
                    <img src="images/attach-icon.svg" alt="icon" />
                    Attachment <span className="blue-txt">{this.state.list_of_attachments.length}</span>
                  </button>
                  <div className="col-md-12 col-xs-12 text-right pad-no">
                    {this.state.deposit_id && this.state.amount > 0 ? (
                      // <button type="button"  data-toggle="modal" data-target="#modal_void_invoice" className="btn btn-green mar-rgt-5 btn-align">
                      //   Void
                      // </button>
                      <>
                        <button
                          className="btn btn-green dropdown-toggle btn useDRP mar-rgt-5"
                          type="button"
                          data-toggle="dropdown"
                        >
                          Action
                          <span className="caret" />
                        </button>
                        <ul className="dropdown-menu category">
                          <li>
                            <a data-toggle="modal" data-target="#modal_void_invoice">
                              Void
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => {
                                this.createCopy();
                              }}
                            >
                              Create a copy
                            </a>
                          </li>
                        </ul>
                      </>
                    ) : (
                      ''
                    )}
                    <button type="button" onClick={() => this.clear()} className="btn btn-lightgray mar-rgt-5 btn-align">
                      Clear
                    </button>
                    <button
                      type="button"
                      disabled={this.total() == Number(this.state.amount).toFixed(2) || !this.state.buttonLoading ? false : true}
                      className="btn btn-blue mar-rgt-5 btn-align"
                      style={{
                        opacity: this.total() == Number(this.state.amount).toFixed(2) || !this.state.buttonLoading ? 1 : 0.5,
                      }}
                      onClick={() => {
                        var urlParams = new URLSearchParams(window.location.search);
                        // var checkedIfCreated= urlParams.size > 0 ? this.state.lock_date >= this.state.transaction_date ||  this.state.lock_date <= this.state.transaction_date  : this.state.lock_date >= this.state.transaction_date ;
                        let checkedIfCreated = false;
                        if (
                          !this.state.item_array.every(d => {
                            let cd = this.state.balancesheetlist.find(item => item.id === parseInt(d['catagory_id']));

                            if (cd.name.includes('Payable') || cd.name.includes('Receivable')) {
                              if (d['third_party_acc_id'] != 0) {
                                return true;
                              } else {
                                return false;
                              }
                            } else {
                              return true;
                            }
                          })
                        ) {
                          Swal.fire('Third Party Name are Required');
                        } else {
                          if (this.state.lock_date) {
                            checkedIfCreated =
                              this.state.deposit_id && this.state.old_transaction_date
                                ? this.state.lock_date >= this.state.transaction_date ||
                                  (this.state.old_transaction_date <= this.state.lock_date &&
                                    this.state.transaction_date >= this.state.lock_date)
                                : this.state.lock_date >= this.state.transaction_date;
                          }
                          if (checkedIfCreated) {
                            window.jQuery('#lock_date').modal('show');
                            this.setState({ saveType: 'savenew' });
                          } else {
                            this.save('savenew');
                          }
                        }
                      }}
                    >
                      Save &amp; New
                    </button>
                    <button
                      type="button"
                      disabled={this.total() == Number(this.state.amount).toFixed(2) || !this.state.buttonLoading ? false : true}
                      className="btn btn-green mar-rgt-5 btn-align"
                      onClick={() => {
                        var urlParams = new URLSearchParams(window.location.search);
                        // var checkedIfCreated= urlParams.size > 0 ? this.state.lock_date >= this.state.transaction_date ||  this.state.lock_date <= this.state.transaction_date  : this.state.lock_date >= this.state.transaction_date ;
                        let checkedIfCreated = false;
                        if (
                          !this.state.item_array.every(d => {
                            let cd = this.state.balancesheetlist.find(item => item.id === parseInt(d['catagory_id']));

                            if (cd.name.includes('Payable') || cd.name.includes('Receivable')) {
                              if (d['third_party_acc_id'] != 0) {
                                return true;
                              } else {
                                return false;
                              }
                            } else {
                              return true;
                            }
                          })
                        ) {
                          Swal.fire('Third Party Name are Required');
                        } else {
                          if (this.state.lock_date) {
                            checkedIfCreated =
                              this.state.deposit_id && this.state.old_transaction_date
                                ? this.state.lock_date >= this.state.transaction_date ||
                                  (this.state.old_transaction_date <= this.state.lock_date &&
                                    this.state.transaction_date >= this.state.lock_date)
                                : this.state.lock_date >= this.state.transaction_date;
                          }
                          if (checkedIfCreated) {
                            window.jQuery('#lock_date').modal('show');
                            this.setState({ saveType: 'save' });
                          } else {
                            this.save('save');
                          }
                        }
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* MainContent Wrapper Ends here */}
          </div>
          {this.state.error == true ? (
            <div className="alert alert-card warning alert-dismissible fade in ">
              <a
                href="#"
                className="close"
                data-dismiss="alert"
                aria-label="close"
                onClick={() => {
                  this.setState({ error: false });
                }}
              >
                ×
              </a>
              <div className="img-wrap">
                <img className="img-responsive" src="images/alert-warning.svg" alt="icon" />
              </div>
              <div className="alert-cont">
                <strong className="title">{this.state.errormessage}</strong>
              </div>
            </div>
          ) : null}
          {/* add new modal */}

          <div className="modal fade pop-modal" id="add-new-modal" role="dialog" data-backdrop="static">
            <div className="modal-dialog modal-xs custom-modal">
              {/* Modal content*/}
              <button type="button" className="close hidden-xs" data-dismiss="modal">
                <img className="img-responsive" src="images/close-red.svg" alt="icon" />
              </button>
              <div className="modal-content">
                <div className="modal-body text-center">
                  <h3>Please select one</h3>
                  <form className="custom-form row column">
                    <div className="form-group col-md-12 col-xs-12">
                      <div className="check-row">
                        <label className="custom-checkbox radio mar-rgt taxable">
                          <input
                            type="radio"
                            name="mail-address"
                            onClick={() => {
                              jQuery('#entername').hide();
                              this.setState({ radio: 'Customer' });
                            }}
                          />
                          <span>Customer</span>
                          <span className="checkmark" />
                        </label>
                        <label className="custom-checkbox radio mar-rgt taxable">
                          <input
                            type="radio"
                            name="mail-address"
                            onClick={() => {
                              jQuery('#entername').hide();
                              this.setState({ radio: 'Vendor' });
                            }}
                          />
                          <span>Vendor</span>
                          <span className="checkmark" />
                        </label>
                        <label className="custom-checkbox radio">
                          <input
                            type="radio"
                            name="mail-address"
                            onClick={() => {
                              jQuery('#entername').show();
                              this.setState({ radio: 'Employee' });
                            }}
                          />
                          <span>Employee</span>
                          <span className="checkmark" />
                        </label>
                        <label className="custom-checkbox radio">
                          <input
                            type="radio"
                            name="mail-address"
                            onClick={() => {
                              jQuery('#entername').show();
                              this.setState({ radio: 'Others' });
                            }}
                          />
                          <span>Others</span>
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                    <div className="col-md-12" id="entername">
                      <div className="form-group">
                        <label>
                          Enter Name<span className="astrick">*</span>
                        </label>
                        <input
                          autoComplete="off"
                          type="text"
                          className="form-control"
                          name="entry_no"
                          value={this.state.new_name}
                          onChange={e => this.setState({ new_name: e.target.value })}
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-12 col-xs-12 mh btn-sec text-center mar-b-no">
                      <button className="btn btn-lightgray mar-rgt-5" data-dismiss="modal">
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-green mar-rgt-5"
                        data-dismiss="modal"
                        onClick={e => {
                          if (this.state.radio == 'Customer') {
                            window.open('/add-new-customer');
                          } else if (this.state.radio == 'Vendor') {
                            window.open('/add_new_vendor');
                          } else if (this.state.radio == 'Employee') {
                            if (this.state.new_name != '') {
                              this.saveEmp();
                            }
                          } else if (this.state.radio == 'Others') {
                            if (this.state.new_name != '') {
                              this.saveOthers();
                            }
                          }
                        }}
                      >
                        OK
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* attachment_modal */}
          <div className="modal fade pop-modal" id="attachment_modal" role="dialog" ata-backdrop="static">
            <div className="modal-dialog modal-md custom-modal">
              <button type="button" className="close hidden-xs" data-dismiss="modal">
                <img className="img-responsive" src="../../images/close-red.svg" alt="icon" />
              </button>
              <div className="modal-content">
                <div className="modal-body text-center">
                  <h3>
                    Attachments <span className="blue-txt">{this.state.list_of_attachments.length}</span>
                  </h3>
                  <form className="custom-form row">
                    <div className="form-group col-md-12 col-xs-12 pad-no mar-b-no searchscroll">
                      {this.state.list_of_attachments.length > 0 &&
                        this.state.list_of_attachments.map(item => (
                          <div key={item.source_id} className="attachment-preview ">
                            <a
                              href="javascript:;"
                              onClick={() => {
                                var win = window.open(`${item.source_path}`, '_blank');
                                win.focus();
                              }}
                            >
                              {item.source_path.split('/')[5]}
                            </a>
                            <button
                              type="button"
                              className="attachment-cancel"
                              onClick={() => {
                                this.setState({
                                  list_of_attachments: this.state.list_of_attachments.filter(x => x.source_id !== item.source_id),
                                });
                              }}
                            >
                              X
                            </button>
                          </div>
                        ))}
                    </div>
                    <button
                      data-dismiss="modal"
                      className="btn btn-green btn-align"
                      type="button"
                      onClick={() => window.jQuery('#upload_doc_modal').modal('show')}
                    >
                      Upload attachment
                    </button>
                    <div className="form-group col-md-12 col-xs-12 btn-sec pad-no mar-b-no">
                      <button className="btn btn-lightgray btn-align" data-dismiss="modal">
                        Close
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* upload_doc_modal */}
          <div className="modal fade pop-modal" id="upload_doc_modal" role="dialog" ata-backdrop="static">
            <div className="modal-dialog modal-md custom-modal">
              <button type="button" className="close hidden-xs" data-dismiss="modal">
                <img className="img-responsive" src="../../images/close-red.svg" alt="icon" />
              </button>
              <div className="modal-content">
                <div className="modal-body text-center">
                  <h3>Upload Attachments</h3>
                  <form className="custom-form row">
                    <div className="form-group  pad-no mar-b-no">
                      <div className="">
                        <div className="form-group">
                          {/* <label>Upload Attachments<span className="astrick">*</span></label> */}
                          <div className="upload-wizard">
                            <div className="upload-thumb hide">
                              <ul className="list-inline">
                                {this.state.attachment_file.map((file_data, index) => {
                                  var file_url = URL.createObjectURL(file_data);
                                  var file_name = file_data.name;
                                  if (file_data.type === 'application/pdf') {
                                    var blob = file_url;
                                    //return this.createPdfImg(file_data);

                                    return (
                                      <li className="previewImg">
                                        <div className="thumb-img">
                                          <a
                                            href="javascript:;"
                                            className="del-btn"
                                            onClick={() => {
                                              this.removeImage(file_data.name, index);
                                            }}
                                          >
                                            <img src="./images/close-icon-white.svg" alt="delete" />
                                          </a>
                                          <PDFtoIMG file={blob}>
                                            {({ pages }) => {
                                              console.log('check length', pages);

                                              if (!pages.length) return 'check Loading...';
                                              return pages.map((page, index) =>
                                                index == 0 ? <img key={index} src={page} title={file_name} alt="pdf" /> : '',
                                              );
                                            }}
                                          </PDFtoIMG>
                                        </div>
                                        <span className="file-name">{file_name}</span>
                                      </li>
                                    );
                                  } else {
                                    return (
                                      <li className="previewImg">
                                        <div className="thumb-img">
                                          <a
                                            href="javascript:;"
                                            className="del-btn"
                                            onClick={() => {
                                              this.removeImage(file_data.name);
                                            }}
                                          >
                                            <img src="./images/close-icon-white.svg" alt="delete" />
                                          </a>

                                          <img
                                            src={
                                              file_data.type === 'application/vnd.ms-excel'
                                                ? '/images/file-icon.svg'
                                                : file_data.type ===
                                                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                                ? '/images/file-icon.svg'
                                                : file_url
                                            }
                                            title={file_data.name}
                                            alt={file_data.name}
                                          />
                                        </div>
                                        <span className="file-name">{file_data.name}</span>
                                      </li>
                                    );
                                  }
                                })}

                                <li className="addMore">
                                  <input
                                    type="file"
                                    name="imgInp[]"
                                    id="imgInp2"
                                    className="add_img"
                                    multiple
                                    onChange={this.loadFile.bind(this)}
                                    accept="image/*,application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                  />
                                  <a href="javascript:;" className="add-more">
                                    <div>
                                      <img src="images/upload-icon.svg" alt="icon" />
                                      Add or Drag here
                                    </div>
                                  </a>
                                </li>

                                {/* <li className="addMore">
                                <a href="javascript:;"  className="add-more" primary={false} onClick={() => {this.upload.click();}}>
                                  <div >
                                  <input id="myInput" type="file" ref={ref => (this.upload = ref)} style={{ display: "none" }}
                                      onChange={this.onChangeFile.bind(this)}/>
                                    <label htmlFor="file">{file}</label>

                                    {this.state.file ? (
                                      <PDFtoIMG file={this.state.file}>
                                        {({ pages }) => {
                                          console.log("length", pages);

                                          if (!pages.length)
                                            return "Loading...";
                                          return pages.map((page, index) => (
                                            (index==0)?
                                            <img   key={index} src={page} style={{height:104.9,width:105.9,marginTop: 13,}}  />
                                            
                                            :""
                                          ));
                                        }}
                                      </PDFtoIMG>
                                    ) : (
                                      <div>Add more</div>
                                    )}
                                    <span className="file-name">{fileName}</span>                              
                                  </div>                                  
                                </a>
                              </li> */}
                              </ul>
                            </div>
                            <div className="upload-space">
                              <input
                                type="file"
                                name="imgInp[]"
                                id="imgInp"
                                className="add_img"
                                multiple
                                onChange={this.loadFile.bind(this)}
                                accept="image/*,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              />
                              {/* <img id="output" src="#" alt="your image" width="70" height="50" /> */}
                              <div>
                                <img className="icon" src="images/upload-icon.svg" alt="icon" />
                                <span>.pdf .png .jpg .xls .xlsx</span>
                                <span className="note-txt">
                                  Drag and drop your files here
                                  <br />
                                  or
                                  <br />
                                  <a href="javascript:;">click here</a>
                                </span>
                              </div>
                            </div>
                          </div>
                          {this.state.is_files_not_selected ? (
                            <div>
                              <small style={{ color: 'red' }}>Please select atleast one file</small>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-md-12 col-xs-12 btn-sec pad-no mar-b-no">
                      <button className="btn btn-lightgray btn-align" data-dismiss="modal">
                        Close
                      </button>
                      <span>{'   '}</span>
                      <button className="btn btn-green btn-align" type="button" onClick={() => this.save_attachment()}>
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* for lock date modal */}
          <div>
            <div class="modal fade in" id="lock_date" role="dialog" style={{ paddingLeft: 15 }}>
              <div class="modal-dialog modal-md" style={{ width: 440 }}>
                <button type="button" class="close hidden-xs" data-dismiss="modal">
                  <img class="img-responsive" src="../../images/close-red.svg" alt="icon" />
                </button>
                <div class="modal-content">
                  <div class="modal-body text-center success-modal">
                    <div class="pop-icon img-size">{/* {<img src="../../images/delete-icon.svg" alt="icon" />} */}</div>

                    <h3>Are you sure?</h3>

                    <p class="fw-500">This date is beyond lock date. To apply this date , need to verify Lock Date password</p>
                    <button className="btn btn-lightgray btn-align" data-dismiss="modal">
                      Cancel
                    </button>
                    <span>{'   '}</span>
                    <button
                      class="btn btn-red btn-align"
                      type="button"
                      data-dismiss="modal"
                      data-toggle="modal"
                      data-target="#asking_password_for_delete"
                      // onClick={this.deleteUser}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade pop-modal" id="asking_password_for_delete">
            <div className="modal-dialog modal-xs custom-modal">
              {/* Modal content*/}
              <button type="button" className="close hidden-xs" data-dismiss="modal">
                <img className="img-responsive" src="images/close-red.svg" alt="icon" />
              </button>
              <div className="modal-content">
                <div className="modal-body text-center">
                  <h3>Change Password</h3>
                  <form className="custom-form row column">
                    <div className="form-group col-md-12 col-xs-12">
                      <label>
                        Enter Lock Password
                        <span className="astrick">*</span>
                      </label>
                      <div className="form-group login-eye">
                        <i
                          className="pass-visible"
                          toggle="#password-fieldc"
                          onClick={() =>
                            this.setState({
                              show: !this.state.show,
                            })
                          }
                        >
                          <img className="off" src="images/visibility-off.svg" alt="hide" />
                          <img className="on" src="images/visibility.svg" alt="show" />
                        </i>
                        <input
                          type={this.state.show ? 'text' : 'password'}
                          value={this.state.old_password}
                          className="form-control"
                          required="required"
                          autocomplete="off"
                          onChange={e =>
                            this.setState({
                              old_password: e.target.value,
                            })
                          }
                        />
                      </div>
                      {this.state.old_password == '' ? (
                        <div style={{ float: 'left' }}>
                          <small style={{ color: 'red' }}>please enter this password field.</small>
                        </div>
                      ) : (
                        ''
                      )}
                      {this.state.old_password != '' && this.state.deleteMsg == true ? (
                        <div style={{ float: 'left' }}>
                          <small style={{ color: 'red' }}>*Incorrect password</small>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="form-group col-md-12 col-xs-12 mh btn-sec text-center mar-b-no">
                      <button className="btn btn-lightgray mar-rgt-5" data-dismiss="modal">
                        Cancel
                      </button>
                      <input
                        type="button"
                        className="btn btn-green mar-rgt-5"
                        value="Submit"
                        onClick={() => {
                          if (this.state.old_password !== '') {
                            this.checkPassword();
                          }
                        }}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* for lock date modal */}
          {/* modal for asking delete invoice */}
          <div>
            <div class="modal fade in" id="modal_void_invoice" role="dialog" style={{ paddingLeft: 15 }}>
              <div class="modal-dialog modal-md" style={{ width: 440 }}>
                <button type="button" class="close hidden-xs" data-dismiss="modal">
                  <img class="img-responsive" src="../../images/close-red.svg" alt="icon" />
                </button>
                <div class="modal-content">
                  <div class="modal-body text-center success-modal">
                    <div class="pop-icon img-size">{/* {<img src="../../images/delete-icon.svg" alt="icon" />} */}</div>

                    <h3>Are you sure?</h3>

                    <p class="fw-500">This deposit will be voided </p>
                    <button className="btn btn-lightgray btn-align" data-dismiss="modal">
                      Cancel
                    </button>
                    <span>{'   '}</span>
                    <button class="btn btn-red btn-align" type="button" onClick={() => this.delete_or_void_deposit(11)}>
                      Make Void
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* modal for asking delete invoice    */}

          {this.state.isSuccessful ? (
            <div className="alert alert-card success alert-dismissible fade in" id="closeme1">
              <a
                href="#"
                className="close"
                data-dismiss="alert"
                aria-label="close"
                onClick={() => {
                  this.setState({ isSuccessful: false });
                }}
              >
                &times;
              </a>
              <div className="img-wrap">
                <img className="img-responsive" src="../../images/alert-success.svg" alt="icon" />
              </div>
              <div className="alert-cont">
                <strong className="title">Success!</strong>
                <strong>Deposit Saved Successfully</strong>
              </div>
            </div>
          ) : (
            ''
          )}

          {this.state.isFailed && (
            <div className="alert alert-card danger alert-dismissible fade in">
              <a
                href="#"
                className="close"
                onClick={() => {
                  this.setState({ isFailed: false });
                }}
                data-dismiss="alert"
                aria-label="close"
              >
                ×
              </a>
              <div className="img-wrap">
                <img className="img-responsive" src="images/alert-cross.svg" alt="icon" />
              </div>
              <div className="alert-cont">
                <strong className="title">Failed!</strong>
                {this.state.errormessage}
              </div>
            </div>
          )}

          {/* add new modal */}
        </div>

        {/* Main Wrapper Ends here */}
        {/* footer Starts here */}
        <Footer defaultcategorylist_onchange={this.defaultcategorylist_onchange} logoutSubmit={e => this.logoutLink(e)} />
        {/* footer Ends here */}
      </div>
    );
  }
}
export default MakeDeposit;
