import React from 'react';
import LeftSidebar from './left_sidebar';
import Footer from './footer';

import Topbar from './topbar';

import FetchAllApi from '../api_links/fetch_all_api';

import { PDFtoIMG } from 'react-pdf-to-image';
import moment from 'moment';

import jQuery from 'jquery';
import config from './../api_links/api_links';
import Swal from 'sweetalert2';
import Comma from './comma';
import NumberFormat from 'react-number-format';

// import { display } from "html2canvas/dist/types/css/property-descriptors/display";
var _ = require('lodash');

// import 'bootstrap';
// import 'bootstrap-select';

class vendor_bill_payment extends React.Component {
  constructor(props) {
    super(props);
    //const { history } = this.props;
    this.state = {
      logged_user_id: localStorage.getItem('logged_user_id'),
      logged_client_id: localStorage.getItem('logged_client_id'),
      logged_role_id: localStorage.getItem('logged_role_id'),
      logged_user_name: localStorage.getItem('logged_user_name'),
      logged_user_email: localStorage.getItem('logged_user_email'),
      logged_user_phone: localStorage.getItem('logged_user_phone'),
      logged_user_image: localStorage.getItem('logged_user_image'),
      logged_company_name: localStorage.getItem('logged_company_name'),
      edit_customer_receive_payment:
        localStorage.getItem('edit_customer_receive_payment') &&
        JSON.parse(localStorage.getItem('edit_customer_receive_payment')),
      customer_receive_payment_notification: JSON.parse(localStorage.getItem('customer_receive_payment_notification')),
      lock_date: localStorage.getItem('lock_date'),
      dropdown: '',
      rec_acc: '',
      inbox_list: [],
      response_stus: 0,
      response_msg: 'No data found',
      item_details: '',
      item_file_path: '',
      waiting_re: [],
      re_assigned: [],
      customerListArray: [],
      TotalPages: [],
      pgNo: '10',
      selected_filter_id: 1,
      selectedLimit: 10,
      overallcustomerBal: 0,
      customer_account_type: [],
      vendor_names: [],
      vendor_invoicelist: [],
      selectValue: '',
      vendor_payment_method: [],
      vendor_credit_list: [],
      vendor_category: [],
      vendor_payment_account_type: [],
      vendor_discount_terms: [],
      applied_credit_history: [],
      invoice_specific_applied_credit_history: [],
      customer_and_job_list: [],
      customer_and_job_lists: [],
      customer_and_job_listss: [],
      customer_id: '',
      job_id: '',
      currency_selected: '',
      isSuccessful: false,
      isFailed: false,
      vendor_payment_method_bank: [],
      received_account: false,
      third_party_name: [],
      received_accounts: false,
      discount_terms: '',
      job_id_details: [],
      //added
      overPayment: '',
      under: false,
      under_amt: '',
      over: false,
      overPay: '',
      invoiceListArray: [],
      totalDueFromApi: '',
      totalDuePaid: '',
      vendorBankAccountList: [],
      toBeSelected: '',
      creditList: [],
      AMNT_DUE: '',
      ID_USE: '',
      selectedCreditList: [],

      VendorAccSelcted: '',
      vendorBal: '',
      currencySelected: '',
      isThirdpartyEssenstial: false,
      // VendorAccSelcted: "",
      writeOff: '',
      vendorName: '',
      modeOfPay: '',
      recievedAccount: '',
      refundStatus: {},

      recievedAccountName: '',
      CurrVendorId: '',
      rowsNeededCredits: [],
      credittotal: 0,
      selected_payment_account: '',
      selected_account_id: '',
      //amount_of_payment: '',
      amount_of_payment: '',
      total_received_amount: 0,
      discount_account_type: '',
      discount_amount: [0, 0],
      added_discount_amount: 0,
      invoiceSeletedId: 0,
      currentInvoiceId: 0,
      TotalDiscountAmount: 0,
      TotalCreditAmount: 0,
      amount_of_payment_used: 0,
      total_amount: 0,
      credit_list: [],
      // third_party_type: 0,
      discount_array: [],
      credit_id_used: '',
      option: 1,
      third_party_type: 0,

      clientHomeCurrency: '',
      exchangeRate: '',
      unused_amt: 0,
      auto_apply: true,
      manually_applied: false,
      is_payment_amount_given: false,
      trigger_from_edit_payment: false,
      is_discount_changed: false,
      credit_available: 0,
      reference: '',
      final_invoice_credit_array: [],
      isEdit: false,
      multi_payment_applied_invoices: '',
      refund_id: 0,
      writeoff_id: 0,
      old_option: 0,
      exchange_rate_temp: 0,
      credit_hist_total: 0,
      credit_his_amounttouse: 0,
      credit_his_creditbalance: 0,
      credit_total: 0,
      credit_amounttouse: 0,
      credit_creditbalance: 0,
      errormessage: '',
      credit_object: {},
      is_payment_account_invalid: false,
      is_payment_date_invalid: false,
      currency_list: config.all_currency_list,
      is_individual_pmt_amt_given: false,
      is_added_from_adjustment_account: false,
      added_adjustment_account_index: -1,
      urlParams: new URLSearchParams(window.location.search),

      selectedFile: '',
      succ_msg: '',
      attachment_file: [],
      attachment_file_length: 0,
      attachment_fileName: [],
      imgThumb: '',
      pages: [],
      list_of_attachments: [],
      is_invalid_subject: false,
      is_invalid_message: false,
      is_files_not_selected: false,
      old_transaction_date: '',
    };
    this.myDivToFocus = React.createRef();
    this.loadFile = this.loadFile.bind(this);
  }

  removeImage = (e, index) => {
    const updated = this.state.attachment_file.filter(item => item.name !== e);
    this.setState(
      {
        attachment_file: updated,
      },
      () => {
        if (this.state.attachment_file.length === 0) {
          // Reset file input value when no files are selected
          document.getElementById('imgInp').value = '';
          // jQuery('.upload-space').removeClass('hide')
          jQuery('.upload-space').css({
            opacity: 1,
          });
          jQuery('.upload-thumb').addClass('hide');
        }
      },
    );
  };

  loadFile(e) {
    var files = e.target.files;
    this.setState({ attachment_file_length: files.length });
    console.log(files, 'res prev');
    if (files.length > 0) {
      // jQuery('.upload-space').addClass('hide')
      jQuery('.upload-space').css({
        opacity: 0,
      });
      jQuery('.upload-thumb').removeClass('hide');
      var fileArra = this.state.attachment_file;
      //var fileThumbArra = this.state.imgThumb;
      for (var i = 0; i < files.length; i++) {
        // let fname = e.target.files[i].name.toLowerCase();
        let fname = files[i].name.toLowerCase();
        if (
          fname.endsWith('.pdf') ||
          fname.endsWith('.png') ||
          fname.endsWith('.jpg') ||
          fname.endsWith('.jpeg') ||
          fname.endsWith('.xls') ||
          fname.endsWith('.xlsx')
        ) {
          // let previous_attachments = this.state.attachment_file.filter(item => item.name.toLowerCase() == e.target.files[i].name.toLowerCase());
          let previous_attachments = this.state.attachment_file.filter(item => item.name.toLowerCase() === fname);
          console.log(this.state.attachment_file, previous_attachments, 'res prev', fname);

          if (previous_attachments.length === 0) {
            // fileArra.push(e.target.files[i]);
            fileArra.push(files[i]);
            this.setState(
              {
                // selectedFile: URL.createObjectURL(e.target.files[i]),
                selectedFile: URL.createObjectURL(files[i]),
                attachment_file: fileArra,
                is_files_not_selected: false,
              },
              () => {},
            );

            // e.target.files = null;
            // e.target.value = '';
          } else {
            e.target.files = null;
            e.target.value = '';
            e.preventDefault();
            this.setState({
              isFailed: true,
              errormessage: 'Cannot select same file again',
            });

            setTimeout(() => {
              this.setState({ isFailed: false, errormessage: '' });
            }, 3000);
          }
        } else {
          e.target.files = null;
          e.target.value = '';
          e.preventDefault();
          this.setState({
            isFailed: true,
            errormessage: 'Unsupported file Format',
          });

          setTimeout(() => {
            this.setState({ isFailed: false, errormessage: '' });
          }, 3000);
        }

        if (i == files.length - 1) {
          if (this.state.attachment_file.length == 0) {
            // jQuery('.upload-space').removeClass('hide')
            jQuery('.upload-space').css({
              opacity: 1,
            });
            jQuery('.upload-thumb').addClass('hide');
          }
        }
      }
    }
  }
  save_attachment = () => {
    const client_id = this.state.logged_client_id;
    const type = 'vendor_bill_payment';
    const source_attachments = this.state.attachment_file;
    console.log(source_attachments, 'resss');
    FetchAllApi.save_source_attachements(client_id, type, source_attachments, (err, response) => {
      if (response.status === 1) {
        this.setState(
          {
            attachment_file: [],
            list_of_attachments: [...this.state.list_of_attachments, ...response.data],
          },
          () => {
            jQuery('.previewImg').remove();
            window.jQuery('#upload_doc_modal').modal('hide');
            window.jQuery('#attachment_modal').modal('show');
            // jQuery('.upload-space').removeClass('hide')
            jQuery('.upload-space').css({
              opacity: 1,
            });

            jQuery('.upload-thumb').addClass('hide');
          },
        );
      } else {
        alert(response.message);
      }
    });
  };

  rename = (obj, curr) => {
    let a = {};
    Object.keys(obj).map(key => {
      let newKey = key.replace(curr, '');
      Object.assign(a, { [newKey]: obj[key] });
    });
    return a;
  };

  // to check lock password

  checkPassword = () => {
    let Input = {
      client_id: this.state.logged_client_id,
      password: this.state.old_password,
    };

    FetchAllApi.verify_lock_date_password(Input, (err, response) => {
      if (response.status === 1) {
        let temp_date = this.state.temp_date;
        jQuery('#date').val(temp_date);

        window.jQuery('.modal-backdrop').css('display', 'none');
        window.jQuery('#asking_password_for_delete').modal('hide');
        window.jQuery('#lock_date').modal('hide');

        setTimeout(() => {
          let date = jQuery('#date').val();
          this.dateChange(date);
        }, 500);
      } else {
        alert(response.message);
        this.setState({ deleteMsg: true });
      }
    });
  };

  changefromDate() {
    // let date = jQuery("#invoice_date").val();
    let date = this.state.temp_date;

    if (date && date != undefined) {
      var array = date.split('/');
      var date_formated = array[2] + '-' + array[1] + '-' + array[0];
      var fomrat = moment(date_formated, 'YYYY-MM-DD').format('DD-MM-YYYY');

      // console.log('ppppp', date_formated)
      // console.log('ppppp', fomrat)
      return date_formated;
    }
  }

  // to check lock password

  dateChange = e => {
    console.log(e, 'res');
    if (this.state.date != e) {
      this.setState({ date: e, is_payment_date_invalid: false }, () => {
        this.get_currency_datails(true);
      });
    }
  };

  get_currency_datails = (is_date_changed = false) => {
    let payment_date = this.state.date ? this.state.date.split('/').reverse().join('-') : moment().format('YYYY-MM-DD');

    var date_formated = payment_date;

    let todayDate = new Date();
    todayDate.setHours(23, 59, 59, 998);
    if (new Date(date_formated).getTime() > todayDate.getTime()) {
      if (is_date_changed == true) {
        if (this.state.selectedCurrency == this.state.clientHomeCurrency) {
          Swal.fire('You are entering a transaction for Future Date!');
        } else {
          Swal.fire(
            'You are entering a transaction for Future Date!',
            "Since you are entering a transaction for future date, The exchange rate will follow today's date And you must change the exchange rate manually for the Transaction",
          );
        }
      }
      date_formated = moment().format('YYYY-MM-DD');
    }

    if (this.state.selectedCurrency !== '' && this.state.selectedCurrency !== undefined) {
      fetch(
        // `https://api.currencylayer.com/historical?access_key=${config.api_key}&date=${date_formated}&source=${this.state.selectedCurrency}`
        `https://api.currencylayer.com/timeframe?access_key=${config.api_key}&start_date=${date_formated}&end_date=${date_formated}&currencies=${this.state.clientHomeCurrency}&source=${this.state.selectedCurrency}`,
      )
        .then(response => response.json())
        .then(data => {
          let newObj = {};
          if (data.success == true) {
            newObj = this.rename(data.quotes, this.state.selectedCurrency);
            let currencyAr = [];
            let first = newObj;
            currencyAr.push({
              date: data.start_date,
              // 'rate': isNaN(first[data.start_date][this.state.selectedCurrency + this.state.clientHomeCurrency]) ? 0.0000 : (first[data.start_date][this.state.selectedCurrency + this.state.clientHomeCurrency]).toFixed(4)
              rate:
                first[data.start_date] && first[data.start_date][this.state.selectedCurrency + this.state.clientHomeCurrency]
                  ? first[data.start_date][this.state.selectedCurrency + this.state.clientHomeCurrency].toFixed(4)
                  : parseFloat(1).toFixed(4),
            });
            let value = currencyAr[0].rate;
            newObj[this.state.clientHomeCurrency] = parseFloat(value);

            if (this.state.clientHomeCurrency != '' && this.state.clientHomeCurrency != undefined) {
              this.setState({
                exchange_rate: newObj[this.state.clientHomeCurrency].toFixed(4),
                exchangeRate: newObj[this.state.clientHomeCurrency].toFixed(4),
                exchange_rate_temp: newObj[this.state.clientHomeCurrency].toFixed(4),
              });
            }
          }
        });
    }
  };

  applyCredit = () => {
    let final_invoice_credit_array = [];
    let credit_amount_total = 0;
    let Due_Total = Number(jQuery('#total2').html());
    let credit_object = {};
    this.state.vendor_invoicelist.map((invoice, selectedRow) => {
      let invoice_credit_array = [];
      let credit_amount = 0;

      this.state.vendor_credit_list.map((credit, i) => {
        let credit_to_be_use = this.state[`credit_to_be_use${i}${selectedRow}`]
          ? this.convertStringToNumber(this.state[`credit_to_be_use${i}${selectedRow}`])
          : 0;
        credit_object[i] = credit_object[i] ? Number(credit_object[i]) + credit_to_be_use : credit_to_be_use;

        let credit_applied_history_id = this.state[`credit_applied_history_id${i}${selectedRow}`]
          ? Number(this.state[`credit_applied_history_id${i}${selectedRow}`])
          : 0;
        let old_credit_amount = this.state[`old_credit_amount${i}${selectedRow}`]
          ? Number(this.state[`old_credit_amount${i}${selectedRow}`])
          : 0;
        credit_amount = credit_amount + credit_to_be_use;
        invoice_credit_array.push({
          credit_id: credit.credit_id,
          credit_amount: credit_to_be_use,
          credit_applied_history_id,
          old_credit_amount,
          invoice_exchange_rate: invoice.invoice_exchange_rate,
          credit_memo_type: credit.credit_memo_type,
        });

        // this set state is newly added on 2024-02-16 to update old credit amount on continously updating the transaction without refresh the page
        this.setState({
          [`old_credit_amount${i}${selectedRow}`]: credit_to_be_use,
        });
        console.log('credit_to_be_use.....498', credit_to_be_use);
        // this set state is newly added... end here
      });

      // newly added on 2022-07-13
      let manually_applied = this.state.manually_applied;
      if (this.state.is_payment_amount_given == false && this.state.manually_applied == false) {
        manually_applied = true;
      }

      // this.setState({ [`credit_amount${selectedRow}`]: credit_amount },()=>{
      this.setState(
        {
          [`credit_amount${selectedRow}`]: credit_amount,
          manually_applied: manually_applied,
        },
        () => {
          //Newly added to reduce applied payment after apply credit automatically
          if (this.state.invoiceSeletedId == selectedRow) {
            if (this.state.manually_applied == true || this.state.is_payment_amount_given == false) {
              this.setState({ is_credit_changed: true });
              let pmt_amt =
                Number(invoice.open_balance_foreign_currency) -
                (this.state[`discount_amount${selectedRow}`] ? Number(this.state[`discount_amount${selectedRow}`]) : 0) -
                (this.state[`credit_amount${selectedRow}`] ? Number(this.state[`credit_amount${selectedRow}`]) : 0);
              this.setState({ [`invoice_due${selectedRow}`]: pmt_amt }, () => {
                this.setState({ is_credit_changed: false });

                if (this.state.manually_applied == true || this.state.is_payment_amount_given == false) {
                  let noOfInvoices = this.state.vendor_invoicelist.length;
                  if (noOfInvoices != '' && noOfInvoices != undefined) {
                    let total_payment = 0;
                    this.state.vendor_invoicelist.map((item, i) => {
                      let amt = this.convertStringToNumber(this.state[`invoice_due${i}`]);
                      total_payment = total_payment + amt;
                    });

                    this.setState(
                      {
                        amount_of_payment: total_payment,
                        amount_of_payment_used: total_payment,
                        isUnused: false,
                        unused_amt: 0,
                      },
                      () => {
                        this.sumTotal();
                      },
                    );
                  }
                }
              });
            } else {
              // else condition newly added 25-04-2022 to block payment apply on invoice only amount of payment available
              let pmt_amt =
                Number(invoice.open_balance_foreign_currency) -
                (this.state[`discount_amount${selectedRow}`] ? Number(this.state[`discount_amount${selectedRow}`]) : 0) -
                (this.state[`credit_amount${selectedRow}`] ? Number(this.state[`credit_amount${selectedRow}`]) : 0);
              let previous_amount_of_payment = this.convertStringToNumber(this.state.amount_of_payment);

              let noOfInvoices = this.state.vendor_invoicelist.length;
              if (noOfInvoices != '' && noOfInvoices != undefined) {
                let total_payment = 0;
                this.state.vendor_invoicelist.map((item, i) => {
                  if (this.state.invoiceSeletedId != i) {
                    let amt = this.convertStringToNumber(this.state[`invoice_due${i}`]);
                    total_payment = total_payment + amt;
                  }
                });

                if (previous_amount_of_payment >= total_payment + pmt_amt) {
                  this.setState({ is_credit_changed: true });
                  this.setState({ [`invoice_due${selectedRow}`]: pmt_amt }, () => {
                    this.setState({ is_credit_changed: false });
                    this.sumTotal();
                  });
                } else {
                  let amt_apply = previous_amount_of_payment - total_payment;
                  this.setState({ is_credit_changed: true });
                  this.setState({ [`invoice_due${selectedRow}`]: amt_apply }, () => {
                    this.setState({ is_credit_changed: false });
                    this.sumTotal();
                  });
                }
              }
            }
          }
        },
      );

      credit_amount_total = credit_amount_total + credit_amount;
      let invoice_id = invoice.id;
      final_invoice_credit_array.push({ [invoice_id]: invoice_credit_array });
    });

    this.setState({
      credit_object: credit_object,
    });
    this.setState({ credit_amount_total, final_invoice_credit_array });
    if (
      this.convertStringToNumber(this.state.amount_of_payment) + this.state.TotalCreditAmount + this.state.TotalDiscountAmount <
        Due_Total &&
      Due_Total
    ) {
      let under_amt =
        Due_Total -
        (Number(credit_amount_total) +
          this.convertStringToNumber(this.state.amount_of_payment) +
          Number(this.state.TotalCreditAmount) +
          Number(this.state.TotalDiscountAmount));
      console.log(under_amt);
      this.setState(
        {
          under_amt: under_amt.toFixed(2),
        },
        () => {
          this.sumTotal();
          console.log(this.state.under_amt);
        },
      );
    }
  };

  clearCredit = selectedRow => {
    if (this.state.vendor_credit_list) {
      this.state.vendor_credit_list.map((cre, l) => {
        let co_amt = this.state.credit_object[`${l}`] ? this.state.credit_object[`${l}`] : 0;
        let pre_applied_amount = this.state[`credit_to_be_use${l}${selectedRow}`]
          ? this.convertStringToNumber(this.state[`credit_to_be_use${l}${selectedRow}`])
          : 0;
        let cre_obj = this.state.credit_object;
        if (co_amt > 0) {
          co_amt = co_amt - pre_applied_amount;
          cre_obj[l] = co_amt;
        }
        this.setState(
          {
            credit_object: cre_obj,
            [`credit_to_be_use${l}${selectedRow}`]: '',
            [`check_box_credit_to_be_use${l}${selectedRow}`]: '',
          },
          () => {
            this.applyCredit();
          },
        );
      });
    }
  };

  credit_onchange = (i, selectedRow, available_credits) => {
    let total = 0;
    this.state.vendor_invoicelist.map((item, selectedRow) => {
      total =
        total + (this.state[`credit_to_be_use${i}${selectedRow}`] ? Number(this.state[`credit_to_be_use${i}${selectedRow}`]) : 0);
      //console.log("Basio state123", total);
    });
    //console.log("Basio state1234", total);
    this.setState({ [`usedCreditsRowTotal${i}`]: available_credits - total });
    // //console.log("Basio state", response);
  };

  get_client_home_currency = () => {
    let client_id = this.state.logged_client_id;

    FetchAllApi.get_client_home_currency(client_id, (err, response) => {
      if (response.status === 1) {
        //console.log("Basio state", response);
        this.setState(
          {
            clientHomeCurrency: response.currency,
          },
          this.getCurrencyList(),
        );
      } else {
      }
    });
  };

  getCurrencyList = () => {
    //commented on 2022-07-13
    // fetch(
    //   `https://api.currencylayer.com/live?access_key=${config.api_key}&source=SGD`
    // )
    //   .then((response) => response.json())
    //   .then((data) => {
    //     let newObj = this.rename(data.quotes, 'SGD')
    //     if (newObj)
    //       this.setState({ currency_list: Object.keys(newObj) })
    //   })
    //   .catch(err => {
    //     console.log(err)
    //   });
  };

  findInSubAccountList = curr => {
    if (
      this.state.edit_customer_receive_payment != '' &&
      this.state.edit_customer_receive_payment != null &&
      this.state.edit_customer_receive_payment != undefined
    ) {
      //
    } else {
      setTimeout(() => this.get_currency_datails(), 500);
    }
    var currency = curr;
    var result = [];
    this.state.SubAccountList &&
      this.state.SubAccountList.forEach((item, i) => {
        var fullString = item.name.split('-');
        var list_curr = fullString[1];

        //console.log("matched", item.name + "=" + list_curr + "=" + currency);
        var kk = 'Accounts Payable' + '-' + currency;

        //console.log("one", item.name);
        //console.log("one1", kk);
        if (item.name == kk) {
          result.push(item);
        }
      });

    console.log('checkssss', result);
    if (result.length === 0) {
      // alert('not matched')
      var coreData = {
        account_name: 'Accounts Payable-' + currency,
        account_type_id: 5,
        currency: currency,
        client_id: this.state.logged_client_id,
      };

      FetchAllApi.addNewAccountName(coreData, (err, response) => {
        //console.log("vendor_nljfskdkdssdkfames", response);

        if (response.status === 1) {
          this.getSubAccountList();

          // id="ar_account"

          jQuery('#account_id').val(response.account_type_id);
          this.setState({
            account_id: response.account_id,
            account_id_name: response.name,

            selected_account_id: response.account_id,
            selected_currency: response.account_id,
            currency_selected: currency,
          });

          setTimeout(() => {
            if (
              this.state.edit_customer_receive_payment != '' &&
              this.state.edit_customer_receive_payment != null &&
              this.state.edit_customer_receive_payment != undefined
            ) {
              //
            } else {
              this.handleChanges();
            }
          }, 1000);
          // alert('new added & refreshed')
          // this.setState({SubAccountList:response.list});

          // alert('success')
          // this.getItems()
          // window.jQuery('#add_items').modal('hide')
        } else {
        }
      });
    } else {
      // alert(result.length)
      //console.log("hfhfh", result);
      jQuery('#account_id').val(result[0].id);
      this.setState({
        account_id: result[0].id,
        account_id_name: result[0].name,

        selected_account_id: result[0].id,
        selected_currency: result[0].id,
        currency_selected: currency,
      });

      setTimeout(() => {
        if (
          this.state.edit_customer_receive_payment != '' &&
          this.state.edit_customer_receive_payment != null &&
          this.state.edit_customer_receive_payment != undefined
        ) {
          //
        } else {
          this.handleChanges();
        }
      }, 1000);
      if (
        jQuery('#invoice_curr_id option:selected').val() != '' &&
        jQuery('#invoice_curr_id option:selected').val() != undefined
      ) {
      }
      // alert('no worries match found')
    }
    // if (this.state.payor_name && this.state.payor_name != 0) {
    if (this.state.payor_name && this.state.payor_name != 0 && !this.state.edit_customer_receive_payment) {
      this.customer_invoicelist();
      this.vendor_credit_list();
      this.applied_credit_history();
      this.vendor_discount_terms();
      this.get_currencies();
    }
  };

  get_currencies = () => {
    // fetch(`https://api.exchangerate-api.com/v4/latest/${this.state.currency_selected}`)
    //   .then((response) => response.json())
    //   .then((data) => {
    //     let first = this.state.currency_selected
    //     // //console.log("Basio state1", data);
    //     // //console.log("Basio state1", first);
    //     // //console.log("Basio state2", data.rates);
    //     // //console.log("Basio state3", data.rates[this.state.currency_selected]);currency_selected  clientHomeCurrency
    //     // //console.log("Basio state4", data.rates.first);
    //     this.setState({ exchangeRate: Number(data.rates[this.state.clientHomeCurrency].toFixed(4)), exchange_rate_temp: Number(data.rates[this.state.clientHomeCurrency].toFixed(4)) });
    //   });
  };

  total_paid = (entered_amt, row, from_checkbox = false) => {
    var total_paid = 0;

    this.state.vendor_invoicelist.map((item, i) => {
      if (row != i) {
        total_paid =
          Number(total_paid) + (this.state[`invoice_due${i}`] ? this.convertStringToNumber(this.state[`invoice_due${i}`]) : 0);
      }
    });

    // if (Number((Number(total_paid) + Number(entered_amt))) > Number(this.state.amount_of_payment)) {
    if (Number(Number(total_paid) + Number(entered_amt)) > this.convertStringToNumber(this.state.amount_of_payment)) {
      // newly added to auto increse payment amount
      if (from_checkbox == true && this.state.is_payment_amount_given == false) {
        let amount_differ =
          Number(Number(total_paid) + Number(entered_amt)) - this.convertStringToNumber(this.state.amount_of_payment);
        let previous_amount_of_payment = this.convertStringToNumber(this.state.amount_of_payment);
        this.setState(
          {
            amount_of_payment: previous_amount_of_payment + amount_differ,
          },
          () => {
            let noOfInvoices = this.state.vendor_invoicelist.length;
            if (noOfInvoices != '' && noOfInvoices != undefined) {
              this.state.vendor_invoicelist.map((item, i) => {
                if (row == i) {
                  let amt =
                    Number(item.open_balance_foreign_currency) -
                    (this.state[`discount_amount${i}`] ? Number(this.state[`discount_amount${i}`]) : 0) -
                    (this.state[`credit_amount${i}`] ? Number(this.state[`credit_amount${i}`]) : 0);

                  if (amt != 0) {
                    this.setState({
                      [`invoice_due${i}`]: amt,
                      [`inv_check${i}`]: true,
                    });
                    this.state.amount_of_payment_used = this.state.amount_of_payment_used + Number(amt);
                  }
                }
              });
            }
            setTimeout(
              function () {
                this.sumTotal();
              }.bind(this),
              3000,
            );
          },
        );
      } else if (from_checkbox == true && this.state.manually_applied == false) {
        let balance_amount = this.convertStringToNumber(this.state.amount_of_payment) - Number(total_paid);
        let noOfInvoices = this.state.vendor_invoicelist.length;
        if (noOfInvoices != '' && noOfInvoices != undefined) {
          this.state.vendor_invoicelist.map((item, i) => {
            if (row == i) {
              let amt =
                Number(item.open_balance_foreign_currency) -
                (this.state[`discount_amount${i}`] ? Number(this.state[`discount_amount${i}`]) : 0) -
                (this.state[`credit_amount${i}`] ? Number(this.state[`credit_amount${i}`]) : 0);
              if (amt != 0) {
                if (balance_amount >= amt) {
                  this.setState({
                    [`invoice_due${i}`]: amt,
                    [`inv_check${i}`]: true,
                  });
                  this.state.amount_of_payment_used = this.state.amount_of_payment_used + Number(amt);
                } else if (balance_amount < amt) {
                  this.setState({
                    [`invoice_due${i}`]: balance_amount,
                    [`inv_check${i}`]: true,
                  });
                  this.state.amount_of_payment_used = this.state.amount_of_payment_used + Number(balance_amount);
                }
              }
            }
          });
        }
        setTimeout(
          function () {
            this.sumTotal();
          }.bind(this),
          3000,
        );
      }
      return true;

      // } else if (Number((Number(total_paid) + Number(entered_amt))) < Number(this.state.amount_of_payment)) {
    } else if (Number(Number(total_paid) + Number(entered_amt)) < this.convertStringToNumber(this.state.amount_of_payment)) {
      if (this.state.is_payment_amount_given == false) {
        var total_paid = 0;
        let vendor_invoicelist = this.state.vendor_invoicelist ? this.state.vendor_invoicelist : [];
        vendor_invoicelist.map((item, i) => {
          if (row != i) {
            total_paid =
              Number(total_paid) +
              (this.state[`invoice_due${i}`] ? this.convertStringToNumber(this.state[`invoice_due${i}`]) : 0);
          }
        });

        let amount_differ =
          this.convertStringToNumber(this.state.amount_of_payment) - Number(Number(total_paid) + Number(entered_amt));
        let previous_amount_of_payment = this.convertStringToNumber(this.state.amount_of_payment);
        let new_amount_of_payment = previous_amount_of_payment - amount_differ;
        if (new_amount_of_payment <= 0) {
          new_amount_of_payment = 0;
        }
        this.setState(
          {
            amount_of_payment: new_amount_of_payment,
            amount_of_payment_used: new_amount_of_payment,
          },
          () => {
            this.handleAmountChange();
          },
        );
      } else {
        this.setState({
          isUnused: true,
          unused_amt: this.convertStringToNumber(this.state.amount_of_payment) - Number(Number(total_paid) + Number(entered_amt)),
        });
      }
      // return (false)
    } else {
      this.setState({ isUnused: false, unused_amt: 0 });
    }
  };

  componentDidUpdate() {
    window.jQuery('.selectpicker').selectpicker('refresh');
    // this.sumTotal();
  }

  // 4 selected vendor id
  handleChange = () => {
    // alert(e)
    let variable_pay_type = jQuery('#variable_pay_type option:selected').data('status');
    console.log('payer2', variable_pay_type, jQuery('#variable_pay_type option:selected').data('name'));
    this.setState(
      {
        payor_name: variable_pay_type,
        selectValue: variable_pay_type,
        vendorName: jQuery('#variable_pay_type option:selected').data('name'),
        customer_id: variable_pay_type,
      },
      () => {
        this.customer_invoicelist();
        this.vendor_credit_list();
        this.applied_credit_history();
        this.vendor_discount_terms();
        this.get_currencies();
      },
    );
    // this.setState({ selectValue: variable_pay_type });
    //this.vendor_invoicelist(variable_pay_type);
    // this.vendor_credit_list(variable_pay_type);

    // this.vendor_discount_terms(variable_pay_type);
    // this.applied_credit_history(variable_pay_type);
    // this.customer_and_job_lists(variable_pay_type);
  };
  handleChanges = () => {
    let variable_pay_type_job = jQuery('#variable_pay_type_job option:selected').data('status');
    // this.customer_and_job_listss(variable_pay_type_job);
    this.customer_invoicelist();
    this.vendor_credit_list();
    this.applied_credit_history();
    this.vendor_discount_terms();
    this.get_currencies();
    this.setState({ job: variable_pay_type_job });
  };

  handleChangess = () => {
    let job_id = jQuery('#variable_pay_type_jobs option:selected').data('status');
    // this.setState({ job_id: job_id });

    this.customer_invoicelist();
    this.vendor_credit_list();
    this.applied_credit_history();
    this.vendor_discount_terms();
  };

  third_party = () => {
    let third_party = jQuery('#received_account option:selected').data('status');

    if (third_party == 2) {
      this.setState({ received_accounts: false });

      this.setState({ received_account: true });
      FetchAllApi.vendor_payment_account_types((err, response) => {
        if (response.status === 1) {
          //console.log("consoleme", response);
          this.setState({
            third_party_name: response.customerData,
            third_party_type: 1,
          });
        }
      });
    } else if (third_party == 5) {
      // var client_id = localStorage.getItem("logged_client_id")
      var client_id = this.state.logged_client_id;

      this.setState({ received_account: false });
      this.setState({ received_accounts: true });

      let filter_id = 2;
      FetchAllApi.getVendorNames(client_id, filter_id, (err, response) => {
        if (response.status === 1) {
          //console.log("consoleme", response);
          this.setState({
            third_party_name: response.list,
            third_party_type: 2,
          });
        }
      });
    } else {
      this.setState({ received_account: false });
      this.setState({ received_accounts: false });
    }
  };

  //  1 vendor_account_type

  customer_account_type = () => {
    let client_id = this.state.logged_client_id;
    FetchAllApi.customer_account_type(client_id, (err, response) => {
      if (response.status === 1) {
        //console.log('kjijkj', this.props.location.state)
        if (this.state.urlParams.get('amount_of_payment') !== null) {
          response.list.forEach((item, i) => {
            if (item.name == 'Accounts Receivable' + '-' + this.state.urlParams.get('amount_of_payment')) {
              // alert('ji')
              this.setState(
                {
                  selected_account_id: item.id,
                  selected_currency: item.id,
                  currency_selected: item.currency,
                },
                this.handleChanges(),
              );
            }
          });
        }

        //console.log("consoleme", response);
        this.setState({ customer_account_type: response.list });
      } else {
        // this.setState({ vendor_account_type: [] });
      }
    });
  };

  // 2 customer_and_job_list

  customer_and_job_list = () => {
    // var client_id = localStorage.getItem("logged_client_id") ;
    var client_id = this.state.logged_client_id;
    let filter_id = 2;
    FetchAllApi.getVendorNames(client_id, filter_id, (err, response) => {
      //console.log("vendor_names", response);

      if (response.status === 1) {
        this.setState({ customer_and_job_list: response.list });
      } else {
      }
    });
  };

  customer_and_job_lists = customer_id => {
    // var client_id = localStorage.getItem("logged_client_id") ;
    var client_id = this.state.logged_client_id;
    var customer_id = customer_id;
    var from_customer_receive_payment = 1;
    let filter_id = 2;
    FetchAllApi.customer_and_job_lists(client_id, customer_id, from_customer_receive_payment, filter_id, (err, response) => {
      //console.log("vendor_names", response);

      if (response.status === 1) {
        this.setState({ customer_and_job_lists: response.list });
        this.setState({ customer_id: customer_id }, () => {
          if (
            this.state.edit_customer_receive_payment != '' &&
            this.state.edit_customer_receive_payment != null &&
            this.state.edit_customer_receive_payment != undefined
          ) {
            //
          } else {
            this.handleChanges();
          }
        });
      } else {
        this.setState({ customer_and_job_lists: [] }, () => {
          if (
            this.state.edit_customer_receive_payment != '' &&
            this.state.edit_customer_receive_payment != null &&
            this.state.edit_customer_receive_payment != undefined
          ) {
            //
          } else {
            this.handleChanges();
          }
        });
      }
    });
  };

  customer_and_job_listss = job_id => {
    // var client_id = localStorage.getItem("logged_client_id") ;
    var client_id = this.state.logged_client_id;
    var customer_id = this.state.customer_id;
    var job_id = job_id;
    var from_customer_receive_payment = 1;
    let filter_id = 2;
    FetchAllApi.customer_and_job_listss(
      client_id,
      customer_id,
      job_id,
      from_customer_receive_payment,
      filter_id,
      (err, response) => {
        //console.log("vendor_names", response);

        if (response.status === 1) {
          this.setState({ customer_and_job_listss: response.list });
        } else {
          this.setState({ customer_and_job_listss: [] });
        }
      },
    );
  };

  // 3 customer_invoicelist

  customer_invoicelist = () => {
    // var client_id = localStorage.getItem("logged_client_id");
    var client_id = this.state.logged_client_id;
    var customer_id = this.state.customer_id;

    // var invoice_account = jQuery("#ar_account").val()
    var invoice_account = this.state.account_id;
    var multi_payment_applied_invoices = this.state.edit_customer_receive_payment
      ? this.state.edit_customer_receive_payment[1]
      : '';
    FetchAllApi.vendor_invoicelist(
      client_id,
      customer_id,
      invoice_account,
      multi_payment_applied_invoices,

      (err, response) => {
        //console.log("vendor_names", response);

        if (response.status === 1) {
          this.setState({ vendor_invoicelist: response.list });
          this.payDue();
        } else {
          this.setState({ vendor_invoicelist: [] });
          this.total_paid(0, 0);
          this.payDue();
        }
      },
    );
  };

  // 6 customer_creditlists

  vendor_credit_list = () => {
    // var client_id = localStorage.getItem("logged_client_id");
    var client_id = this.state.logged_client_id;
    var customer_id = this.state.customer_id;
    var multi_payment_applied_invoices = this.state.edit_customer_receive_payment
      ? this.state.edit_customer_receive_payment[1]
      : '';

    var credit_account = this.state.account_id;
    FetchAllApi.vendor_credit_list(
      // client_id,
      customer_id,
      // job_id,
      credit_account,
      multi_payment_applied_invoices,
      1, // 1 - for list out batch deposits and cheques also
      (err, response) => {
        //console.log("vendor_names", response);

        if (response.status === 1) {
          let total = 0;
          let creditamount = 0; //let credit_balance=0
          this.setState(
            {
              vendor_credit_list: response.response,
              credit_available: response.available_credit_foreign_currency,
            },
            () => {
              this.state.vendor_credit_list &&
                this.state.vendor_credit_list.map(item => {
                  total = total === 0 ? item.amount : parseFloat(parseFloat(total) + parseFloat(item.amount)).toFixed(2);
                  creditamount =
                    creditamount === 0
                      ? item.available_credits
                      : parseFloat(parseFloat(creditamount) + parseFloat(item.available_credits)).toFixed(2);
                  // creditbalance=creditbalance===0?item.credit_balance:parseFloat(parseFloat(creditbalance)+parseFloat(item.credit_balance)).toFixed(2);
                });
            },
          );
          this.setState({
            credit_total: total,
            credit_amounttouse: creditamount,
          });
        } else {
          this.setState({ vendor_credit_list: [], credit_available: 0 });
        }
      },
    );
  };

  // 5 vendor payment method

  vendor_payment_method = () => {
    FetchAllApi.getPaymethod(this.state.logged_client_id, (err, response) => {
      //console.log("vendor_namews", response);

      if (response.status === 1) {
        this.setState({ vendor_payment_method: response.lists });
      } else {
      }
    });
  };

  // 7 vendor_category
  vendor_category = () => {
    var client_id = this.state.logged_client_id;
    // FetchAllApi.vendor_category(client_id, (err, response) => {

    //   if (response.status === 1) {
    //     this.setState({ vendor_category: response.response });
    //   } else {
    //   }
    // });

    FetchAllApi.get_chart_accounts(client_id, 1, (err, response) => {
      if (response.status === 1) {
        let list = response.account_lists ? response.account_lists : [];
        this.setState({ vendor_category: list });
      } else {
        this.setState({ vendor_category: [] });
      }
    });
  };

  // 8 vendor_payment_account_type ( )

  vendor_payment_account_type = () => {
    FetchAllApi.vendor_payment_account_types((err, response) => {
      //console.log("vendor_names", response);

      if (response.status === 1) {
        this.setState({
          // vendor_payment_account_type: response.accountData,
          vendor_payment_method_bank: response.list,
        });
      } else {
      }
    });
  };

  onChange_filter_balancesheet = () => {
    let search_key = '';
    var client_id = this.state.logged_client_id;
    let filter_id = 2;
    //alert(search_key)
    // FetchAllApi.balancesheetlist_onchange(
    FetchAllApi.paymentAccountList(search_key, client_id, filter_id, (err, response) => {
      //console.log("defaultcategorylist", response);
      if (response.status === 1) {
        // alert('k')
        this.setState({
          vendor_payment_account_type: response.list,
        });
      } else {
        this.setState({
          vendor_payment_account_type: [],
        });
      }
    });
  };
  // 10 applied_credit_history

  applied_credit_history = () => {
    // var client_id = localStorage.getItem("logged_client_id");
    var client_id = this.state.logged_client_id;
    var customer_id = this.state.customer_id;
    var job_id = jQuery('#variable_pay_type_job option:selected').data('status');

    FetchAllApi.applied_credit_history(
      // client_id,
      customer_id,
      // job_id,
      (err, response) => {
        //console.log("vendor_names", response);

        if (response.status === 1) {
          let total = 0;
          let creditamount = 0;
          let creditbalance = 0;
          this.setState({ applied_credit_history: response.response }, () => {
            let currentInvoiceId = this.state.currentInvoiceId
              ? this.state.currentInvoiceId
              : this.state.vendor_invoicelist && this.state.vendor_invoicelist.length > 0
              ? this.state.vendor_invoicelist[0].id
              : 0;
            if (currentInvoiceId) {
              let filtered_data = [];
              filtered_data = response.response.filter(credit => credit.invoice_id == currentInvoiceId);
              this.setState({
                invoice_specific_applied_credit_history: filtered_data,
              });
            } else {
              this.setState({ invoice_specific_applied_credit_history: [] });
            }
            // this.state.applied_credit_history && this.state.applied_credit_history.map(item => {
            //   total = total === 0 ? item.amount : parseFloat(parseFloat(total) + parseFloat(item.amount)).toFixed(2);
            //   creditamount = creditamount === 0 ? item.amount_to_us : parseFloat(parseFloat(creditamount) + parseFloat(item.amount_to_us)).toFixed(2);
            //   creditbalance = creditbalance === 0 ? item.credit_balance : parseFloat(parseFloat(creditbalance) + parseFloat(item.credit_balance)).toFixed(2);
            // })
          });
          // this.setState({ credit_hist_total: total, credit_his_amounttouse: creditamount, credit_his_creditbalance: creditbalance });
        } else {
        }
      },
    );
  };

  // customer_discount_terms

  vendor_discount_terms = () => {
    // var client_id = localStorage.getItem("logged_client_id");
    var client_id = this.state.logged_client_id;
    var customer_id = this.state.customer_id;
    var job_id = jQuery('#variable_pay_type_job option:selected').data('status');

    FetchAllApi.customer_discount_terms(client_id, customer_id, job_id, (err, response) => {
      //console.log("vendor_nkkkkkkk0000kames", response);

      if (response.status === 1) {
        this.setState({
          discount_terms: response.data[0].payment_terms,

          job_id_details: response.data,
        });
      } else {
      }
      // response.data[0].payment_terms  open_balance_foreign_currency
    });
  };

  //default_discount_term
  default_discount_term = () => {
    FetchAllApi.default_discount_term((err, response) => {
      //console.log("vendor_names", response);

      if (response.status === 1) {
        this.setState({ vendor_discount_terms: response.lists });
      } else {
      }
    });
  };

  // customer_recived_payment

  customer_recived_payment = x => {
    let ar_account = this.state.account_id;
    let customer_id = jQuery('#variable_pay_type option:selected').data('status');
    let payor_name = jQuery('#variable_pay_type option:selected').data('name');
    let job_id = jQuery('#variable_pay_type_job').val();

    let payment_date;
    if (this.state.isEdit) {
      payment_date = this.convert_date(jQuery('#date').val());
    } else {
      payment_date = this.convert_date(jQuery('#date').val());
    }
    let unusedamout = 0;
    if (this.state.urlParams.get('amount') != null) {
      unusedamout = this.state.urlParams.get('amount');
    } else {
      unusedamout = this.state.unused_amt;
    }
    // let amount = Number(jQuery("#getEntredAmnt").val());
    let amount = jQuery('#getEntredAmnt').val() ? this.convertStringToNumber(jQuery('#getEntredAmnt').val()) : 0;

    let type = jQuery('#modeofpayment_type option:selected').data('status');
    let reference_number = jQuery('#paymentreference').val(); //
    let received_account = jQuery('#received_account').val(); //
    let third_party_type = this.state.third_party_type;
    let third_party_account_id = this.state.received_account
      ? jQuery('#third_party_id').val()
      : jQuery('#third_party_vendor').val();
    let third_party_account_name = this.state.received_account
      ? jQuery('#third_party_id option:selected').data('name')
      : jQuery('#third_party_vendor option:selected').data('name');
    let descripation = jQuery('#descripation').val();
    let exchange_rate =
      this.state.exchangeRate == '' || this.state.exchangeRate == 0 ? this.state.exchange_rate_temp : this.state.exchangeRate;
    let option = this.state.option;
    let writeOff = this.state.writeOff;
    let client_id = this.state.logged_client_id;
    let Vendor_bank_account = 7767;
    let discount = this.state.discount_array;
    let invoice_list = this.state.invoiceListArray;
    let credit_list = this.state.credit_list;
    let refundStatus = this.state.refundStatus;
    let total_amount = this.state.totalDueFromApi;
    let total_amount_due = jQuery('#total2').html();
    let total_original_amount = jQuery('#total1').html();
    let applied_amount = jQuery('#total3').html();
    let total_discount_credits_amount = 0.0;
    let total_payment_balance = 100;
    let payment_balance = 100;
    let isEdit = this.state.isEdit;
    let multi_payment_applied_invoices = this.state.multi_payment_applied_invoices;

    let is_payment_account_invalid = amount > 0 && !received_account ? true : false;
    let is_payment_date_invalid = amount > 0 && !payment_date ? true : false;
    this.setState({
      is_payment_account_invalid: is_payment_account_invalid,
      is_payment_date_invalid: is_payment_date_invalid,
    });
    let source_attachment_ids = this.state.list_of_attachments.map(obj => obj.source_id);
    let source_attachments = source_attachment_ids.join(',');

    if (!is_payment_account_invalid && !is_payment_date_invalid) {
      FetchAllApi.vendor_bill_payment(
        source_attachments,
        customer_id,
        payor_name,
        client_id,
        descripation,
        exchange_rate,
        amount,
        type,
        third_party_account_name,
        reference_number,
        payment_date,
        Vendor_bank_account,
        job_id,
        ar_account,

        writeOff,

        discount,

        invoice_list,
        credit_list,

        refundStatus,

        total_amount,
        total_amount_due,
        total_original_amount,
        applied_amount,
        total_discount_credits_amount,
        total_payment_balance,
        payment_balance,
        option,
        this.state.old_option,
        third_party_type,
        third_party_account_id,
        received_account,
        // this.state.unused_amt,
        unusedamout,
        this.state.currency_selected,
        this.state.selected_account_id,
        this.state.logged_user_id,
        this.state.final_invoice_credit_array,
        isEdit,
        multi_payment_applied_invoices,

        (err, response) => {
          //console.log("vendor_names", response);
          if (response.status === 1) {
            this.setState({ under: false, over: false });

            jQuery('#getEntredAmnt').val('');
            if (this.myDivToFocus.current) {
              this.myDivToFocus.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
            }

            if (x === 'save') {
              //alert("Success ! succesfully saved");

              this.setState({ isSuccessful: true });
              setTimeout(() => {
                this.setState({
                  isSuccessful: false,
                  errormessage: response.message,
                });
              }, 3000);
              if (this.state.urlParams.size > 0) {
                this.props.history.push('/loading', ['/bank_reconcile_match']);
              } else {
                //  window.location.reload(false);

                this.setState({
                  isEdit: true,
                  multi_payment_applied_invoices: response.multi_payment_applied_invoices,
                  hasId: response.multi_payment_applied_invoices,
                  old_transaction_date: payment_date,
                });

                // this.props.history.push("/loading", ["/Customer_receive_payment"]);
              }
            } else {
              //  alert("Success ! succesfully saved");

              this.setState({ isSuccessful: true });
              setTimeout(() => {
                this.setState({
                  isSuccessful: false,
                  errormessage: response.message,
                });
              }, 3000);

              window.location.reload(false);
              // this.props.history.push("/loading", ["/Customer_receive_payment"]);
            }
          } else {
            // alert(response.message);
            this.setState({ isFailed: true, errormessage: response.message });
            setTimeout(() => {
              this.setState({
                isFailed: false,
                errormessage: response.message,
              });
            }, 2000);
          }
        },
      );
    } else {
      if (is_payment_account_invalid) {
        this.setState({
          isFailed: true,
          errormessage: 'Payment account required',
        });
      } else {
        this.setState({
          isFailed: true,
          errormessage: 'Payment Date required',
        });
      }

      setTimeout(() => {
        this.setState({ isFailed: false });
      }, 2000);
    }
  };

  routedChange(parameter) {
    this.props.history.push('/' + parameter);
    window.scrollTo(0, 0);
  }
  componentWillUnmount() {
    localStorage.setItem('edit_customer_receive_payment', null);
  }

  logoutLink() {
    localStorage.clear();
    this.props.history.push('/');
  }

  dataTaggingFunc(list_id, file_id) {
    this.props.history.push('/data_tagging/' + list_id + '/' + file_id);
    window.scrollTo(0, 0);
  }

  pageLink(page_slug) {
    this.props.history.push('/' + page_slug);
  }
  UNSAFE_componentWillMount() {
    jQuery(document.body).removeClass('minimize_leftbar');
    ////console.log("logged_user_id", this.state.logged_user_id);

    // jQuery('title').html('User Inbox | GBSC')

    if (this.state.logged_user_id === '' || this.state.logged_user_id === null || this.state.logged_user_id === undefined) {
      this.props.history.push('/');
    }

    // this.get_inbox_list();
  }

  get_customer_multipayment_details = () => {
    let input = {
      client_id: this.state.logged_client_id,
      vendor_id: this.state.edit_customer_receive_payment[0],
      multi_payment_applied_invoices: this.state.edit_customer_receive_payment[1],
    };

    // let data1 = from
    // alert('noti2')
    // let input

    // if (data1 == 'edit') {
    //   input = {
    //     client_id: this.state.logged_client_id,
    //     customer_id: this.state.edit_customer_receive_payment[0],
    //     multi_payment_applied_invoices: this.state.edit_customer_receive_payment[1]
    //   }
    // } else {
    //   input = {
    //     notification_id: this.state.customer_receive_payment_notification
    //   }

    // }

    FetchAllApi.get_vendor_multipayment_details(input, (err, response) => {
      localStorage.setItem('edit_customer_receive_payment', '');
      if (response.status === 1) {
        let data = response.details;
        let detail = response.details.paydetails;

        this.setState(
          {
            isEdit: true,
            multi_payment_applied_invoices: this.state.edit_customer_receive_payment[1],
            hasId: this.state.edit_customer_receive_payment[1],

            vendor_invoicelist: data.invoice_list,
            vendor_credit_list: data.credit_list,
            credit_available: data.credits_available,
            applied_credit_history: data.credit_applied_history,

            selectedCurrency: data.payment_currency,

            payor_name: detail.vendor_id,
            selectValue: detail.vendor_id,
            vendorName: detail.vendor_name, // customer name should come

            job: detail.job_id,

            amount_of_payment: detail.amount, // amount of payment should come
            trigger_from_edit_payment: true,

            pay_method: detail.payment_method, // payment method should come

            reference: detail.reference_number, //detail.reference_number     comes empty,

            rec_acc: detail.ap_account,

            descripation: detail.descripation,

            exchangeRate: detail.exchange_rate,

            received_account: detail.third_party_type == 1 ? true : false, // one param should be added for received acc is customer or vendor to show third party

            received_accounts: detail.third_party_type == 2 ? true : false, // one param should be added for received acc is customer or vendor to show third party

            // for refund
            recievedAccount: data.refund ? data.refund.account : '',
            refundStatus: data.refund ? data.refund : {},
            modeOfPay: data.refund ? data.refund.mode_of_payment : '',
            refund_id: data.refund ? data.refund.refund_id : '',
            // for refund

            // for write off
            writeOff: data.writeoff ? data.writeoff : {},
            VendorAccSelcted: data.writeoff ? data.writeoff.account : '',
            under_amt: data.writeoff ? data.writeoff.amount : '',
            writeoff_id: data.writeoff ? data.writeoff.writeoff_id : '',
            // for write off

            option: data.option,
            old_option: data.option,

            list_of_attachments: data.source_attachments ? data.source_attachments : [],
          },
          () => {
            if (this.state.applied_credit_history && this.state.applied_credit_history.length > 0) {
              //Newly added to display invoice specific applied credit history
              let currentInvoiceId = this.state.currentInvoiceId
                ? this.state.currentInvoiceId
                : this.state.vendor_invoicelist && this.state.vendor_invoicelist.length > 0
                ? this.state.vendor_invoicelist[0].id
                : 0;
              if (currentInvoiceId) {
                let filtered_data = [];
                filtered_data = this.state.applied_credit_history.filter(credit => credit.invoice_id == currentInvoiceId);
                this.setState({
                  invoice_specific_applied_credit_history: filtered_data,
                });

                // Newly added on 29-09-2022 to adjust availbale balance on a credit based on previously applied history
                this.state.vendor_invoicelist.forEach((inv, inv_index) => {
                  let inv_filtered_data = [];
                  let cu_invoice_id = inv.id;
                  inv_filtered_data = this.state.applied_credit_history.filter(credit => credit.invoice_id == cu_invoice_id);
                  let v_credit_list = this.state.vendor_credit_list;
                  if (inv_filtered_data.length > 0) {
                    v_credit_list.forEach((ite, l) => {
                      let cu_credit_id = ite.credit_id;
                      let filter_credits_applied_with_credit = inv_filtered_data.filter(
                        credit => credit.credit_id == cu_credit_id,
                      );

                      filter_credits_applied_with_credit.forEach((cre, k) => {
                        v_credit_list[l]['available_credits'] =
                          v_credit_list[l]['available_credits'] + parseFloat(cre['credit_used_foreign_currency']);
                      });
                    });
                    this.setState({ vendor_credit_list: v_credit_list });
                  }
                });
              } else {
                this.setState({ invoice_specific_applied_credit_history: [] });
              }

              // newly added to update available credits amount with previously applied credit in specific payments
              let previously_applied_credit_amount = 0;
              previously_applied_credit_amount = _.sumBy(this.state.applied_credit_history, function (o) {
                return parseFloat(o.amount_to_us);
              });
              if (previously_applied_credit_amount > 0) {
                let cre_available = this.state.credit_available ? this.state.credit_available : 0;
                this.setState({
                  credit_available: parseFloat(cre_available) + parseFloat(previously_applied_credit_amount),
                });
              }
            }

            this.findInSubAccountList(data.payment_currency);
            this.customer_and_job_lists(detail.vendor_id);
            jQuery('#date').val(moment(detail.payment_date).format('DD/MM/YYYY'));

            //for lock date check purpose
            this.setState({ old_transaction_date: detail.payment_date });

            jQuery('#total2').html(data.vendor_balance);

            // for refund
            jQuery('#refund_exchange').val(data.refund ? data.refund.exchange_rate : '');
            jQuery('#refundamnt').val(data.refund ? data.refund.amount : '');
            jQuery('#refund_date').val(data.refund ? data.refund.refund_date : '');
            // for refund jQuery("#refund_date").val()

            this.state.vendor_invoicelist &&
              this.state.vendor_invoicelist.map((item, i) => {
                this.setState({
                  [`invoice_due${i}`]: item.payment_amount_foreign_currency,
                  [`credit_amount${i}`]: item.credit_amount_foreign_currency,
                  [`discount_amount${i}`]: item.discount_amount_foreign_currency,
                  [`inv_check${i}`]: item.invoice_selected == 1 ? true : false,

                  // invoice_selected    this.state[`inv_check${i}`]
                  [`discount_amount${i}`]: item.discount_amount_foreign_currency,
                  [`discount_amount_temp${i}`]: item.discount_amount_foreign_currency,
                  [`discount_account_type${i}`]: item.discount_account_id,
                  [`discount_account_type_temp${i}`]: item.discount_account_id,

                  isUnused: data.unapplied_payment_amount > 0 ? true : false,
                  unused_amt: data.unapplied_payment_amount,
                });

                // value={this.state[`credit_to_be_use${i}${selectedRow}`]

                data.final_invoice_credit_array &&
                  data.final_invoice_credit_array.map((item1, i1) => {
                    if (item.id == Object.keys(item1)[0]) {
                      Object.values(item1)[0].map((item2, i2) => {
                        this.state.vendor_credit_list &&
                          this.state.vendor_credit_list.map((item3, i3) => {
                            if (item2.credit_id == item3.credit_id) {
                              this.setState({
                                [`credit_to_be_use${i3}${i}`]: item2.credit_amount,
                                [`credit_applied_history_id${i3}${i}`]: item2.credit_applied_history_id,
                                [`old_credit_amount${i3}${i}`]: item2.old_credit_amount,
                              });
                            }
                          });
                      });
                    }
                  });
              });

            setTimeout(() => {
              this.addDiscount();
              this.applyCredit();
              this.handleAmountChange();
            }, 2000);
          },
        );
      } else {
        alert(response.message);
      }
    });
  };

  getSubAccountList = () => {
    var coreData = {
      account_type_id: 5,
      client_id: this.state.logged_client_id,
    };

    FetchAllApi.getSubAccountList(coreData, (err, response) => {
      //console.log("vendor_nljfskdkdssdkfames", response);

      if (response.status === 1) {
        this.setState({ SubAccountList: response.list });

        // alert('success')
        // this.getItems()
        // window.jQuery('#add_items').modal('hide')
      } else {
      }
    });
  };

  watchMe = () => {
    setInterval(() => {
      // for add new customer
      var customer = localStorage.getItem('customer_multipayment_add_new_customer');
      console.log('customer_multipayment_add_new_customer_id', customer);

      if (customer !== undefined && customer !== '' && customer !== null && customer === 'yes') {
        var client_id = this.state.logged_client_id;
        var from_customer_receive_payment = 1;
        let filter_id = 2;
        FetchAllApi.customer_and_job_list(client_id, from_customer_receive_payment, filter_id, (err, response) => {
          if (response.status === 1) {
            this.setState(
              {
                customer_and_job_list: response.list,
                payor_name: localStorage.getItem('customer_multipayment_add_new_customer_id'),
              },
              () => {
                localStorage.setItem('customer_multipayment_add_new_customer', '');
                localStorage.setItem('customer_multipayment_add_new_customer_id', '');

                localStorage.setItem('customer_added', 'yes');
                localStorage.setItem('customer_added_id', response.customer_id);

                localStorage.setItem('third_party_customer_id', response.customer_id);
                localStorage.setItem('third_party_customer', 'yes1');
                this.handleChanges();
              },
            );
          } else {
          }
        });
      }

      // for add new customer

      // for add new job

      var job = localStorage.getItem('job_added');
      console.log('job_added_id', customer);

      if (job !== undefined && job !== '' && job !== null && job === 'yes') {
        var client_id = this.state.logged_client_id;
        var customer_id = this.state.payor_name;
        var from_customer_receive_payment = 1;
        let filter_id = 2;
        FetchAllApi.customer_and_job_lists(client_id, customer_id, from_customer_receive_payment, filter_id, (err, response) => {
          //console.log("vendor_names", response);

          if (response.status === 1) {
            this.setState(
              {
                customer_and_job_lists: response.list,
                job: localStorage.getItem('job_added_id'),
              },
              () => {
                localStorage.setItem('job_id', '');
                localStorage.setItem('job_added', '');
                localStorage.setItem('job_added_id', '');
                this.handleChanges();
              },
            );
          } else {
            this.setState({ customer_and_job_lists: [] }, () => {
              localStorage.setItem('job_id', '');
              localStorage.setItem('job_added', '');
              localStorage.setItem('job_added_id', '');
              this.handleChanges();
            });
          }
        });
      }

      // for add new job
    }, 2000);
  };

  filterObjectsByFieldName(inputArray, fieldName, searchString) {
    const filteredArray = [];

    for (const obj of inputArray) {
      if (obj[fieldName] === searchString) {
        filteredArray.push(obj);
      }
    }

    return filteredArray;
  }

  // set default currency
  set_default_currency = async () => {
    await this.getSubAccountList();

    var defaultCurrency = localStorage.getItem('default_currency');
    this.setState({ selectedCurrency: defaultCurrency });

    var currency = defaultCurrency;

    var coreDataa = {
      account_type_id: 5,
      client_id: this.state.logged_client_id,
    };

    FetchAllApi.getSubAccountList(coreDataa, (err, response) => {
      if (response.status === 1) {
        this.setState({ SubAccountList: response.list });
        if (response.list.length <= 0) {
          // alert('not matched')
          var coreData = {
            account_name: 'Accounts Payable-' + localStorage.getItem('default_currency'),
            account_type_id: 5,
            currency: localStorage.getItem('default_currency'),
            client_id: this.state.logged_client_id,
          };

          FetchAllApi.addNewAccountName(coreData, (err, response) => {
            //console.log("vendor_nljfskdkdssdkfames", response);

            if (response.status === 1) {
              this.getSubAccountList();

              jQuery('#account_id').val(response.account_type_id);
              this.setState({
                account_id: response.account_id,
                account_id_name: response.name,

                selected_account_id: response.account_id,
                selected_currency: response.account_id,
                currency_selected: localStorage.getItem('default_currency'),
              });
              this.handleChanges();
            }
          });
        } else {
          // alert("matched");
          if (response.status === 1) {
            this.setState({ SubAccountList: response.list });

            response.list.forEach((item, i) => {
              var kk = `Accounts Payable-${defaultCurrency}`;

              if (item.name === kk) {
                console.log('check3', response);
                jQuery('#account_id').val(item.id);
                this.setState({
                  account_id: item.id,
                  account_id_name: item.name,

                  selected_account_id: item.id,
                  selected_currency: item.id,
                  currency_selected: localStorage.getItem('default_currency'),
                });

                setTimeout(() => {
                  if (
                    this.state.edit_customer_receive_payment !== '' &&
                    this.state.edit_customer_receive_payment !== null &&
                    this.state.edit_customer_receive_payment !== undefined
                  ) {
                    //
                  } else {
                    this.handleChanges();
                  }
                }, 1000);
              }
            });
          }
        }
      }
    });
  };

  componentDidMount() {
    this.watchMe();

    this.getSubAccountList();

    this.get_client_home_currency();
    this.onChange_filter_balancesheet();
    this.customer_account_type();
    // this.getVendorNames();
    this.vendor_payment_method();
    this.vendor_category();
    this.customer_and_job_list();

    this.vendor_payment_account_type();
    this.default_discount_term();

    if (
      this.state.edit_customer_receive_payment != '' &&
      this.state.edit_customer_receive_payment != null &&
      this.state.edit_customer_receive_payment != undefined
    ) {
      if (this.state.edit_customer_receive_payment[0]) {
        this.setState(
          {
            customer_id: this.state.edit_customer_receive_payment[0],
          },
          () => {
            this.get_customer_multipayment_details();
          },
        );
      }
    } else {
      if (localStorage.getItem('default_currency')) {
        this.set_default_currency();
      }
    }

    if (this.state.urlParams.size > 0) {
      jQuery('#variable_pay_type').val(`${this.state.urlParams.get('payor_name')}`);
      this.getSubAccountList();

      this.setState(
        {
          isEdit: false,
          rec_acc: this.state.urlParams.get('selected_bank'),
          selectedCurrency: this.state.urlParams.get('amount_of_payment'),
          payor_name: this.state.urlParams.get('payor_name'),
          selectValue: this.state.urlParams.get('payor_name'),
          amount_of_payment: this.state.urlParams.get('amount'),
          date: moment(this.state.urlParams.get('date')).isValid()
            ? this.state.urlParams.get('date')
            : moment().format('YYYY-MM-DD'),
          temp_date: moment(this.state.urlParams.get('date')).isValid()
            ? this.state.urlParams.get('date')
            : moment().format('YYYY-MM-DD'),
          is_payment_date_invalid: false,
          customer_id: this.state.urlParams.get('payor_name'),
        },
        () => {
          jQuery('#variable_pay_type').val(this.state.urlParams.get('payor_name'));
          var coreData = {
            account_type_id: 5,
            client_id: this.state.logged_client_id,
          };

          FetchAllApi.getSubAccountList(coreData, (err, response) => {
            if (response.status === 1) {
              this.findInSubAccountList(this.state.selectedCurrency);
            } else {
            }
          });

          let client_id = this.state.logged_client_id;
          FetchAllApi.get_client_home_currency(client_id, (err, response) => {
            if (response.status === 1) {
              this.setState(
                {
                  clientHomeCurrency: response.currency,
                },
                () => {
                  this.getCurrencyList();
                  this.get_currency_datails();
                },
              );
            } else {
            }
          });
          jQuery('#date').val(this.state.urlParams.get('date') ? this.state.urlParams.get('date') : '');
        },
      );
    }
    let payorname = localStorage.getItem('customer_id'); //from vendor list
    console.log(payorname, 'payorname');

    if (payorname && payorname != undefined) {
      this.setState({ payor_name: payorname, customer_id: payorname });
      jQuery('#variable_pay_type').val(payorname);
    }
    // if (
    //   this.state.customer_receive_payment_notification != '' &&
    //   this.state.customer_receive_payment_notification != null &&
    //   this.state.customer_receive_payment_notification != undefined
    // ) {
    //   alert('notification1')
    //   this.get_customer_multipayment_details('notification')
    // }
    // else if (
    //   (this.state.edit_customer_receive_payment != '' &&
    //     this.state.edit_customer_receive_payment != null &&
    //     this.state.edit_customer_receive_payment != undefined) &&
    //   (
    //     this.state.customer_receive_payment_notification == '' ||
    //     this.state.customer_receive_payment_notification == null ||
    //     this.state.customer_receive_payment_notification == undefined
    //   )

    // ) {
    //   alert('edit1')
    //   this.get_customer_multipayment_details('edit')
    // } else {
    // }

    // if(f    customer_receive_payment_notification
    //   this.props.location.state != '' &&
    //   this.props.location.state != null &&
    //   this.props.location.state != undefined
    // ){
    //   this.get_customer_multipayment_details()
    // }

    //script starts
    jQuery('.dropdown-menu a.dropdown-toggle').on('click', function (e) {
      if (!jQuery(this).next().hasClass('show')) {
        jQuery(this).parents('.dropdown-menu').first().find('.show').removeClass('show');
      }
      var jQuerysubMenu = jQuery(this).next('.dropdown-menu');
      jQuerysubMenu.toggleClass('show');

      jQuery(this)
        .parents('li.nav-item.dropdown.show')
        .on('hidden.bs.dropdown', function (e) {
          jQuery('.dropdown-submenu .show').removeClass('show');
        });

      return false;
    });

    jQuery(window).on('load', function () {
      jQuery('.mscroll-y').mCustomScrollbar({
        axis: 'y',
        scrollEasing: 'linear',
        scrollInertia: 600,
        autoHideScrollbar: 'true',
        autoExpandScrollbar: 'true',
      });
      jQuery('.mscroll-x').mCustomScrollbar({
        axis: 'x',
        scrollEasing: 'linear',
        scrollInertia: 600,
        autoHideScrollbar: 'true',
        autoExpandScrollbar: 'true',
      });

      jQuery('.ib-scroll').mCustomScrollbar({
        scrollEasing: 'linear',
        scrollInertia: 600,
        scrollbarPosition: 'outside',
      });
    });

    jQuery(document).ready(function () {
      jQuery('.left-navmenu .has-sub').click(function () {
        jQuery('.left-navmenu li a').removeClass('active');
        jQuery(this).addClass('active');
        jQuery('.left-navmenu li a:not(.active)').siblings('.sub-menu').slideUp();
        jQuery(this).siblings('.sub-menu').slideToggle();
      });
      jQuery('.left-navmenu .sub-menu li a').click(function () {
        jQuery('.left-navmenu .sub-menu li a').removeClass('active');
        jQuery(this).addClass('active');
      });
      jQuery('.search-btn').click(function () {
        jQuery('.hdr-search').addClass('active');
      });
      jQuery('.hdr-search .close-icon').click(function () {
        jQuery('.hdr-search').removeClass('active');
      });

      window.jQuery('.select-picker').selectpicker();

      jQuery('.label-enclose .label').click(function () {
        jQuery(this).toggleClass('active');
      });
      jQuery('.nav-brand-res').click(function () {
        jQuery('.left-navbar').addClass('active');
      });
      jQuery('.menu-close').click(function () {
        jQuery('.left-navbar').removeClass('active');
      });

      window.jQuery('.input-group.date').datepicker({ format: 'dd/mm/yyyy', autoclose: true });

      jQuery('.dis-link').click(function () {
        jQuery(this).toggleClass('active');
        jQuery('.discount-wrap').slideToggle();
      });
    });

    require('jquery-mousewheel');
    require('malihu-custom-scrollbar-plugin');

    jQuery('.item-listwrap').mCustomScrollbar({
      scrollEasing: 'linear',
      scrollInertia: 600,
      scrollbarPosition: 'outside',
    });

    jQuery('.label-enclose .label span').click(function () {
      //jQuery('.label-enclose .label').removeClass('active')
      jQuery(this).parent('.label-enclose .label').addClass('active');
    });
    jQuery('.label-enclose .label a').click(function () {
      jQuery(this).parent('.label-enclose .label').removeClass('active');
    });
  }

  // calculation starts

  payDue = amnt => {
    this.state.amount_of_payment_used = 0;
    if (this.state.creditList) {
    }
    // let ttl = this.state.amount_of_payment != '' && this.state.amount_of_payment != 0 ? Number(parseFloat(this.state.amount_of_payment).toFixed(2)) : this.state.amount_of_payment;
    let ttl = this.convertStringToNumber(this.state.amount_of_payment);
    let noOfInvoices = this.state.vendor_invoicelist.length;

    jQuery('#due_of_invoice0').html();
    this.setState({ isUnused: false, unused_amt: 0 });
    if (noOfInvoices != '' && noOfInvoices != undefined) {
      this.state.vendor_invoicelist.map((item, i) => {
        let amt =
          Number(item.open_balance_foreign_currency) -
          (this.state[`discount_amount${i}`] ? Number(this.state[`discount_amount${i}`]) : 0) -
          (this.state[`credit_amount${i}`] ? Number(this.state[`credit_amount${i}`]) : 0);
        if (amt != 0 && amt <= ttl) {
          this.setState({
            [`invoice_due${i}`]: amt,
            [`inv_check${i}`]: ttl > 0 ? true : false,
          });
          this.state.amount_of_payment_used = this.state.amount_of_payment_used + Number(amt);
          ttl = Number(ttl) - Number(amt);
        } else {
          if (amt === 0) {
            this.setState({
              [`invoice_due${i}`]: amt,
              [`inv_check${i}`]: true,
            });
            this.state.amount_of_payment_used = this.state.amount_of_payment_used + Number(amt);
          } else {
            this.setState({
              [`invoice_due${i}`]: ttl,
              [`inv_check${i}`]: ttl > 0 ? true : false,
            });
            this.state.amount_of_payment_used = this.state.amount_of_payment_used + Number(ttl);

            //here newly added on 12-10-2022 to fix amount of payment becomes 0 on applying credit to first invoice
            //If there is any issue on payment given scenario please check here
            ttl = 0;
          }

          //here newly added on 12-10-2022 to fix amount of payment becomes 0 on applying credit to first invoice
          //If there is any issue on payment given scenario please check here
          //  ttl = 0;
        }
      });
    }
    setTimeout(
      function () {
        this.sumTotal();
        // this.clearDue()
      }.bind(this),
      3000,
    );
  };

  clearDue = y => {
    let fisrtEle = jQuery('#invoice_due0').html();
    if (fisrtEle === undefined || fisrtEle === '' || fisrtEle === '0.00') {
      this.state.vendor_invoicelist.forEach((itm, i) => {
        jQuery('#invoice_chekbox' + i).prop('checked', false);
        jQuery('#invoice_due' + i).html('');
      });
      // this.sumTotal()
    }
    // let Due_Total = Number(this.state.totalDueFromApi)
    let Due_Total = Number(jQuery('#total2').html());
    let Payment_total = Number(jQuery('#total3').html());
    let amountEntered = Number(jQuery('#getEntredAmnt').val());
    if (jQuery('#appliedcredits').html() != '') {
      var credittotal = Number(
        isNaN(jQuery('#appliedcredits').html() != true) &&
          jQuery('#appliedcredits').html() != undefined &&
          jQuery('#appliedcredits').html() != ''
          ? jQuery('#appliedcredits').html()
          : 0,
      );
    } else {
      var credittotal = 0;
    }

    if (y === 'x') {
    }
    // //console.log('111paymenttotal',this.state.amount_of_payment)
    // //console.log('111paymenttotal1',Due_Total)
    // //console.log('111paymenttotal1', this.state.TotalDiscountAmount);
    // //console.log('111paymenttotal1', this.state.TotalCreditAmount);
    // //console.log('111paymenttotal2',(Number(this.state.amount_of_payment) + this.state.TotalCreditAmount + this.state.TotalDiscountAmount) < Due_Total)

    // if ((Number(this.state.amount_of_payment) + this.state.TotalCreditAmount + this.state.TotalDiscountAmount) < Due_Total && Due_Total) {
    if (
      this.convertStringToNumber(this.state.amount_of_payment) + this.state.TotalCreditAmount + this.state.TotalDiscountAmount <
      Due_Total
    ) {
      // let under_amt = Due_Total - (Number(this.state.amount_of_payment) + Number(this.state.TotalCreditAmount) + Number(this.state.TotalDiscountAmount))
      let under_amt =
        Due_Total -
        (this.convertStringToNumber(this.state.amount_of_payment) +
          Number(this.state.TotalCreditAmount) +
          Number(this.state.TotalDiscountAmount));
      this.setState(
        {
          over: false,
          under: true,
          // option: this.state.option,
          option: 1,

          under_amt: under_amt.toFixed(2),
          overPay: '',
        },
        () => {
          let writeOff = this.state.writeOff;
          let writeOff_temp = { ...writeOff, amount: under_amt.toFixed(2) };
        },
      );
    } else {
      this.setState({
        under: false,
        under_amt: 0,
      });
    }
    // if ((Number(this.state.amount_of_payment) + + this.state.TotalCreditAmount + this.state.TotalDiscountAmount) > Due_Total && Due_Total) {
    //  if (Number(this.state.amount_of_payment) > Number(this.state.amount_of_payment_used)) {
    if (this.convertStringToNumber(this.state.amount_of_payment) > Number(this.state.amount_of_payment_used)) {
      let overPay = this.convertStringToNumber(this.state.amount_of_payment) - Number(this.state.amount_of_payment_used);

      this.setState(
        {
          over: true,
          under: false,
          // option: '3',
          option: this.state.option == 4 ? '4' : '3',
          under_amt: '',
          overPay: overPay.toFixed(2),
        },
        () => {
          let refundStatus = this.state.refundStatus;
          let refundStatus_temp = {
            ...refundStatus,
            amount: overPay.toFixed(2),
          };
        },
      );
    } else {
      this.setState({
        over: false,
        overPay: '',
      });
    }

    // if (Number(this.state.amount_of_payment) - Due_Total == 0 || this.state.amount_of_payment == 0) {
    if (
      this.convertStringToNumber(this.state.amount_of_payment) - Due_Total == 0 ||
      (this.convertStringToNumber(this.state.amount_of_payment) == 0 && this.state.manually_applied == false)
    ) {
      this.setState({
        over: false,
        under: false,
        under_amt: '',
        overPay: '',
      });
    }
  };

  defaultcategorylist_onchang = (dum, val, id) => {
    if (this.state.is_added_from_adjustment_account == true) {
      this.vendor_category();
      this.setState(
        {
          is_added_from_adjustment_account: false,
        },
        () => {
          let acc_index = this.state.added_adjustment_account_index;
          this.setState({ [`discount_account_type_temp${acc_index}`]: id }, () => {
            this.setState({
              added_adjustment_account_index: -1,
            });
          });
        },
      );
    } else {
      this.onChange_filter_balancesheet();
      this.setState({ rec_acc: id });
      jQuery('#received_account').val(id);
    }
  };

  convertStringToNumber = value => {
    if (!value) {
      return 0;
    } else {
      if (value && typeof value != 'string') {
        value = value.toString();
      }
      value = value.replace(/\&nbsp;/g, '');
      let v = parseFloat(value.replace(/,/g, ''));
      if (!isNaN(Number(v))) {
        return v;
      } else {
        return 0;
      }
    }
  };

  sumTotal = () => {
    var sum_of_original = [];
    var sum_of_due = [];
    var sum_of_payment = [];
    var invoiceListArray = [];
    var creditTotal = [];
    var discountTotal = [];
    var selectedCreditList = [];
    var Creditamount = [];
    var discount_array = [];
    var credit_list = [];

    this.state.vendor_credit_list &&
      this.state.vendor_credit_list.map((item, i) => {
        credit_list.push({
          credit_id: item.credit_id ? item.credit_id : 0,
          credit_amount_use: this.state[`credit_to_be_use${i}`]
            ? this.convertStringToNumber(this.state[`credit_to_be_use${i}`])
            : 0,
          credit_memo_type: item.credit_memo_type ? item.credit_memo_type : 0,
        });
      });

    this.state.vendor_invoicelist.map((item, i) => {
      let discount = {
        discount_amount: this.state[`discount_amount_temp${i}`]
          ? this.convertStringToNumber(this.state[`discount_amount_temp${i}`])
          : 0,
        discount_account: this.state[`discount_account_type_temp${i}`] ? this.state[`discount_account_type_temp${i}`] : 0,
        discount_id: item.discount_id ? item.discount_id : 0,
        old_discount_foreign_currency: item.old_discount_foreign_currency ? item.old_discount_foreign_currency : 0,
        invoice_id: item.id,
        invoice_account: item.invoice_account,
        invoice_exchange_rate: item.invoice_exchange_rate,
      };
      discount_array.push(discount);
    });

    this.state.vendor_invoicelist &&
      this.state.vendor_invoicelist.forEach((itm, i) => {
        sum_of_original.push(
          Number(
            jQuery('#original' + i).html() != '' && jQuery('#original' + i).html() != undefined
              ? this.convertStringToNumber(jQuery('#original' + i).html())
              : 0,
          ),
        );

        sum_of_due.push(
          Number(
            jQuery('#due_of_invoice' + i).html() != undefined && jQuery('#due_of_invoice' + i).html() != ''
              ? this.convertStringToNumber(jQuery('#due_of_invoice' + i).html())
              : 0,
          ),
        );
        sum_of_payment.push(
          Number(
            jQuery('#invoice_due' + i).html() != undefined && jQuery('#invoice_due' + i).html() != ''
              ? this.convertStringToNumber(jQuery('#invoice_due' + i).html())
              : 0,
          ),
        );

        discountTotal.push(
          Number(
            jQuery('#total_discount_amount' + i).html() != undefined && jQuery('#total_discount_amount' + i).html() != ''
              ? this.convertStringToNumber(jQuery('#total_discount_amount' + i).html())
              : 0,
          ),
        );

        creditTotal.push(
          Number(
            jQuery('#total_credit_amount' + i).html() != undefined && jQuery('#total_credit_amount' + i).html() != ''
              ? this.convertStringToNumber(jQuery('#total_credit_amount' + i).html())
              : 0,
          ),
        );
        //  this.makeInvoiceArray()

        if (jQuery('#creditAmntIDhidden' + i).val() != '') {
        }
        // if (jQuery('#creditAmntIDhidden' + i).val() != '') {
        //   selectedCreditList.push({
        //     credit_id: jQuery('#creditAmntIDhidden' + i).val(),
        //     credit_amount_use:Number( jQuery('#creditAmnt' + i).html()!=undefined &&  jQuery('#creditAmnt' + i).html()!=''? jQuery('#creditAmnt' + i).html():0)
        //   })

        // }
        //  Creditamount =
        // _(selectedCreditList)
        //     .groupBy('credit_id')
        //     .map((objs, key) => ({
        //         'credit_id': key,
        //         'credit_amount_use': _.sumBy(objs, 'credit_amount_use')
        //     }))
        //     .value();

        this.state.invoiceListArray = [];
        this.state.vendor_invoicelist.map((item, i) => {
          let exampleObj = {
            invoice_id: item.id,
            old_credit_amount_foreign_currency: item.old_credit_amount_foreign_currency,
            old_discount_amount_foreign_currency: item.old_discount_amount_foreign_currency,
            payment_id: item.payment_id ? item.payment_id : 0,
            // oringinal_amount: jQuery('#original' + i).html(),
            // amount_due: jQuery('#due_of_invoice' + i).html(),
            // payment_amount: this.state[`invoice_due${i}`],
            // discount_amount: this.state[`discount_amount${i}`] ? this.state[`discount_amount${i}`] : 0,

            oringinal_amount: jQuery('#original' + i).html() ? this.convertStringToNumber(jQuery('#original' + i).html()) : 0,
            amount_due: jQuery('#due_of_invoice' + i).html()
              ? this.convertStringToNumber(jQuery('#due_of_invoice' + i).html())
              : 0,
            payment_amount: this.state[`invoice_due${i}`] ? this.convertStringToNumber(this.state[`invoice_due${i}`]) : 0,
            discount_amount: this.state[`discount_amount${i}`]
              ? this.convertStringToNumber(this.state[`discount_amount${i}`])
              : 0,

            group_accounting_sales_invoice_id: item.group_accounting_sales_invoice_id
              ? item.group_accounting_sales_invoice_id
              : 0,
            // invoice_account:jQuery("#invoice_account" + i).val() , selected_account_id
            invoice_account: item.invoice_account,
            invoice_exchange_rate: jQuery('#invoice_exchange_rate' + i).val(),

            // credit_id:  jQuery("#credit_id" + i).html() ? jQuery("#credit_id" + i).html() : 0 ,
            credit_id: jQuery('#invoice_credit' + i).val() ? jQuery('#invoice_credit' + i).val() : 0,
            credit_amount_to_use: this.state[`credit_amount${i}`] ? this.state[`credit_amount${i}`] : 0,
            // credit_id:
            //   jQuery('#creditAmntIDhidden' + i).val() != ''
            //     ? Number(jQuery('#creditAmntIDhidden' + i).val())
            //     : 0,
            // credit_amount_to_use:
            //   jQuery('#creditAmnt' + i).html() != undefined &&
            //   jQuery('#creditAmnt' + i).html() != ''
            //     ? jQuery('#creditAmnt' + i).html()
            //     : 0
          };
          // if( jQuery('#invoice_chekbox' + i).prop('checked')){

          this.state.invoiceListArray.push(exampleObj);
          // }
        });
      });

    //console.log('testme', this.state.invoiceListArray)

    let total1 = sum_of_original.reduce(function (a, b) {
      return a + b;
    }, 0);
    let total2 = sum_of_due.reduce(function (a, b) {
      return a + b;
    }, 0);
    let total3 = sum_of_payment.reduce(function (a, b) {
      return a + b;
    }, 0);
    let CreditTotal = creditTotal.reduce(function (a, b) {
      return a + b;
    }, 0);
    let DiscountTotal = discountTotal.reduce(function (a, b) {
      return a + b;
    }, 0);

    jQuery('#total1').html(total1.toFixed(2));
    jQuery('#total2').html(total2.toFixed(2));
    // jQuery('#total3').html(total3.toFixed(2))
    //  jQuery('.appliedcredits').html(CreditTotal)

    this.setState(
      {
        credit_list: credit_list,
        discount_array: discount_array,
        selectedCreditList: Creditamount,
        // invoiceListArray: invoiceListArray,
        totalDueFromApi: Number(total2),
        totalDuePaid: Number(total3),
        TotalDiscountAmount: Number(DiscountTotal),
        TotalCreditAmount: Number(CreditTotal),
        // total_received_amount : Number(total2 - this.state.amount_of_payment)
      },
      () => {
        this.clearDue();
      },
    );
    this.findCreditRequiredPlace();
    console.log('gjsdghjgsdjghsdjgjshgdjghs', discount_array);
    setTimeout(
      function () {
        this.clearDue();
      }.bind(this),
      2000,
    );
  };

  addDiscount = e => {
    if (
      // this.state[`discount_amount_temp${this.state.invoiceSeletedId}`] != "" &&
      this.state[`discount_account_type_temp${this.state.invoiceSeletedId}`] &&
      this.state[`discount_account_type_temp${this.state.invoiceSeletedId}`] != ''
    ) {
      let amt =
        this.state[`discount_amount_temp${this.state.invoiceSeletedId}`] &&
        this.state[`discount_amount_temp${this.state.invoiceSeletedId}`] != ''
          ? this.convertStringToNumber(this.state[`discount_amount_temp${this.state.invoiceSeletedId}`])
          : 0;

      this.setState(
        {
          [`discount_amount_already_applied${this.state.invoiceSeletedId}`]: true,
          is_discount_changed: false,
          [`discount_amount${this.state.invoiceSeletedId}`]: amt,
          [`discount_account_type${this.state.invoiceSeletedId}`]:
            this.state[`discount_account_type_temp${this.state.invoiceSeletedId}`],
        },
        () => {
          //newly added to redouce total amount of payment when discount added after give full payment
          if (this.state.manually_applied == true) {
            let noOfInvoices = this.state.vendor_invoicelist.length;
            if (noOfInvoices != '' && noOfInvoices != undefined) {
              let total_payment = 0;
              this.state.vendor_invoicelist.map((item, i) => {
                let amt = this.convertStringToNumber(this.state[`invoice_due${i}`]);
                total_payment = total_payment + amt;
              });

              this.setState(
                {
                  amount_of_payment: total_payment,
                  amount_of_payment_used: total_payment,
                  unused_amt: 0, // Since amount of payment calculated by manual selection
                  isUnused: false,
                },
                () => {
                  this.clearDue();
                },
              );
            }
          }
          //
        },
      );
      jQuery('.discount-wrap').slideToggle();
      setTimeout(
        function () {
          this.sumTotal();
          // this.clearDue()
        }.bind(this),
        2000,
      );
    } else {
      this.setState({
        [`discount_amount_error${this.state.invoiceSeletedId}`]: true,
        [`discount_account_error${this.state.invoiceSeletedId}`]: true,
      });
    }
    // //console.log("adddiscount" , this.state.discount_amount2);
  };

  // handleCreditChange = (checked, row, credit_value) => {
  //   let total_amount = 0;
  //   var credit_id_used = ''

  //   this.state.vendor_credit_list.map((item, i) => {

  //     if ((checked === true) && (Number(row) === i)) {
  //       //console.log('yes', credit_value)
  //       this.setState({ [`credit_to_be_use${i}`]: credit_value });
  //       total_amount = total_amount + Number(credit_value)
  //       credit_id_used = item.credit_id
  //       // this.state.selectedCreditList.push({
  //       //           credit_id: item.id,
  //       //           credit_amount_use:credit_value
  //       //         })
  //     }
  //     if ((checked === false) && (Number(row) === i)) {
  //       this.setState({ [`credit_to_be_use${i}`]: 0 })
  //       total_amount = total_amount - Number(credit_value)
  //       credit_id_used = ''
  //     }
  //   })
  //   this.setState({ total_amount: total_amount, credit_id_used: credit_id_used },

  //     () => {
  //       setTimeout(
  //         () => this.handleCreditCal(total_amount, credit_id_used)
  //         ,
  //         2000
  //       )
  //     }

  //   )

  // }

  selectRow = (id, invoice_id) => {
    // //console.log(id);
    // this.state.invoiceSeletedId = id;

    this.setState(
      {
        currentInvoiceId: invoice_id,
        invoiceSeletedId: id,
        [`selectedRow${id}`]: this.state[`selectedRow${id}`] == true ? false : true,
      },
      () => {
        if (this.state.applied_credit_history) {
          let filtered_data = this.state.applied_credit_history.filter(
            credit => credit.invoice_id == this.state.currentInvoiceId,
          );
          this.setState({
            invoice_specific_applied_credit_history: filtered_data,
          });
        } else {
          this.setState({ invoice_specific_applied_credit_history: [] });
        }
      },
    );
  };

  handleAmountChange = () => {
    let totalAmount = 0;
    let noOfInvoices = this.state.vendor_invoicelist.length;
    if (noOfInvoices != '' && noOfInvoices != undefined) {
      for (let i = 0; i < noOfInvoices; i++) {
        totalAmount =
          totalAmount + (this.state[`invoice_due${i}`] ? this.convertStringToNumber(this.state[`invoice_due${i}`]) : 0);
      }
      this.setState({ amount_of_payment_used: totalAmount }, () => {
        this.sumTotal();
      });
    }
  };

  handleCreditCal = (total_amount, credit_id_used) => {
    var credit_list = [];

    let ttl = total_amount;
    //console.log("total_amount", total_amount)

    this.state.vendor_invoicelist.map((item, i) => {
      if (i == this.state.invoiceSeletedId) {
        let amt =
          Number(item.open_balance_foreign_currency) -
          (this.state[`discount_amount${i}`] ? Number(this.state[`discount_amount${i}`]) : 0);

        if (amt <= ttl) {
          this.setState({ [`credit_amount${i}`]: amt });
          ttl = Number(ttl) - Number(amt);
          jQuery('#invoice_credit' + i).val(credit_id_used);
        } else {
          this.setState({ [`credit_amount${i}`]: ttl });
          ttl = 0;
          jQuery('#invoice_credit' + i).val(credit_id_used);
        }
      }
    });

    setTimeout(
      function () {
        this.sumTotal();
        // this.clearDue()
      }.bind(this),
      2000,
    );
    //   setTimeout(
    //     function() {
    //       this.payDue();
    //     }
    //     .bind(this),
    //     1000
    // );
  };

  findCreditRequiredPlace = () => {
    var rowFind = [];
    this.setState({ rowsNeededCredits: rowFind });
    var credittotal = 0;
    var originalTot = 0;
    var totalduesum = 0;
    this.state.vendor_invoicelist.forEach((item, i) => {
      let due_on_invoice = Number(jQuery('#due_of_invoice' + i).html());
      // let paidduetoinvoice = Number(jQuery("#invoice_due" + i).html());
      let paidduetoinvoice = this.convertStringToNumber(jQuery('#invoice_due' + i).html());
      credittotal =
        credittotal + jQuery('#creditAmnt' + i).html() != undefined && jQuery('#creditAmnt' + i).html() != ''
          ? jQuery('#creditAmnt' + i).html()
          : 0;

      var creditAppiled = Number(
        jQuery('#creditAmnt' + i).html() != '' && jQuery('#creditAmnt' + i).html() != undefined
          ? jQuery('#creditAmnt' + i).html()
          : 0,
      );
      if (paidduetoinvoice + creditAppiled < due_on_invoice) {
        rowFind.push(i);
      }

      // if (paidduetoinvoice + creditAppiled > 0) {
      //   jQuery("#invoice_chekbox" + i).prop("checked", true);
      // }
      if (jQuery('#invoice_chekbox' + i).prop('checked')) {
        // alert(jQuery('#creditAmnt' + i).html())

        originalTot =
          originalTot +
          Number(
            jQuery('#original' + i).html() != undefined && jQuery('#original' + i).html() != ''
              ? jQuery('#original' + i).html()
              : 0,
          );
        totalduesum =
          totalduesum +
          Number(
            jQuery('#due_of_invoice' + i).html() != undefined && jQuery('#due_of_invoice' + i).html() != ''
              ? this.convertStringToNumber(jQuery('#due_of_invoice' + i).html())
              : 0,
          );
      }
    });
    this.setState({ credittotal: credittotal, totalDueFromApi: totalduesum });

    jQuery('#setcredit').html(Number(credittotal));
    //console.log("Number(credittotal)", Number(credittotal));
    // jQuery('#total1').html(originalTot.toFixed(2))
    // jQuery('#total2').html(totalduesum.toFixed(2))
  };

  convert_date = date => {
    if (date && date != undefined) {
      var array = date.split('/');
      var date_formated = array[2] + '-' + array[1] + '-' + array[0];
    }
    return date_formated;
  };

  accountTypeSelection = e => {
    this.state.customer_account_type.forEach(
      (item, i) => {
        if (item.id == e.target.value) {
          this.setState({
            selected_account_id: item.id,
            selected_currency: item.id,
            currency_selected: item.currency,
          });
        }
      },
      setTimeout(() => {
        this.handleChanges();
      }, 500),
    );
  };

  // calculation ends

  render() {
    // let vender_id =this.state.discount_terms
    // this.vendor_invoicelist(vender_id)

    // console.log("vendor_account_typeqb", this.state.refundStatus);
    // console.log("vendor_account_typeqb123", this.state.recievedAccount);

    let get_file_path,
      dis_file_path = [],
      item_file_path = [],
      attach_file_path,
      options = [],
      page_no = 1,
      items_limit = 10,
      no_items;

    ////console.log('response_stus',this.state.response_stus);

    // if(this.state.response_stus === 0){
    //     no_items = "<span className='no_rec'>No items found!</span>"
    // } else{
    //     no_items = ''
    // }

    if (this.state.item_details.user_image !== '' && this.state.item_details.user_image !== 'null') {
      var item_user_image = this.state.item_details.user_image;
    } else {
      var item_user_image = 'images/user-img-1.png';
    }

    ////console.log('item_files', this.state.item_file_path);
    if (this.state.item_file_path !== '' && this.state.item_file_path !== 'null') {
      item_file_path = [];
      var split_file_path = this.state.item_file_path.toString().split(',');
      var split_file_id = this.state.item_file_id.toString().split(',');
      if (split_file_path.length >= 1) {
        for (var i = 0; i < split_file_path.length; i++) {
          var get_file_url = split_file_path[i];
          var split_file_name = split_file_path[i].toString().split('/');
          var arr_reverse = split_file_name.reverse();

          var get_file_name = arr_reverse[0].substring(arr_reverse[0].length - 15, arr_reverse[0].length);

          var get_file_ext = arr_reverse[0].substring(arr_reverse[0].lastIndexOf('.') + 1, arr_reverse[0].length);
          if (get_file_ext === 'pdf') {
            var file_icon = 'images/pdf-icon.png';
          } else {
            var file_icon = 'images/img-icon.png';
          }

          ////console.log('pdf_file_link',get_file_url);

          if (get_file_ext === 'pdf') {
            item_file_path.push(
              <div className="attach-item">
                <a
                  onClick={this.dataTaggingFunc.bind(this, this.state.list_id, split_file_id[i])}
                  className="img-wrap"
                  data-id={split_file_id[i]}
                >
                  <iframe
                    src={get_file_url}
                    id="pdf_thumb_viewer"
                    frameborder="0"
                    scrolling="no"
                    width="190"
                    height="190"
                  ></iframe>
                  <span className="go">
                    <img src="../images/next-arrow-white.svg" className="mCS_img_loaded" />
                  </span>
                </a>
                <a
                  onClick={this.dataTaggingFunc.bind(this, this.state.list_id, split_file_id[i])}
                  data-toggle="tooltip"
                  data-placement="top"
                  title={get_file_url}
                  data-id={split_file_id[i]}
                >
                  <span>{get_file_name}</span>
                  <img src="../images/download-icon.svg" alt="Icon" className="mCS_img_loaded" />
                </a>
              </div>,
            );
          } else {
            item_file_path.push(
              <div className="attach-item">
                <a
                  onClick={this.dataTaggingFunc.bind(this, this.state.list_id, split_file_id[i])}
                  className="img-wrap"
                  data-id={split_file_id[i]}
                >
                  <img className="img-responsive mCS_img_loaded" src={get_file_url} alt={get_file_ext} />
                  <span className="go">
                    <img src="../images/next-arrow-white.svg" className="mCS_img_loaded" />
                  </span>
                </a>
                <a
                  onClick={this.dataTaggingFunc.bind(this, this.state.list_id, split_file_id[i])}
                  data-toggle="tooltip"
                  data-placement="top"
                  title={get_file_url}
                  data-id={split_file_id[i]}
                >
                  <span>{get_file_name}</span>
                  <a href={get_file_url} download={get_file_name}>
                    {get_file_name}
                    <img src="../images/download-icon.svg" alt="Icon" className="mCS_img_loaded" />
                  </a>
                </a>
              </div>,
            );
          }
        }
      }
    }

    options.push(<option>ORG-250</option>);

    return (
      <div ref={this.myDivToFocus}>
        <div className="container-fluid">
          <div className="row">
            <LeftSidebar history={this.props.history} pageSubmit={e => this.pageLink(e)} />

            <div className="main-wrap col-md-12 col-xs-12 pad-r-no">
              <div className="top-bar col-md-12 col-xs-12 pad-r-no">
                <div className="nav-brand-res visible-xs">
                  <img className="img-responsive" src="../images/logo-icon.png" alt="LogoIcon" />
                </div>
                <a href="javascript:;" class="back hidden-xs" onClick={() => this.props.history.goBack()}>
                  <img src="../images/back-arrow-blue.svg" />
                </a>
                {/* <span className='page-title hidden-xs'>Inbox</span> */}
                <ul className="list-unstyled breadcrumb page-title hidden-xs">
                  <li>
                    <a href="javascript: ;" onClick={this.routedChange.bind(this, 'customers-list')}>
                      Vendor
                    </a>
                  </li>
                  <li>Bill Payment</li>
                </ul>
                <Topbar history={this.props.history} logoutSubmit={e => this.logoutLink()} />
              </div>

              <div className="content-top col-md-12 col-xs-12">
                <form className="custom-form mh row">
                  <div className="form-group col-md-3 col-xs-12">
                    <label>Currency</label>
                    <select
                      className="selectpicker form-control"
                      data-live-search="true"
                      title={`Choose`}
                      data-width="100%"
                      value={this.state.selectedCurrency}
                      onChange={e => {
                        this.setState({ selectedCurrency: e.target.value }, this.findInSubAccountList(e.target.value));
                      }}
                    >
                      {this.state.currency_list &&
                        this.state.currency_list.map(item => {
                          if (item.code !== 'ALL') {
                            return <option value={item}> {item}</option>;
                          }
                        })}
                    </select>
                  </div>

                  <div className="form-group col-md-3 col-sm-4 no-edit">
                    <label>Accounts</label>
                    <div>
                      <span className="form-control w-calc">{this.state.account_id_name}</span>
                    </div>
                  </div>

                  <div className="col-md-3" style={{ display: 'none' }}>
                    <div className="form-group">
                      <label>A/R Account</label>
                      <select
                        className="selectpicker form-control add-new"
                        // id="ar_account"
                        data-live-search="true"
                        title="Choose account"
                        value={this.state.selected_account_id}
                        // onChange={e => {
                        //   this.setState({
                        //     preferred_deliveryMethod: e.target.value
                        //   })
                        // }}
                        onChange={e => {
                          this.accountTypeSelection(e);
                        }}
                      >
                        <option selected={true}>Choose</option>
                        {this.state.customer_account_type &&
                          this.state.customer_account_type.map(item => {
                            // var selected = "selected";

                            return (
                              <React.Fragment>
                                <option
                                  // selected={selected}
                                  data-status={item.id}
                                  value={item.id}
                                >
                                  {item.name}
                                </option>
                              </React.Fragment>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3 bal-txt-wrap">
                    <label className="bal-txt">
                      Vendor Balance:{' '}
                      <strong>
                        {this.state.currency_selected} {/* {jQuery('#total2').html()} */}
                        <Comma value={jQuery('#total2').html()} />
                        {/* {this.state.overallcustomerBal} */}
                      </strong>
                    </label>
                  </div>
                </form>
              </div>

              <div className="main-content col-md-12 col-xs-12">
                <div className="content-sec col-md-12 col-xs-12 pad-no mar-t-no">
                  <div className="col-md-12 col-xs-12 block-shadow pad-b-no mar-ver">
                    <form className="custom-form invoice-form">
                      <div className="row">
                        <div className="form-group col-lg-4 col-md-6 col-sm-6 col-xs-12">
                          <label>Payee Name</label>

                          <select
                            // className="selectpicker form-control add-new"
                            className="selectpicker form-control "
                            data-live-search="true"
                            title="Choose vendor"
                            id="variable_pay_type"
                            value={this.state.payor_name}
                            // onChange={e => {
                            //   if (e.target.event != '') {
                            //     this.setSt
                            //   } else {
                            //     this.setState({ iscustomer_name: true })
                            //   }
                            // }}

                            onChange={e => {
                              if (e.target.value == 'add_new') {
                                window.open('/add-new-customer', '_blank');
                              } else {
                                this.handleChange();
                              }
                            }}

                            // onChange={event => {
                            //   this.vendor_invoicelist(event.target.value)
                            // }}
                          >
                            {/* <option value={'add_new'}>Add New Customer</option> */}
                            {this.state.customer_and_job_list &&
                              this.state.customer_and_job_list.map(item => {
                                return (
                                  <option
                                    value={item.id}
                                    data-status={item.id}
                                    data-name={item.vendor_name}
                                    disabled={item.status == 2 ? true : false}
                                    style={{
                                      display: item.status == 2 ? 'none' : 'block',
                                    }}
                                  >
                                    {item.vendor_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <div className="form-group col-lg-4 col-md-6 col-sm-6 col-xs-12" style={{ display: 'none' }}>
                          <label>Job List</label>
                          {this.state.customer_and_job_lists ? (
                            <select
                              className="selectpicker form-control add-new"
                              data-live-search="true"
                              title="Choose a job"
                              id="variable_pay_type_job"
                              value={this.state.job}
                              // onChange={() => {
                              //   this.handleChanges();
                              // }}

                              onChange={e => {
                                if (e.target.value == 'add_new') {
                                  if (this.state.payor_name) {
                                    localStorage.setItem('selected_customer_id', this.state.payor_name);
                                    window.open('/add-job', '_blank');
                                  } else {
                                    alert('Please choose a customer first');
                                  }
                                } else {
                                  this.handleChanges();
                                }
                              }}
                            >
                              <option value={'add_new'}>Add New Job</option>
                              {this.state.customer_and_job_lists &&
                                this.state.customer_and_job_lists.map(item => {
                                  return (
                                    <option value={item.id} data-status={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                            </select>
                          ) : (
                            ' '
                          )}
                        </div>

                        <div className="form-group col-lg-4 col-md-6 col-sm-6 col-xs-12">
                          <label>Date of Payment</label>
                          {this.state.is_payment_date_invalid ? (
                            <span>
                              <small style={{ color: 'red' }}>* Required.</small>
                            </span>
                          ) : (
                            ''
                          )}{' '}
                          <div className="input-group date mar-t-no" data-date-format="DD/MM/YYYY">
                            <input
                              type="text"
                              className="form-control"
                              autoComplete="off"
                              id="date"
                              //  value={this.state.date}
                              onBlur={event => {
                                // same function for check lock date

                                if (this.state.previous_temp_date != jQuery('#date').val()) {
                                  this.setState({
                                    previous_temp_date: jQuery('#date').val(),
                                  });
                                }

                                setTimeout(() => {
                                  this.setState({
                                    temp_date: jQuery('#date').val(),
                                  });
                                }, 500);

                                setTimeout(() => {
                                  if (this.state.lock_date) {
                                    // if (jQuery("#date").val()) {
                                    if (jQuery('#date').val() && this.state.previous_temp_date != jQuery('#date').val()) {
                                      // console.log('ppppp', this.state.lock_date)
                                      let date = this.changefromDate();
                                      // console.log('ppppp', date)
                                      //  var checkedIfCreated= this.state.hasId ? (this.state.lock_date >= date || (this.state.old_transaction_date <= this.state.lock_date && date >= this.state.lock_date)): this.state.lock_date >= date;
                                      var checkedIfCreated = this.state.hasId
                                        ? this.state.lock_date >= date ||
                                          (this.state.old_transaction_date <= this.state.lock_date &&
                                            date >= this.state.lock_date)
                                        : this.state.lock_date >= date;

                                      if (checkedIfCreated) {
                                        jQuery('#date').val('');
                                        window.jQuery('#lock_date').modal('show');
                                        // console.log('ppppp', 'ask password')
                                      } else {
                                        // normal function
                                        setTimeout(() => {
                                          let date = jQuery('#date').val();
                                          this.dateChange(date);
                                        }, 500);
                                        // normal function
                                        window.jQuery('#lock_date').modal('hide');
                                        // console.log('ppppp', 'leave date')
                                      }
                                    }
                                  } else {
                                    setTimeout(() => {
                                      let date = jQuery('#date').val();
                                      this.dateChange(date);
                                    }, 500);
                                  }
                                }, 1000);

                                // same function for check lock date

                                // setTimeout(() => {
                                //   let date = jQuery('#date').val()
                                //   this.dateChange(date)
                                // }, 500);
                              }}
                            />
                            {/* <input
                              type="text"
                              className="form-control"
                              id="date"
                            /> */}
                            <div className="input-group-addon" onClick={() => jQuery('#date').focus()}>
                              <img src="images/calendar-icon.svg" alt="icon" />
                            </div>
                          </div>
                        </div>
                        <div className="form-group exchange-col col-lg-4 col-md-6 col-sm-6 col-xs-12">
                          <label className="mar-btm">Amount of Payment</label>
                          <div className="w-100 res-sm-mt-0">
                            {/* <input */}
                            <NumberFormat
                              autoComplete="off"
                              thousandSeparator={true}
                              name="exchangeRate"
                              className="form-control"
                              id="getEntredAmnt"
                              value={this.state.amount_of_payment}
                              placeholder="0"
                              // onKeyPress={(event) => {
                              //   if (!/[0-9]/.test(event.key)) {
                              //     event.preventDefault();
                              //   }
                              // }}

                              // onChange={(e) => {
                              //   this.state.amount_of_payment = e.target.value
                              //   this.setState({ amount_of_payment: e.target.value }, () => {
                              //     if (this.state.auto_apply) {
                              //       this.payDue(this.state.amount_of_payment)

                              //       if (this.state.vendor_invoicelist.length == 0) {

                              //         this.setState({ isUnused: true, unused_amt: this.state.amount_of_payment })
                              //       }

                              //     } else {
                              //       if (this.state.vendor_invoicelist.length == 0) {
                              //         this.setState({ isUnused: true, unused_amt: this.state.amount_of_payment })
                              //       }
                              //       this.sumTotal()
                              //     }
                              //   })

                              // }}

                              onValueChange={values => {
                                const { formattedValue, value } = values;
                                if (this.state.manually_applied == false) {
                                  this.setState({
                                    is_payment_amount_given: true,
                                  });
                                }
                                this.setState({ amount_of_payment: formattedValue }, () => {
                                  if (this.state.auto_apply) {
                                    if (this.state.manually_applied == false && this.state.trigger_from_edit_payment == false) {
                                      this.payDue(value);
                                      if (this.state.vendor_invoicelist.length == 0) {
                                        this.setState({
                                          isUnused: true,
                                          unused_amt: value,
                                        });
                                      }
                                    } else {
                                      // newly added on 29-06-2022 - to update invoice pmt amount when amount of payment changed manually
                                      // if(this.state.manually_applied == true) {
                                      //modifed on 18-07-2022 -to block auto apply when pmt given for individual invoices
                                      if (
                                        this.state.manually_applied == true &&
                                        this.state.is_individual_pmt_amt_given == false
                                      ) {
                                        this.payDue(value);
                                      } // end here

                                      this.setState({
                                        trigger_from_edit_payment: false,
                                      });
                                    }
                                  } else {
                                    if (this.state.vendor_invoicelist.length == 0) {
                                      this.setState({
                                        isUnused: true,
                                        unused_amt: value,
                                      });
                                    }
                                    this.sumTotal();
                                  }
                                });
                              }}
                            />
                            {this.state.currency_selected && <span className="label">{this.state.currency_selected}</span>}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-lg-4 col-md-6 col-sm-6 col-xs-12">
                          <label>Mode of Payment</label>
                          {/* <select className="selectpicker form-control">
                            {this.state.vendor_payment_method &&
                              this.state.vendor_payment_method.map(item => {
                                return (
                                  <option value={item.id} data-status={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })}
                          </select> */}
                          <select
                            className="selectpicker form-control add-new"
                            data-live-search="true"
                            title="Choose..."
                            id="modeofpayment_type"
                            value={this.state.pay_method}
                            onChange={e => {
                              if (e.target.value == '1qw') {
                                this.setState({ pay_method: '' });
                                jQuery('#modeofpayment_type').val('');
                                window.jQuery('#add_new_payment').modal('show');
                                jQuery('#payment_method option').prop('selected', false).trigger('change');
                              }
                            }}
                          >
                            <option value="1qw">Add new</option>
                            {this.state.vendor_payment_method &&
                              this.state.vendor_payment_method.map(item => {
                                return (
                                  <option value={item.id} data-status={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <div className="form-group col-lg-4 col-md-6 col-sm-6 col-xs-12">
                          <label>Payment Reference</label>
                          <input
                            autoComplete="off"
                            type="text"
                            name="pay-ref"
                            id="paymentreference"
                            className="form-control"
                            value={this.state.reference}
                            onChange={e => this.setState({ reference: e.target.value })}
                          />
                        </div>
                        <div className="form-group col-lg-4 col-md-6 col-sm-6 col-xs-12">
                          <label>Payment Account</label>
                          {this.state.is_payment_account_invalid ? (
                            <span>
                              <small style={{ color: 'red' }}>* Required.</small>
                            </span>
                          ) : (
                            ''
                          )}{' '}
                          <select
                            className="selectpicker form-control add-new"
                            data-live-search="true"
                            id="received_account"
                            value={this.state.rec_acc}
                            title="Choose..."
                            onChange={e => {
                              this.setState({
                                is_payment_account_invalid: false,
                              });
                              if (e.target.value == '1e') {
                                jQuery(`#categry_id option`).prop('selected', false).trigger('change');

                                window.jQuery('#pop-modal').modal('show');
                              } else {
                                this.setState({ rec_acc: e.target.value });
                                this.third_party();
                              }
                            }}
                          >
                            {/* <option>Choose</option> */}
                            <option value="1e">Add new</option>
                            {this.state.vendor_payment_account_type &&
                              this.state.vendor_payment_account_type.map(item => {
                                return (
                                  <option
                                    value={item.id}
                                    data-status={item.account_type_id}
                                    data-name={item.name}
                                    disabled={item.status == 2 ? true : false}
                                    style={{
                                      display: item.status == 2 ? 'none' : 'block',
                                    }}
                                  >
                                    {item.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        {this.state.received_account ? (
                          <div className="form-group col-lg-4 col-md-6 col-sm-6 col-xs-12">
                            <label>Third Party Account Name</label>
                            <select
                              className="selectpicker form-control add-new"
                              data-live-search="true"
                              title="Choose..."
                              id="third_party_id"
                              onChange={e =>
                                this.setState({
                                  third_party_id: e.target.value,
                                })
                              }
                              value={this.state.third_party_id}
                            >
                              {/* <option>
                                <img src="images/plus-icon.svg" alt="img" />
                                Add new
                              </option> */}
                              <option>choose...</option>
                              {this.state.third_party_name &&
                                this.state.third_party_name.map(item => {
                                  return (
                                    <option
                                      value={item.id}
                                      data-status={item.id}
                                      data-name={item.customer_name}
                                      disabled={item.status == 2 ? true : false}
                                      style={{
                                        display: item.status == 2 ? 'none' : 'block',
                                      }}
                                    >
                                      {item.customer_name}
                                    </option>
                                  );
                                })}
                              third_party_name
                            </select>
                          </div>
                        ) : (
                          ''
                        )}

                        {this.state.received_accounts ? (
                          <div className="form-group col-lg-4 col-md-6 col-sm-6 col-xs-12">
                            <label>Third Party Account Name</label>
                            <select
                              className="selectpicker form-control add-new"
                              data-live-search="true"
                              title="Choose..."
                              id="third_party_vendor"
                              onChange={e =>
                                this.setState({
                                  third_party_id: e.target.value,
                                })
                              }
                              value={this.state.third_party_id}
                            >
                              <option>
                                {/* <img src="images/plus-icon.svg" alt="img" /> */}
                                Add new
                              </option>
                              {this.state.third_party_name &&
                                this.state.third_party_name.map(item => {
                                  return (
                                    <option
                                      value={item.id}
                                      data-status={item.id}
                                      data-name={item.customer_name}
                                      disabled={item.status == 2 ? true : false}
                                      style={{
                                        display: item.status == 2 ? 'none' : 'block',
                                      }}
                                    >
                                      {item.vendor_name}
                                    </option>
                                  );
                                })}
                              third_party_name
                            </select>
                          </div>
                        ) : (
                          ''
                        )}
                        <div className="form-group col-lg-4 col-md-6 col-sm-6 col-xs-12">
                          <label>Description</label>
                          <input
                            autoComplete="off"
                            type="text"
                            name="desc"
                            id="descripation"
                            className="form-control"
                            value={this.state.descripation}
                            onChange={e => this.setState({ descripation: e.target.value })}
                          />
                        </div>

                        <div className="form-group exchange-col col-lg-4 col-md-6 col-sm-6 col-xs-12">
                          <label className="mar-btm">
                            Exchange Rate 1 {''} {this.state.currency_selected}
                          </label>
                          <div className="w-100">
                            <input
                              type="text"
                              name="exchangeRate"
                              className="form-control"
                              id="exchange_rate"
                              placeholder={this.state.exchange_rate_temp}
                              value={this.state.exchangeRate}
                              onKeyPress={event => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              onChange={e => this.setState({ exchangeRate: e.target.value })}
                            />
                            {this.state.currency_selected && <span className="label">{this.state.currency_selected}</span>}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <form className="custom-form form-inline mh col-md-12 col-xs-12 mar-top pad-top">
                    <div className="row pad-top">
                      <div className="form-group res-auto-apply">
                        <label className="custom-checkbox mar-b-no">
                          <input
                            type="checkbox"
                            name=""
                            checked={this.state.auto_apply}
                            onChange={e => {
                              if (e.target.checked) {
                                this.setState({
                                  auto_apply: true,
                                  manually_applied: false,
                                });
                                this.payDue(this.convertStringToNumber(this.state.amount_of_payment));
                              } else {
                                this.setState({ auto_apply: false });
                              }
                            }}
                          />{' '}
                          Auto Apply Payment
                          <span className="checkmark"></span>
                        </label>
                        <a href="javascript:;" className="dis-link">
                          Adjustments & Credits
                          <img src="images/down-arrow-blue.svg" alt="icon" />
                        </a>
                        {'  '}
                        {/* <label>Available Credits : {this.state.credit_available} </label> */}
                        {/* <label>Available Credits : {this.state.credit_available - Number(this.state.TotalCreditAmount) >= 0 ? this.state.credit_available - Number(this.state.TotalCreditAmount) :0} </label> */}
                        <label>
                          Available Credits :{' '}
                          {this.state.credit_available - Number(this.state.TotalCreditAmount) >= 0 ? (
                            <Comma value={this.state.credit_available - Number(this.state.TotalCreditAmount)} />
                          ) : (
                            0
                          )}{' '}
                        </label>
                      </div>
                    </div>
                  </form>
                  <div className="tab-enclose col-md-12 col-xs-12 discount-wrap">
                    <div className="row">
                      <ul className="nav nav-tabs nowrap">
                        <li role="presentation" className="active">
                          <a data-toggle="tab" href="#discount">
                            Adjustments
                          </a>
                        </li>
                        <li role="presentation">
                          <a data-toggle="tab" href="#credits">
                            Credits
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content col-md-12 col-xs-12">
                        <div id="discount" className="tab-pane fade in active">
                          <form className="custom-form mh row">
                            {this.state.vendor_invoicelist &&
                              this.state.vendor_invoicelist.map((item, i) => {
                                if (this.state.invoiceSeletedId === i) {
                                  // console.log('manojkumar', this.state[`discount_account_type_temp${i}`])
                                  return (
                                    <div>
                                      <div className="form-group exchange-col col-lg-3 col-md-6 col-xs-12">
                                        <label className="mar-btm">Amount of Adjustment</label>
                                        <div className="w-100">
                                          {/* <input */}
                                          <NumberFormat
                                            thousandSeparator={true}
                                            autoComplete="off"
                                            // type={this.state.invoiceSeletedId === i ? "number" : "hidden"}
                                            className="form-control"
                                            id={`discount_amount_temp${i}`}
                                            name={`discount_amount_temp${i}`}
                                            placeholder="0"
                                            value={this.state[`discount_amount_temp${i}`]}
                                            // onChange={(e) => {
                                            //   let entered_amount = e.target.value != '' ? e.target.value : 0
                                            //   if (Number(entered_amount) > (Number(item.open_balance_foreign_currency) - Number((this.state[`credit_amount${i}`]) ? Number(this.state[`credit_amount${i}`]) : 0))) {
                                            //     alert("you may not able to enter more amount than amount due")
                                            //   } else {
                                            //     let currentTotal = Number(Number(entered_amount) + Number((this.state[`invoice_due${i}`]) ? Number(this.state[`invoice_due${i}`]) : 0) + Number((this.state[`credit_amount${i}`]) ? Number(this.state[`credit_amount${i}`]) : 0))
                                            //     if (currentTotal <= Number(item.open_balance_foreign_currency)) {
                                            //       this.setState({ [`discount_amount_temp${i}`]: entered_amount != 0 ? entered_amount : '', [`inv_check${i}`]: true })
                                            //     } else {
                                            //       var inv_amt = Number(item.open_balance_foreign_currency) - ((Number(entered_amount) + Number((this.state[`credit_amount${i}`]) ? Number(this.state[`credit_amount${i}`]) : 0)))
                                            //       this.setState({
                                            //         [`discount_amount_temp${i}`]: entered_amount != 0 ? entered_amount : '', [`invoice_due${i}`]: inv_amt, [`inv_check${i}`]: true
                                            //       })
                                            //       setTimeout(
                                            //         () => {
                                            //           this.total_paid(inv_amt, i)
                                            //         }
                                            //         ,
                                            //         1000
                                            //       )
                                            //     }
                                            //   }
                                            // }}

                                            onValueChange={values => {
                                              const { formattedValue, value } = values;
                                              let entered_amount = value != '' ? value : 0;
                                              if (
                                                this.state.is_payment_amount_given == false &&
                                                this.state.manually_applied == false
                                              ) {
                                                this.setState({
                                                  manually_applied: true,
                                                });
                                              }
                                              if (
                                                Number(entered_amount) >
                                                Number(item.open_balance_foreign_currency) -
                                                  Number(
                                                    this.state[`credit_amount${i}`] ? Number(this.state[`credit_amount${i}`]) : 0,
                                                  )
                                              ) {
                                                Swal.fire(
                                                  'Sorry',
                                                  'You may not able to enter amount more than Amount Due',
                                                  'error',
                                                );
                                              } else {
                                                let currentTotal = Number(
                                                  Number(entered_amount) +
                                                    (this.state[`invoice_due${i}`]
                                                      ? this.convertStringToNumber(this.state[`invoice_due${i}`])
                                                      : 0) +
                                                    Number(
                                                      this.state[`credit_amount${i}`]
                                                        ? Number(this.state[`credit_amount${i}`])
                                                        : 0,
                                                    ),
                                                );
                                                if (currentTotal <= Number(item.open_balance_foreign_currency)) {
                                                  this.setState(
                                                    {
                                                      [`discount_amount_temp${i}`]: entered_amount != 0 ? formattedValue : '',
                                                      [`inv_check${i}`]: true,
                                                    },
                                                    () => {
                                                      if (this.state.manually_applied == true) {
                                                        var inv_amt =
                                                          Number(item.open_balance_foreign_currency) -
                                                          (Number(entered_amount) +
                                                            Number(
                                                              this.state[`credit_amount${i}`]
                                                                ? Number(this.state[`credit_amount${i}`])
                                                                : 0,
                                                            ));
                                                        this.setState(
                                                          {
                                                            [`invoice_due${i}`]: inv_amt,
                                                            [`inv_check${i}`]: true,
                                                            is_discount_changed: true,
                                                          },
                                                          () => {
                                                            // this.total_paid(inv_amt, i);
                                                            // modified on 29-06-2022
                                                            this.total_paid(inv_amt, i, true);
                                                          },
                                                        );
                                                      } // else condition newly added on 28-06-2022
                                                      else {
                                                        var inv_amt =
                                                          Number(item.open_balance_foreign_currency) -
                                                          (Number(entered_amount) +
                                                            Number(
                                                              this.state[`credit_amount${i}`]
                                                                ? Number(this.state[`credit_amount${i}`])
                                                                : 0,
                                                            ));
                                                        this.setState(
                                                          {
                                                            [`invoice_due${i}`]: inv_amt,
                                                            [`inv_check${i}`]: true,
                                                            is_discount_changed: true,
                                                          },
                                                          () => {
                                                            //this.total_paid(inv_amt, i);
                                                            // modified on 29-06-2022
                                                            this.total_paid(inv_amt, i, true);
                                                          },
                                                        );
                                                      }
                                                    },
                                                  );
                                                } else {
                                                  var inv_amt =
                                                    Number(item.open_balance_foreign_currency) -
                                                    (Number(entered_amount) +
                                                      Number(
                                                        this.state[`credit_amount${i}`]
                                                          ? Number(this.state[`credit_amount${i}`])
                                                          : 0,
                                                      ));
                                                  this.setState(
                                                    {
                                                      [`discount_amount_temp${i}`]: entered_amount != 0 ? formattedValue : '',
                                                      [`invoice_due${i}`]: inv_amt,
                                                      [`inv_check${i}`]: true,
                                                    },
                                                    () => {
                                                      //this.total_paid(inv_amt, i);
                                                      // modified on 29-06-2022
                                                      this.total_paid(inv_amt, i, true);
                                                    },
                                                  );
                                                }
                                              }
                                            }}
                                          />
                                          <span className="label">{this.state.currency_selected}</span>
                                        </div>
                                      </div>
                                      <div className="form-group col-lg-3 col-md-6 col-xs-12">
                                        <label>Adjustment Account</label>
                                        <select
                                          className="selectpicker form-control"
                                          data-live-search="true"
                                          // title='Choose...'
                                          id={`discount_account_type_temp${i}`}
                                          // name={`discount_account_type_temp${i}`}
                                          value={this.state[`discount_account_type_temp${i}`]}
                                          onChange={e => {
                                            if (e.target.value == '1e') {
                                              this.setState(
                                                {
                                                  is_added_from_adjustment_account: true,
                                                  added_adjustment_account_index: i,
                                                  [`discount_account_type_temp${i}`]: e.target.value,
                                                },
                                                () => {
                                                  jQuery(`#categry_id option`).prop('selected', false).trigger('change');

                                                  window.jQuery('#pop-modal').modal('show');
                                                },
                                              );
                                            } else {
                                              this.setState({
                                                [`discount_account_type_temp${i}`]: e.target.value,
                                              });
                                            }
                                          }}
                                        >
                                          <option value={''}> {'choose...'} </option>
                                          <option value="1e">Add new</option>
                                          {this.state.vendor_category &&
                                            this.state.vendor_category.map((item, i) => {
                                              // //console.log('lololol', item)
                                              return (
                                                <option
                                                  value={item.id}
                                                  data-status={item.id}
                                                  // selected={(i = 0) ? selected : null}
                                                >
                                                  {item.name}
                                                </option>
                                              );
                                            })}
                                        </select>
                                      </div>
                                    </div>
                                  );
                                }
                              })}

                            <div className="col-md-12 col-xs-12 text-right">
                              <button
                                type="button"
                                className="btn btn-lightgray mar-rgt-5"
                                data-dismiss="modal"
                                onClick={() => {
                                  jQuery('.discount-wrap').slideToggle();

                                  // Already applied discount amounts do not clear when click on cancel button- user should give 0 value to cancel it
                                  if (this.state[`discount_amount_already_applied${this.state.invoiceSeletedId}`] != true) {
                                    this.setState(
                                      {
                                        [`discount_amount_temp${this.state.invoiceSeletedId}`]: '',
                                        [`inv_check${this.state.invoiceSeletedId}`]: true,
                                        [`discount_account_type_temp${this.state.invoiceSeletedId}`]: '',
                                      },
                                      () => {
                                        if (this.state.manually_applied == true) {
                                          let open_balnce =
                                            this.state.vendor_invoicelist &&
                                            this.state.vendor_invoicelist.length > 0 &&
                                            this.state.vendor_invoicelist[this.state.invoiceSeletedId] &&
                                            this.state.vendor_invoicelist[this.state.invoiceSeletedId][
                                              'open_balance_foreign_currency'
                                            ]
                                              ? this.state.vendor_invoicelist[this.state.invoiceSeletedId][
                                                  'open_balance_foreign_currency'
                                                ]
                                              : 0;
                                          var inv_amt =
                                            Number(open_balnce) -
                                            (0 +
                                              Number(
                                                this.state[`credit_amount${this.state.invoiceSeletedId}`]
                                                  ? Number(this.state[`credit_amount${this.state.invoiceSeletedId}`])
                                                  : 0,
                                              ));
                                          this.setState(
                                            {
                                              [`invoice_due${this.state.invoiceSeletedId}`]: inv_amt,
                                              [`inv_check${this.state.invoiceSeletedId}`]: true,
                                              is_discount_changed: true,
                                            },
                                            () => {
                                              this.total_paid(inv_amt, this.state.invoiceSeletedId);
                                            },
                                          );
                                        }
                                      },
                                    );
                                  }
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                className="btn btn-green mar-rgt-5"
                                type="button"
                                onClick={() => {
                                  this.addDiscount();
                                }}
                              >
                                Ok
                              </button>
                            </div>
                          </form>
                        </div>
                        <div id="credits" className="tab-pane fade in">
                          <div className="clearfix">
                            <p className="fw-med">Available Credits</p>
                            <div className="table-responsive" style={{ paddingright: '1px' }}>
                              {this.state.vendor_invoicelist &&
                                this.state.vendor_invoicelist.map((item1, selectedRow) => {
                                  if (this.state.invoiceSeletedId == selectedRow) {
                                    return (
                                      <table className="dis-table">
                                        <thead>
                                          <tr>
                                            <th className="text-center">
                                              <img className="tick-white" src="images/tick-big.svg" alt="icon" />
                                            </th>
                                            <th>Date</th>
                                            <th>Credit No.</th>
                                            <th className="text-right">Original Credit Amount</th>
                                            <th className="text-right">Credit Balance</th>
                                            <th className="text-right">Amount to Use</th>
                                            <th className="text-right">Net Credit Balance</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.vendor_credit_list &&
                                            this.state.vendor_credit_list.map((item, i) => {
                                              return (
                                                <tr
                                                  onDoubleClick={() => {
                                                    if (item.credit_memo_type == 1) {
                                                      let arr = ['Vendor credit note', item.credit_id];
                                                      localStorage.setItem('vendor_bill', JSON.stringify(arr));

                                                      var win = window.open(
                                                        '/data_tagging/' + item.list_id + '/' + item.file_id,
                                                        '_blank',
                                                      );
                                                      win.focus();

                                                      // window.open("/data_tagging/" + item.list_id +"/" + item.file_id,"_blank")
                                                    } else if (item.credit_memo_type == 11) {
                                                      if (item.is_batch_transaction == 0) {
                                                        localStorage.setItem('deposit_id', item.credit_id);
                                                        let win = window.open('/make_deposit', '_blank');
                                                        win.focus();
                                                      } else {
                                                        localStorage.setItem('batchid', item.batch_transaction_id);
                                                        localStorage.setItem(
                                                          'batch_transaction_row_index',
                                                          item.batch_transaction_row_index,
                                                        );
                                                        let win = window.open('/enter_batch_transaction', '_blank');
                                                        win.focus();
                                                      }
                                                    } else if (item.credit_memo_type == 10) {
                                                      if (item.is_batch_transaction == 0) {
                                                        localStorage.setItem('cheque_id', item.credit_id);
                                                        let win = window.open('/write_cheque', '_blank');
                                                        win.focus();
                                                      } else {
                                                        localStorage.setItem('batchid', item.batch_transaction_id);
                                                        localStorage.setItem(
                                                          'batch_transaction_row_index',
                                                          item.batch_transaction_row_index,
                                                        );
                                                        let win = window.open('/enter_batch_transaction', '_blank');
                                                        win.focus();
                                                      }
                                                    } else if (item.credit_memo_type == 2) {
                                                      let arr = [item.customer_id, item.multi_payment_applied_invoices];

                                                      localStorage.setItem('edit_customer_receive_payment', JSON.stringify(arr));

                                                      var win = window.open('/Customer_receive_payment', '_blank');
                                                      win.focus();
                                                    }
                                                  }}
                                                >
                                                  <td className="text-center">
                                                    <label className="custom-checkbox mar-b-no">
                                                      <input
                                                        type="checkbox"
                                                        name=""
                                                        // value={i}
                                                        checked={this.state[`check_box_credit_to_be_use${i}${selectedRow}`]}
                                                        // onChange={e => this.handleCreditChange(e.target.checked, i, item.available_credits)}
                                                        onChange={e => {
                                                          if (e.target.checked) {
                                                            this.setState({
                                                              [`check_box_credit_to_be_use${i}${selectedRow}`]: true,
                                                            });
                                                            let currently_applied_credits = 0;
                                                            this.state.vendor_credit_list.map((cre, l) => {
                                                              let appl_amt = this.state[`credit_to_be_use${l}${selectedRow}`]
                                                                ? this.convertStringToNumber(
                                                                    this.state[`credit_to_be_use${l}${selectedRow}`],
                                                                  )
                                                                : 0;
                                                              currently_applied_credits = currently_applied_credits + appl_amt;
                                                            });
                                                            let selected_invoice_open_balance =
                                                              Number(item1.open_balance_foreign_currency) -
                                                              (this.state[`discount_amount${selectedRow}`]
                                                                ? Number(this.state[`discount_amount${selectedRow}`])
                                                                : 0) -
                                                              currently_applied_credits;
                                                            let selected_credit_open_balance =
                                                              Number(item.available_credits) -
                                                              (this.state.credit_object[`${i}`]
                                                                ? this.state.credit_object[`${i}`]
                                                                : 0);
                                                            if (selected_invoice_open_balance <= 0) {
                                                              Swal.fire(
                                                                'Sorry',
                                                                "Selected Invoice Did't have Open balance",
                                                                'error',
                                                              );
                                                              this.setState({
                                                                [`check_box_credit_to_be_use${i}${selectedRow}`]: false,
                                                              });
                                                            } else {
                                                              if (selected_credit_open_balance > 0) {
                                                                if (
                                                                  selected_invoice_open_balance > selected_credit_open_balance
                                                                ) {
                                                                  this.setState({
                                                                    [`credit_to_be_use${i}${selectedRow}`]:
                                                                      selected_credit_open_balance,
                                                                  });
                                                                } else {
                                                                  this.setState({
                                                                    [`credit_to_be_use${i}${selectedRow}`]:
                                                                      selected_invoice_open_balance,
                                                                  });
                                                                }
                                                              } else {
                                                                Swal.fire(
                                                                  'Sorry',
                                                                  'Selected Credit already applied with full amount and have no open balance',
                                                                  'error',
                                                                );
                                                                this.setState({
                                                                  [`check_box_credit_to_be_use${i}${selectedRow}`]: false,
                                                                });
                                                              }
                                                            }
                                                          } else {
                                                            this.setState({
                                                              [`check_box_credit_to_be_use${i}${selectedRow}`]: false,
                                                            });
                                                            this.setState({
                                                              [`credit_to_be_use${i}${selectedRow}`]: '',
                                                            });
                                                          }
                                                        }}
                                                      />
                                                      <span className="checkmark"></span>
                                                    </label>
                                                  </td>
                                                  <td>{item.credit_date}</td>
                                                  <td
                                                    style={{
                                                      display: 'none',
                                                    }}
                                                    id={`credit_id${i}`}
                                                  >
                                                    {item.credit_id}
                                                  </td>

                                                  <td>{item.credit_number}</td>
                                                  <td className="text-right">
                                                    <Comma value={item.amount} />
                                                  </td>
                                                  <td className="text-right">
                                                    <Comma value={item.available_credits} />
                                                  </td>
                                                  <td className="text-right">
                                                    {/* <input  */}
                                                    <NumberFormat
                                                      // type='number'
                                                      autoComplete="off"
                                                      thousandSeparator={true}
                                                      id={`credit_to_be_use${i}${selectedRow}`}
                                                      name={`credit_to_be_use${i}${selectedRow}`}
                                                      value={this.state[`credit_to_be_use${i}${selectedRow}`]}
                                                      // onChange={
                                                      //   e => {
                                                      //     let entered_amount = e.target.value == '' ? 0 : Number(e.target.value)
                                                      //     if (Number(entered_amount) > (this.state[`usedCreditsRowTotal${i}`] ? Number(this.state[`usedCreditsRowTotal${i}`]) : Number(item.available_credits))) {
                                                      //       alert("You may not pay more than Credit Amount")
                                                      //     } else if ((Number(this.state[`discount_amount${i}`] ? Number(this.state[`discount_amount${i}`]) : 0) + Number(entered_amount) >
                                                      //       item1.open_balance_foreign_currency)) {
                                                      //       alert("You may not pay more than Invoice due")
                                                      //     } else {
                                                      //       this.setState({ [`credit_to_be_use${i}${selectedRow}`]: entered_amount })
                                                      //       setTimeout(() => this.credit_onchange(i, selectedRow, item.available_credits), 500)
                                                      //     }
                                                      //   }
                                                      // }

                                                      onValueChange={values => {
                                                        const { formattedValue, value } = values;
                                                        let entered_amount = value == '' ? 0 : Number(value);
                                                        let pre_applied_amt_from_this_credit = this.state.credit_object[`${i}`]
                                                          ? this.state.credit_object[`${i}`]
                                                          : 0;
                                                        if (
                                                          Number(entered_amount) + pre_applied_amt_from_this_credit >
                                                          Number(item.available_credits)
                                                        ) {
                                                          Swal.fire('Sorry', 'You may not pay more than Credit Amount', 'error');
                                                          this.setState({
                                                            [`credit_to_be_use${i}${selectedRow}`]:
                                                              this.convertStringToNumber(
                                                                this.state[`credit_to_be_use${i}${selectedRow}`],
                                                              ) > 0
                                                                ? this.convertStringToNumber(
                                                                    this.state[`credit_to_be_use${i}${selectedRow}`],
                                                                  )
                                                                : 0,
                                                          });
                                                        } else if (
                                                          Number(
                                                            this.state[`discount_amount${i}`]
                                                              ? Number(this.state[`discount_amount${i}`])
                                                              : 0,
                                                          ) +
                                                            Number(entered_amount) >
                                                          item1.open_balance_foreign_currency
                                                        ) {
                                                          Swal.fire('Sorry', 'You may not pay more than Invoice due', 'error');
                                                          this.setState({
                                                            [`credit_to_be_use${i}${selectedRow}`]:
                                                              this.convertStringToNumber(
                                                                this.state[`credit_to_be_use${i}${selectedRow}`],
                                                              ) > 0
                                                                ? this.convertStringToNumber(
                                                                    this.state[`credit_to_be_use${i}${selectedRow}`],
                                                                  )
                                                                : 0,
                                                          });
                                                        } else {
                                                          this.setState(
                                                            {
                                                              [`credit_to_be_use${i}${selectedRow}`]: formattedValue,
                                                            },
                                                            () => {
                                                              if (entered_amount > 0) {
                                                                this.setState({
                                                                  [`check_box_credit_to_be_use${i}${selectedRow}`]: true,
                                                                });
                                                              } else {
                                                                this.setState({
                                                                  [`check_box_credit_to_be_use${i}${selectedRow}`]: false,
                                                                });
                                                              }
                                                            },
                                                          );
                                                        }
                                                      }}
                                                    />
                                                  </td>
                                                  <td className="text-right">
                                                    {/* <span id={`creditBal${i}`}>{this.state[`usedCreditsRowTotal${i}`]}</span> */}
                                                    <Comma
                                                      value={
                                                        this.state.credit_object[`${i}`]
                                                          ? item.available_credits - this.state.credit_object[`${i}`]
                                                          : this.state[`credit_to_be_use${i}${selectedRow}`]
                                                          ? item.available_credits -
                                                            this.convertStringToNumber(
                                                              this.state[`credit_to_be_use${i}${selectedRow}`],
                                                            )
                                                          : item.available_credits
                                                      }
                                                    />
                                                  </td>
                                                </tr>
                                              );
                                            })}
                                          <tr>
                                            <td colspan="3" className="text-right">
                                              Total
                                            </td>
                                            <td className="text-right">
                                              <Comma value={this.state.credit_total} />
                                            </td>
                                            <td className="text-right">
                                              <Comma value={this.state.credit_amounttouse} />
                                            </td>
                                            {/* <td className='text-right'>--</td> */}
                                          </tr>
                                        </tbody>
                                      </table>
                                    );
                                  }
                                })}
                            </div>
                            <div className="col-md-6 pad-no">
                              <em className="info-em">
                                Of all the credits issued to this vendor, only unused credits applicable to this specific invoice
                                are displayed above
                              </em>
                            </div>
                            <div className="col-md-6 text-right pad-no">
                              <button
                                className="btn btn-lightgray"
                                onClick={() => {
                                  this.clearCredit(this.state.invoiceSeletedId);
                                }}
                              >
                                Clear
                              </button>
                            </div>
                          </div>

                          <div className="clearfix">
                            <p className="fw-med">Applied Credits History</p>
                            <div className="table-responsive" style={{ paddingright: '1px' }}>
                              <table className="dis-table">
                                <thead>
                                  <tr>
                                    <th className="text-center">
                                      <img className="tick-white" src="images/tick-big.svg" alt="icon" />
                                    </th>
                                    <th>Date</th>
                                    <th>Credit No.</th>
                                    <th className="text-right">Original Credit Amount</th>
                                    <th className="text-right">Credit Balance</th>
                                    <th className="text-right">Amount to Use</th>
                                    <th className="text-right">Net Credit Balance</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {/* {this.state.applied_credit_history &&
                                    this.state.applied_credit_history.map( */}
                                  {this.state.invoice_specific_applied_credit_history &&
                                    this.state.invoice_specific_applied_credit_history.map(item => {
                                      return (
                                        <tr
                                          onDoubleClick={() =>
                                            window.open('/data_tagging/' + item.list_id + '/' + item.file_id, '_blank')
                                          }
                                        >
                                          <td className="text-center">
                                            <label className="custom-checkbox mar-b-no default-cursor">
                                              <input type="checkbox" name="" checked="checked" />
                                              <span className="checkmark"></span>
                                            </label>
                                          </td>
                                          <td>{item.credit_date}</td>
                                          <td>{item.credit_number}</td>
                                          <td className="text-right">
                                            <Comma value={item.amount} />
                                          </td>
                                          <td className="text-right">
                                            <Comma value={item.credit_balance} />
                                          </td>
                                          <td className="text-right">
                                            <Comma value={item.amount_to_us} />
                                          </td>
                                          <td className="text-right">
                                            <Comma value={item.credit_balance} />
                                          </td>
                                          {/* <td className='text-right'>--</td> */}
                                        </tr>
                                      );
                                    })}
                                  {/* <tr>
                                    <td colspan='3' className='text-right'>
                                      Total
                                    </td>
                                    <td className='text-right'>{this.state.credit_hist_total}</td>
                                    <td className='text-right'>{this.state.credit_his_amounttouse}</td>
                                    <td className='text-right'>{this.state.credit_his_creditbalance}</td>
                                  </tr> */}
                                </tbody>
                              </table>
                            </div>
                            <div className="col-md-12 text-right pad-no">
                              <button className="btn btn-lightgray" onClick={() => jQuery('.discount-wrap').slideToggle()}>
                                Cancel
                              </button>
                              <button
                                className="btn btn-green"
                                onClick={() => {
                                  this.applyCredit();
                                  jQuery('.discount-wrap').slideToggle();
                                }}
                              >
                                Ok
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive col-md-12 col-xs-12 pad-no">
                    <table className="payment-table">
                      <thead>
                        <tr>
                          <th className="text-center">
                            <img src="images/tick-big.svg" alt="icon" />
                          </th>
                          <th>Date</th>
                          <th>Number</th>
                          <th className="text-right">Org Amount</th>
                          <th className="text-right">Amount Due</th>
                          <th className="text-right">Adjustment</th>
                          <th className="text-right">Credit Used</th>

                          <th className="text-right">Payment</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.vendor_invoicelist &&
                          this.state.vendor_invoicelist.map((item, i) => {
                            return (
                              <tr
                                onClick={() => this.selectRow(i, item.id)}
                                onDoubleClick={() => window.open('/data_tagging/' + item.list_id + '/' + item.file_id, '_blank')}
                              >
                                <td className="text-center">
                                  <label className="custom-checkbox mar-b-no">
                                    <input
                                      type="checkbox"
                                      id={`invoice_chekbox${i}`}
                                      checked={this.state[`inv_check${i}`]}
                                      onChange={e => {
                                        if (this.state.is_payment_amount_given == false) {
                                          this.setState({
                                            manually_applied: true,
                                          });
                                        }
                                        if (e.target.checked) {
                                          var amt =
                                            Number(item.open_balance_foreign_currency) -
                                            (this.state[`discount_amount${i}`] ? Number(this.state[`discount_amount${i}`]) : 0) -
                                            (this.state[`credit_amount${i}`] ? Number(this.state[`credit_amount${i}`]) : 0);

                                          if (!this.total_paid(amt, i, true)) {
                                            this.setState(
                                              {
                                                [`inv_check${i}`]: true,
                                                [`invoice_due${i}`]: amt,
                                              },
                                              () => {
                                                this.sumTotal();
                                                this.handleAmountChange();
                                                jQuery('#invoice_due' + i).html(amt);
                                              },
                                            );
                                          } else if (!this.total_paid(this.state.unused_amt, i)) {
                                            this.setState(
                                              {
                                                [`inv_check${i}`]: true,
                                                [`invoice_due${i}`]: this.state.unused_amt,
                                              },
                                              () => {
                                                this.sumTotal();
                                                this.handleAmountChange();
                                              },
                                            );
                                          }
                                        } else {
                                          let current_invoice_payment_amount = this.state[`invoice_due${i}`];
                                          let previous_amount_of_payment = this.state.amount_of_payment;

                                          // this.setState({ [`inv_check${i}`]: false, [`invoice_due${i}`]: 0, [`discount_amount${i}`]: 0, [`credit_amount${i}`]: 0, [`discount_amount_temp${this.state.i}`]: '' }
                                          // )
                                          // setTimeout(
                                          //   () => {
                                          //     this.sumTotal()
                                          //     this.handleAmountChange()
                                          //   }
                                          //   ,
                                          //   2000
                                          // )
                                          // this.total_paid(0, i)
                                          // jQuery('#invoice_due' + i).html('')
                                          // jQuery('#creditAmnt' + i).html('')

                                          if (this.state.manually_applied == true) {
                                            this.setState(
                                              {
                                                amount_of_payment:
                                                  this.convertStringToNumber(previous_amount_of_payment) -
                                                  this.convertStringToNumber(current_invoice_payment_amount),
                                                [`inv_check${i}`]: false,
                                                [`invoice_due${i}`]: 0,
                                              },
                                              () => {
                                                this.sumTotal();
                                                this.handleAmountChange();
                                                this.total_paid(0, i, true);
                                              },
                                            );
                                          } else {
                                            this.setState(
                                              {
                                                [`inv_check${i}`]: false,
                                                [`invoice_due${i}`]: 0,
                                              },
                                              () => {
                                                this.sumTotal();
                                                this.handleAmountChange();
                                                this.total_paid(0, i, true);
                                              },
                                            );
                                          }
                                        }
                                      }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </td>
                                <td
                                  style={{
                                    background: this.state.invoiceSeletedId === i ? '#e3fbed' : 'white',
                                    position: this.state.invoiceSeletedId === i ? 'relative' : '',
                                  }}
                                >
                                  <span>{item.invoice_date}</span>
                                </td>
                                {/* for save */}
                                <input type="hidden" id={`invoice_credit${i}`} />
                                <input type="hidden" id={`invoice_account${i}`} value={item.invoice_account} />
                                <input type="hidden" id={`invoice_exchange_rate${i}`} value={item.invoice_exchange_rate} />
                                {/* for save  */}
                                <td
                                  style={{
                                    background: this.state.invoiceSeletedId === i ? '#e3fbed' : 'white',
                                    position: this.state.invoiceSeletedId === i ? 'relative' : '',
                                  }}
                                >
                                  <span id={`invoice_id${i}`}>{item.invoice_number}</span>
                                </td>
                                <td
                                  className="text-right"
                                  style={{
                                    background: this.state.invoiceSeletedId === i ? '#e3fbed' : 'white',
                                    position: this.state.invoiceSeletedId === i ? 'relative' : '',
                                  }}
                                >
                                  <span id={`original${i}`}>
                                    <Comma value={item.grand_total_foreign_currency} />
                                  </span>
                                </td>
                                <td
                                  className="text-right"
                                  style={{
                                    background: this.state.invoiceSeletedId === i ? '#e3fbed' : 'white',
                                    position: this.state.invoiceSeletedId === i ? 'relative' : '',
                                  }}
                                >
                                  <span id={`due_of_invoice${i}`}>
                                    <Comma value={item.open_balance_foreign_currency} />
                                  </span>
                                </td>
                                <td
                                  className="text-right"
                                  style={{
                                    background: this.state.invoiceSeletedId === i ? '#e3fbed' : 'white',
                                    position: this.state.invoiceSeletedId === i ? 'relative' : '',
                                  }}
                                >
                                  <span id={`total_discount_amount${i}`}>
                                    <Comma value={this.state[`discount_amount${i}`]} />
                                  </span>
                                </td>
                                <td
                                  className="text-right"
                                  style={{
                                    background: this.state.invoiceSeletedId === i ? '#e3fbed' : 'white',
                                    position: this.state.invoiceSeletedId === i ? 'relative' : '',
                                  }}
                                >
                                  <span id={`total_credit_amount${i}`}>
                                    <Comma value={this.state[`credit_amount${i}`]} />
                                  </span>
                                </td>
                                <td
                                  className="text-right"
                                  style={{
                                    background: this.state.invoiceSeletedId === i ? '#e3fbed' : 'white',
                                    position: this.state.invoiceSeletedId === i ? 'relative' : '',
                                  }}
                                >
                                  {/* <input */}
                                  <NumberFormat
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    // type={Number}
                                    id={`invoice_due${i}`}
                                    name={`invoice_due${i}`}
                                    value={this.state[`invoice_due${i}`]}
                                    onClick={() => {
                                      this.setState({
                                        is_individual_pmt_amt_given: true,
                                      });
                                    }}
                                    onBlur={() => {
                                      //  this.setState({is_individual_pmt_amt_given:false});
                                    }}
                                    // onChange={
                                    //   e => {
                                    //     if (e.target.value > (Number(item.open_balance_foreign_currency) - ((this.state[`discount_amount${i}`]) ? Number(this.state[`discount_amount${i}`]) : 0) - ((this.state[`credit_amount${i}`]) ? Number(this.state[`credit_amount${i}`]) : 0))) {
                                    //       alert("You may not pay more than Amount Due")
                                    //     } else if (this.total_paid(e.target.value, i)) {
                                    //       alert("You may not pay more than  amount of payment")
                                    //     } else {
                                    //       this.setState({ [`invoice_due${i}`]: e.target.value, [`inv_check${i}`]: e.target.value > 0 ? true : false },
                                    //         () => {
                                    //           setTimeout(
                                    //             () => this.handleAmountChange()
                                    //             ,
                                    //             2000
                                    //           )
                                    //         })
                                    //     }
                                    //   }
                                    // }

                                    onValueChange={values => {
                                      const { formattedValue, value } = values;
                                      if (
                                        value >
                                        Number(item.open_balance_foreign_currency) -
                                          (this.state[`discount_amount${i}`] ? Number(this.state[`discount_amount${i}`]) : 0) -
                                          (this.state[`credit_amount${i}`] ? Number(this.state[`credit_amount${i}`]) : 0)
                                      ) {
                                        if (!this.state.is_discount_changed && !this.state.is_credit_changed) {
                                          Swal.fire('Sorry', 'You may not pay more than Amount Due', 'error');
                                        }

                                        this.setState(
                                          {
                                            [`invoice_due${i}`]:
                                              this.convertStringToNumber(this.state[`invoice_due${i}`]) > 0
                                                ? this.convertStringToNumber(this.state[`invoice_due${i}`])
                                                : 0,
                                            [`inv_check${i}`]:
                                              this.convertStringToNumber(this.state[`invoice_due${i}`]) > 0 ? true : false,
                                          },
                                          () => {
                                            this.handleAmountChange();
                                          },
                                        );
                                      } else if (this.total_paid(value, i)) {
                                        if (!this.state.is_discount_changed && !this.state.is_credit_changed) {
                                          // Swal.fire("Sorry","You may not pay more than amount of payment","error");
                                          // Newly added to update payment amount on manual apply
                                          if (this.state.is_payment_amount_given == false) {
                                            // Newly added on 16-06-2022- To update amount of payment when manually entering amount without given Amount of Payment
                                            this.setState({
                                              manually_applied: true,
                                            });

                                            var total_paid = 0;
                                            let vendor_invoicelist = this.state.vendor_invoicelist
                                              ? this.state.vendor_invoicelist
                                              : [];
                                            vendor_invoicelist.map((item, ite) => {
                                              if (ite != i) {
                                                total_paid =
                                                  Number(total_paid) +
                                                  (this.state[`invoice_due${ite}`]
                                                    ? this.convertStringToNumber(this.state[`invoice_due${ite}`])
                                                    : 0);
                                              }
                                            });

                                            let amount_differ = Number(value);
                                            let noOfInvoices = this.state.vendor_invoicelist.length;
                                            if (noOfInvoices != '' && noOfInvoices != undefined) {
                                              this.state.vendor_invoicelist.map((item, ite) => {
                                                if (ite == i) {
                                                  let amt =
                                                    Number(item.open_balance_foreign_currency) -
                                                    (this.state[`discount_amount${ite}`]
                                                      ? Number(this.state[`discount_amount${ite}`])
                                                      : 0) -
                                                    (this.state[`credit_amount${ite}`]
                                                      ? Number(this.state[`credit_amount${ite}`])
                                                      : 0);

                                                  if (amt != 0) {
                                                    if (amount_differ <= amt) {
                                                      this.setState({
                                                        amount_of_payment: total_paid + amount_differ,
                                                        amount_of_payment_used: total_paid + amount_differ,
                                                        [`invoice_due${ite}`]: amount_differ,
                                                        [`inv_check${ite}`]: true,
                                                      });
                                                    } else {
                                                      Swal.fire('Sorry', 'You may not pay more than Invoice due amount', 'error');
                                                    }
                                                  }
                                                }
                                              });
                                            }
                                            setTimeout(
                                              function () {
                                                this.sumTotal();
                                              }.bind(this),
                                              3000,
                                            );
                                          } else {
                                            Swal.fire('Sorry', 'You may not pay more than amount of payment', 'error');
                                            //Newly added- to set as previous amount when amount greater than Amount of payment
                                            this.setState(
                                              {
                                                [`invoice_due${i}`]:
                                                  this.convertStringToNumber(this.state[`invoice_due${i}`]) > 0
                                                    ? this.convertStringToNumber(this.state[`invoice_due${i}`])
                                                    : 0,
                                                [`inv_check${i}`]:
                                                  this.convertStringToNumber(this.state[`invoice_due${i}`]) > 0 ? true : false,
                                              },
                                              () => {
                                                this.handleAmountChange();
                                              },
                                            );
                                          }
                                        }
                                      } else {
                                        this.setState(
                                          {
                                            [`invoice_due${i}`]: formattedValue,
                                            [`inv_check${i}`]: value > 0 ? true : false,
                                          },
                                          () => {
                                            this.handleAmountChange();
                                          },
                                        );
                                      }
                                    }}
                                  />
                                  <span contentEditable id={`invoice_due${i}`}></span>
                                </td>
                              </tr>
                            );
                          })}
                        <tr>
                          <td colspan="3" className="text-right">
                            <span className="fw-sbold">Total</span>
                          </td>
                          <td className="text-right">
                            <span class="fw-sbold" id="total1" style={{ display: 'none' }}></span>
                            <span className="fw-sbold">
                              <Comma value={jQuery('#total1').html()} />
                            </span>
                          </td>
                          <td className="text-right">
                            <span className="fw-sbold" id="total2" style={{ display: 'none' }}></span>
                            <span className="fw-sbold">
                              <Comma value={jQuery('#total2').html()} />
                            </span>
                          </td>
                          <td className="text-right">
                            {/* <span className='fw-sbold'  >{this.state.TotalDiscountAmount}</span> */}
                            <span className="fw-sbold">
                              <Comma value={this.state.TotalDiscountAmount} />
                            </span>
                          </td>
                          <td className="text-right">
                            {/* <span className='fw-sbold'>{this.state.credit_amount_total}</span> */}
                            <span className="fw-sbold">
                              <Comma value={this.state.credit_amount_total} />
                            </span>
                          </td>
                          <td className="text-right">
                            {/* <span className='fw-sbold' id='total3'>{this.state.amount_of_payment_used}</span> */}
                            <span className="fw-sbold" id="total3">
                              <Comma value={this.state.amount_of_payment_used} />
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  {this.state.under && (
                    <>
                      <div className="col-lg-4 col-md-5 under-pay " style={{ background: 'red' }}>
                        <span className="lead col-md-12 col-xs-12">
                          <span>Under Payment</span>
                          <span className="pull-right">
                            {/* {this.state.currency_selected + ''} {this.state.under_amt} */}
                            {this.state.currency_selected + ''} <Comma value={this.state.under_amt} />
                          </span>
                        </span>
                        <div className="form-group col-md-12 col-xs-12 pad-no mar-b-no">
                          <label className="custom-checkbox radio">
                            <input
                              type="radio"
                              name="over"
                              checked={this.state.option == 1 ? true : false}
                              onClick={() => {
                                this.setState({ option: '1' });
                              }}
                            />{' '}
                            Leave this as an underpayment
                            <span className="checkmark"></span>
                          </label>
                          {/* <label className='custom-checkbox radio mar-b-no'>
                            <input
                              type='radio'
                              name='over'
                              checked={this.state.option == 2 ? true : false}
                              onClick={() => {
                                this.setState({ option: '2' })
                                window.jQuery('#writeoff-modal').modal('show')
                              }}
                            />
                          Write off the extra amount
                          <span className='checkmark'></span>
                          </label> */}
                        </div>
                      </div>
                    </>
                  )}

                  {this.state.over && (
                    <>
                      <div className="col-lg-4 col-md-5 under-pay over-pay " style={{ background: 'springgreen' }}>
                        <span className="lead col-md-12 col-xs-12">
                          <span>OverPayment</span>
                          <span className="pull-right">
                            {/* {this.state.currency_selected + ''} {this.state.overPay} */}
                            {this.state.currency_selected + ''} {<Comma value={this.state.overPay} />}
                          </span>
                        </span>
                        <div className="form-group col-md-12 col-xs-12 pad-no mar-b-no">
                          <label className="custom-checkbox radio">
                            <input
                              type="radio"
                              name="over"
                              // defaultChecked={true}
                              checked={this.state.option == 3 ? true : false}
                              onClick={() => {
                                this.setState({ option: '3' });
                              }}
                            />{' '}
                            Leave the credit to be used later
                            <span className="checkmark"></span>
                          </label>
                          <label className="custom-checkbox radio mar-b-no">
                            <input
                              type="radio"
                              name="over"
                              checked={this.state.option == 4 ? true : false}
                              onClick={() => {
                                this.setState({ option: '4' });
                                jQuery('#refundamnt').val(this.state.overPay);
                                jQuery('#vendor_name').val(this.state.vendorName);

                                window.jQuery('#refund-modal').modal('show');
                              }}
                            />
                            Refund the amount to the vendor
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </>
                  )}

                  {this.state.isUnused && (
                    <div className="col-lg-4 col-md-5 under-pay " style={{ background: 'yellow' }}>
                      <span className="lead col-md-12 col-xs-12">
                        <span>Unapplied Amount</span>
                        <span className="pull-right">
                          {/* {this.state.currency_selected + ''} {this.state.unused_amt} */}
                          {this.state.currency_selected + ''} <Comma value={this.state.unused_amt} />
                        </span>
                      </span>
                      <div className="form-group col-md-12 col-xs-12 pad-no mar-b-no">
                        <label className="custom-checkbox radio">
                          A credit for over payment will remain on the customer's account
                          {/* <span className='checkmark'></span> */}
                        </label>
                      </div>
                    </div>
                  )}

                  <div className="col-lg-4 col-md-5 inv-total">
                    <span className="lead col-md-12 col-xs-12">Amounts for selected invoices</span>
                    <span className="row-block">
                      <span>Amount Due ({this.state.currency_selected})</span>
                      <span>
                        <Comma value={Number(this.state.totalDueFromApi).toFixed(2)} />
                      </span>
                    </span>
                    <span className="row-block">
                      <span>Applied ({this.state.currency_selected})</span>
                      <span>
                        {this.state.amount_of_payment_used ? (
                          <Comma value={Number(this.state.amount_of_payment_used).toFixed(2)} />
                        ) : (
                          0.0
                        )}
                      </span>
                    </span>
                    <span className="row-block">
                      <span>Adjustments and Credits Applied ({this.state.currency_selected})</span>
                      <span className="appliedcredits">
                        <Comma value={Number(this.state.TotalDiscountAmount) + Number(this.state.TotalCreditAmount)} />
                      </span>
                    </span>
                    {/* <span className='row-white'>
                      <span>Amount Received (USD)</span>
                      <span>{Number(this.state.totalDueFromApi - (this.state.amount_of_payment) ? Number(this.state.amount_of_payment).toFixed(2) : 0.00).toFixed(2)}</span>
                    </span> */}
                  </div>

                  <div className="pf-btm-wrap bg-sticky">
                    <div className="col-md-6 col-sm-6 col-xs-12 pad-no">
                      <button
                        className="btn btn-empty ico"
                        // onClick={this.convertHtmlToPdf.bind(this)}
                        type="button"
                        onClick={() => window.jQuery('#attachment_modal').modal('show')}
                      >
                        <img src="images/attach-icon.svg" alt="icon" />
                        Attachment <span className="blue-txt">{this.state.list_of_attachments.length}</span>
                      </button>
                      {/* <button className="btn btn-empty ico">
                        <img src="images/print-icon.svg" alt="icon" />
                        Print
                      </button>
                      <button className="btn btn-empty ico">
                        <img src="images/pdf-icon.svg" alt="icon" />
                        Save as PDF
                      </button> */}
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12 text-right pad-no">
                      {this.state.multi_payment_applied_invoices ? (
                        <button
                          className="btn btn-lightgray btn-align"
                          data-toggle="modal"
                          type="button"
                          onClick={() => {
                            let input = {
                              client_id: this.state.logged_client_id,
                              multi_payment_applied_invoices: this.state.multi_payment_applied_invoices,
                              status_to_set: 11,
                            };

                            FetchAllApi.void_bill_multi_payment(input, (err, response) => {
                              if (response.status === 1) {
                                alert(response.message);
                              } else {
                                alert(response.message);
                              }
                            });
                          }}
                        >
                          Void
                        </button>
                      ) : (
                        ''
                      )}

                      <button
                        className="btn btn-lightgray btn-align"
                        data-toggle="modal"
                        // data-target="#refund-modal"
                        type="button"
                        onClick={() => {
                          this.props.history.push('/customers-list');
                        }}
                      >
                        Close
                      </button>

                      {'  '}
                      <button
                        className="btn btn-yellow btn-align"
                        data-toggle="modal"
                        // data-target="#writeoff-modal"
                        // onClick={() => {
                        //   window.jQuery("#pop-modal-for-notes").modal("show");
                        // }}
                        type="button"
                        onClick={() => this.customer_recived_payment('save&new')}
                      >
                        Save &amp; New
                      </button>
                      {'   '}
                      <button className="btn btn-green btn-align" onClick={() => this.customer_recived_payment('save')}>
                        Save
                      </button>
                      {'   '}
                    </div>
                  </div>
                </div>
              </div>

              {this.state.isSuccessful ? (
                <div className="alert alert-card success alert-dismissible fade in" id="closeme1">
                  <a
                    href="#"
                    className="close"
                    data-dismiss="alert"
                    aria-label="close"
                    onClick={() => {
                      this.setState({ isSuccessful: false });
                    }}
                  >
                    &times;
                  </a>
                  <div className="img-wrap">
                    <img className="img-responsive" src="../../images/alert-success.svg" alt="icon" />
                  </div>
                  <div className="alert-cont">
                    <strong className="title">Success!</strong>" Inserted successfully"
                  </div>
                </div>
              ) : (
                ''
              )}

              {this.state.isFailed && (
                <div className="alert alert-card danger alert-dismissible fade in">
                  <a
                    href="#"
                    className="close"
                    onClick={() => {
                      this.setState({ isFailed: false });
                    }}
                    data-dismiss="alert"
                    aria-label="close"
                  >
                    ×
                  </a>
                  <div className="img-wrap">
                    <img className="img-responsive" src="images/alert-cross.svg" alt="icon" />
                  </div>
                  <div className="alert-cont">
                    <strong className="title">Failed!</strong>
                    {this.state.errormessage}
                  </div>
                </div>
              )}

              {/* for lock date modal */}

              <div>
                <div class="modal fade in" id="lock_date" role="dialog" style={{ paddingLeft: 15 }}>
                  <div class="modal-dialog modal-md" style={{ width: 440 }}>
                    <button type="button" class="close hidden-xs" data-dismiss="modal">
                      <img class="img-responsive" src="../../images/close-red.svg" alt="icon" />
                    </button>
                    <div class="modal-content">
                      <div class="modal-body text-center success-modal">
                        <div class="pop-icon img-size">{/* {<img src="../../images/delete-icon.svg" alt="icon" />} */}</div>

                        <h3>Are you sure?</h3>

                        <p class="fw-500">
                          This date is beyond lock date. To apply this date , need to verify Lock Date password
                        </p>
                        <button className="btn btn-lightgray" data-dismiss="modal">
                          Cancel
                        </button>
                        <span>{'   '}</span>
                        <button
                          class="btn btn-red"
                          type="button"
                          data-dismiss="modal"
                          data-toggle="modal"
                          data-target="#asking_password_for_delete"
                          // onClick={this.deleteUser}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade pop-modal"
                id="asking_password_for_delete"
                // data-backdrop='static'
              >
                <div className="modal-dialog modal-xs custom-modal">
                  {/* Modal content*/}
                  <button type="button" className="close hidden-xs" data-dismiss="modal">
                    <img className="img-responsive" src="images/close-red.svg" alt="icon" />
                  </button>
                  <div className="modal-content">
                    <div className="modal-body text-center">
                      <h3>Change Password</h3>
                      <form className="custom-form row column">
                        <div className="form-group col-md-12 col-xs-12">
                          <label>
                            Enter Lock Password
                            <span className="astrick">*</span>
                          </label>
                          <div className="form-group login-eye">
                            <i
                              className="pass-visible"
                              toggle="#password-fieldc"
                              onClick={() => this.setState({ show: !this.state.show })}
                            >
                              <img className="off" src="images/visibility-off.svg" alt="hide" />
                              <img className="on" src="images/visibility.svg" alt="show" />
                            </i>
                            <input
                              type={this.state.show ? 'text' : 'password'}
                              value={this.state.old_password}
                              className="form-control"
                              required="required"
                              autocomplete="off"
                              onChange={e => this.setState({ old_password: e.target.value })}
                            />
                          </div>
                          {this.state.old_password == '' ? (
                            <div style={{ float: 'left' }}>
                              <small style={{ color: 'red' }}>please enter this password field.</small>
                            </div>
                          ) : (
                            ''
                          )}
                          {this.state.old_password != '' && this.state.deleteMsg == true ? (
                            <div style={{ float: 'left' }}>
                              <small style={{ color: 'red' }}>*Incorrect password</small>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className="form-group col-md-12 col-xs-12 mh btn-sec text-center mar-b-no">
                          <button className="btn btn-lightgray mar-rgt-5" data-dismiss="modal">
                            Cancel
                          </button>
                          <input
                            type="button"
                            className="btn btn-green mar-rgt-5"
                            value="Submit"
                            onClick={() => {
                              if (this.state.old_password !== '') {
                                this.checkPassword();
                              }
                            }}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              {/* for lock date modal */}

              <div class="modal fade pop-modal" id="refund-modal" role="dialog">
                <div class="modal-dialog modal-md custom-modal">
                  <button type="button" class="close hidden-xs" data-dismiss="modal">
                    <img class="img-responsive" src="images/close-red.svg" alt="icon" />
                  </button>
                  <div class="modal-content">
                    <div class="modal-body text-center">
                      <h3>Refund the Amount</h3>
                      <form class="custom-form row column">
                        <div class="form-group col-md-6 col-xs-12">
                          <label>Vendor Name</label>
                          <input type="text" name="ac-name" class="form-control" id="vendor_name" />
                        </div>
                        <div class="form-group col-md-6 col-xs-12">
                          <label>Mode of Payment</label>
                          <select
                            className="selectpicker form-control add-new"
                            data-live-search="true"
                            title="Choose..."
                            value={this.state.modeOfPay}
                            onChange={e => {
                              this.setState({
                                modeOfPay: e.target.value,
                              });
                            }}
                          >
                            <option>Create New </option>
                            {this.state.vendor_payment_method &&
                              this.state.vendor_payment_method.map(item => {
                                return (
                                  <option value={item.id} data-status={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <div class="form-group exchange-col col-md-6 col-xs-12">
                          <label>Refund Amount</label>
                          <div class="w-100">
                            <input type="text" name="exchangeRate" class="form-control" id="refundamnt" />
                            <span class="label">{this.state.currency_selected}</span>
                          </div>
                        </div>
                        <div class="form-group exchange-col col-md-6 col-xs-12">
                          <label>
                            Exchange Rate 1 {''} {this.state.currency_selected}
                          </label>
                          <div class="w-100">
                            <input
                              type="text"
                              name="exchangeRate"
                              class="form-control text-right"
                              id="refund_exchange"
                              // value="1.38"
                              autoComplete="off"
                            />
                            <span class="label">{this.state.currency_selected}</span>
                          </div>
                        </div>
                        <div class="form-group col-md-6 col-xs-12">
                          <label>Date of Refund</label>
                          <div class="input-group date mar-t-no" data-date-format="dd/mm/yyyy">
                            <input
                              type="text"
                              class="form-control"
                              id="refund_date"
                              onBlur={e => {
                                let date = e.target.value;
                                this.dateChange(date, 'refund');
                              }}
                            />
                            <div class="input-group-addon">
                              <img src="images/calendar-icon.svg" alt="icon" />
                            </div>
                          </div>
                        </div>
                        <div class="form-group col-md-6 col-xs-12">
                          <label>Received Account</label>
                          <select
                            class="selectpicker form-control"
                            data-live-search="true"
                            title="Choose..."
                            value={this.state.recievedAccount}
                            onChange={e => {
                              this.setState({
                                recievedAccount: e.target.value,
                                recievedAccountName: e.target.name,
                              });
                            }}
                          >
                            {this.state.vendor_payment_method_bank &&
                              this.state.vendor_payment_method_bank.map(item => {
                                return (
                                  <option value={item.id} name={item.name}>
                                    {item.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <div class="form-group col-md-12 col-xs-12 mh btn-sec text-right mar-b-no">
                          <button class="btn btn-lightgray" type="button">
                            Cancel
                          </button>
                          {'   '}
                          <button
                            class="btn btn-green"
                            type="button"
                            onClick={() => {
                              let refundamnt = jQuery('#refundamnt').val();
                              var date = this.convert_date(jQuery('#refund_date').val());

                              if (
                                this.state.vendorName != '' &&
                                this.state.modeOfPay &&
                                this.state.recievedAccount &&
                                date != undefined &&
                                refundamnt != undefined
                              ) {
                                this.setState({
                                  refundStatus: {
                                    vendor_id: jQuery('#variable_pay_type option:selected').data('status'),
                                    vendor_name: jQuery('#variable_pay_type option:selected').data('name'),
                                    exchange_rate: jQuery('#refund_exchange').val(),
                                    account: this.state.recievedAccount,
                                    type: 1,
                                    amount: refundamnt,
                                    refund_date: date,
                                    client_id: this.state.logged_client_id,
                                    foreign_currency: this.state.currency_selected,
                                    job_id: jQuery('#variable_pay_type_job').val(),
                                    mode_of_payment: this.state.modeOfPay,
                                    refund_id: this.state.refund_id,
                                  },
                                });
                                window.jQuery('#refund-modal').modal('hide');
                              }
                            }}
                          >
                            Refund
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal fade pop-modal" id="writeoff-modal" role="dialog">
                <div class="modal-dialog custom-modal">
                  <button type="button" class="close hidden-xs" data-dismiss="modal">
                    <img class="img-responsive" src="images/close-red.svg" alt="icon" />
                  </button>
                  <div class="modal-content">
                    <div class="modal-body text-center">
                      <h3>Write Off Amount</h3>
                      <p class="sub">
                        Write off Amount{' '}
                        <span class="blue-txt">
                          {this.state.currency_selected} {this.state.under_amt}
                        </span>
                      </p>
                      <form class="custom-form row column">
                        <div class="form-group col-md-12 col-xs-12">
                          <label>Account</label>
                          <select
                            class="selectpicker form-control add-new"
                            data-live-search="true"
                            title="Choose..."
                            value={this.state.VendorAccSelcted}
                            onChange={e => {
                              this.setState({
                                VendorAccSelcted: e.target.value,
                              });
                            }}
                          >
                            <option>Add new</option>

                            {this.state.vendor_category &&
                              this.state.vendor_category.map(item => {
                                return (
                                  <option value={item.id} data-status={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <div class="form-group col-md-12 col-xs-12 mh btn-sec text-center mar-b-no">
                          <button class="btn btn-lightgray" type="button" data-dismiss="modal">
                            Cancel
                          </button>
                          {'   '}
                          <button
                            class="btn btn-green"
                            type="button"
                            onClick={() => {
                              if (this.state.VendorAccSelcted != '' && this.state.VendorAccSelcted != undefined) {
                                var writeOff = [];
                                this.state.vendor_invoicelist.map((item, i) => {
                                  if (i == this.state.invoiceSeletedId) {
                                    let writeOff_obj = {
                                      account: this.state.VendorAccSelcted,
                                      amount: this.state.under_amt,
                                      invoice_id: item.id,
                                      invoice_account: item.invoice_account,
                                      invoice_exchange_rate: item.invoice_exchange_rate,
                                      writeoff_id: this.state.writeoff_id,
                                    };
                                    writeOff.push(writeOff_obj);
                                  }
                                });
                                this.setState({
                                  writeOff: writeOff,
                                });
                                window.jQuery('#writeoff-modal').modal('hide');
                              }
                            }}
                          >
                            Write Off
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              {/* attachment_modal */}
              <div className="modal fade pop-modal" id="attachment_modal" role="dialog" ata-backdrop="static">
                <div className="modal-dialog modal-md custom-modal">
                  <button type="button" className="close hidden-xs" data-dismiss="modal">
                    <img className="img-responsive" src="../../images/close-red.svg" alt="icon" />
                  </button>
                  <div className="modal-content">
                    <div className="modal-body text-center">
                      <h3>
                        Attachments <span className="blue-txt">{this.state.list_of_attachments.length}</span>
                      </h3>
                      <form className="custom-form row">
                        <div className="form-group col-md-12 col-xs-12 pad-no mar-b-no searchscroll">
                          {this.state.list_of_attachments.length > 0 &&
                            this.state.list_of_attachments.map(item => (
                              <div key={item.source_id} className="attachment-preview ">
                                <a
                                  href="javascript:;"
                                  onClick={() => {
                                    var win = window.open(`${item.source_path}`, '_blank');
                                    win.focus();
                                  }}
                                >
                                  {item.source_path.split('/')[5]}
                                </a>
                                <button
                                  type="button"
                                  className="attachment-cancel"
                                  onClick={() => {
                                    this.setState({
                                      list_of_attachments: this.state.list_of_attachments.filter(
                                        x => x.source_id !== item.source_id,
                                      ),
                                    });
                                  }}
                                >
                                  X
                                </button>
                              </div>
                            ))}
                        </div>
                        <button
                          data-dismiss="modal"
                          className="btn btn-green btn-align"
                          type="button"
                          onClick={() => window.jQuery('#upload_doc_modal').modal('show')}
                        >
                          Upload attachment
                        </button>
                        <div className="form-group col-md-12 col-xs-12 btn-sec pad-no mar-b-no">
                          <button className="btn btn-lightgray btn-align" data-dismiss="modal" type="button">
                            Close
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              {/* upload_doc_modal */}
              <div className="modal fade pop-modal" id="upload_doc_modal" role="dialog" ata-backdrop="static">
                <div className="modal-dialog modal-md custom-modal">
                  <button type="button" className="close hidden-xs" data-dismiss="modal">
                    <img className="img-responsive" src="../../images/close-red.svg" alt="icon" />
                  </button>
                  <div className="modal-content">
                    <div className="modal-body text-center">
                      <h3>Upload Attachments</h3>
                      <form className="custom-form row">
                        <div className="form-group  pad-no mar-b-no">
                          <div className="">
                            <div className="form-group">
                              {/* <label>Upload Attachments<span className="astrick">*</span></label> */}
                              <div className="upload-wizard">
                                <div className="upload-thumb hide">
                                  <ul className="list-inline">
                                    {this.state.attachment_file.map((file_data, index) => {
                                      var file_url = URL.createObjectURL(file_data);
                                      var file_name = file_data.name;

                                      if (file_data.type === 'application/pdf') {
                                        var blob = file_url;

                                        console.log('blob_url', file_data.name);

                                        //return this.createPdfImg(file_data);

                                        return (
                                          <li className="previewImg">
                                            <div className="thumb-img">
                                              <a
                                                href="javascript:;"
                                                className="del-btn"
                                                onClick={() => {
                                                  this.removeImage(file_data.name, index);
                                                }}
                                              >
                                                <img src="./images/close-icon-white.svg" alt="delete" />
                                              </a>
                                              <PDFtoIMG file={blob}>
                                                {({ pages }) => {
                                                  console.log('check length', pages);

                                                  if (!pages.length) return 'check Loading...';
                                                  return pages.map((page, index) =>
                                                    index == 0 ? <img key={index} src={page} title={file_name} alt="pdf" /> : '',
                                                  );
                                                }}
                                              </PDFtoIMG>
                                            </div>
                                            <span className="file-name">{file_name}</span>
                                          </li>
                                        );
                                      } else {
                                        return (
                                          <li className="previewImg">
                                            <div className="thumb-img">
                                              <a
                                                href="javascript:;"
                                                className="del-btn"
                                                onClick={() => {
                                                  this.removeImage(file_data.name);
                                                }}
                                              >
                                                <img src="./images/close-icon-white.svg" alt="delete" />
                                              </a>

                                              <img
                                                src={
                                                  file_data.type === 'application/vnd.ms-excel'
                                                    ? '/images/file-icon.svg'
                                                    : file_data.type ===
                                                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                                    ? '/images/file-icon.svg'
                                                    : file_url
                                                }
                                                title={file_data.name}
                                                alt={file_data.name}
                                              />
                                            </div>
                                            <span className="file-name">{file_data.name}</span>
                                          </li>
                                        );
                                      }
                                    })}

                                    <li className="addMore">
                                      <input
                                        type="file"
                                        name="imgInp[]"
                                        id="imgInp2"
                                        className="add_img"
                                        multiple
                                        onChange={this.loadFile.bind(this)}
                                        accept="image/*,application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                      />
                                      <a href="javascript:;" className="add-more">
                                        <div>
                                          <img src="images/upload-icon.svg" alt="icon" />
                                          Add or Drag here
                                        </div>
                                      </a>
                                    </li>

                                    {/* <li className="addMore">
                                <a href="javascript:;"  className="add-more" primary={false} onClick={() => {this.upload.click();}}>
                                  <div >
                                  <input id="myInput" type="file" ref={ref => (this.upload = ref)} style={{ display: "none" }}
                                      onChange={this.onChangeFile.bind(this)}/>
                                    <label htmlFor="file">{file}</label>

                                    {this.state.file ? (
                                      <PDFtoIMG file={this.state.file}>
                                        {({ pages }) => {
                                          console.log("length", pages);

                                          if (!pages.length)
                                            return "Loading...";
                                          return pages.map((page, index) => (
                                            (index==0)?
                                            <img   key={index} src={page} style={{height:104.9,width:105.9,marginTop: 13,}}  />
                                            
                                            :""
                                          ));
                                        }}
                                      </PDFtoIMG>
                                    ) : (
                                      <div>Add more</div>
                                    )}
                                    <span className="file-name">{fileName}</span>                              
                                  </div>                                  
                                </a>
                              </li> */}
                                  </ul>
                                </div>
                                <div className="upload-space">
                                  <input
                                    type="file"
                                    name="imgInp[]"
                                    id="imgInp"
                                    className="add_img"
                                    multiple
                                    onChange={this.loadFile.bind(this)}
                                    accept="image/*,application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                  />
                                  {/* <img id="output" src="#" alt="your image" width="70" height="50" /> */}
                                  <div>
                                    <img className="icon" src="images/upload-icon.svg" alt="icon" />
                                    <span>.pdf .png .jpg .xls .xlsx</span>
                                    <span className="note-txt">
                                      Drag and drop your files here
                                      <br />
                                      or
                                      <br />
                                      <a href="javascript:;">click here</a>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {this.state.is_files_not_selected ? (
                                <div>
                                  <small style={{ color: 'red' }}>Please select atleast one file</small>
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="form-group col-md-12 col-xs-12 btn-sec pad-no mar-b-no">
                          <button className="btn btn-lightgray btn-align" data-dismiss="modal" type="button">
                            Close
                          </button>
                          <span>{'   '}</span>
                          <button className="btn btn-green btn-align" type="button" onClick={() => this.save_attachment()}>
                            Save
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade pop-modal" id="add_new_payment" role="dialog" data-backdrop="static">
                <div className="modal-dialog modal-md custom-modal">
                  <button
                    type="button"
                    className="close hidden-xs"
                    data-dismiss="modal"
                    onClick={() => {
                      this.setState({ roleStringLen: false });
                    }}
                  >
                    <img className="img-responsive" src="../../images/close-red.svg" alt="icon" />
                  </button>
                  <div className="modal-content">
                    <div className="modal-body text-center">
                      <h3>Add Options</h3>
                      <form className="custom-form row">
                        <div className="form-group col-md-12 col-xs-12 pad-no mar-b-no">
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <label>Options</label>
                          </div>
                          <div className="col-md-8 col-sm-8 col-xs-12">
                            <input autoComplete="off" type="text" className="form-control" id="pay" />
                            <div>{this.state.roleStringLen && <small style={{ color: 'red' }}>*Required.</small>}</div>{' '}
                          </div>
                        </div>

                        <div className="form-group col-md-12 col-xs-12 btn-sec pad-no mar-b-no">
                          <button
                            className="btn btn-lightgray"
                            data-dismiss="modal"
                            onClick={() => {
                              this.setState({ roleStringLen: false });
                            }}
                          >
                            Cancel
                          </button>
                          <span>{'   '}</span>
                          <input type="hidden" id="colid" />

                          <button
                            className="btn btn-green"
                            type="button"
                            onClick={() => {
                              if (jQuery('#pay').val() != '' && jQuery('#pay').val() != undefined) {
                                var coreData = {
                                  name: jQuery('#pay').val(),
                                  client_id: this.state.logged_client_id,
                                };

                                FetchAllApi.create_paymenttype(coreData, (err, response) => {
                                  //console.log("new document", response.message);
                                  // alert(response.message)
                                  if (response.status === 1) {
                                    this.setState({
                                      pay_method: response.insertId,
                                    });
                                    this.vendor_payment_method();
                                    jQuery('#pay').val('');
                                    window.jQuery('#add_new_payment').modal('hide');
                                    //   this.setState({ items: response.list[0].columns })
                                  } else if (response.message) {
                                    alert(response.message);
                                  }
                                });
                              } else {
                                alert('Please fill out....');
                              }

                              // FetchAllApi.invoiceadd_dropdown_options(
                              //   userId,
                              //   coulmnId,
                              //   optionsArray,
                              //   (err, response) => {
                              //     //console.log('vendor_names', response)

                              //     if (response.status === 1) {
                              //       alert('success')
                              //       this.getColList()
                              //       window.jQuery('#add_new_role').modal('hide')
                              //     } else {
                              //     }
                              //   }
                              // )
                            }}
                          >
                            Save
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <input type="hidden" id="option" />
          </div>

          <Footer defaultcategorylist_onchange={this.defaultcategorylist_onchang} logoutSubmit={e => this.logoutLink()} />
        </div>
      </div>
    );
  }
}
export default vendor_bill_payment;
