import React, { Fragment } from 'react';
import UserTopbar from '../components/first_user_module/header';
import FetchAllApi from '../api_links/fetch_all_api';
import Sidebar from './preferenceSide';
import jQuery from 'jquery';
import Footer from '../components/footer.jsx';
import Category from './categoryadd';
import './preference.css';
import Comma from './../components/comma';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import config from '../api_links/api_links';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import category from './categoryadd';
import XLSX from 'xlsx';
var _ = require('lodash');

export default class Charts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logged_user_id: localStorage.getItem('logged_user_id'),
      logged_client_id: localStorage.getItem('logged_client_id'),
      logged_role_id: localStorage.getItem('logged_role_id'),
      logged_user_name: localStorage.getItem('logged_user_name'),
      logged_user_email: localStorage.getItem('logged_user_email'),
      logged_user_phone: localStorage.getItem('logged_user_phone'),
      logged_user_image: localStorage.getItem('logged_user_image'),
      logged_company_name: localStorage.getItem('logged_company_name'),
      select_id: 'All Accounts',
      account_list: [],
      account_list_type: [],
      sortedarray: [],
      sorted: 0,
      account_list_types: [],
      confirm_status: 0,
      catid: 0,
      sort_by: 'asc',
      sort_by_column_key: '',
      searchtext: '',
      category_id_to_edit: '',
      account_type_id_to_edit: '',
      // currency_to_edit: 'SGD',
      currency_to_edit: localStorage.getItem('home_currency'),
      sub_account_id_to_edit: '',
      sub_category_id_to_edit: '',
      category_name_to_edit: '',
      account_type_name_to_edit: '',
      sub_category_name_to_edit: '',
      sub_account_name_to_edit: '',
      account_name_to_edit: '',
      account_id_to_edit: '',
    };
  }

  edit_accountname = async id => {
    let input = {
      client_id: this.state.logged_client_id,
      account_id: id,
    };

    // window.jQuery("#pop-modal-to-edit-account").modal("show")
    FetchAllApi.get_account_details(input, (err, response) => {
      if (err) {
        alert('Failed to fetch account details');
      } else {
        if (response.status === 1) {
          let details = response.details;
          this.setState(
            {
              category_id_to_edit: details.category_id,
              account_type_id_to_edit: details.account_type_id,
              currency_to_edit: details.currency,
              sub_account_id_to_edit: details.sub_account_id,
              sub_category_id_to_edit: details.sub_category_id,
              category_name_to_edit: details.category_id == 1 ? 'Balance sheet' : 'Profit or loss',
              account_type_name_to_edit: details.account_type_name,
              sub_category_name_to_edit: details.sub_category_name,
              sub_account_name_to_edit: details.sub_account_name,
              account_name_to_edit: details.account_name,
              account_id_to_edit: details.account_id,
            },
            () => window.jQuery('#pop-modal-to-edit-account').modal('show'),
          );
        } else if (response.status === 0) {
          alert('Failed to get account details');
        }
      }
    });
  };
  delete_or_inactive_account_name = (statuscode, id) => {
    let input = {
      client_id: this.state.logged_client_id,
      status_to_set: statuscode,
      account_id: id,
      confirm_status: this.state.confirm_status,
    };

    this.setState({ deleteInput: input });

    // if (this.state.role_permissions.includes(19)) {

    FetchAllApi.delete_or_inactive_account_name(input, (err, response) => {
      // alert(response.response.length)
      if (response.status === 1) {
        alert(response.message);
        this.setState({ confirm_status: '0' }, () => window.jQuery('#deActiveModal').modal('hide'));
        // this.setState(
        //   { isSuccessful: true, res_msg: response.message },
        //   () => {
        //     setTimeout(() => {
        //       this.setState({ isSuccessful: true })
        //     }, 1500)
        //   }
        // )
        this.getAccount(this.state.status ? this.state.status : '1');
      }
      if (response.status === 2) {
        this.setState({ response_to_inactive: response.message }, () => window.jQuery('#deActiveModal').modal('show'));

        // this.setState({ response_to_inactive: response.message }, () => {
        //   window.jQuery('#pop_add_notes').modal('show')
        // })
      }

      if (response.status === 0) {
        // alert('you cant delete')
        alert(response.message);
        // this.setState(
        //   { isSuccessful: true, isWarning: true, res_msg: response.message },
        //   () => {
        //     setTimeout(() => {
        //       this.setState({ isWarning: false })
        //     }, 1500)
        //   }
        // )
      }
      if (response.status === 3) {
        // this.setState({ response_to_inactive: response.message }, () => {
        alert(response.message);
        // window.jQuery('#pop_add_notes').modal('show')
        // this.deleteCustomer(3, customer_id)
        // })
      }
    });
    // } else {
    //   alert("Required permission")
    // }
  };

  componentWillMount() {
    if (this.state.logged_user_id === '' || this.state.logged_user_id === null || this.state.logged_user_id === undefined) {
      this.props.history.push('/');
    }
  }

  logoutLink() {
    localStorage.clear();

    this.props.history.push('/');
  }

  componentDidMount = () => {
    this.getAccount(1);
    // jQuery('a[href=#"' + "All Accounts" + '"]').tab('show');
    window.jQuery('.mscroll-y').mCustomScrollbar({
      axis: 'y',
      scrollEasing: 'linear',
      scrollInertia: 600,
      autoHideScrollbar: 'true',
      autoExpandScrollbar: 'true',
    });
    window.jQuery('.mscroll-x').mCustomScrollbar({
      axis: 'x',
      scrollEasing: 'linear',
      scrollInertia: 600,
      autoHideScrollbar: 'true',
      autoExpandScrollbar: 'true',
    });
  };

  printDocument() {
    var doc = new jsPDF('p', 'in', 'letter');
    var tTB = document.getElementById('table-to-xls');
    var atTB = doc.autoTableHtmlToJson(tTB, true);
    var cols = atTB.columns;
    var data = atTB.rows;
    let new_rows = [];
    data.forEach((row, index) => {
      let row_data = row;
      row_data.splice(5, 1);
      new_rows.push(row_data);
    });

    //here you are going to set which column you will truncate. Moreover, .splice(index number of the column(your start), the number of columns you will exclude)
    cols.splice(5, 1);
    doc.text('Chart Of Accounts', 3, 0.5);
    doc.autoTable({
      startY: 0.6,
      head: [cols],
      body: new_rows,
    });
    doc.save('Chart-Of-Accounts.pdf');
  }

  exportFunc = accountarray => {
    if (accountarray && accountarray.length > 0) {
      let accountarray = this.state.sorted == 1 ? this.state.sortedarray : this.state.account_list;

      let new_array = [];
      if (this.state.select_id == 'All Accounts') {
        new_array = accountarray;
      } else {
        accountarray.map((lis, idx) => {
          if (this.state.select_id == lis.account_type_name) {
            new_array.push(lis);
          }
        });
      }

      let customer_data = [];
      // accountarray.forEach((cus,i)=>{
      new_array.forEach((cus, i) => {
        let obj = {
          Code: cus.code,
          Name: cus.name,
          Currency: cus.currency,
          Type: cus.account_type_name,
          YTD: cus.ytd,
        };
        customer_data.push(obj);
      });

      var ws = XLSX.utils.json_to_sheet(customer_data);
      ws['!cols'] = [{ width: 15 }, { width: 40 }, { width: 15 }, { width: 15 }, { width: 15 }];
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'Chart-Of-Accounts.xlsx');
    } else {
      alert('No data found to export');
    }
  };

  addAccountFunc = () => {
    window.jQuery('#pop-modal').modal('show');
  };

  onFunc = async val => {
    this.setState({ select_id: val, sorted: 0 });
  };
  defaultcategorylist_onchange = (x, y, status) => {
    let keyy = '';
    let from_create_invoice = 1;
    var client_id = this.state.logged_client_id;
    let filter_id = 2;
    FetchAllApi.manual_journal_defaultcategorylist(keyy, from_create_invoice, client_id, filter_id, (err, response) => {
      if (response.status === 1) {
        if (x == 'added') {
          let id = response.list.find(e => e.name == y || e.original_name == y);
          this.getAccount('', id.id);

          //   this.categoryChange(this.state.idx, id.id)
          //     this.setState({
          //       selectNeedIndex: response.list.length - 1,
          //       nameFilter: y,
          //     });
          //   }
          //   this.setState(
          //     {
          //       default_category_list: response.list,
          //     },
          //     () => {
          //       window.jQuery("#categry_id0").selectpicker("refresh");
          //     }
          //   );
        }
      }
    });
  };
  getAccount = async (status, val) => {
    FetchAllApi.get_chart_accounts(this.state.logged_client_id, status, (err, response) => {
      if (response.status === 1) {
        this.setState(
          {
            account_list: response.account_lists,
            account_list_type: response.account_types,
            account_list_types: response.account_types,
            status,
            sorted: 0,
          },
          () => console.log(this.state.account_lists, this.state.sorted),
        );
        if (val && val != undefined) {
          let acntlist = response.account_lists;
          let accntname = acntlist.filter(e => e.id == val);
          this.setState({ select_id: accntname[0].account_type_name }, () => this.onFunc(accntname[0].account_type_name));
          jQuery('a[href="#' + accntname[0].account_type_name + '"]').tab('show');
          jQuery(' li >').parent().removeClass('active');
          jQuery('.nav nav-pills > li >').parent().removeClass('active');
          jQuery(' li > a[href="#' + accntname[0].account_type_name + '"]')
            .parent()
            .addClass('active');
          jQuery('.nav nav-pills > li > a[href="#' + accntname[0].account_type_name + '"]')
            .parent()
            .addClass('active');
        }
      }
    });
  };
  newChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  getSortbyData = () => {
    if (this.state.select_id == 'All Accounts') {
      let account_list = this.state.account_list;
      account_list = _.orderBy(account_list, [this.state.sort_by_column_key], [this.state.sort_by]);
      if (this.state.searchtext != '') {
        account_list = account_list.filter(
          item =>
            item.name.toLowerCase().includes(this.state.searchtext.toLowerCase()) ||
            item.currency.toLowerCase().includes(this.state.searchtext.toLowerCase()) ||
            item.code == this.state.searchtext ||
            item.ytd == this.state.searchtext,
        );
      }
      this.setState({ sortedarray: account_list, sorted: 1 });
    } else {
      let account_list = this.state.account_list;
      account_list = account_list.filter(item => item.account_type_name == this.state.select_id);
      if (this.state.searchtext != '') {
        account_list = account_list.filter(
          item =>
            item.name.toLowerCase().includes(this.state.searchtext.toLowerCase()) ||
            item.currency.toLowerCase().includes(this.state.searchtext.toLowerCase()) ||
            item.code == this.state.searchtext ||
            item.ytd == this.state.searchtext,
        );
      }
      account_list = _.orderBy(account_list, [this.state.sort_by_column_key], [this.state.sort_by]);
      this.setState({ sortedarray: account_list, sorted: 1 });
    }
  };

  pageLink(page_slug) {
    this.props.history.push('/' + page_slug);
  }

  render() {
    let accountarray = this.state.sorted == 1 ? this.state.sortedarray : this.state.account_list;
    return (
      <React.Fragment>
        <div className="container-fluid">
          {/* header Starts here */}
          <UserTopbar logoutSubmit={e => this.logoutLink()} />
          {/* header Ends here */}
          {/* <div class="title-sec col-md-12 col-xs-12">
        <h3>Subscribers</h3>
    </div> */}
          {/* user-content Starts here */}
          <section className="user-content row pad-b-no">
            <Sidebar pageSubmit={e => this.pageLink(e)} />
            <div className="user-cont-right">
              <div className="title-sec col-md-12 col-xs-12 mar-btm">
                <h3>Chart of Accounts</h3>
                <div>
                  <button className="btn btn-blue with-icon mar-rgt-5" onClick={this.addAccountFunc}>
                    <img src="images/plus-add.svg" className="filter-white" />
                    Add Account
                  </button>
                  <div className="dib">
                    <div className="dropdown menu-item mar-rgt-5 new-cus">
                      <button className="btn btn-blue dropdown-toggle btn-arrow" data-toggle="dropdown" aria-expanded="false">
                        More
                        <span className="caret" />
                      </button>
                      <ul className="dropdown-menu align-right">
                        {/* <li><a href="javascript:;">Add Bank Account</a></li>
                        <li><a href="javascript:;">Import</a></li> */}
                        {/* <li><a href="javascript:;">Export</a></li>
                        <li><a href="javascript:;">Print PDF</a></li> */}
                        <li>
                          <a
                            href="javascript:;"
                            onClick={() => {
                              this.printDocument();
                            }}
                            className="export-pdf-button"
                          >
                            Export As PDF
                          </a>
                        </li>
                        {/* Print table exactly */}
                        {/* <li><ReactHTMLTableToExcel id="test-table-xls-button"
                              className="excel-button"
                              table="table-to-xls"
                              filename="Chart-Of-Accounts"
                              sheet="Sheet1"
                              buttonText="Export As Excel" />
                        </li> */}
                        <li>
                          <a
                            href="javascript:;"
                            onClick={() => {
                              this.exportFunc(this.state.account_list);
                            }}
                            className="export-pdf-button"
                          >
                            Export As Excel
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-xs-12">
                <div className="row">
                  <div className="col-md-12 pad-no nav-com-search">
                    <ul className="nav nav-pills transparent nowrap ofx-auto">
                      <li className="active">
                        <a
                          data-toggle="pill"
                          href="#All Accounts"
                          onClick={() => {
                            this.onFunc('All Accounts');
                          }}
                        >
                          All Accounts
                        </a>
                      </li>
                      {this.state.account_list_types.map((type, idx) => {
                        return (
                          <li>
                            <a
                              data-toggle="pill"
                              href={'#' + type.name}
                              onClick={() => {
                                this.onFunc(type.name);
                              }}
                            >
                              {type.name}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                    {/* <form className="custom-form h-small mar-b-no">
                      <div className="form-group search-box">
                        <input type="text" name="search" className="form-control" placeholder="Search..." />
                      </div>
                    </form> */}
                  </div>
                </div>

                {/* 
                <div className="row col-md-4" style={{ marginTop: '21px' }}>
                  <div className='form-group mar-rgt'>
                    <div className="form-cont" >

                      <select
                        // style={{ width: 95 }}
                        className="selectpicker form-control hh "
                        onChange={(e) => this.getAccount(e.target.value)}
                      >
                        <option value='1'>Active Accounts</option>

                        <option value=''>All Accounts</option>

                        <option value='2'>Inactive Accounts</option>
                      </select>
                    </div>
                  </div>
                </div> */}
                <form className="custom-form filter-form col-md-12 col-xs-12">
                  <div className="row">
                    <div className="form-group col-md-4">
                      <label className="col-md-12">Filter by</label>
                      <select
                        className="selectpicker form-control " //add-new
                        data-live-search="true"
                        // title='Choose Sub Account Category'
                        value={this.state.sub_Account_id}
                        onChange={e => this.getAccount(e.target.value)}
                      >
                        <option value="0">Choose...</option>
                        <option value="1">Active Accounts</option>
                        <option value="">All Accounts</option>
                        <option value="2">Inactive Accounts</option>
                      </select>
                    </div>

                    <div className="form-group col-md-4">
                      <label className="col-md-12">Search by</label>
                      <div className="input-group">
                        <input
                          type="text"
                          placeholder="Search text"
                          className="form-control"
                          value={this.state.searchtext}
                          name="searchtext"
                          onChange={this.newChange}
                        />
                      </div>
                    </div>

                    <div className="form-group col-md-4  ">
                      <label className="col-md-12"> . </label>
                      <div className="input-group">
                        <button
                          className="btn btn-lightgray btn-align"
                          type="button"
                          onClick={() => {
                            this.setState(
                              {
                                sub_Account_id: '0',
                                searchtext: '',
                                sorted: 0,
                              },
                              this.getSortbyData(),
                            );
                          }}
                        >
                          Clear
                        </button>
                        <div
                          className="btn btn-green btn-align"
                          type="button"
                          onClick={() => {
                            this.getSortbyData();
                          }}
                        >
                          Search
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                {/* <div className="custom-form mar-b-no mar-top row ">
                  <div className=" col-md-4 col-sm-4 col-xs-12 form-group mar-b-no mar-top pad-hor-no">
                    {/* <label>Mode of Payment</label>*/}

                {/*  </div>
                  <div className=" col-md-4 col-sm-4 col-xs-12 form-group mar-b-no mar-top pad-hor-no">

                 
                  </div>
                  <div className=" col-md-4 col-sm-4 col-xs-12 form-group mar-b-no mar-top pad-hor-no">

<input type="text" placeholder="Search text" className="form-control" value={this.state.searchtext} name="searchtext" onChange={this.newChange} />
</div>
                </div> */}

                <div className="row tab-content pad-top">
                  {this.state.account_list.length == 0 ? (
                    <div id={this.state.select_id} className="col-md-12 tab-pane fade in pad-no">
                      <div className="landing-wrap">
                        <div className="img-concept text-center">
                          <img className="img-responsive mar-auto" src="images/no-data.svg" alt="img" />
                          <p>Looks like there's no data</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div id={this.state.select_id} className="col-md-12 tab-pane fade active in pad-no">
                      <div className="report-table reconcile-table pad-td-rgt col-md-12 col-xs-12 mar-t-no pad-no">
                        <div className="table-responsive">
                          <table className="table detail-report" id="table-to-xls">
                            <thead>
                              <tr>
                                {/* <th className="checkbox-td">
                                  <label className="custom-checkbox small">
                                    <input type="checkbox" name="all" />&nbsp;
                                  <span className="checkmark" />
                                  </label>
                                </th> */}
                                <th className="text-left">
                                  Code
                                  <i className="th-sort">
                                    <img
                                      src={config.sort_icon_url}
                                      alt="SortIcon"
                                      onClick={() => {
                                        this.setState(
                                          { sort_by: this.state.sort_by == 'asc' ? 'desc' : 'asc', sort_by_column_key: 'code' },
                                          this.getSortbyData(),
                                        );
                                      }}
                                    />
                                  </i>
                                </th>
                                <th>
                                  Name
                                  <i className="th-sort">
                                    <img
                                      src={config.sort_icon_url}
                                      alt="SortIcon"
                                      onClick={() => {
                                        this.setState(
                                          { sort_by: this.state.sort_by == 'asc' ? 'desc' : 'asc', sort_by_column_key: 'name' },
                                          this.getSortbyData(),
                                        );
                                      }}
                                    />
                                  </i>
                                </th>
                                <th>
                                  Currency
                                  <i className="th-sort">
                                    <img
                                      src={config.sort_icon_url}
                                      alt="SortIcon"
                                      onClick={() => {
                                        this.setState(
                                          {
                                            sort_by: this.state.sort_by == 'asc' ? 'desc' : 'asc',
                                            sort_by_column_key: 'currency',
                                          },
                                          this.getSortbyData(),
                                        );
                                      }}
                                    />
                                  </i>
                                </th>
                                <th>
                                  Type
                                  <i className="th-sort">
                                    <img
                                      src={config.sort_icon_url}
                                      alt="SortIcon"
                                      onClick={() => {
                                        this.setState(
                                          {
                                            sort_by: this.state.sort_by == 'asc' ? 'desc' : 'asc',
                                            sort_by_column_key: 'account_type_name',
                                          },
                                          this.getSortbyData(),
                                        );
                                      }}
                                    />
                                  </i>
                                </th>
                                <th className="text-right">
                                  YTD
                                  <i className="th-sort">
                                    <img
                                      src={config.sort_icon_url}
                                      alt="SortIcon"
                                      onClick={() => {
                                        this.setState(
                                          { sort_by: this.state.sort_by == 'asc' ? 'desc' : 'asc', sort_by_column_key: 'ytd' },
                                          this.getSortbyData(),
                                        );
                                      }}
                                    />
                                  </i>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {(() => {
                                if (this.state.select_id == 'All Accounts') {
                                  return accountarray.map((lis, idx) => {
                                    return (
                                      <tr>
                                        {/*  <td className="extra-pad-no">
                                           <label className="custom-checkbox small">
                                              <input type="checkbox" name="all" />&nbsp;
                                  <span className="checkmark" />
                                            </label>
                                          </td> */}
                                        <td>{lis.code}</td>
                                        <td>{lis.name}</td>
                                        <td>{lis.currency}</td>
                                        <td>{lis.account_type_name}</td>
                                        <td className="text-right">
                                          <Comma value={lis.ytd} />
                                        </td>
                                        <td className="action-td text-right">
                                          <div className="dropdown menu-item new-cus">
                                            <a
                                              href="javascript"
                                              class="dropdown-toggle"
                                              data-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <button className="btn btn-green dropdown-toggle" type="button">
                                                Action
                                                <span className="caret" />
                                              </button>
                                            </a>
                                            <ul className="dropdown-menu align-right">
                                              <li>
                                                <a
                                                  onClick={() => {
                                                    this.edit_accountname(lis.id);
                                                  }}
                                                >
                                                  Edit
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  onClick={() => {
                                                    this.delete_or_inactive_account_name('0', lis.id);
                                                  }}
                                                >
                                                  Delete
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  onClick={() => {
                                                    this.delete_or_inactive_account_name(lis.status == '1' ? '2' : '1', lis.id);
                                                  }}
                                                >
                                                  {lis.status == '1' ? 'In active' : 'Active'}
                                                </a>
                                              </li>
                                            </ul>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  });
                                } else {
                                  return accountarray.map((lis, idx) => {
                                    if (this.state.select_id == lis.account_type_name) {
                                      return (
                                        <tr>
                                          {/* <td className="extra-pad-no">
                                              <label className="custom-checkbox small">
                                                <input type="checkbox" name="all" />&nbsp;
                                              <span className="checkmark" />
                                              </label>
                                            </td> */}
                                          <td>{lis.code}</td>
                                          <td>{lis.name}</td>
                                          <td>{lis.currency}</td>
                                          <td>{lis.account_type_name}</td>
                                          <td className="text-right">
                                            <Comma value={lis.ytd} />
                                          </td>
                                          <td className="action-td text-right">
                                            <div className="dropdown menu-item new-cus">
                                              <a
                                                href="javascript"
                                                class="dropdown-toggle"
                                                data-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                <button className="btn btn-green dropdown-toggle" type="button">
                                                  Action
                                                  <span className="caret" />
                                                </button>
                                              </a>
                                              <ul className="dropdown-menu align-right">
                                                <li>
                                                  <a
                                                    onClick={() => {
                                                      this.edit_accountname(lis.id);
                                                    }}
                                                  >
                                                    Edit
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    onClick={() => {
                                                      this.delete_or_inactive_account_name('0', lis.id);
                                                    }}
                                                  >
                                                    Delete
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    onClick={() => {
                                                      this.delete_or_inactive_account_name(lis.status == '1' ? '2' : '1', lis.id);
                                                    }}
                                                  >
                                                    {lis.status == '1' ? 'In active' : 'Active'}
                                                  </a>
                                                </li>
                                              </ul>
                                              {/* <ul className="dropdown-menu align-right">
                                                <li>
                                                    <a
                                                      onClick={() => { 
                                                        localStorage.setItem('editcategoryid',lis.id) ;
                                                        this.edit_accountname();
                                                        window.jQuery("#pop-modal-edit").modal("show")
                                                    
                                                        }}
                                                    >Edit</a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      onClick={() => { this.delete_or_inactive_account_name(0, lis.id) }}
                                                    >Delete</a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      onClick={() => { this.delete_or_inactive_account_name(2, lis.id) }}
                                                    >In active</a>
                                                  </li>
                                                </ul> */}
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  });
                                }
                              })()}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="modal fade pop-modal" id="deActiveModal" role="dialog">
                  <div className="modal-dialog modal-md custom-modal">
                    <button type="button" className="close hidden-xs" data-dismiss="modal">
                      <img className="img-responsive" src="../../images/close-red.svg" alt="icon" />
                    </button>
                    <div className="modal-content">
                      <div className="modal-body text-center">
                        <h3>Warning!</h3>

                        <form className="custom-form row">
                          <div className="form-group col-md-12 col-xs-12 pad-no mar-b-no">{this.state.response_to_inactive} </div>

                          <div className="form-group col-md-12 col-xs-12 btn-sec pad-no mar-b-no">
                            <button className="btn btn-lightgray" data-dismiss="modal">
                              Cancel
                            </button>
                            <span>{'   '}</span>
                            <button
                              className="btn btn-green"
                              type="button"
                              onClick={() => {
                                this.setState({ confirm_status: 1 });
                                setTimeout(() => {
                                  this.delete_or_inactive_account_name('2', this.state.deleteInput['account_id']);
                                }, 500);
                              }}
                            >
                              Make Inactive
                            </button>
                          </div>
                          {/* <div className='mymsg'>{this.state.modal_info_msg}</div> */}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* user-content Ends here */}
        </div>

        {/*Pass props as an object*/}
        <Category
          defaultcategorylist_onchange={this.defaultcategorylist_onchange}
          id={this.state.sort_by_column_key}
          category_id={this.state.category_id_to_edit}
          account_type_id={this.state.account_type_id_to_edit}
          currency={this.state.currency_to_edit}
          sub_account_id={this.state.sub_account_id_to_edit}
          sub_category_id={this.state.sub_category_id_to_edit}
          category_name={this.state.category_name_to_edit}
          account_type_name={this.state.account_type_name_to_edit}
          sub_category_name={this.state.sub_category_name_to_edit}
          sub_account_name={this.state.sub_account_name_to_edit}
          account_name={this.state.account_name_to_edit}
          account_id={this.state.account_id_to_edit}
          edit_time={new Date()}
        />

        {/* Main Wrapper Ends here */}
        {/* Bootstrap Select Picker JS */}
        {/* Scrollbar Js */}
        {/* Bootstrap Datepicker JS */}
        {/* jQueryUI JS */}
      </React.Fragment>
    );
  }
}
