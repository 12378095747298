import React from 'react';
import LeftSidebar from '../left_sidebar';
// import Footer from "../footer";
import FetchAllApi from '../../api_links/fetch_all_api';
import config from '../../api_links/api_links';
import { jsPDF, specialElementHandlers, margins } from 'jspdf';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Topbar from '../topbar';

import moment from 'moment';
// import { PDFtoIMG } from "react-pdf-to-image";

import Loader from 'react-loader-spinner';

import jQuery from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//excel export with formula - starts
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
let excelColumnName = require('excel-column-name');
let row_starts_from = 3;

const containerStyle = { width: '100%', height: '100%', display: 'none' };
const gridStyle = { height: '100%', width: '100%' };
//excel export with formula - end

var _ = require('lodash');
// import 'bootstrap';
// import 'bootstrap-select';
class open_invoice extends React.Component {
  constructor(props) {
    super(props);
    //const { history CONVER} = this.props;

    this.gridRef = React.createRef();

    this.state = {
      loading: true,

      logged_user_id: localStorage.getItem('logged_user_id'),
      logged_client_id: localStorage.getItem('logged_client_id'),
      logged_role_id: localStorage.getItem('logged_role_id'),
      logged_user_name: localStorage.getItem('logged_user_name'),
      logged_user_email: localStorage.getItem('logged_user_email'),
      logged_user_phone: localStorage.getItem('logged_user_phone'),
      logged_user_image: localStorage.getItem('logged_user_image'),
      logged_company_name: localStorage.getItem('logged_company_name'),
      client_date_format: localStorage.getItem('date_format'),

      country_sortname: localStorage.getItem('country_sortname'),
      language_code: localStorage.getItem('language_code'),
      home_currency: localStorage.getItem('home_currency'),
      incorporation_date: localStorage.getItem('incorporation_date'),
      home_currency_symbol: localStorage.getItem('home_currency_symbol'),
      // home_currency: 'SGD',

      selectedAccountIds: '',
      total_revenue: '',
      cost_of_goods_sold: '',
      gross_profit: '',
      net_income: '',
      reportObject: [],
      numberOfColumns: [],
      dateList: [],
      endDate: '',
      startDate: '',
      dropdown: '',
      show_column: '',
      inbox_list: [],
      response_stus: 0,
      response_msg: 'No data found',
      item_details: '',
      item_file_path: '',
      waiting_re: [],
      re_assigned: [],
      client_id: 1,
      start_date: localStorage.getItem('incorporation_date'),
      end_date: moment().add(10, 'years').format('YYYY-MM-DD'),
      show_columns: 2,
      balance_sheet_data: [],
      columnList: {},
      show_coulmns_filter: [],
      isChecked2: false,
      isChecked: false,
      sub_columns: [],
      cadchange: false,
      cadpercentage: false,
      changetotal: 0,
      changetotal1: 0,
      isInvoice: true,
      selected_vals: [],
      coulmns_head: [],
      filtervalue: [],
      options: '',
      From: '',
      To: '',
      filter_options: { condition: '', value: '', from: '', to: '' },
      valueAmount: '',
      valueAmount_type: '',
      disable: false,
      selectedFil: 0,
      // currencies: [],
      currencies: config.all_currency_list,
      vendorNames: [],
      selectedCurrencies: '',
      selected_vendor_ids: [],
      changefromDate_duedate: '',
      todate_duedate: '',

      data: [
        {
          id: 1,
          // heading_name: "Trans#",
          heading_name: 'Transction Number',
          clsname: 'trans',
        },
        {
          id: 2,
          heading_name: 'Last Modified',
          clsname: 'lastmodified',
        },
        {
          id: 3,
          heading_name: 'Last Modified By',
          clsname: 'lastmodifiedby',
        },
        {
          id: 4,
          heading_name: 'Num',
          clsname: 'num',
        },
        {
          id: 5,
          heading_name: 'Memo',
          clsname: 'memo',
        },
        {
          id: 6,
          heading_name: 'Account',
          clsname: 'account',
        },
        {
          id: 7,
          heading_name: 'Open Balance',
          clsname: 'openbalance',
        },
        {
          id: 8,
          heading_name: 'Debit',
          clsname: 'debit',
        },
        {
          id: 9,
          heading_name: 'Credit',
          clsname: 'credit',
        },
        {
          id: 10,
          heading_name: 'Balance',
          clsname: 'balance',
        },
        {
          id: 11,
          heading_name: 'Currency',
          clsname: 'currency',
        },
        {
          id: 12,
          heading_name: 'Exchange Rate',
          clsname: 'exchangerate',
        },
      ],
      response: [],

      result_array: [],
      valueAmount_type1: '',
      valueAmount_type2: '',
      valueAmount_type3: '',
      valueAmount_type4: '',
      valueAmount1: '',
      valueAmount2: '',
      valueAmount3: '',
      valueAmount4: '',

      text1: '',
      text2: '',
      text3: '',
      text4: '',
      text5: '',
      text6: '',
      text7: '',
      date_start: '',
      date_end: '',
      selectedTerms: [],
      selectedVendor_type: [],
      vendor_type: [],
      paymentTerms: [],
      all_report_name_id: '',
      customer_type: [],
      selectedCustomer_type: [],

      type: false,
      sort_type: 'asc',
      sortBynames: [],
      selectedName: '',
      filter_key_names: [],
      date_range: 'Custom',
      fullResponse: {},
      export_file_name_date_range: 'All',
      is_current_date_report: 1,

      //excel export with formula - starts
      export_formula_column_defs: [],
      defaultColDef: {
        flex: 1,
        minWidth: 100,
        resizable: true,
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: params => {
          const rowIndex = params.accumulatedRowIndex;
          const valueGetter = params.column.getColDef().valueGetter;
          return !!valueGetter ? `=CONCATENATE(A${rowIndex}, " ", B${rowIndex})` : params.value;
        },
        fileName: 'Balance_sheet.xls',
        sheetName: 'Balance_sheet',
      },
      parent_job_object: {},
      //excel export with formula - end
    };
  }

  //excel export with formula - starts
  onBtExport = () => {
    this.gridRef.current.api.exportDataAsExcel();
  };

  htmlToJson = html => {
    FetchAllApi.html_to_json_converter({ html_content: html }, (err, response) => {
      this.setState(prevState => ({
        defaultExcelExportParams: {
          ...prevState.defaultExcelExportParams,
          // fileName: `Balance_Sheet-`+this.state.export_file_name_date_range+`.xls`,
          fileName: 'Customer-open-invoices-' + this.state.export_file_name_date_range + '.xls',
          sheetName: this.state.export_file_name_date_range,
        },
      }));

      if (response.status === 1) {
        let columnDefs = [
          {
            field: 'Name',
          },
        ];

        let excel_column_of_table_heading_obj = {};
        let excel_column_of_table_heading_array = [];

        let data = response.data;

        if (this.state.coulmns_head && this.state.coulmns_head.length > 0) {
          let col_number = 1;

          let currently_selected_options = jQuery('#myselect option');

          let active_columns_for_export = [];
          for (var i = 0, iLen = currently_selected_options.length; i < iLen; i++) {
            let opt = currently_selected_options[i];

            if (opt.selected) {
              let value = JSON.parse(opt.value);
              //  active_columns_for_export.push(value.b);
              active_columns_for_export.push(value.b + 1);
            }
          }
          //console.log('active_columns_for_export....287....before',active_columns_for_export);
          //console.log('active_columns_for_export....288....this.state.view',this.state.view);
          // if expand view is enabled means add split columns to excel export
          if (this.state.view == false) {
            active_columns_for_export = active_columns_for_export.concat([14, 19, 27]);
          }
          console.log('active_columns_for_export....292....after', active_columns_for_export);
          //console.log('this.state.coulmns_head....294....after',this.state.coulmns_head);
          this.state.coulmns_head.forEach((date, i) => {
            // let actual_col = i + 1;
            let actual_col = i + 2;

            // if((active_columns_for_export.indexOf(actual_col) >= 0) || (actual_col == 1)) {
            if (active_columns_for_export.indexOf(actual_col) >= 0) {
              col_number = col_number + 1;

              columnDefs.push({
                field: date.heading_name,
              });
            }

            let current_column = excelColumnName.intToExcelCol(col_number);
            console.log('308.....####...current_column', current_column);
            excel_column_of_table_heading_obj[date.heading_name] = current_column;
          });
        }

        console.log('312.....####', excel_column_of_table_heading_obj);

        if (data.length > 1) {
          let current_row = row_starts_from - 1;
          let is_transactions_starts = 0;

          let customer_job_currency_object = {};
          let customer_object = {};
          let grand_total_object = {
            sub_category_total_rows: [],
          };

          let date_detailsArray = this.state.dateList;
          data.forEach((d, i) => {
            current_row = current_row + 1;

            let val = '';
            date_detailsArray.forEach((column, k) => {
              val = column + '_' + k;
              d[val] = this.commaSeperatedValueToNumber(d[column]);
            });

            if (d['customer id'] == -1 && d['job id'] == -1 && d['currency code'] == -1) {
            } else if (d['customer id'] && !d['job id'] && !d['currency code']) {
              grand_total_object['sub_category_total_rows'].push(current_row);
            } else if (d['customer id'] && d['job id'] && d['currency code'] && !d['Name'].toLowerCase().includes('total')) {
              d.Debit = this.commaSeperatedValueToNumber(d.Debit);
              d.Credit = this.commaSeperatedValueToNumber(d.Credit);
              d.Amount = this.commaSeperatedValueToNumber(d.Amount);
              d['Open Balance'] = this.commaSeperatedValueToNumber(d['Open Balance']);
              d['Balance'] = this.commaSeperatedValueToNumber(d['Balance']);

              d['Foreign Debit'] = this.commaSeperatedValueToNumber(d['Foreign Debit']);
              d['Foreign Credit'] = this.commaSeperatedValueToNumber(d['Foreign Credit']);
              d['Foreign Amount'] = this.commaSeperatedValueToNumber(d['Foreign Amount']);
              d['Foreign Open Balance'] = this.commaSeperatedValueToNumber(d['Foreign Open Balance']);
              d['Foreign Balance'] = this.commaSeperatedValueToNumber(d['Foreign Balance']);

              let c_id = d['customer id'];
              let j_id = d['job id'];
              let c_code = d['currency code'];

              if (customer_job_currency_object[j_id] == undefined) {
                customer_job_currency_object[j_id] = {
                  job_id: j_id,
                  sub_category_total_rows: [],
                  currencies: {},
                };
              }

              if (customer_job_currency_object[j_id]['currencies'][c_code] == undefined) {
                customer_job_currency_object[j_id]['currencies'][c_code] = {
                  c_code: c_code,
                  sub_category_total_rows: [],
                };
              }
              customer_job_currency_object[j_id]['currencies'][c_code]['sub_category_total_rows'].push(current_row);
            } else if (d['customer id'] && d['job id'] && d['currency code'] && d['Name'].toLowerCase().includes('total')) {
              let j_id = d['job id'];
              if (customer_job_currency_object[j_id] == undefined) {
                customer_job_currency_object[j_id] = {
                  job_id: j_id,
                  sub_category_total_rows: [],
                  currencies: {},
                };
              }
              customer_job_currency_object[j_id]['sub_category_total_rows'].push(current_row);
            } else if (d['customer id'] && d['job id'] && !d['currency code'] && d['Name'].toLowerCase().includes('total')) {
              let c_id = d['customer id'];
              let j_id = d['job id'];
              if (customer_object[c_id] == undefined) {
                customer_object[c_id] = {
                  customer_id: c_id,
                  sub_category_total_rows: [],
                };
              }

              if (this.state.parent_job_object[j_id] == 0) {
                customer_object[c_id]['sub_category_total_rows'].push(current_row);
              } else {
                let parent_job_id = this.state.parent_job_object[j_id];
                if (customer_job_currency_object[parent_job_id] == undefined) {
                  customer_job_currency_object[parent_job_id] = {
                    job_id: parent_job_id,
                    sub_category_total_rows: [],
                    currencies: {},
                  };
                }
                customer_job_currency_object[parent_job_id]['sub_category_total_rows'].push(current_row);
              }
            }

            if (d['Name'].toLowerCase().includes('total')) {
              if (
                (d['customer id'] && d['job id'] && d['currency code']) ||
                (d['customer id'] && d['job id'] && !d['currency code']) ||
                (d['customer id'] && !d['job id'] && !d['currency code']) ||
                (d['customer id'] == -2 && d['job id'] == -2 && d['currency code'] == -2)
              ) {
                let j_id = d['job id'];
                let c_code = d['currency code'];
                let c_id = d['customer id'];

                if (current_row == 36) {
                  console.log('417.....customer id', d['customer id']);
                  console.log('418.....job id', d['job id']);
                  console.log('419.....currency code', d['currency code']);
                }

                let sub_rows = [];
                if (d['customer id'] && d['job id'] && d['currency code']) {
                  sub_rows =
                    customer_job_currency_object[j_id] &&
                    customer_job_currency_object[j_id]['currencies'][c_code] &&
                    customer_job_currency_object[j_id]['currencies'][c_code]['sub_category_total_rows']
                      ? customer_job_currency_object[j_id]['currencies'][c_code]['sub_category_total_rows']
                      : [];
                  if (d['customer id'] == -2 && d['job id'] == -2 && d['currency code'] == -2) {
                    sub_rows = grand_total_object['sub_category_total_rows'] ? grand_total_object['sub_category_total_rows'] : [];
                  }

                  if (current_row == 36) {
                    console.log('430');
                  }
                } else if (d['customer id'] && d['job id'] && !d['currency code']) {
                  sub_rows =
                    customer_job_currency_object[j_id] && customer_job_currency_object[j_id]['sub_category_total_rows']
                      ? customer_job_currency_object[j_id]['sub_category_total_rows']
                      : [];

                  if (current_row == 36) {
                    console.log('437');
                  }
                } else if (d['customer id'] && !d['job id'] && !d['currency code']) {
                  sub_rows =
                    customer_object[c_id] && customer_object[c_id]['sub_category_total_rows']
                      ? customer_object[c_id]['sub_category_total_rows']
                      : [];

                  if (current_row == 36) {
                    console.log('443');
                  }
                }

                if (sub_rows.length > 0) {
                  let debit_string = `=SUM(`;
                  let credit_string = `=SUM(`;
                  let amount_string = `=SUM(`;
                  let open_balance_string = `=SUM(`;

                  let foreign_debit_string = `=SUM(`;
                  let foreign_credit_string = `=SUM(`;
                  let foreign_amount_string = `=SUM(`;
                  let foreign_open_balance_string = `=SUM(`;

                  sub_rows.forEach((sr, l) => {
                    debit_string = debit_string + `${excel_column_of_table_heading_obj['Debit']}${sr},`;
                    credit_string = credit_string + `${excel_column_of_table_heading_obj['Credit']}${sr},`;
                    amount_string = amount_string + `${excel_column_of_table_heading_obj['Amount']}${sr},`;
                    open_balance_string = open_balance_string + `${excel_column_of_table_heading_obj['Open Balance']}${sr},`;

                    foreign_debit_string = foreign_debit_string + `${excel_column_of_table_heading_obj['Foreign Debit']}${sr},`;
                    foreign_credit_string =
                      foreign_credit_string + `${excel_column_of_table_heading_obj['Foreign Credit']}${sr},`;
                    foreign_amount_string =
                      foreign_amount_string + `${excel_column_of_table_heading_obj['Foreign Amount']}${sr},`;
                    foreign_open_balance_string =
                      foreign_open_balance_string + `${excel_column_of_table_heading_obj['Foreign Open Balance']}${sr},`;
                  });

                  debit_string = debit_string.substring(0, debit_string.length - 1) + `)`;
                  credit_string = credit_string.substring(0, credit_string.length - 1) + `)`;
                  amount_string = amount_string.substring(0, amount_string.length - 1) + `)`;
                  open_balance_string = open_balance_string.substring(0, open_balance_string.length - 1) + `)`;

                  foreign_debit_string = foreign_debit_string.substring(0, foreign_debit_string.length - 1) + `)`;
                  foreign_credit_string = foreign_credit_string.substring(0, foreign_credit_string.length - 1) + `)`;
                  foreign_amount_string = foreign_amount_string.substring(0, foreign_amount_string.length - 1) + `)`;
                  foreign_open_balance_string =
                    foreign_open_balance_string.substring(0, foreign_open_balance_string.length - 1) + `)`;

                  d['Debit'] = debit_string;
                  d['Credit'] = credit_string;
                  d['Amount'] = amount_string;
                  d['Open Balance'] = open_balance_string;

                  d['Foreign Debit'] = foreign_debit_string;
                  d['Foreign Credit'] = foreign_credit_string;
                  d['Foreign Amount'] = foreign_amount_string;
                  d['Foreign Open Balance'] = foreign_open_balance_string;
                }
              }
            }

            let detailsArray = this.state.dateList;
            if (is_transactions_starts == 1) {
              is_transactions_starts = 0;
            }
          });
          console.log('customer_object....484', customer_object);
        }

        let header_data = [
          {
            Name:
              this.state.date_range == 'All'
                ? 'Date Range: All'
                : moment(this.state.start_date).format('DD MMM YYYY') +
                  ' TO ' +
                  moment(this.state.end_date).format('DD MMM YYYY'),
          },
        ];
        header_data = header_data.concat(data);

        console.log('columnDefs....478', columnDefs);

        this.setState(
          {
            export_formula_column_defs: columnDefs,
            export_formula_row_data: header_data,
          },
          () => {
            this.onBtExport();
          },
        );
      } else {
        alert('Failed to export excel');
      }
    });
  };

  commaSeperatedValueToNumber = val => {
    try {
      if (val) {
        if (val == '') {
          return val;
        } else {
          if (typeof val == 'string') {
            val = val.replace(/\s/g, '');
            val = val.replace(/,/g, '');
          }
          return parseFloat(val);
        }
      } else {
        return 0;
      }
    } catch (exe) {
      console.log(exe);
      return 0;
    }
  };
  //excel export with formula - end

  rename = (obj, curr) => {
    let a = {};
    Object.keys(obj).map(key => {
      let newKey = key.replace(curr, '');
      Object.assign(a, { [newKey]: obj[key] });
    });
    return a;
  };

  repeat = (sub_categories, paddingLeft) => {
    console.log('july', paddingLeft);
    console.log('1234', 'paddingLeft');

    if (sub_categories) {
      return (
        <React.Fragment>
          {sub_categories &&
            sub_categories.map((itm, i) => {
              if (Object.values(itm)[0] != undefined) {
                //  console.log('july',sub_categories[0][itm])
                return (
                  <React.Fragment key={i}>
                    {/* invoice heading others */}
                    {/* 2024-05-29 */}
                    {/* {Object.values(itm)[0].invoices &&
                      !isNaN(Number(Object.values(itm)[0].total_amount)) &&
                      Number(Object.values(itm)[0].total_amount) != 0 && ( */}
                    {Object.values(itm)[0].invoices && Number(Object.values(itm)[0].is_transactions_available) == 1 && (
                      <React.Fragment>
                        <tr class="item-step1 sub-title">
                          <td
                            style={{
                              paddingLeft: `${paddingLeft}px`,
                              //  position: "sticky",
                              // left: "0.25rem",
                              backgroundColor: '#EFEFFF',
                            }}
                          >
                            <div> {Object.values(itm)[0].job_name} </div>
                          </td>

                          {/* <td>
                              <div> </div>
                            </td>
                            <td>
                              <div> </div>
                            </td>
                            <td>
                              <div> </div>
                            </td>
                            <td>
                              <div> </div>
                            </td>
                            <td>
                              <div> </div>
                            </td>
                            <td>
                              <div> </div>
                            </td>
                            <td>
                              <div> </div>
                            </td>
                            <td>
                              <div> </div>
                            </td>

                            <td>
                              <div> </div>
                            </td>
                            <td>
                              <div> </div>
                            </td>
                            <td>
                              <div> </div>
                            </td>
                            <td>
                              <div> </div>
                            </td>

                            <td>
                              <div> </div>
                            </td>
                            <td class="trans" style={{ paddingLeft: 1 }}>
                              <div>
                              </div>{" "}
                            </td>
                            <td>
                              <div> </div>
                            </td>
                            <td>
                              <div> </div>
                            </td>
                            <td>
                              <div> </div>
                            </td>
                            <td>
                              <div> </div>
                            </td>
                            <td>
                              <div> </div>
                            </td>
                            <td>
                              <div> </div>
                            </td>
                            <td class="trans" style={{ paddingLeft: 1 }}>
                              <div>
                              </div>
                            </td>
                            <td>
                              <div> </div>
                            </td> */}

                          {/* formual excel export */}
                          {/* <td className="hide-table-column">
                              <div>-1</div>
                            </td>
                            <td className="hide-table-column">
                              <div>-1</div>
                            </td>
                            <td className="hide-table-column">
                              <div>-1</div>
                            </td> */}
                          {/* formula excel export - end */}

                          {this.state.coulmns_head.map((x, y) => {
                            if (
                              x.clsname == 'current_customer_id' ||
                              x.clsname == 'current_job_id' ||
                              x.clsname == 'current_currency_code'
                            ) {
                              return (
                                <td className="hide-table-column">
                                  <div>-1</div>
                                </td>
                              );
                            } else {
                              return (
                                <td>
                                  <div></div>
                                </td>
                              );
                            }
                          })}
                        </tr>
                        {console.log('cde', Object.values(itm)[0])}
                        {Object.values(itm)[0].sub_categories &&
                          this.repeat(Object.values(itm)[0].sub_categories, paddingLeft + 45)}
                      </React.Fragment>
                    )}

                    {/* others total */}

                    {/* others map */}
                    {Object.values(itm)[0].invoices &&
                      // dont delete the below conditions in any case - 06-03-2024(invoice length condition newly added)
                      // !isNaN(Number(Object.values(itm)[0].total_amount)) &&
                      // Number(Object.values(itm)[0].total_amount) != 0
                      Object.values(itm)[0].invoices.length > 0 &&
                      Object.values(itm)[0].invoices.map(e1 => {
                        // alert('hi')
                        return (
                          <>
                            <React.Fragment>
                              <tr
                                className="item-step1 istep-3"
                                key={e1}
                                onDoubleClick={() => {
                                  this.break(e1);
                                }}
                              >
                                <td className="">
                                  <span></span>
                                </td>

                                {/* <td className="">
                                  <span>{e1.transaction_number}</span>
                                </td>
                                <td className="" >
                                  <span>{e1.type}</span>
                                </td>
                                <td className="">
                                  <span>{e1.last_modified}</span>
                                </td>
                                <td className="">
                                  <span>{e1.last_modified_by}</span>
                                </td>
                                <td className="">
                                  <span>{e1.transaction_date}</span>
                                </td>
                                <td className="">
                                  <span>{e1.num}</span>
                                </td>
                                <td className="">
                                  <span>{e1.memo}</span>
                                </td>
                                <td className="">
                                  <span>{e1.account}</span>
                                </td>
                                <td className="">
                                  <span>{e1.split}</span>
                                </td>
                                <td className="">
                                  <span>
                                    {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                      { style: 'currency', currency: this.state.home_currency }).format(e1.open_balance_home_currency)).replace(this.state.home_currency_symbol, '')}
                                  </span>
                                </td>
                                <td className="">
                                  <span>
                                    {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                      { style: 'currency', currency: this.state.home_currency }).format(e1.debit)).replace(this.state.home_currency_symbol, '')}
                                  </span>
                                </td>
                                <td className="">
                                  <span>
                                    {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                      { style: 'currency', currency: this.state.home_currency }).format(e1.credit)).replace(this.state.home_currency_symbol, '')}
                                  </span>
                                </td>
                                <td className="">
                                  <span>
                                    {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                      { style: 'currency', currency: this.state.home_currency }).format(e1.amount)).replace(this.state.home_currency_symbol, '')}
                                  </span>
                                </td>
                                <td className="">
                                  <span>
                                    {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                      { style: 'currency', currency: this.state.home_currency }).format(e1.balance)).replace(this.state.home_currency_symbol, '')}
                                  </span>
                                </td>
                                <td className="">
                                  <span>{e1.currency}</span>
                                </td>
                                <td className="">
                                  <span>{e1.exchange_rate}</span>
                                </td>
                                <td className="">
                                  <span>
                                    {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                      { style: 'currency', currency: this.state.home_currency }).format(e1.open_balance_foreign_currency)).replace(this.state.home_currency_symbol, '')}
                                  </span>
                                </td>
                                <td className="">

                                  <span>
                                    {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                      { style: 'currency', currency: this.state.home_currency }).format(e1.foreign_debit)).replace(this.state.home_currency_symbol, '')}
                                  </span>
                                </td>
                                <td className="">
                                  <span>
                                    {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                      { style: 'currency', currency: this.state.home_currency }).format(e1.foreign_credit)).replace(this.state.home_currency_symbol, '')}
                                  </span>
                                </td>
                                <td className="">
                                  <span>
                                    {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                      { style: 'currency', currency: this.state.home_currency }).format(e1.foreign_amount)).replace(this.state.home_currency_symbol, '')}
                                  </span>
                                </td>
                                <td className="">

                                  <span>
                                    {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                      { style: 'currency', currency: this.state.home_currency }).format(e1.foreign_balance)).replace(this.state.home_currency_symbol, '')}
                                  </span>
                                </td>

                                <td className="">
                                  <span>{e1.aging}</span>
                                </td> */}

                                {/* excel export with formula */}
                                {/* <td className="hide-table-column"><span>{e1.customer_id}</span></td>
                                <td className="hide-table-column"><span>{e1.job_id}</span></td>
                                <td className="hide-table-column"><span>{e1.currency}</span></td> */}
                                {/* excel export with formula end */}

                                {this.state.coulmns_head.map((x, y) => {
                                  if (
                                    x.clsname == 'open_balance_home_currency' ||
                                    x.clsname == 'debit' ||
                                    x.clsname == 'credit' ||
                                    x.clsname == 'amount' ||
                                    x.clsname == 'open_balance_foreign_currency' ||
                                    x.clsname == 'foreign_debit' ||
                                    x.clsname == 'foreign_credit' ||
                                    x.clsname == 'foreign_amount'
                                  ) {
                                    return (
                                      <td>
                                        <span className=" ">
                                          {new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname, {
                                            style: 'currency',
                                            currency: this.state.home_currency,
                                          })
                                            .format(e1[x.clsname])
                                            .replace(this.state.home_currency_symbol, '')}
                                        </span>
                                      </td>
                                    );
                                  } else if (x.clsname == 'foreign_balance') {
                                    return (
                                      <td className="">
                                        <span>{e1[x.clsname] ? e1[x.clsname] : 0}</span>
                                      </td>
                                    );
                                  } else if (
                                    x.clsname == 'current_customer_id' ||
                                    x.clsname == 'current_job_id' ||
                                    x.clsname == 'current_currency_code'
                                  ) {
                                    return (
                                      <td className="hide-table-column">
                                        <span>{e1[x.clsname] ? e1[x.clsname] : ''}</span>
                                      </td>
                                    );
                                  } else {
                                    return (
                                      <td className="">
                                        <span>{e1[x.clsname] ? e1[x.clsname] : ''}</span>
                                      </td>
                                    );
                                  }
                                })}
                              </tr>
                            </React.Fragment>
                          </>
                        );
                      })}
                    {/* 2024-05-29 */}
                    {/* {Object.values(itm)[0].invoices &&
                      !isNaN(Number(Object.values(itm)[0].total_amount)) &&
                      Number(Object.values(itm)[0].total_amount) != 0 && ( */}
                    {Object.values(itm)[0].invoices && Object.values(itm)[0].is_transactions_available == 1 && (
                      <React.Fragment>
                        <tr className="item-step1 istep-2 title1">
                          <td style={{ paddingLeft: `${paddingLeft}px` }}>
                            <span>Total {Object.values(itm)[0].job_name} </span>
                          </td>

                          {/* <td>
                              <span className="text-right"></span>
                            </td>
                            <td>
                              <span className="text-right"></span>
                            </td>{" "}
                            <td>
                              <span className="text-right"></span>
                            </td>{" "}
                            <td>
                              <span className="text-right"></span>
                            </td>{" "}
                            <td>
                              <span className="text-right"></span>
                            </td>{" "}
                            <td>
                              <span className="text-right"></span>
                            </td>
                            <td>
                              <span className="text-right"></span>
                            </td>{" "}
                            <td>
                              <span className="text-right"></span>
                            </td>
                            <td>
                              <span className="text-right"></span>
                            </td>{" "}
                            <td>
                              <span className="">                              
                                { Object.values(itm)[0].total_open_balance &&!isNaN(Object.values(itm)[0].total_open_balance) ?(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
 { style: 'currency', currency: this.state.home_currency }).format(Object.values(itm)[0].total_open_balance)).replace(this.state.home_currency_symbol, ''):0}
                              </span>
                            </td>
                            <td>
                              <span className="">
                                {Object.values(itm)[0].total_debit && !isNaN(Object.values(itm)[0].total_debit) ?(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                  { style: 'currency', currency: this.state.home_currency }).format(Object.values(itm)[0].total_debit)).replace(this.state.home_currency_symbol, ''):0}
                              </span>
                            </td>{" "}
                            <td>
                              <span className="">
                                {Object.values(itm)[0].total_credit && !isNaN(Object.values(itm)[0].total_credit)?(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                  { style: 'currency', currency: this.state.home_currency }).format(Object.values(itm)[0].total_credit)).replace(this.state.home_currency_symbol, ''):0}
                              </span>
                            </td>
                            <td>
                              <span className=" ">
                                {Object.values(itm)[0].total_amount && !isNaN(Object.values(itm)[0].total_amount)?(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                  { style: 'currency', currency: this.state.home_currency }).format(Object.values(itm)[0].total_amount)).replace(this.state.home_currency_symbol, ''):0}
                              </span>
                            </td>
                            <td>
                              <span className=" ">
                                {Object.values(itm)[0].current_closing_balance && !isNaN(Object.values(itm)[0].current_closing_balance)?(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                  { style: 'currency', currency: this.state.home_currency }).format(Object.values(itm)[0].current_closing_balance)).replace(this.state.home_currency_symbol, ''):0}
                              </span>
                            </td>
                            <td>
                              <span className="text-right"></span>
                            </td>
                            <td>
                              <span className="text-right">
                                {
                                  Object.values(itm)[0].total_exhange_rate
                                }
                              </span>
                            </td>
                            <td>
                              <span className=" ">
                                {Object.values(itm)[0].total_open_foreign_balance && !isNaN(Object.values(itm)[0].total_open_foreign_balance)?(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                  { style: 'currency', currency: this.state.home_currency }).format(Object.values(itm)[0].total_open_foreign_balance)).replace(this.state.home_currency_symbol, ''):0}
                              </span>
                            </td>
                            <td>
                              <span className=" ">
                                {Object.values(itm)[0].total_foreign_debit && !isNaN(Object.values(itm)[0].total_foreign_debit)?(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                  { style: 'currency', currency: this.state.home_currency }).format(Object.values(itm)[0].total_foreign_debit)).replace(this.state.home_currency_symbol, ''):0}
                              </span>
                            </td>
                            <td>
                              <span className=" ">
                                {Object.values(itm)[0].total_foreign_credit && !isNaN(Object.values(itm)[0].total_foreign_credit)?(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                  { style: 'currency', currency: this.state.home_currency }).format(Object.values(itm)[0].total_foreign_credit)).replace(this.state.home_currency_symbol, ''):0}
                              </span>
                            </td>
                            <td>
                              <span className=" ">
                                {Object.values(itm)[0].total_foreign_amount && !isNaN(Object.values(itm)[0].total_foreign_amount)?(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                  { style: 'currency', currency: this.state.home_currency }).format(Object.values(itm)[0].total_foreign_amount)).replace(this.state.home_currency_symbol, ''):0}
                              </span>
                            </td>
                            <td>
                              <span className=" ">
                                {Object.values(itm)[0].current_closing_foreign_balance && !isNaN(Object.values(itm)[0].current_closing_foreign_balance)?(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                  { style: 'currency', currency: this.state.home_currency }).format(Object.values(itm)[0].current_closing_foreign_balance)).replace(this.state.home_currency_symbol, ''):0}
                              </span>
                            </td>
                            <td>
                              <span className="text-right">
                                {
                                  Object.values(itm)[0].aging}
                              </span>
                            </td> */}

                          {this.state.coulmns_head.map((x, y) => {
                            switch (x.clsname) {
                              case 'open_balance_home_currency':
                                return (
                                  <td>
                                    <span className="">
                                      {Object.values(itm)[0].total_open_balance &&
                                      !isNaN(Object.values(itm)[0].total_open_balance)
                                        ? new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname, {
                                            style: 'currency',
                                            currency: this.state.home_currency,
                                          })
                                            .format(Object.values(itm)[0].total_open_balance)
                                            .replace(this.state.home_currency_symbol, '')
                                        : 0}
                                    </span>
                                  </td>
                                );
                              case 'debit':
                                return (
                                  <td>
                                    <span className="">
                                      {Object.values(itm)[0].total_debit && !isNaN(Object.values(itm)[0].total_debit)
                                        ? new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname, {
                                            style: 'currency',
                                            currency: this.state.home_currency,
                                          })
                                            .format(Object.values(itm)[0].total_debit)
                                            .replace(this.state.home_currency_symbol, '')
                                        : 0}
                                    </span>
                                  </td>
                                );
                              case 'credit':
                                return (
                                  <td>
                                    <span className="">
                                      {Object.values(itm)[0].total_credit && !isNaN(Object.values(itm)[0].total_credit)
                                        ? new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname, {
                                            style: 'currency',
                                            currency: this.state.home_currency,
                                          })
                                            .format(Object.values(itm)[0].total_credit)
                                            .replace(this.state.home_currency_symbol, '')
                                        : 0}
                                    </span>
                                  </td>
                                );
                              case 'amount':
                                return (
                                  <td>
                                    <span className=" ">
                                      {Object.values(itm)[0].total_amount && !isNaN(Object.values(itm)[0].total_amount)
                                        ? new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname, {
                                            style: 'currency',
                                            currency: this.state.home_currency,
                                          })
                                            .format(Object.values(itm)[0].total_amount)
                                            .replace(this.state.home_currency_symbol, '')
                                        : 0}
                                    </span>
                                  </td>
                                );
                              case 'balance':
                                return (
                                  <td>
                                    <span className=" ">
                                      {Object.values(itm)[0].current_closing_balance &&
                                      !isNaN(Object.values(itm)[0].current_closing_balance)
                                        ? new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname, {
                                            style: 'currency',
                                            currency: this.state.home_currency,
                                          })
                                            .format(Object.values(itm)[0].current_closing_balance)
                                            .replace(this.state.home_currency_symbol, '')
                                        : 0}
                                    </span>
                                  </td>
                                );
                              case 'open_balance_foreign_currency':
                                return (
                                  <td>
                                    <span className=" ">
                                      {Object.values(itm)[0].total_open_foreign_balance &&
                                      !isNaN(Object.values(itm)[0].total_open_foreign_balance)
                                        ? new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname, {
                                            style: 'currency',
                                            currency: this.state.home_currency,
                                          })
                                            .format(Object.values(itm)[0].total_open_foreign_balance)
                                            .replace(this.state.home_currency_symbol, '')
                                        : 0}
                                    </span>
                                  </td>
                                );
                              case 'foreign_debit':
                                return (
                                  <td>
                                    <span className=" ">
                                      {Object.values(itm)[0].total_foreign_debit &&
                                      !isNaN(Object.values(itm)[0].total_foreign_debit)
                                        ? new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname, {
                                            style: 'currency',
                                            currency: this.state.home_currency,
                                          })
                                            .format(Object.values(itm)[0].total_foreign_debit)
                                            .replace(this.state.home_currency_symbol, '')
                                        : 0}
                                    </span>
                                  </td>
                                );
                              case 'foreign_credit':
                                return (
                                  <td>
                                    <span className=" ">
                                      {Object.values(itm)[0].total_foreign_credit &&
                                      !isNaN(Object.values(itm)[0].total_foreign_credit)
                                        ? new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname, {
                                            style: 'currency',
                                            currency: this.state.home_currency,
                                          })
                                            .format(Object.values(itm)[0].total_foreign_credit)
                                            .replace(this.state.home_currency_symbol, '')
                                        : 0}
                                    </span>
                                  </td>
                                );
                              case 'foreign_amount':
                                return (
                                  <td>
                                    <span className=" ">
                                      {Object.values(itm)[0].total_foreign_amount &&
                                      !isNaN(Object.values(itm)[0].total_foreign_amount)
                                        ? new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname, {
                                            style: 'currency',
                                            currency: this.state.home_currency,
                                          })
                                            .format(Object.values(itm)[0].total_foreign_amount)
                                            .replace(this.state.home_currency_symbol, '')
                                        : 0}
                                    </span>
                                  </td>
                                );
                              case 'foreign_balance':
                                return (
                                  <td>
                                    <span className=" ">
                                      {Object.values(itm)[0].current_closing_foreign_balance &&
                                      !isNaN(Object.values(itm)[0].current_closing_foreign_balance)
                                        ? new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname, {
                                            style: 'currency',
                                            currency: this.state.home_currency,
                                          })
                                            .format(Object.values(itm)[0].current_closing_foreign_balance)
                                            .replace(this.state.home_currency_symbol, '')
                                        : 0}
                                    </span>
                                  </td>
                                );
                              case 'current_customer_id':
                                return (
                                  <td className="hide-table-column">
                                    <span>{Object.values(itm)[0].current_customer_id}</span>
                                  </td>
                                );
                              case 'current_job_id':
                                return (
                                  <td className="hide-table-column">
                                    <span>{Object.values(itm)[0].current_job_id}</span>
                                  </td>
                                );
                              case 'current_currency_code':
                                return (
                                  <td className="hide-table-column">
                                    <span>{Object.values(itm)[0].current_currency_code}</span>
                                  </td>
                                );

                              default:
                                return (
                                  <td>
                                    <span className="text-right"></span>
                                  </td>
                                );
                            }
                          })}

                          {/* excel export with formula */}
                          {/* <td className="hide-table-column">
                              <span>{Object.values(itm)[0].current_customer_id}</span>
                            </td>
                              <td className="hide-table-column">
                              <span>{Object.values(itm)[0]
                                    .current_job_id}</span>
                            </td>
                             <td className="hide-table-column">
                              <span>{Object.values(itm)[0]
                                    .current_currency_code}</span>
                            </td> */}
                          {/* excel export with formula end */}
                        </tr>
                      </React.Fragment>
                    )}
                    {/* others  ends */}
                  </React.Fragment>
                );
              }
            })}
        </React.Fragment>
      );
    }
  };

  break = e1 => {
    console.log('break', e1);
    if (e1.type == 'Sales Invoice' || e1.type == 'Payment') {
      if (e1.type == 'Sales Invoice') {
        var setID = e1.trans;
      } else if (e1.type == 'Payment') {
        var setID = e1.invoice_id + '=' + e1.trans;
      }

      localStorage.setItem('invoice_id', setID);
      localStorage.setItem('job_id', e1.job_id);
      // alert("job id" + e1.job_id);

      var win = window.open('/create_invoice', '_blank');
      win.focus();
    }

    if (e1.type == 'Customer multipayment unapplied amount' && e1.multi_payment_applied_invoices) {
      let arr = [e1.customer_id, e1.multi_payment_applied_invoices];

      localStorage.setItem('edit_customer_receive_payment', JSON.stringify(arr));

      var winn = window.open('/Customer_receive_payment', '_blank');
      winn.focus();
    }

    if (
      e1.type == 'Batch - Bill' ||
      e1.type == 'Batch - Vendor credit note' ||
      e1.type == 'Batch - Cheque' ||
      e1.type == 'Batch - Deposit' ||
      e1.type == 'Batch - Sales Invoice' ||
      e1.type == 'Batch - Customer credit note' ||
      e1.type == 'Batch-Cheque' ||
      e1.type == 'Batch-Deposit'
    ) {
      localStorage.setItem('batchid', e1.batch_transaction_id);
      localStorage.setItem('batch_transaction_row_index', e1.batch_transaction_row_index);
      var win = window.open('/enter_batch_transaction', '_blank');
      win.focus();
    }

    if (e1.type == 'Transfer Funds') {
      localStorage.setItem('transfer_fund_transaction_number', e1.transaction_number);
      var win = window.open('/transfer_funds', '_blank');
      win.focus();
    }

    if (e1.type == 'Bill' || e1.type == 'Credit') {
      if (e1.type == 'Bill' || e1.type == 'Credit') {
        let arr = [e1.type, e1.invoice_id];
        console.log('hy', e1.invoice_id);
        localStorage.setItem('vendor_bill', JSON.stringify(arr));
      }

      var win = window.open('/data_tagging/' + e1.type + '/' + e1.invoice_id, '_blank');
      win.focus();
    }

    if (e1.type == 'Journal entry') {
      localStorage.setItem('journal_id', e1.invoice_id);
      var win = window.open('/new_journal', '_blank');
      win.focus();
    }

    if (e1.type == 'Bill payment') {
      let arr = [e1.type, e1.invoice_id, e1.payment_id];

      localStorage.setItem('vendor_bill', JSON.stringify(arr));

      // alert(e1.payment_id)
      // this.props.history.push('/data_tagging/' + e1.list_id + '/' + e1.file_id)
      var win = window.open('/data_tagging/' + e1.type + '/' + e1.invoice_id, '_blank');
      win.focus();
    }
    if (e1.type == 'Third Party Payment') {
      if (e1.third_party_payment_from == 'vendor bill payment') {
        let arr = ['Bill payment', e1.invoice_id, e1.payment_id];

        localStorage.setItem('vendor_bill', JSON.stringify(arr));

        var win = window.open('/data_tagging/' + e1.list_id + '/' + e1.file_id, '_blank');
        win.focus();
      } else if (e1.third_party_payment_from == 'customer invoice payment') {
        let arr = [e1.type, e1.invoice_id];
        var setID = e1.invoice_id + '=' + e1.trans;

        localStorage.setItem('invoice_id', setID);
        localStorage.setItem('job_id', e1.job_id);
        localStorage.setItem('vendor_bill', JSON.stringify(arr));

        // alert(e1.payment_id)
        // this.props.history.push('/data_tagging/' + e1.list_id + '/' + e1.file_id)
        var win = window.open('/create_invoice', '_blank');
        win.focus();
      }
    } else if (e1.type == 'Deposit') {
      console.log(e1);
      localStorage.setItem('deposit_id', e1.id);

      var win = window.open('/make_deposit', '_blank');
      win.focus();
    } else if (e1.type == 'Cheque') {
      localStorage.setItem('cheque_id', e1.id);

      var win = window.open('/write_cheque', '_blank');
      win.focus();
    } else if (e1.type == 'Customer credit note' || e1.type == 'Customer credit memo') {
      // alert(e1.payment_id)
      // this.props.history.push('/data_tagging/' + e1.list_id + '/' + e1.file_id)
      var win = window.open('/create_creditmemo?memo_id=' + e1.credit_memo_id, '_blank');
      win.focus();
    }
  };

  customRadioChange4 = x => {
    this.setState({ valueAmount_type4: x }, () => {
      this.callAPIDATA();
    });
  };

  changeText4 = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: this.state.valueAmount_type3,
            value: this.state.valueAmount3,
            from: this.state.From4,
            to: this.state.To4,
          },
        },
        () => {
          this.callAPIDATA();
        },
      );
    });
  };

  multiSelectedAccount = () => {
    this.setState({ selectedAccountIds: jQuery('#selectedAccountIds').val() }, () => {
      this.callAPIDATA();
    });
  };

  all_account_list = () => {
    let input = {
      client_id: this.state.logged_client_id,
    };
    // alert('hjgh')
    FetchAllApi.all_account_list(input, (err, response) => {
      if (response.status === 1) {
        this.setState({ all_account_list: response.list });
      } else {
      }
    });
  };

  sortingApi = () => {
    if (this.state.selectedName != '') {
      this.callAPIDATA();
    }
  };

  sortByNames = () => {
    let report_id = this.state.all_report_name_id;
    // alert('hjgh')
    FetchAllApi.reportSortbyOptions(report_id, (err, response) => {
      if (response.status === 1) {
        console.log('rty', response);
        this.setState({ sortBynames: response.list });
      } else {
      }
    });
  };

  all_report_name = () => {
    FetchAllApi.all_report_name((err, response) => {
      if (response.status === 1) {
        let report_ids = response.response;
        for (var i = 0; i < report_ids.length; i++) {
          if (report_ids[i].column_name == 'Open_invoices') {
            this.setState({ all_report_name_id: report_ids[i].report_id }, () => {
              // this.callAPIDATA();
              this.sortByNames();
            });
          }
        }
      } else {
      }
    });
  };

  customRadioChange1 = x => {
    this.setState({ valueAmount_type1: x });
  };
  customRadioChange2 = x => {
    this.setState({ valueAmount_type2: x });
  };
  customRadioChange3 = x => {
    this.setState({ valueAmount_type3: x });
  };
  customRadioChange4 = x => {
    this.setState({ valueAmount_type4: x });
  };

  paymentTerms = () => {
    FetchAllApi.payment_terms((err, response) => {
      console.log('Customer list', response);
      if (response.status === 1) {
        this.setState({ paymentTerms: response.lists });
      } else {
        this.setState({ paymentTerms: [] });
      }
    });
  };

  vendor_type = () => {
    var client_id = this.state.logged_client_id;

    FetchAllApi.vendorTypes(client_id, (err, response) => {
      console.log('Customer list', response);

      if (response.status === 1) {
        this.setState({ vendor_type: response.list });
      } else {
        this.setState({ vendor_type: [] });
      }
    });
  };

  selectedVendor_type = e => {
    var result = [];

    this.setState({ selectedVendor_type: result }, () => {
      this.callAPIDATA();
    });

    var options = e.target.options;
    var opt;
    for (var i = 0, iLen = options.length; i < iLen; i++) {
      opt = options[i];

      if (opt.selected) {
        result.push(Number(opt.value) || Number(opt.text));
      } else {
      }
    }
  };

  selectedTerms = e => {
    var result = [];

    this.setState({ selectedTerms: result }, () => {
      this.callAPIDATA();
    });

    var options = e.target.options;
    var opt;
    for (var i = 0, iLen = options.length; i < iLen; i++) {
      opt = options[i];

      if (opt.selected) {
        result.push(Number(opt.value) || Number(opt.text));
      } else {
      }
    }
  };

  changefromDate1(fromdate) {
    let date = jQuery('#fromdate1').val();
    if (date != undefined && date != '') {
      var array = date.split('/');
      var date_formated = array[2] + '-' + array[1] + '-' + array[0];
      if (array != '' && array != undefined) {
        this.setState({ date_start: date_formated }, () => {
          this.callAPIDATA();
        });
      }
    }
  }

  changetoDate1(todate) {
    // alert(jQuery('#todate').val())
    let date = jQuery('#todate1').val();
    if (date != undefined && date != '') {
      var array = date.split('/');
      var date_formated = array[2] + '-' + array[1] + '-' + array[0];
      if (array != '' && array != undefined) {
        this.setState({ date_end: date_formated }, () => {
          this.callAPIDATA();
        });
      }
    }
  }

  changeText1 = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: this.state.valueAmount_type1,
            value: this.state.valueAmount1,
            from: this.state.From,
            to: this.state.To,
          },
        },
        () => {
          this.callAPIDATA();
        },
      );
    });
  };
  changeText_Num = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: '',
            value: this.state.number_from,
            from: '',
            to: '',
          },
        },
        () => {
          this.callAPIDATA();
        },
      );
    });
  };
  changeText2 = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: this.state.valueAmount_type2,
            value: this.state.valueAmount2,
            from: this.state.From,
            to: this.state.To,
          },
        },
        () => {
          this.callAPIDATA();
        },
      );
    });
  };
  changeText3 = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: this.state.valueAmount_type3,
            value: this.state.valueAmount3,
            from: this.state.From,
            to: this.state.To,
          },
        },
        () => {
          this.callAPIDATA();
        },
      );
    });
  };
  changeText4 = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: this.state.valueAmount_type4,
            value: this.state.valueAmount4,
            from: '',
            to: '',
          },
        },
        () => {
          this.callAPIDATA();
        },
      );
    });
  };

  text1 = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: '',
            value: this.state.text1,
            from: '',
            to: '',
          },
        },
        () => {
          this.callAPIDATA();
        },
      );
    });
  };

  text2 = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: '',
            value: this.state.text2,
            from: '',
            to: '',
          },
        },
        () => {
          this.callAPIDATA();
        },
      );
    });
  };

  text3 = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: '',
            value: this.state.text3,
            from: '',
            to: '',
          },
        },
        () => {
          this.callAPIDATA();
        },
      );
    });
  };
  recursiveCategeory = subcat => {
    var useFirst = subcat;
    var result = [];
    var currencyBasedArray = [];
    const swingHead = subcat => {
      if (subcat != undefined) {
        let blockMe = Object.values(subcat).length;
        if (Number(blockMe) == 1) {
          let itemsentry = Object.values(subcat[0]);
          if (itemsentry != undefined) {
            itemsentry.map(prime => {
              if (prime.invoices != undefined) {
                prime.invoices.map(invoice => {
                  result.push(invoice);
                });

                let jobName = prime.job_name;
                let data = [];
                //Access foreign_currency object
                if (prime.foreign_currency != undefined) {
                  prime.foreign_currency.map(item => {
                    data.push(item);
                  });
                }

                var myobj = {
                  job_name: jobName,
                  data: data,
                };
                currencyBasedArray.push(myobj);
              }
              swingHead(prime.sub_categories);
            });
          }
        } else if (Number(blockMe) > 0) {
          for (let i = 0; i < Number(blockMe); i++) {
            let itemsentry = Object.values(subcat[i]);
            if (itemsentry != undefined) {
              itemsentry.map(prime => {
                if (prime.invoices != undefined) {
                  prime.invoices.map(invoice => {
                    result.push(invoice);
                  });

                  let jobName = prime.job_name;
                  let data = [];
                  //Access foreign_currency object
                  if (prime.foreign_currency != undefined) {
                    prime.foreign_currency.map(item => {
                      data.push(item);
                    });
                  }

                  var myobj = {
                    job_name: jobName,
                    data: data,
                  };
                  currencyBasedArray.push(myobj);
                }
                swingHead(prime.sub_categories);
              });
            }
          }
        }
      }
    };
    swingHead(useFirst);
    console.log('currencyBasedArray', currencyBasedArray);
    return this.renderSubJobs(_.orderBy(currencyBasedArray, ['job_name'], ['asc']));
  };
  renderSubJobs = currencyBasedArray => {
    const myarray = currencyBasedArray;
    if (myarray.length > 0) {
      return myarray.map(prime => {
        // alert(prime.job_name)

        const renderData = prime.data.map(item => {
          return (
            <React.Fragment>
              {item.values.map(overallinvoice => {
                return (
                  <React.Fragment>
                    <tr className="item-step1 istep-3" key={overallinvoice}>
                      <td className="">
                        <span></span>
                      </td>

                      <td className="">
                        <span>{overallinvoice.transaction_number}</span>
                      </td>
                      <td className="">
                        <span>{overallinvoice.type}</span>
                      </td>
                      <td className="">
                        <span>{overallinvoice.last_modified}</span>
                      </td>
                      <td className="">
                        <span>{overallinvoice.last_modified_by}</span>
                      </td>
                      <td className="">
                        <span>{overallinvoice.transaction_date}</span>
                      </td>
                      <td className="">
                        <span>{overallinvoice.num}</span>
                      </td>
                      <td className="">
                        <span>{overallinvoice.memo}</span>
                      </td>
                      {/* added */}
                      <td className="">
                        <span>{overallinvoice.account}</span>
                      </td>
                      <td className="">
                        <span>{overallinvoice.split}</span>
                      </td>
                      <td className="">
                        <span>{overallinvoice.open_balance_home_currency}</span>
                      </td>
                      {/* added */}
                      <td className="">
                        <span>{overallinvoice.debit}</span>
                      </td>
                      <td className="">
                        <span>{overallinvoice.credit}</span>
                      </td>
                      <td className="">
                        <span>{overallinvoice.amount}</span>
                      </td>
                      <td className="">
                        <span>{overallinvoice.balance}</span>
                      </td>
                      <td className="">
                        <span>{overallinvoice.currency}</span>
                      </td>
                      <td className="">
                        <span>{overallinvoice.exchange_rate}</span>
                      </td>
                      <td className="">
                        <span>{overallinvoice.open_balance_foreign_currency}</span>
                      </td>
                      <td className="">
                        <span>{overallinvoice.foreign_debit}</span>
                      </td>
                      <td className="">
                        <span>{overallinvoice.foreign_credit}</span>
                      </td>
                      <td className="">
                        <span>{overallinvoice.foreign_amount}</span>
                      </td>
                      <td className="">
                        <span>{overallinvoice.foreign_balance}</span>
                      </td>
                      <td className="">
                        <span>{overallinvoice.grand_total_foreign_currency}</span>
                      </td>
                      <td className="">
                        <span>{overallinvoice.aging}</span>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
              {/* <tr class='item-step1 title1 istep-3'>
                <td>
                  <span style={{ paddingLeft: 40 }}>Sub Total</span>
                </td>
                {this.state.coulmns_head.map((a, b) => {
                  return (
                    <td>
                      <span>{b == 9 ? item.total : null}</span>
                    </td>
                  )
                })}
              </tr> */}
            </React.Fragment>
          );
        });
        return (
          <React.Fragment>
            <tr className="item-step1 sub-title">
              <td style={{ paddingLeft: 45, position: 'sticky', left: '0.25rem', backgroundColor: '#EFEFFF' }}>
                <div>{prime.job_name}</div>
              </td>

              {this.state.coulmns_head.map((a, b) => {
                return (
                  <td className={a.clsname}>
                    <div>&nbsp;</div>
                  </td>
                );
              })}
            </tr>

            {renderData}
          </React.Fragment>
        );
      });
    }
  };

  text4 = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: '',
            value: this.state.text4,
            from: '',
            to: '',
          },
        },
        () => {
          this.callAPIDATA();
        },
      );
    });
  };

  text5 = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: '',
            value: this.state.text5,
            from: '',
            to: '',
          },
        },
        () => {
          this.callAPIDATA();
        },
      );
    });
  };

  text6 = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: '',
            value: this.state.text6,
            from: '',
            to: '',
          },
        },
        () => {
          this.callAPIDATA();
        },
      );
    });
  };

  text7 = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: '',
            value: this.state.text7,
            from: '',
            to: '',
          },
        },
        () => {
          this.callAPIDATA();
        },
      );
    });
  };

  selectedCustomer_type = e => {
    var result = [];

    this.setState({ selectedCustomer_type: result }, () => {
      this.callAPIDATA();
    });

    var options = e.target.options;
    var opt;
    for (var i = 0, iLen = options.length; i < iLen; i++) {
      opt = options[i];

      if (opt.selected) {
        result.push(Number(opt.value) || Number(opt.text));
      } else {
      }
    }
  };

  customer_type = () => {
    FetchAllApi.customerTypes((err, response) => {
      console.log('Customer list', response);
      if (response.status === 1) {
        this.setState({ customer_type: response.lists });
      } else {
        this.setState({ customer_type: [] });
      }
    });
  };

  componentDidUpdate(prevProp, prevState) {
    window.jQuery('.selectpicker').selectpicker('refresh');
    window.jQuery('.input-group.date').datepicker({ format: 'dd/mm/yyyy', autoclose: true }); //DidUpdate

    if (prevState.all_report_name_id !== this.state.all_report_name_id) {
      this.callAPIDATA();
    }
  }
  componentDidMount() {
    this.all_account_list();
    this.all_report_name();
    this.get_col();
    this.get_currencies(); //didMount
    this.get_vendorNames();

    this.paymentTerms();
    this.vendor_type();
    this.customer_type();

    document.getElementById('sticky-tb-hdr').addEventListener('scroll', function () {
      var translate = 'translate(0,' + this.scrollTop + 'px)';
      if (
        this.querySelector('thead') != null &&
        this.querySelector('thead') != undefined &&
        this.querySelector('thead').style != null
      ) {
        this.querySelector('thead').style.transform = translate;
      }
    });

    const urlParams = new URLSearchParams(window.location.search);
    let start = urlParams.get('start');
    let end = urlParams.get('end');
    let range = urlParams.get('range');
    let is_export = urlParams.get('is_export');
    let export_type = urlParams.get('export_type');

    //newly added for all reports open
    let is_from_all_report = urlParams.get('is_from_all_report');
    if (is_from_all_report == 1 && urlParams != null && urlParams != undefined) {
      this.setState(
        {
          start_date: start,
          end_date: end,
          is_export: is_export ? is_export : 0,
          export_type: export_type ? export_type : '',
        },
        () => {
          this.callAPIDATA();
        },
      );
      // document.getElementById("fromdate").value = moment(start).format("DD-MM-YYYY");
      document.getElementById('todate').value = moment(end).format('DD-MM-YYYY');
    } else if (
      localStorage.getItem('fiscal_start_year') != '' &&
      localStorage.getItem('fiscal_start_year') != null &&
      localStorage.getItem('fiscal_start_year') != undefined
    ) {
      // let start = moment(localStorage.getItem('fiscal_start_year')).format("DD-MM-YYYY");
      let end = moment(localStorage.getItem('fiscal_end_year')).format('DD-MM-YYYY');
      this.setState(
        { start_date: localStorage.getItem('fiscal_start_year'), end_date: localStorage.getItem('fiscal_end_year') },
        () => {
          this.callAPIDATA();
        },
      );
      // document.getElementById("fromdate").value = start;
      document.getElementById('todate').value = end;
    } else {
      this.setState({ date_range: 'All' }, () => {
        this.callAPIDATA();
      });
      this.changedatevalue('All');
    }

    // this.show_columnslist()
    // jQuery(".custom-select-drop .dropdown-menu a").click(function () {
    //   jQuery(".open.custom-select-drop .dropdown-menu li.active").removeClass(
    //     "active"
    //   );
    //   jQuery(this).parent("li").addClass("active");
    //   jQuery(".open #selected").text(jQuery(this).text());
    // });

    window.jQuery('.input-group.date').datepicker({ format: 'dd/mm/yyyy', autoclose: true });

    jQuery('.snippet').mouseenter(function () {
      jQuery('.snippet').removeClass('active');
      jQuery(this).addClass('active');
    });

    jQuery('.filter-btn').click(function () {
      jQuery(this).css('visibility', 'hidden');
      jQuery('.report-filter').slideDown();
    });

    jQuery('.report-filter .close-btn').click(function () {
      jQuery('.filter-btn').css('visibility', 'visible');
      jQuery('.report-filter').slideUp();
    });
    // this.callAPIDATA();
  }
  componentWillMount() {
    jQuery('title').html('GBSC | Customer Open Invoices');
    if (this.state.logged_user_id === '' || this.state.logged_user_id === null || this.state.logged_user_id === undefined) {
      this.props.history.push('/');
    }
  }

  logoutLink() {
    localStorage.clear();

    this.props.history.push('/');
  }
  generate = () => {
    //  var doc = new jsPDF('p', 'pt', 'letter');
    var doc = new jsPDF({
      orientation: 'landscape',
      unit: 'in',
      format: [11.7, 16.5],
    });
    var htmlstring = '';
    var tempVarToCheckPageHeight = 0;
    var pageHeight = 0;
    pageHeight = doc.internal.pageSize.height;
    // specialElementHandlers = {
    //     // element with id of "bypass" - jQuery style selector
    //     '#bypassme': function (element, renderer) {
    //         // true = "handled elsewhere, bypass text extraction"
    //         return true
    //     }
    // };
    // margins = {
    //     top: 150,
    //     bottom: 60,
    //     left: 40,
    //     right: 40,
    //     width: 600
    // };

    //commented on 05-01-2022
    //  var y = 20;
    //  doc.setLineWidth(2);
    //  doc.text(200, y = y + 30, "CustomerInvoice");
    //  doc.autoTable({
    //      html: '#mytable',
    //      startY: 70,
    //      theme: 'grid',
    //      columnStyles: {
    //          0: {
    //              cellWidth: 180,
    //          },
    //          1: {
    //              cellWidth: 180,
    //          },
    //          2: {
    //              cellWidth: 180,
    //          }
    //      },
    //      styles: {
    //          minCellHeight: 40
    //      }
    //  })

    var y = 20;
    doc.setLineWidth(2);
    doc.text(7, 0.5, 'Customer Open Invoices');

    let date_text =
      this.state.date_range == 'All'
        ? 'Date Range: All'
        : moment(this.state.start_date).format('DD MMM YYYY') + ' TO ' + moment(this.state.end_date).format('DD MMM YYYY');
    if (this.state.date_range == 'All') {
      doc.text(7.2, 0.8, date_text);
    } else {
      doc.text(6.5, 0.8, date_text);
    }

    doc.autoTable({
      html: '#mytable',
      includeHiddenHtml: false,
      // startY: 0.6
      startY: 0.9,
    });
    doc.save('Customer-open-invoice-' + this.state.export_file_name_date_range + '.pdf');
  };

  get_col = () => {
    let report_id = 13;
    FetchAllApi.get_col(this.state.logged_client_id, report_id, (err, response) => {
      if (response.status === 1) {
        var active = [];
        let active_headings = response.response.map(item => {
          if (item.status === 1) {
            active.push(item.heading_name);
          }
        });
        let coulmns_head = response.response;

        coulmns_head.forEach(col => {
          switch (col.heading_name) {
            case 'Trans#':
              col.clsname = 'transaction_number';
              break;

            case 'Type':
              col.clsname = 'type';
              break;

            case 'Last Modified':
              col.clsname = 'last_modified';
              break;

            case 'Last Modified By':
              col.clsname = 'last_modified_by';
              break;

            case 'Transaction Date':
              col.clsname = 'transaction_date';
              break;

            case 'Num':
              col.clsname = 'num';
              break;

            case 'Related invoice number':
              col.clsname = 'related_invoice_number';
              break;

            case 'Source Name':
              col.clsname = 'source_name';
              break;

            case 'Job Name':
              col.clsname = 'job_name';
              break;

            case 'Memo':
              col.clsname = 'memo';
              break;

            case 'Account':
              col.clsname = 'account';
              break;

            case 'Split':
              col.clsname = 'split';
              break;

            case 'Split- Breakdown String':
              col.clsname = 'split_breakdown_string';
              break;

            case 'Open Balance':
              col.clsname = 'open_balance_home_currency';
              break;

            case 'Debit':
              col.clsname = 'debit';
              break;

            case 'Credit':
              col.clsname = 'credit';
              break;

            case 'Amount':
              col.clsname = 'amount';
              break;

            case 'Split- Breakdown Amount':
              col.clsname = 'split_breakdown_amount';
              break;

            case 'Balance':
              col.clsname = 'balance';
              break;

            case 'Currency':
              col.clsname = 'currency';
              break;

            case 'Exchange Rate':
              col.clsname = 'exchange_rate';
              break;

            case 'Foreign Open Balance':
              col.clsname = 'open_balance_foreign_currency';
              break;

            case 'Foreign Debit':
              col.clsname = 'foreign_debit';
              break;

            case 'Foreign Credit':
              col.clsname = 'foreign_credit';
              break;

            case 'Foreign Amount':
              col.clsname = 'foreign_amount';
              break;

            case 'Split- Breakdown Foreign Amount':
              col.clsname = 'split_breakdown_foreign_amount';
              break;

            case 'Foreign Balance':
              col.clsname = 'foreign_balance';
              break;

            case 'Aging':
              col.clsname = 'aging';
              break;

            case 'customer id':
              col.clsname = 'current_customer_id';
              break;

            case 'job id':
              col.clsname = 'current_job_id';
              break;

            case 'currency code':
              col.clsname = 'current_currency_code';
              break;

            default:
              break;
          }
        });

        let optionList = '';
        if (coulmns_head) {
          var options = coulmns_head.map((item, i) => {
            return <option>{item.heading_name}</option>;
          });
        }

        this.setState(
          {
            selected_vals: active,
            coulmns_head: coulmns_head,
            options: options,
          },
          () => {
            setTimeout(() => {
              for (let k = 0; k < coulmns_head.length; k++) {
                try {
                  if (coulmns_head[k].status == 2) {
                    console.log('coulmns_head[k]........', coulmns_head[k]);
                    let h = k + 2;
                    console.log('h......1968', h);
                    jQuery('td:nth-child(' + h + '),th:nth-child(' + h + ')').hide();
                  }
                } catch (exception) {
                  console.log('Error while column hiding....', exception);
                }
              }
            }, 1000);
          },
        );

        //excel export formula change
        //hide default coulmns
        // const inactiveArray = coulmns_head.filter(
        //   (item) => item.status_range === "Deactive"
        // );
        // setTimeout(() => {
        //   inactiveArray.forEach((item) => {
        //     jQuery(
        //       "td:nth-child(" + item.id + "),th:nth-child(" + item.id + ")"
        //     ).hide();
        //   });
        // }, 5000);
        //excel export formula change -end
      } else {
        this.setState({
          gst_list: [],
        });
      }
    });
  };

  selected_item = e => {
    var index = e.target.selectedIndex;
    var optionElement = e.target.childNodes[index];
    let show_columns = optionElement.getAttribute('data-id');
    this.setState({ show_columns: show_columns }, () => {
      this.callAPIDATA();
    });
  };
  selected_items = e => {
    var index = e.target.selectedIndex;
    var optionElement = e.target.childNodes[index];
    let show_columns = optionElement.getAttribute('data-id');

    // this.setState({ show_columns: show_columns }, () => {
    //   this.callAPIDATA()
    // })
  };

  slected_itemid = id => {
    // alert(id);
  };
  changedatevalue(seleteddateformat) {
    var dateresult = moment();
    let from_date, to_date;
    this.setState({ date_range: seleteddateformat }, () => {
      if (seleteddateformat === 'This Month-to-date') {
        from_date = dateresult.startOf('month');
        document.getElementById('fromdate').value = from_date.format('DD-MM-YYYY');
        this.state.start_date = from_date.format('YYYY-MM-DD');
        document.getElementById('todate').value = moment(new Date()).format('DD-MM-YYYY');
        this.state.end_date = moment(new Date()).format('YYYY-MM-DD');
        this.callAPIDATA();
      } else if (seleteddateformat === 'This Week') {
        from_date = dateresult.startOf('week');
        document.getElementById('fromdate').value = from_date.format('DD-MM-YYYY');
        this.state.start_date = from_date.format('YYYY-MM-DD');
        console.log('startdate', this.state.start_date);
        to_date = dateresult.endOf('week');
        document.getElementById('todate').value = to_date.format('DD-MM-YYYY');
        this.state.end_date = to_date.format('YYYY-MM-DD');
        this.callAPIDATA();
      } else if (seleteddateformat === 'This Month') {
        from_date = dateresult.startOf('month');
        document.getElementById('fromdate').value = from_date.format('DD-MM-YYYY');
        this.state.start_date = from_date.format('YYYY-MM-DD');
        to_date = dateresult.endOf('month');
        document.getElementById('todate').value = to_date.format('DD-MM-YYYY');
        this.state.end_date = to_date.format('YYYY-MM-DD');
        this.callAPIDATA();
      } else if (seleteddateformat === 'This Week-to-date') {
        from_date = dateresult.startOf('week');
        document.getElementById('fromdate').value = from_date.format('DD-MM-YYYY');
        this.state.start_date = from_date.format('YYYY-MM-DD');
        document.getElementById('todate').value = moment(new Date()).format('DD-MM-YYYY');
        this.state.end_date = moment(new Date()).format('YYYY-MM-DD');
        this.callAPIDATA();
      } else if (seleteddateformat === 'This Year') {
        from_date = dateresult.startOf('year');
        document.getElementById('fromdate').value = from_date.format('DD-MM-YYYY');
        this.state.start_date = from_date.format('YYYY-MM-DD');
        to_date = dateresult.endOf('year');
        document.getElementById('todate').value = to_date.format('DD-MM-YYYY');
        this.state.end_date = to_date.format('YYYY-MM-DD');
        this.callAPIDATA();
      } else if (seleteddateformat === 'This Year-to-date') {
        from_date = dateresult.startOf('year');
        document.getElementById('fromdate').value = from_date.format('DD-MM-YYYY');
        this.state.start_date = from_date.format('YYYY-MM-DD');
        to_date = moment(new Date()).format('YYYY-MM-DD');
        document.getElementById('todate').value = moment(new Date()).format('DD-MM-YYYY');
        this.state.end_date = to_date;
        this.callAPIDATA();
      } else if (seleteddateformat == 'All') {
        this.setState(
          {
            start_date: '1970-01-01',
            end_date: moment().add(10, 'years').format('YYYY-MM-DD'),
          },
          () => this.callAPIDATA(),
        );
        document.getElementById('fromdate').value = '';
        document.getElementById('todate').value = '';
      }
      // let startDate = jQuery('#fromdate').val()
      // let end_date = jQuery('#todate').val()
      // this.setState({ start_date: startDate, end_date: end_date }, () => {
      //   this.callAPIDATA()
      // })
    });
  }
  changefromDate(fromdate) {
    let date = jQuery('#fromdate').val();
    if (date != undefined && date != '') {
      var array = date.split('/');
      var date_formated = array[2] + '-' + array[1] + '-' + array[0];
      this.setState({ start_date: date_formated }, () => {
        this.callAPIDATA();
      });
    }

    // this.state.start_date = moment(date).format('YYYY-MM-DD')
  }

  show_columnslist = () => {
    let report_name = 'balance_sheet';
    FetchAllApi.get_coulmnlist(report_name, (err, response) => {
      if (response.status === 1) {
        // console.log(('jsjsjsjsgsg',response.details))
        this.setState({
          show_coulmns_filter: response.details,
        });
      } else {
        this.setState({
          gst_list: [],
        });
      }
    });
  };

  onChange_filterbysubvalue = val => {
    var sub_columns;
    if (val === 2 || val === 3) {
      sub_columns = [1];
      if (val === 2) this.setState({ cadchange: true, cadpercentage: false });
      else this.setState({ cadchange: false, cadpercentage: true });
    } else {
      sub_columns = [4];
      if (val === 5) this.setState({ cadchange: true, cadpercentage: false });
      else this.setState({ cadchange: false, cadpercentage: true });
    }
    this.setState({ sub_columns: sub_columns }, () => {
      this.callAPIDATA();
      // alert(this.state.cadchange)
    });
  };

  onChange_filterby = val => {
    var sub_columns = [val];
    if (val === 1) {
      this.setState({ isChecked2: false, isChecked: true });
    } else {
      this.setState({ isChecked: false, isChecked2: true });
    }
    this.setState({ sub_columns: sub_columns }, () => {
      this.callAPIDATA();
    });
  };
  changetoDate(todate) {
    // alert(jQuery('#todate').val())
    let date = jQuery('#todate').val();
    if (date != undefined && date != '') {
      var array = date.split('/');
      var date_formated = array[2] + '-' + array[1] + '-' + array[0];
      if (array != '' && array != undefined) {
        this.setState({ end_date: date_formated }, () => {
          this.callAPIDATA();
        });
      }
    }
    // this.state.end_date = moment(date).format('YYYY-MM-DD')
    //  alert(moment(todate).format('YYYY-MM-DD'))
  }

  callAPIDATA() {
    let filter_id = this.state.result_array;
    let filter_options = {
      1: {
        condition: this.state.valueAmount_type,
        value: this.state.valueAmount,
        from: this.state.From,
        to: this.state.To,
      },
      3: {
        condition: '',
        value: '',
        from: this.state.changefromDate_duedate,
        to: this.state.todate_duedate,
      },
      5: {
        condition: '',
        value: [...this.state.selected_vendor_ids],
        from: '',
        to: '',
      },
      6: {
        condition: '',
        value: [...this.state.selectedCurrencies],
        from: '',
        to: '',
      },
      8: {
        condition: '',
        value: '',
        from: this.state.date_start,
        to: this.state.date_end,
      },

      11: { condition: '', value: this.state.text1, from: '', to: '' },
      17: { condition: '', value: this.state.text2, from: '', to: '' },
      24: { condition: '', value: this.state.text3, from: '', to: '' },
      25: { condition: '', value: this.state.text4, from: '', to: '' },
      26: { condition: '', value: this.state.text5, from: '', to: '' },
      27: { condition: '', value: this.state.text6, from: '', to: '' },
      28: { condition: '', value: this.state.text7, from: '', to: '' },

      2: {
        condition: this.state.valueAmount_type1,
        value: this.state.valueAmount1,
        from: '',
        to: '',
      },
      10: {
        condition: this.state.valueAmount_type2,
        value: this.state.valueAmount2,
        from: '',
        to: '',
      },
      13: {
        condition: this.state.valueAmount_type3,
        value: this.state.valueAmount3,
        from: '',
        to: '',
      },
      18: {
        condition: this.state.valueAmount_type4,
        value: this.state.valueAmount4,
        from: '',
        to: '',
      },
      22: {
        condition: '',
        value: [...this.state.selectedTerms],
        from: '',
        to: '',
      },
      23: {
        condition: '',
        value: [...this.state.selectedVendor_type],
        from: '',
        to: '',
      },
      29: {
        condition: '',
        value: [...this.state.selectedCustomer_type],
        from: '',
        to: '',
      },

      50: {
        condition: '',
        value: [...this.state.selectedAccountIds],
        from: '',
        to: '',
      },

      32: {
        condition: this.state.valueAmount_type4,
        value: this.state.valueAmount4,
        from: this.state.From4,
        to: this.state.To4,
      },
    };

    this.setState({ loading: true });
    let { start_date, end_date, show_columns, sub_columns, logged_client_id, sort_type, selectedName } = this.state;
    console.log('start date', start_date);
    console.log('End date', end_date);
    // alert(this.state.logged_client_id);

    if (!this.state.date_range || this.state.date_range == 'All') {
      this.setState({
        export_file_name_date_range: 'All',
      });
    } else if (this.state.end_date) {
      let s_date_str = moment('1970-01-01').format(this.state.client_date_format);
      let e_date_str = moment(this.state.end_date).format(this.state.client_date_format);
      let name = s_date_str + '-TO-' + e_date_str;
      this.setState({
        export_file_name_date_range: name,
      });
    }

    FetchAllApi.open_invoices(
      (start_date = '1970-01-01'),
      end_date,
      show_columns,
      logged_client_id,
      sub_columns,
      filter_id,
      filter_options,
      selectedName,
      sort_type,
      this.state.is_current_date_report,
      (err, response) => {
        if (response.status === 1) {
          var chars = Object.values(response.details);

          //excel export formula - 2023-05-31
          this.get_col();
          //excel export formula end

          chars = _.orderBy(chars, ['customer_name'], ['asc']); // Use Lodash to sort array by 'name'
          // console.log("kkkkkkkjjj", chars);
          this.setState(
            {
              response: chars,
              loading: false,
              fullResponse: response,
              parent_job_object: response.parent_job_object,
            },
            () => {
              if (this.state.is_export == 1) {
                setTimeout(() => {
                  this.setState({
                    is_export: false,
                  });
                  if (this.state.export_type == 'pdf') {
                    this.generate();
                  } else if (this.state.export_type == 'excel') {
                    jQuery('#test-table-xls-button').trigger('click');
                  }
                }, 500);
              }
            },
          );
          console.log('1111111res1', response.overall_total_home_debit);

          // console.log
        } else {
          this.setState({
            response: [],
            customername: [],
            fullResponse: {},
            loading: false,
            is_export: false,
            parent_job_object: {},
          });
        }
      },
    );

    FetchAllApi.filter_column(
      this.state.all_report_name_id,
      this.state.logged_client_id,
      this.state.filter_key_names,
      (errResponse, filtervalue) => {
        console.log('Filter Result', filtervalue);
        this.setState({ filtervalue: filtervalue });
      },
    );
  }
  pageLink(page_slug) {
    this.props.history.push('/' + page_slug);
  }
  changetoDate_duedate(todate) {
    // alert(jQuery('#todate').val())
    let date = jQuery('#todate_duedate').val();
    if (date != undefined && date != '') {
      var array = date.split('/');
      var date_formated = array[2] + '-' + array[1] + '-' + array[0];
      if (array != '' && array != undefined) {
        this.setState({ todate_duedate: date_formated }, () => {
          this.callAPIDATA();
        });
      }
    }
  }
  changefromDate_duedate(fromdate) {
    let date = jQuery('#fromdate_duedate').val();
    console.log('fromdate RTEdsadaasdadasdadad', date);
    if (date != undefined && date != '') {
      var array = date.split('/');
      var date_formated = array[2] + '-' + array[1] + '-' + array[0];
      if (array != '' && array != undefined) {
        this.setState({ changefromDate_duedate: date_formated }, () => {
          this.callAPIDATA();
        });
      }
    }
  }
  multiSelectedCurrency = cur => {
    //alert(jQuery('#slectedCurrency').val())
    this.setState({ selectedCurrencies: jQuery('#slectedCurrency').val() }, () => {
      this.callAPIDATA();
    });
  };
  customRadioChange = x => {
    this.setState({ valueAmount_type: x });
  };

  get_currencies = () => {
    // fetch("https://api.exchangerate-api.com/v4/latest/SGD")
    fetch(`https://api.currencylayer.com/live?access_key=${config.api_key}&source=SGD`)
      .then(response => response.json())
      .then(data => {
        let newObj = this.rename(data.quotes, 'SGD');

        const currencyAr = [];
        let first = newObj;
        for (const key in first) {
          currencyAr.push(key);
        }
        //this.setState({ currencies: currencyAr, currency_clone: currencyAr });
      });
  };
  get_vendorNames = () => {
    let client_id = this.state.logged_client_id;
    // alert('hjgh')
    FetchAllApi.getCustomerNames(client_id, (err, response) => {
      if (response.status === 1) {
        this.setState({ vendorNames: response.list });
      } else {
      }
    });
  };
  selected_filters = e => {
    var result = [];
    var options = e.target.options;
    var opt;
    var j = 0;
    for (var i = 0, iLen = options.length; i < iLen; i++) {
      opt = options[i];

      if (opt.selected) {
        result[j] = Number(opt.value);
        j++;
      }
    }
    this.setState({ result_array: result }, () => {
      this.callAPIDATA();
    });
    if (result.length > 0) {
      if (result.includes(1)) {
        this.setState({ selectedFil: 1 });
      }
      if (result.includes(2)) {
        this.setState({ selectedFil: 2 });
      }
      if (result.includes(3)) {
        this.setState({ selectedFil: 3 });
      }
      if (result.includes(5)) {
        this.setState({ selectedFil: 5 });
      }
      if (result.includes(6)) {
        this.setState({ selectedFil: 6 });
      }
      if (result.includes(8)) {
        this.setState({ selectedFil: 8 });
      }
      if (result.includes(10)) {
        this.setState({ selectedFil: 10 });
      }
      if (result.includes(11)) {
        this.setState({ selectedFil: 11 });
      }

      if (result.includes(13)) {
        this.setState({ selectedFil: 13 });
      }
      if (result.includes(17)) {
        this.setState({ selectedFil: 17 });
      }
      if (result.includes(18)) {
        this.setState({ selectedFil: 18 });
      }
      if (result.includes(22)) {
        this.setState({ selectedFil: 22 });
      }

      if (result.includes(23)) {
        this.setState({ selectedFil: 23 });
      }
      if (result.includes(24)) {
        this.setState({ selectedFil: 24 });
      }
      if (result.includes(25)) {
        this.setState({ selectedFil: 25 });
      }
      if (result.includes(26)) {
        this.setState({ selectedFil: 26 });
      }
      if (result.includes(27)) {
        this.setState({ selectedFil: 27 });
      }
      if (result.includes(28)) {
        this.setState({ selectedFil: 28 });
      }
      if (result.includes(29)) {
        this.setState({ selectedFil: 29 });
      }

      if (result.includes(32)) {
        this.setState({ selectedFil: 32 });
      }

      if (result.includes(50)) {
        this.setState({ selectedFil: 50 });
      }
    } else {
      this.setState({ selectedFil: 0 });
    }
  };

  changeText = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: this.state.valueAmount_type,
            value: this.state.valueAmount,
            from: this.state.From,
            to: this.state.To,
          },
          filter_options1: {
            condition: this.state.valueAmount_type,
            value: this.state.valueAmount,
            from: this.state.From,
            to: this.state.To,
          },
        },
        () => {
          this.callAPIDATA();
        },
      );
    });
  };
  selectedVendorIds = e => {
    var result = [];

    this.setState({ selected_vendor_ids: result }, () => {
      this.callAPIDATA();
    });

    var options = e.target.options;
    var opt;
    for (var i = 0, iLen = options.length; i < iLen; i++) {
      opt = options[i];

      if (opt.selected) {
        var vas = { customer_id: opt.value || opt.text, job_id: 0 };
        result.push(vas);
      } else {
      }
    }
  };
  convertToarray(str) {
    console.log('subcat string', str);
    console.log('Sub Cat', Object.values(str));
    return Object.values(str);
  }

  show_coulmn_filter = e => {
    var names = [];
    var result = [];
    var options = e.target.options;
    var opt;
    for (var i = 0, iLen = options.length; i < iLen; i++) {
      opt = options[i];

      if (opt.selected) {
        let value = JSON.parse(opt.value);

        result.push(value.b || opt.text);
        names.push(value.a || opt.text);

        // result.push(opt.value || opt.text)
        var optvals = value.b + 1;
        jQuery('td:nth-child(' + optvals + '),th:nth-child(' + optvals + ')').show();
      } else {
        let value = JSON.parse(opt.value);
        var optvals = value.b + 1;
        // var optvals = parseInt(opt.value) + 1
        jQuery('td:nth-child(' + optvals + '),th:nth-child(' + optvals + ')').hide();
      }
    }
    this.setState({ ColscountArray: result }, this.filterFilters(names));
  };

  filterFilters = result => {
    let filter_key_names = [];
    this.state.coulmns_head &&
      this.state.coulmns_head !== undefined &&
      this.state.coulmns_head.map((item, i) => {
        console.log('filter_key_names', item);
        console.log('filter_key_w', result);

        if (result.includes(item.id)) filter_key_names.push(`'${item.filter_key_name}'`);
      });
    console.log('filter_key_wq', filter_key_names);
    this.setState({ filter_key_names: filter_key_names });
    // this.callAPIDATA()
    FetchAllApi.filter_column(
      this.state.all_report_name_id,
      this.state.logged_client_id,
      filter_key_names,
      (errResponse, filtervalue) => {
        console.log('Fijkjlter Result', filtervalue);
        this.setState({ filtervalue: filtervalue });
      },
    );
  };

  changevaluetotals() {
    this.state.changetotal1 = this.state.changetotal;
    this.state.changetotal = 0;
  }
  changevaluetotalsx(value) {
    this.state.changetotal = parseInt(this.state.changetotal) - parseInt(value);
  }
  renderRows = mapArray => {
    return mapArray.map(overallinvoice => {
      return (
        <React.Fragment>
          {/* <tr className='item-step1 istep-3' key={overallinvoice}>
            <td className=''>
              <span></span>
            </td>

            <td className='trans'>
              <span>{overallinvoice.invoice_id}</span>
            </td>
            <td className='lastmodified'>
              <span>{overallinvoice.lastmodified}</span>
            </td>
            <td className='lastmodifiedby'>
              <span>{overallinvoice.lastmodifiedby}</span>
            </td>
            <td className='num'>
              <span>{overallinvoice.invoice_number}</span>
            </td>
            <td className='memo'>
              <span>{overallinvoice.memo}</span>
            </td>
            <td className='account'>
              <span>{overallinvoice.account}</span>
            </td>
            <td className=''>
              <span>{overallinvoice.open_balance_home_currency}</span>
            </td>
            <td className=''>
              <span>{overallinvoice.debit}</span>
            </td>
            <td className=''>
              <span>{overallinvoice.credit}</span>
            </td>
            <td className=''>
              <span>{overallinvoice.open_balance_home_currency}</span>
            </td>
            <td className=''>
              <span>{overallinvoice.foreign_currency}</span>
            </td>
            <td className=''>
              <span>{overallinvoice.exchange_rate}</span>
            </td>
            <td className=''>
              <span>{overallinvoice.grand_total_foreign_currency}</span>
            </td>
            <td className=''>
              <span>{overallinvoice.open_balance_foreign_currency}</span>
            </td>
            <td className=''>
              <span>{overallinvoice.foreign_balance}</span>
            </td>
            <td className=''>
              <span>
                {overallinvoice.split != '' ? overallinvoice.split : ''}
              </span>
            </td>
            <td className=''>
              <span>
                {overallinvoice.aging != '' ? overallinvoice.aging : ''}
              </span>
            </td>
            <td className=''>
              <span>{overallinvoice.terms}</span>
            </td>
            <td className=''>
              <span>{overallinvoice.contact}</span>
            </td>
            <td className=''>
              <span>{overallinvoice.postal_code}</span>
            </td>
            <td className=''>
              <span>{overallinvoice.province}</span>
            </td>
            <td className=''>
              <span>{overallinvoice.city}</span>
            </td>
            <td className=''>
              <span>{overallinvoice.address}</span>
            </td>
            <td className=''>
              <span>{overallinvoice.email}</span>
            </td>
            <td className=''>
              <span>{overallinvoice.phone}</span>
            </td>
            <td className=''>
              <span>{overallinvoice.job_name}</span>
            </td>
            <td className=''>
              <span>{overallinvoice.fax}</span>
            </td>
            <td className=''>
              <span>{overallinvoice.type_name}</span>
            </td>
          </tr> */}
        </React.Fragment>
      );
    });
  };

  render() {
    console.log('oiiiioioi', this.state.fullResponse);
    // let balance_sheet_data = this.state.balance_sheet_data
    // let total = 0

    return (
      <div>
        <div className="container-fluid">
          <div className="row">
            <LeftSidebar history={this.props.history} pageSubmit={e => this.pageLink(e)} />

            {/* MainContent Wrapper Starts here */}
            <div className="main-wrap col-md-12 col-xs-12 pad-r-no">
              <div className="top-bar col-md-12 col-xs-12 pad-r-no">
                <div className="nav-brand-res visible-xs">
                  <img className="img-responsive" src="../images/logo-icon.png" alt="LogoIcon" />
                </div>
                <span className="page-title hidden-xs">Customer Open Invoice</span>

                <Topbar history={this.props.history} logoutSubmit={e => this.logoutLink()} />
              </div>

              <div className="col-md-12 col-xs-12 mar-top visible-xs">
                <a href="javascript:;" className="back">
                  <img src="images/back-arrow-blue.svg" />
                </a>
                <span className="page-title">Customer Open Invoice</span>
              </div>
              {/* content-top Starts here */}
              <div className="content-top col-md-12 col-xs-12">
                <h4 className="fw-sbold mar-t-no">Customer Open Invoices</h4>
                <h5 className="fw-sbold">{/* {moment(new Date()).format("MMM YYYY")} */}</h5>
              </div>

              <div className="main-content col-md-12 col-xs-12">
                <div className="content-sec col-md-12 col-xs-12 pad-no mar-t-no">
                  <div className="report-setting">
                    <form className="custom-form form-inline">
                      <div className="form-group mar-rgt">
                        <label>Date Range</label>
                        <div className="form-cont">
                          <select
                            id="custom"
                            className="selectpicker form-control hh "
                            value={this.state.date_range}
                            data-live-search="true"
                            onChange={e => this.changedatevalue(e.target.value)}
                            disabled
                          >
                            <option value="All">All</option>
                            <option value="Custom">Custom</option>
                            <option value="This Month-to-date">This Month-to-date</option>
                            <option value="This Week">This Week</option>
                            <option value="This Month">This Month</option>
                            <option value="This Week-to-date">This Week-to-date</option>
                            <option value="This Year">This Year</option>
                            <option value="This Year-to-date">This Year-to-date</option>
                          </select>
                        </div>
                      </div>
                      {/* <div className="form-group mar-rgt">
                        <label>From</label>
                        <div
                          className="input-group date mar-t-no"
                          data-date-format="dd/mm/yyyy"
                        >
                          <input
                            type="text"
                            id="fromdate"
                            onBlur={(e) => {
                              let value = e.target.value
                              this.setState({date_range: "Custom"})
                              jQuery("#custom").val("Custom")
                              setTimeout(() => { this.changefromDate(value) }, 500)
                            }}
                            className="form-control"
                            autoComplete="off"
                          />
                          <div className="input-group-addon" onClick={() => jQuery('#fromdate').focus()} >
                            <img src="images/calendar-icon.svg" alt="icon" />
                          </div>
                        </div>
                      </div> */}
                      <div className="form-group mar-rgt">
                        <label>Select Date</label>
                        <div className="input-group date mar-t-no" data-date-format="dd/mm/yyyy">
                          <input
                            type="text"
                            id="todate"
                            onBlur={e => {
                              let value = e.target.value;
                              this.setState({ date_range: 'Custom' });
                              jQuery('#custom').val('Custom');
                              setTimeout(() => {
                                this.changetoDate(value);
                              }, 500);
                            }}
                            className="form-control"
                            autoComplete="off"
                          />
                          <div className="input-group-addon" onClick={() => jQuery('#todate').focus()}>
                            <img src="images/calendar-icon.svg" alt="icon" />
                          </div>
                        </div>
                      </div>
                      <a href="javascript:;" className="text-link filter-btn">
                        Advanced
                      </a>
                      <a
                        href="javascript:;"
                        className="fa fa-refresh"
                        onClick={() => {
                          this.callAPIDATA();
                        }}
                      >
                        <img src="images/refresh.svg" style={{ width: '20px', marginLeft: '10px' }} />
                      </a>
                    </form>
                    <div className="pull-right">
                      <div className="dropdown menu-item new-cus">
                        <button className="btn btn-green dropdown-toggle btn-arrow" data-toggle="dropdown" aria-expanded="false">
                          Export
                          <span className="caret" />
                        </button>
                        <ul className="dropdown-menu align-right">
                          <li>
                            <a href="javascript:void(0);" onClick={this.generate}>
                              Export as PDF
                            </a>
                          </li>
                          <li>
                            {/* <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="excel-button"
                            table="mytable"
                            filename={`Customer-open-invoices-`+this.state.export_file_name_date_range}
                            sheet={`Customer-open-invoices-`+this.state.export_file_name_date_range}
                            buttonText="Export as Excel"/> */}

                            {/* excel export with formula - starts */}
                            <a href="javascript:void(0);">
                              <button
                                className="btn btn-success"
                                id="test-table-xls-button"
                                onClick={() => {
                                  let table = document.getElementById('mytable');
                                  let html = table.outerHTML;
                                  this.htmlToJson(html);
                                }}
                              >
                                Export as Excel
                              </button>
                            </a>
                            {/* excel export with formula - end */}
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="col-md-12 col-xs-12 report-filter">
                      <a href="javascript:;" className="close-btn">
                        <img src="images/icons8-minus.png" />
                      </a>

                      <form className="custom-form">
                        <div className="col-lg-4 col-md-12 pad-l-no">
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div className="row">
                                <div className="col-lg-5 col-md-3">
                                  <label className="fw-sbold">Report Type</label>
                                </div>
                                <div className="col-lg-7 col-md-9">
                                  <label className="custom-checkbox radio mar-t-no mar-rgt">
                                    <input
                                      type="radio"
                                      name="tax-item"
                                      checked={this.state.is_current_date_report == 1 ? true : false}
                                      onChange={e => {
                                        if (e.target.checked) {
                                          this.setState({ is_current_date_report: 1 }, () => this.callAPIDATA());
                                        }
                                      }}
                                    />{' '}
                                    Current Date
                                    <span className="checkmark" />
                                  </label>
                                  <label className="custom-checkbox radio">
                                    <input
                                      type="radio"
                                      name="tax-item"
                                      checked={this.state.is_current_date_report == 2 ? true : false}
                                      onChange={e => {
                                        if (e.target.checked) {
                                          this.setState({ is_current_date_report: 2 }, () => this.callAPIDATA());
                                        }
                                      }}
                                    />{' '}
                                    Report Date
                                    <span className="checkmark" />
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="form-group col-md-12 col-xs-12">
                              <div className="row">
                                <div className="col-lg-5 col-md-3">
                                  <label className="fw-sbold">Show Columns</label>
                                </div>
                                <div className="col-lg-7 col-md-9">
                                  <div className="custom-select-drop dropdown">
                                    {this.state.coulmns_head && this.state.coulmns_head !== undefined && (
                                      <select
                                        className="selectpicker"
                                        id="myselect"
                                        multiple
                                        data-live-search="true"
                                        onChange={e => this.show_coulmn_filter(e)}
                                      >
                                        {this.state.coulmns_head &&
                                          this.state.coulmns_head !== undefined &&
                                          this.state.coulmns_head &&
                                          this.state.coulmns_head.map((item, i) => {
                                            let statusSelected = '';
                                            if (item.status === 1) statusSelected = 'selected';
                                            let object = {
                                              a: item.id,
                                              b: i + 1,
                                            };
                                            return (
                                              <option value={JSON.stringify(object)} selected={statusSelected}>
                                                {item.heading_name}
                                              </option>
                                            );
                                          })}
                                      </select>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="form-group col-md-12 col-xs-12">
                              <div className="row">
                                <div className="col-lg-5 col-md-3">
                                  <label className="fw-sbold">Filter by</label>
                                </div>
                                <div className="col-lg-7 col-md-9">
                                  <div className="custom-select-drop dropdown">
                                    <select
                                      className="selectpicker"
                                      multiple
                                      data-live-search="true"
                                      onChange={e => this.selected_filters(e)}
                                    >
                                      {/* { this.state.coulmns_head && this.state.coulmns_head !==undefined && 
                this.state.coulmns_head && 
                
                this.state.coulmns_head.map((item,i)=>{
                let statusSelected="";
                if(item.status === 1)
                    statusSelected="selected"

                return(<option value={ i+1 } selected={ statusSelected } >{item.heading_name}</option>)
                

                }) } */}
                                      {this.state.filtervalue &&
                                        this.state.filtervalue.name &&
                                        this.state.filtervalue.name.map((item, index) => {
                                          return (
                                            <option key={index} id={item.filter_name} data-id={item.id} value={item.id}>
                                              {item.filter_name}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="form-group col-md-12 col-xs-12 mar-b-no">
                              <div className="row">
                                <div className="col-lg-5 col-md-3">
                                  <label className="fw-sbold">Sort By</label>
                                </div>
                                <div className="col-lg-7 col-md-9">
                                  <div id="currency_selected">
                                    <select
                                      className="selectpicker form-control"
                                      id="customer_type"
                                      data-live-search="true"
                                      onChange={e => {
                                        this.setState({ selectedName: e.target.value });
                                        setTimeout(() => {
                                          this.callAPIDATA();
                                        }, 500);
                                      }}
                                    >
                                      <option selected={true}>Choose</option>
                                      {this.state.sortBynames &&
                                        this.state.sortBynames.map(item => {
                                          return (
                                            <React.Fragment>
                                              <option value={item.column_key}>{item.name}</option>
                                            </React.Fragment>
                                          );
                                        })}
                                    </select>
                                  </div>

                                  <div className="sort-by-icon-margin-pl" style={{ float: 'right' }}>
                                    <a
                                      href="#"
                                      onClick={e => {
                                        e.preventDefault();
                                        let a = this.state.sort_type == 'desc' ? 'asc' : 'desc';
                                        this.setState({ sort_type: a, type: !this.state.type });
                                        setTimeout(() => {
                                          if (this.state.selectedName != '') {
                                            this.callAPIDATA();
                                          }
                                        }, 500);
                                      }}
                                    >
                                      {/* {this.state.sort_type == "desc" ? "asc" : "desc"} */}
                                      {this.state.type ? (
                                        <FontAwesomeIcon icon="fas fa-sort-amount-up" />
                                      ) : (
                                        <FontAwesomeIcon icon="fas fa-sort-amount-down" />
                                      )}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-1 col-md-12 pad-r-no">
                          <div className="row"></div>
                        </div>
                        {this.state.selectedFil == 5 && (
                          <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                            <div className="row">
                              <div className="form-group col-md-12 col-xs-12">
                                <div id={1} style={{ display: 'block' }}>
                                  <div className="custom-select-drop dropdown">
                                    <label>Name &nbsp;</label>
                                    <select
                                      className="selectpicker"
                                      multiple
                                      data-live-search="true"
                                      onChange={e => this.selectedVendorIds(e)}
                                    >
                                      {this.state.vendorNames &&
                                        this.state.vendorNames.map((item, index) => {
                                          return (
                                            <option key={index} id={item.id} data-id={item.id} value={item.id}>
                                              {item.name}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>

                                  {this.state.disable && (
                                    <div>
                                      <div></div>
                                      <div></div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {this.state.selectedFil === 3 && (
                          <div className="col-lg-4 col-md-12 pad-r-no">
                            <div className="row">
                              <div className="form-group mar-rgt">
                                <label>From</label>
                                <div className="input-group date mar-t-no" data-date-format="dd/mm/yyyy">
                                  <input
                                    type="text"
                                    id="fromdate_duedate"
                                    onBlur={e => {
                                      let value = e.target.value;
                                      setTimeout(() => {
                                        this.changefromDate_duedate(value);
                                      }, 500);
                                    }}
                                    className="form-control"
                                    autoComplete="off"
                                    style={{ height: '43px' }}
                                  />
                                  <div className="input-group-addon" onClick={() => jQuery('#fromdate_duedate').focus()}>
                                    <img src="images/calendar-icon.svg" alt="icon" />
                                  </div>
                                </div>
                              </div>
                              <div className="form-group mar-rgt">
                                <label>To</label>
                                <div className="input-group date mar-t-no" data-date-format="dd/mm/yyyy">
                                  <input
                                    type="text"
                                    id="todate_duedate"
                                    onBlur={e => {
                                      let value = e.target.value;
                                      setTimeout(() => {
                                        this.changetoDate_duedate(value);
                                      }, 500);
                                    }}
                                    className="form-control"
                                    autoComplete="off"
                                    style={{ height: '43px' }}
                                  />
                                  <div className="input-group-addon" onClick={() => jQuery('#todate_duedate').focus()}>
                                    <img src="images/calendar-icon.svg" alt="icon" />
                                  </div>
                                </div>
                              </div>
                            </div>{' '}
                          </div>
                        )}

                        {this.state.selectedFil === 8 && (
                          <div className="col-lg-4 col-md-12 pad-r-no">
                            <div className="row">
                              <div className="form-group mar-rgt">
                                <label>From</label>
                                <div className="input-group date mar-t-no" data-date-format="dd/mm/yyyy">
                                  <input
                                    type="text"
                                    id="fromdate1"
                                    onBlur={e => {
                                      let value = e.target.value;
                                      setTimeout(() => {
                                        this.changefromDate1(value);
                                      }, 500);
                                    }}
                                    className="form-control"
                                    autoComplete="off"
                                    style={{ height: '43px' }}
                                  />
                                  <div className="input-group-addon" onClick={() => jQuery('#fromdate1').focus()}>
                                    <img src="images/calendar-icon.svg" alt="icon" />
                                  </div>
                                </div>
                              </div>
                              <div className="form-group mar-rgt">
                                <label>To</label>
                                <div className="input-group date mar-t-no" data-date-format="dd/mm/yyyy">
                                  <input
                                    type="text"
                                    id="todate1"
                                    onBlur={e => {
                                      let value = e.target.value;
                                      setTimeout(() => {
                                        this.changetoDate1(value);
                                      }, 500);
                                    }}
                                    className="form-control"
                                    autoComplete="off"
                                    style={{ height: '43px' }}
                                  />
                                  <div className="input-group-addon" onClick={() => jQuery('#todate1').focus()}>
                                    <img src="images/calendar-icon.svg" alt="icon" />
                                  </div>
                                </div>
                              </div>
                            </div>{' '}
                          </div>
                        )}
                        {this.state.selectedFil == 6 && (
                          <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                            <div className="row">
                              <div className="form-group col-md-12 col-xs-12">
                                <div id={1} style={{ display: 'block' }}>
                                  <div className="custom-select-drop dropdown">
                                    <label>Currency</label>
                                    <select
                                      className="selectpicker"
                                      multiple
                                      data-live-search="true"
                                      id="slectedCurrency"
                                      onChange={e => {
                                        this.multiSelectedCurrency(e.target.value);
                                      }}
                                    >
                                      {this.state.currencies &&
                                        this.state.currencies.map((item, index) => {
                                          return (
                                            <option key={index} id={item.filter_name} data-id={item.id} value={item.id}>
                                              {item}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>

                                  {this.state.disable && (
                                    <div>
                                      <div></div>
                                      <div></div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {this.state.selectedFil === 1 && (
                          <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                            <div className="row">
                              <div className="form-group col-md-12 col-xs-12">
                                <div id={1} style={{ display: 'block' }}>
                                  <label className="custom-checkbox mar-rgt">
                                    <input
                                      type="radio"
                                      id="male"
                                      name="gender"
                                      defaultValue={'='}
                                      onChange={e => {
                                        this.customRadioChange(e.target.value);
                                      }}
                                    />
                                    =
                                    <span className="checkmark" />
                                  </label>
                                  <label className="custom-checkbox mar-rgt">
                                    <input
                                      type="radio"
                                      id="male"
                                      name="gender"
                                      defaultValue={'<='}
                                      onChange={e => {
                                        this.customRadioChange(e.target.value);
                                      }}
                                    />
                                    &lt;=
                                    <span className="checkmark" />
                                  </label>
                                  <label className="custom-checkbox mar-rgt">
                                    <input
                                      type="radio"
                                      id="male"
                                      name="gender"
                                      defaultValue={'>='}
                                      onChange={e => {
                                        this.customRadioChange(e.target.value);
                                      }}
                                    />
                                    &gt;=
                                    <span className="checkmark" />
                                  </label>
                                  {/* <label className="custom-checkbox mar-rgt">
                                    <input
                                      type="radio"
                                      id="male"
                                      name="gender"
                                      defaultValue={"true"}
                                      onChange={(e) => {
                                        this.customRadioChange(e.target.value);
                                      }}
                                    />
                                    any
                                    <span className="checkmark" />
                                  </label> */}
                                  <div>
                                    <input
                                      type="text"
                                      id="male"
                                      name="valueAmount"
                                      className="form-control"
                                      onChange={this.changeText}
                                      style={{ width: '128px' }}
                                    />
                                  </div>

                                  <div>
                                    <div>
                                      <label>From</label>
                                      <input
                                        type="text"
                                        id="male"
                                        name="From"
                                        className="form-control"
                                        style={{ width: '128px' }}
                                        onChange={this.changeText}
                                      />
                                    </div>
                                    <div>
                                      <label>To</label>
                                      <input
                                        type="text"
                                        id="male"
                                        name="To"
                                        onChange={this.changeText}
                                        className="form-control"
                                        style={{ width: '128px' }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {this.state.selectedFil === 2 && (
                          <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                            <div className="row">
                              <div className="form-group col-md-12 col-xs-12">
                                <div id={1} style={{ display: 'block' }}>
                                  <label className="custom-checkbox mar-rgt">
                                    <input
                                      type="radio"
                                      id="male"
                                      name="gender"
                                      defaultValue={'='}
                                      onChange={e => {
                                        this.customRadioChange1(e.target.value);
                                      }}
                                    />
                                    =
                                    <span className="checkmark" />
                                  </label>
                                  <label className="custom-checkbox mar-rgt">
                                    <input
                                      type="radio"
                                      id="male"
                                      name="gender"
                                      defaultValue={'<='}
                                      onChange={e => {
                                        this.customRadioChange1(e.target.value);
                                      }}
                                    />
                                    &lt;=
                                    <span className="checkmark" />
                                  </label>
                                  <label className="custom-checkbox mar-rgt">
                                    <input
                                      type="radio"
                                      id="male"
                                      name="gender"
                                      defaultValue={'>='}
                                      onChange={e => {
                                        this.customRadioChange1(e.target.value);
                                      }}
                                    />
                                    &gt;=
                                    <span className="checkmark" />
                                  </label>
                                  <label className="custom-checkbox mar-rgt">
                                    <input
                                      type="radio"
                                      id="male"
                                      name="gender"
                                      defaultValue={'true'}
                                      onChange={e => {
                                        this.customRadioChange1(e.target.value);
                                      }}
                                    />
                                    any
                                    <span className="checkmark" />
                                  </label>
                                  <div>
                                    <input
                                      type="text"
                                      id="male"
                                      name="valueAmount1"
                                      className="form-control"
                                      onChange={this.changeText1}
                                      style={{ width: '128px' }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {this.state.selectedFil === 10 && (
                          <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                            <div className="row">
                              <div className="form-group col-md-12 col-xs-12">
                                <div>
                                  <div>
                                    <label>Number</label>
                                    <input
                                      type="text"
                                      id="male"
                                      name="number_from"
                                      className="form-control"
                                      style={{ width: '128px' }}
                                      onChange={this.changeText_Num}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div className="row">
                              <div className="form-group col-md-12 col-xs-12">
                                <div id={1} style={{ display: "block" }}>
                                  <label className="custom-checkbox mar-rgt">
                                    <input
                                      type="radio"
                                      id="male"
                                      name="gender"
                                      defaultValue={"="}
                                      onChange={(e) => {
                                        this.customRadioChange2(e.target.value);
                                      }}
                                    />
                                    =
                                    <span className="checkmark" />
                                  </label>
                                  <label className="custom-checkbox mar-rgt">
                                    <input
                                      type="radio"
                                      id="male"
                                      name="gender"
                                      defaultValue={"<="}
                                      onChange={(e) => {
                                        this.customRadioChange2(e.target.value);
                                      }}
                                    />
                                    &lt;=
                                    <span className="checkmark" />
                                  </label>
                                  <label className="custom-checkbox mar-rgt">
                                    <input
                                      type="radio"
                                      id="male"
                                      name="gender"
                                      defaultValue={">="}
                                      onChange={(e) => {
                                        this.customRadioChange2(e.target.value);
                                      }}
                                    />
                                    &gt;=
                                    <span className="checkmark" />
                                  </label>
                                  <label className="custom-checkbox mar-rgt">
                                    <input
                                      type="radio"
                                      id="male"
                                      name="gender"
                                      defaultValue={"true"}
                                      onChange={(e) => {
                                        this.customRadioChange2(e.target.value);
                                      }}
                                    />
                                    any
                                    <span className="checkmark" />
                                  </label>
                                  <div>
                                    <input
                                      type="text"
                                      id="male"
                                      name="valueAmount2"
                                      className="form-control"
                                      onChange={this.changeText2}
                                      style={{ width: "128px" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        )}
                        {this.state.selectedFil === 13 && (
                          <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                            <div className="row">
                              <div className="form-group col-md-12 col-xs-12">
                                <div id={1} style={{ display: 'block' }}>
                                  <label className="custom-checkbox mar-rgt">
                                    <input
                                      type="radio"
                                      id="male"
                                      name="gender"
                                      defaultValue={'='}
                                      onChange={e => {
                                        this.customRadioChange3(e.target.value);
                                      }}
                                    />
                                    =
                                    <span className="checkmark" />
                                  </label>
                                  <label className="custom-checkbox mar-rgt">
                                    <input
                                      type="radio"
                                      id="male"
                                      name="gender"
                                      defaultValue={'<='}
                                      onChange={e => {
                                        this.customRadioChange3(e.target.value);
                                      }}
                                    />
                                    &lt;=
                                    <span className="checkmark" />
                                  </label>
                                  <label className="custom-checkbox mar-rgt">
                                    <input
                                      type="radio"
                                      id="male"
                                      name="gender"
                                      defaultValue={'>='}
                                      onChange={e => {
                                        this.customRadioChange3(e.target.value);
                                      }}
                                    />
                                    &gt;=
                                    <span className="checkmark" />
                                  </label>
                                  <label className="custom-checkbox mar-rgt">
                                    <input
                                      type="radio"
                                      id="male"
                                      name="gender"
                                      defaultValue={'true'}
                                      onChange={e => {
                                        this.customRadioChange3(e.target.value);
                                      }}
                                    />
                                    any
                                    <span className="checkmark" />
                                  </label>
                                  <div>
                                    <input
                                      type="text"
                                      id="male"
                                      name="valueAmount3"
                                      className="form-control"
                                      onChange={this.changeText3}
                                      style={{ width: '128px' }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {this.state.selectedFil === 18 && (
                          <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                            <div className="row">
                              <div className="form-group col-md-12 col-xs-12">
                                <div id={1} style={{ display: 'block' }}>
                                  <label className="custom-checkbox mar-rgt">
                                    <input
                                      type="radio"
                                      id="male"
                                      name="gender"
                                      defaultValue={'='}
                                      onChange={e => {
                                        this.customRadioChange4(e.target.value);
                                      }}
                                    />
                                    =
                                    <span className="checkmark" />
                                  </label>
                                  <label className="custom-checkbox mar-rgt">
                                    <input
                                      type="radio"
                                      id="male"
                                      name="gender"
                                      defaultValue={'<='}
                                      onChange={e => {
                                        this.customRadioChange4(e.target.value);
                                      }}
                                    />
                                    &lt;=
                                    <span className="checkmark" />
                                  </label>
                                  <label className="custom-checkbox mar-rgt">
                                    <input
                                      type="radio"
                                      id="male"
                                      name="gender"
                                      defaultValue={'>='}
                                      onChange={e => {
                                        this.customRadioChange4(e.target.value);
                                      }}
                                    />
                                    &gt;=
                                    <span className="checkmark" />
                                  </label>
                                  <label className="custom-checkbox mar-rgt">
                                    <input
                                      type="radio"
                                      id="male"
                                      name="gender"
                                      defaultValue={'true'}
                                      onChange={e => {
                                        this.customRadioChange4(e.target.value);
                                      }}
                                    />
                                    any
                                    <span className="checkmark" />
                                  </label>
                                  <div>
                                    <input
                                      type="text"
                                      id="male"
                                      name="valueAmount4"
                                      className="form-control"
                                      onChange={this.changeText4}
                                      style={{ width: '128px' }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {this.state.selectedFil === 11 && (
                          <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                            <div className="row">
                              <div className="form-group col-md-12 col-xs-12">
                                <div>
                                  <label>memo</label>
                                  <input
                                    type="text"
                                    id="male"
                                    name="text1"
                                    className="form-control"
                                    onChange={this.text1}
                                    style={{ width: '128px' }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {this.state.selectedFil === 17 && (
                          <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                            <div className="row">
                              <div className="form-group col-md-12 col-xs-12">
                                <div>
                                  <label>Exchange rate</label>
                                  <input
                                    type="text"
                                    id="male"
                                    name="text2"
                                    className="form-control"
                                    onChange={this.text2}
                                    style={{ width: '128px' }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {this.state.selectedFil === 24 && (
                          <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                            <div className="row">
                              <div className="form-group col-md-12 col-xs-12">
                                <div>
                                  <label>address</label>
                                  <input
                                    type="text"
                                    id="male"
                                    name="text3"
                                    className="form-control"
                                    onChange={this.text3}
                                    style={{ width: '128px' }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {this.state.selectedFil === 25 && (
                          <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                            <div className="row">
                              <div className="form-group col-md-12 col-xs-12">
                                <div>
                                  <label>website</label>
                                  <input
                                    type="text"
                                    id="male"
                                    name="text4"
                                    className="form-control"
                                    onChange={this.text4}
                                    style={{ width: '128px' }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {this.state.selectedFil === 26 && (
                          <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                            <div className="row">
                              <div className="form-group col-md-12 col-xs-12">
                                <div>
                                  <label>email</label>
                                  <input
                                    type="text"
                                    id="male"
                                    name="text5"
                                    className="form-control"
                                    onChange={this.text5}
                                    style={{ width: '128px' }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {this.state.selectedFil === 27 && (
                          <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                            <div className="row">
                              <div className="form-group col-md-12 col-xs-12">
                                <div>
                                  <label>Acc num</label>
                                  <input
                                    type="text"
                                    id="male"
                                    name="text6"
                                    className="form-control"
                                    onChange={this.text6}
                                    style={{ width: '128px' }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {this.state.selectedFil === 28 && (
                          <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                            <div className="row">
                              <div className="form-group col-md-12 col-xs-12">
                                <div>
                                  <label>phonenumber</label>
                                  <input
                                    type="text"
                                    id="male"
                                    name="text7"
                                    className="form-control"
                                    onChange={this.text7}
                                    style={{ width: '128px' }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {this.state.selectedFil == 22 && (
                          <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                            <div className="row">
                              <div className="form-group col-md-12 col-xs-12">
                                <div style={{ display: 'block' }}>
                                  <div className="custom-select-drop dropdown">
                                    <label>payment-terms</label>
                                    <select
                                      className="selectpicker"
                                      multiple
                                      data-live-search="true"
                                      onChange={e => this.selectedTerms(e)}
                                    >
                                      {this.state.paymentTerms &&
                                        this.state.paymentTerms.map((item, index) => {
                                          return (
                                            <option key={index} id={item.filter_name} data-id={item.id} value={item.id}>
                                              {item.terms}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>

                                  {this.state.disable && (
                                    <div>
                                      <div></div>
                                      <div></div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {this.state.selectedFil == 23 && (
                          <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                            <div className="row">
                              <div className="form-group col-md-12 col-xs-12">
                                <div id={1} style={{ display: 'block' }}>
                                  <div className="custom-select-drop dropdown">
                                    <label>vendor type</label>
                                    <select
                                      className="selectpicker"
                                      multiple
                                      data-live-search="true"
                                      onChange={e => this.selectedVendor_type(e)}
                                    >
                                      {this.state.vendor_type &&
                                        this.state.vendor_type.map((item, index) => {
                                          return (
                                            <option key={index} id={item.filter_name} data-id={item.id} value={item.id}>
                                              {item.name}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>

                                  {this.state.disable && (
                                    <div>
                                      <div></div>
                                      <div></div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {this.state.selectedFil == 29 && (
                          <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                            <div className="row">
                              <div className="form-group col-md-12 col-xs-12">
                                <div id={1} style={{ display: 'block' }}>
                                  <div className="custom-select-drop dropdown">
                                    <label>customer type</label>
                                    <select
                                      className="selectpicker"
                                      multiple
                                      data-live-search="true"
                                      onChange={e => this.selectedCustomer_type(e)}
                                    >
                                      {this.state.customer_type &&
                                        this.state.customer_type.map((item, index) => {
                                          return (
                                            <option key={index} id={item.filter_name} data-id={item.id} value={item.id}>
                                              {item.name}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>

                                  {this.state.disable && (
                                    <div>
                                      <div></div>
                                      <div></div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {this.state.selectedFil == 50 && (
                          <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                            <div className="row">
                              <div className="form-group col-md-12 col-xs-12">
                                <div id={1} style={{ display: 'block' }}>
                                  <div className="custom-select-drop dropdown">
                                    <label>Accounts</label>
                                    <select
                                      className="selectpicker"
                                      multiple
                                      data-live-search="true"
                                      id="selectedAccountIds"
                                      onChange={e => {
                                        this.multiSelectedAccount(e.target.value);
                                      }}
                                    >
                                      {this.state.all_account_list &&
                                        this.state.all_account_list.map((item, index) => {
                                          return (
                                            <option key={index} id={item.name} data-id={item.id} value={item.id}>
                                              {item.name}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>

                                  {this.state.disable && (
                                    <div>
                                      <div></div>
                                      <div></div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {this.state.selectedFil === 32 && (
                          <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                            <div className="row">
                              <div className="form-group col-md-12 col-xs-12">
                                <div id={1} style={{ display: 'block' }}>
                                  <label className="custom-checkbox mar-rgt">
                                    <input
                                      type="radio"
                                      id="male"
                                      name="gender"
                                      defaultValue={'='}
                                      onChange={e => {
                                        this.customRadioChange4(e.target.value);
                                      }}
                                    />
                                    =
                                    <span className="checkmark" />
                                  </label>
                                  <label className="custom-checkbox mar-rgt">
                                    <input
                                      type="radio"
                                      id="male"
                                      name="gender"
                                      defaultValue={'<='}
                                      onChange={e => {
                                        this.customRadioChange4(e.target.value);
                                      }}
                                    />
                                    &lt;=
                                    <span className="checkmark" />
                                  </label>
                                  <label className="custom-checkbox mar-rgt">
                                    <input
                                      type="radio"
                                      id="male"
                                      name="gender"
                                      defaultValue={'>='}
                                      onChange={e => {
                                        this.customRadioChange4(e.target.value);
                                      }}
                                    />
                                    &gt;=
                                    <span className="checkmark" />
                                  </label>
                                  {/* <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={"true"}
                                    onChange={(e) => {
                                      this.customRadioChange4(e.target.value);
                                    }}
                                  />
                                  any
                                  <span className="checkmark" />
                                </label> */}
                                  <div>
                                    <input
                                      type="text"
                                      id="male"
                                      name="valueAmount4"
                                      className="form-control"
                                      onChange={this.changeText4}
                                      style={{ width: '128px' }}
                                    />
                                  </div>

                                  <div>
                                    <div>
                                      <label>From</label>
                                      <input
                                        type="text"
                                        id="male"
                                        name="From4"
                                        className="form-control"
                                        style={{ width: '128px' }}
                                        onChange={this.changeText4}
                                      />
                                    </div>
                                    <div>
                                      <label>To</label>
                                      <input
                                        type="text"
                                        id="male"
                                        name="To4"
                                        onChange={this.changeText4}
                                        className="form-control"
                                        style={{ width: '128px' }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {this.state.selectedFil == 43 && (
                          <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                            <div className="row">
                              <div className="form-group ">
                                <div id={1} style={{ display: 'block' }}>
                                  <div>
                                    <input
                                      type="radio"
                                      className="custom-control-input"
                                      onChange={e => {
                                        if (e.target.checked) {
                                          this.setState({ sort_type: 'desc' }, this.sortingApi());
                                        }
                                      }}
                                      value="asc"
                                      checked={this.state.sort_type === 'desc'}
                                    />
                                    <label className="custom-control-label" for="customRadioInline1" style={{ margin: '4%' }}>
                                      Increment
                                    </label>
                                  </div>
                                  <div>
                                    <input
                                      type="radio"
                                      className="custom-control-input"
                                      onChange={e => {
                                        if (e.target.checked) {
                                          this.setState({ sort_type: 'asc' }, this.sortingApi());
                                        }
                                      }}
                                      value="desc"
                                      checked={this.state.sort_type === 'asc'}
                                    />
                                    <label className="custom-control-label" for="customRadioInline2" style={{ margin: '4%' }}>
                                      Decrement
                                    </label>
                                  </div>
                                  {this.state.disable && (
                                    <div>
                                      <div></div>
                                      <div></div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                  <div className="report-table">
                    <div className="table-responsive" id="sticky-tb-hdr">
                      <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} visible={this.state.loading} />
                      {!this.state.loading && (
                        <table className="table" id="mytable">
                          <thead>
                            <th>{`As of ${moment(this.state.end_date).format('DD MMM YYYY')}`}</th>
                            {this.state.coulmns_head.map(i => (
                              <th key={i} style={{ width: '100%', background: 'white' }}></th>
                            ))}

                            <tr>
                              {/* <th>&nbsp;</th> */}
                              <th>Name</th>
                              {this.state.coulmns_head.map((x, y) => {
                                return (
                                  <th
                                    className="text-right"
                                    /* className={x.clsname} */
                                  >
                                    {x.heading_name}
                                    <i className="th-sort">
                                      <img
                                        // src="../images/sort-icon.svg"
                                        src={config.sort_icon_url}
                                        alt="SortIcon"
                                      />
                                    </i>
                                  </th>
                                );
                              })}
                              {/* formula excel export  2023-07-17 */}
                              {/* <th className="hide-table-column">customer id</th>
                               <th className="hide-table-column">job id</th>
                               <th className="hide-table-column">currency code</th> */}
                              {/* formula excel export - end */}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.response &&
                              this.state.response.map((entry, key) => {
                                return (
                                  <React.Fragment>
                                    {/* main heading */}
                                    {/* 2024-05-29 */}
                                    {/* {isNaN(Number(entry.total_amount))
                                      ? 0
                                      : Number(entry.total_amount) != 0 && ( */}
                                    {entry.is_transactions_available == 1 && (
                                      <tr className="item-step1 sub-title title-1">
                                        <td
                                          style={{
                                            // position: "sticky",
                                            // left: "0.25rem",
                                            backgroundColor: '#EFEFFF',
                                          }}
                                        >
                                          {entry.customer_name}
                                        </td>

                                        {this.state.coulmns_head.map((x, y) => {
                                          return (
                                            <td
                                              style={{
                                                // position: "sticky",
                                                // left: "0.25rem",
                                                backgroundColor: '#EFEFFF',
                                              }}
                                              className={x.clsname}
                                            >
                                              &nbsp;
                                            </td>
                                          );
                                        })}

                                        {/* formula excel export  2023-07-17 */}
                                        {/* <td className="hide-table-column">-1</td>
                                          <td className="hide-table-column">-1</td>
                                          <td className="hide-table-column">-1</td> */}
                                        {/* formula excel export - end */}
                                      </tr>
                                    )}
                                    {/* main heading  ends */}

                                    {/* recursive logic starts */}
                                    {/* 2024-05-29 */}
                                    {/* {!isNaN(Number(entry.total_amount)) &&
                                      Number(entry.total_amount) != 0 && */}
                                    {entry.is_transactions_available == 1 && this.repeat(entry.sub_categories, 45)}
                                    {/* recursive logic ends */}

                                    {/* main total starts  */}

                                    {/* main total ends  */}

                                    {/* 2024-05-29 */}
                                    {/* {isNaN(Number(entry.total_amount))
                                      ? 0.00
                                      : Number(entry.total_amount) != 0 && ( */}
                                    {entry.is_transactions_available == 1 && (
                                      <tr className="item-step1 title1 bdr-no">
                                        <td>
                                          <span>Total {' ' + entry.customer_name} </span>
                                        </td>
                                        {/* <td>
                                            <span className="text-right"></span>
                                          </td>
                                          <td>
                                            <span className="text-right"></span>
                                          </td>{" "}
                                          <td>
                                            <span className="text-right"></span>
                                          </td>{" "}
                                          <td>
                                            <span className="text-right"></span>
                                          </td>{" "}
                                          <td>
                                            <span className="text-right"></span>
                                          </td>{" "}
                                          <td>
                                            <span className="text-right"></span>
                                          </td>
                                          <td>
                                            <span className="text-right"></span>
                                          </td>{" "}
                                          <td>
                                            <span className="text-right"></span>
                                          </td>{" "}
                                          <td>
                                            <span className="text-right"></span>
                                          </td>{" "}
                                          <td>
                                            <span className="">

                                            {!isNaN(entry.total_open_balance) && entry.total_open_balance ?(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                                { style: 'currency', currency: this.state.home_currency }).format(entry.total_open_balance)).replace(this.state.home_currency_symbol, ''):0}
                                            </span>
                                          </td>
                                          <td>
                                            <span className="">
                                              {!isNaN(entry.total_debit) && entry.total_debit ?(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                                { style: 'currency', currency: this.state.home_currency }).format(entry.total_debit)).replace(this.state.home_currency_symbol, ''):0}
                                            </span>
                                          </td>{" "}
                                          <td>
                                            <span className="">
                                              {!isNaN(entry.total_credit) && entry.total_credit ?(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                                { style: 'currency', currency: this.state.home_currency }).format(entry.total_credit)).replace(this.state.home_currency_symbol, ''):0}
                                            </span>
                                          </td>
                                          <td>
                                            <span className=" ">
                                              {!isNaN(entry.total_amount) && entry.total_amount ?(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                                { style: 'currency', currency: this.state.home_currency }).format(entry.total_amount)).replace(this.state.home_currency_symbol, ''):0}
                                            </span>
                                          </td>
                                          <td>
                                            <span className=" ">
                                              {!isNaN(entry.current_closing_balance) && entry.current_closing_balance ?(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                                { style: 'currency', currency: this.state.home_currency }).format(entry.current_closing_balance)).replace(this.state.home_currency_symbol, ''):0}
                                            </span>
                                          </td>
                                          <td>
                                            <span className="text-right"></span>
                                          </td>{" "}
                                          <td>
                                            <span className="text-right"></span>
                                          </td>{" "}
                                          <td>
                                            <span className=" ">

                                              {!isNaN(entry.total_open_foreign_balance) && entry.total_open_foreign_balance ?(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                                { style: 'currency', currency: this.state.home_currency }).format(entry.total_open_foreign_balance)).replace(this.state.home_currency_symbol, ''):0}
                                            </span>
                                          </td>
                                          <td>
                                            <span className=" ">
                                              {!isNaN(entry.total_foreign_debit) && entry.total_foreign_debit?(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                                { style: 'currency', currency: this.state.home_currency }).format(entry.total_foreign_debit)).replace(this.state.home_currency_symbol, ''):0}
                                            </span>
                                          </td>
                                          <td>
                                            <span className=" ">
                                              {!isNaN(entry.total_foreign_credit) && entry.total_foreign_credit ?(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                                { style: 'currency', currency: this.state.home_currency }).format(entry.total_foreign_credit)).replace(this.state.home_currency_symbol, ''):0}
                                            </span>
                                          </td>
                                          <td>
                                            <span className=" ">
                                              {!isNaN(entry.total_foreign_amount) && entry.total_foreign_amount?(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                                { style: 'currency', currency: this.state.home_currency }).format(entry.total_foreign_amount)).replace(this.state.home_currency_symbol, ''):0}
                                            </span>
                                          </td>
                                          <td>
                                            <span className=" ">
                                              {!isNaN(entry.current_closing_foreign_balance) && entry.current_closing_foreign_balance?(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                                { style: 'currency', currency: this.state.home_currency }).format(entry.current_closing_foreign_balance)).replace(this.state.home_currency_symbol, ''):0}
                                            </span>
                                          </td> */}

                                        {/* excel export formula */}
                                        {/* <td>
                                            <span className="text-right"></span>
                                          </td>
                                          <td className="hide-table-column">
                                            <span>{entry.current_customer_id}</span>
                                          </td>
                                           <td className="hide-table-column">
                                            <span></span>
                                          </td>
                                           <td className="hide-table-column">
                                            <span></span>
                                          </td> */}
                                        {/* excel export formula - end */}

                                        {this.state.coulmns_head.map((x, y) => {
                                          switch (x.clsname) {
                                            case 'open_balance_home_currency':
                                              return (
                                                <td>
                                                  <span className="">
                                                    {!isNaN(entry.total_open_balance) && entry.total_open_balance
                                                      ? new Intl.NumberFormat(
                                                          this.state.language_code + '-' + this.state.country_sortname,
                                                          { style: 'currency', currency: this.state.home_currency },
                                                        )
                                                          .format(entry.total_open_balance)
                                                          .replace(this.state.home_currency_symbol, '')
                                                      : 0}
                                                  </span>
                                                </td>
                                              );
                                            case 'debit':
                                              return (
                                                <td>
                                                  <span className="">
                                                    {!isNaN(entry.total_debit) && entry.total_debit
                                                      ? new Intl.NumberFormat(
                                                          this.state.language_code + '-' + this.state.country_sortname,
                                                          { style: 'currency', currency: this.state.home_currency },
                                                        )
                                                          .format(entry.total_debit)
                                                          .replace(this.state.home_currency_symbol, '')
                                                      : 0}
                                                  </span>
                                                </td>
                                              );
                                            case 'credit':
                                              return (
                                                <td>
                                                  <span className="">
                                                    {!isNaN(entry.total_credit) && entry.total_credit
                                                      ? new Intl.NumberFormat(
                                                          this.state.language_code + '-' + this.state.country_sortname,
                                                          { style: 'currency', currency: this.state.home_currency },
                                                        )
                                                          .format(entry.total_credit)
                                                          .replace(this.state.home_currency_symbol, '')
                                                      : 0}
                                                  </span>
                                                </td>
                                              );
                                            case 'amount':
                                              return (
                                                <td>
                                                  <span className=" ">
                                                    {!isNaN(entry.total_amount) && entry.total_amount
                                                      ? new Intl.NumberFormat(
                                                          this.state.language_code + '-' + this.state.country_sortname,
                                                          { style: 'currency', currency: this.state.home_currency },
                                                        )
                                                          .format(entry.total_amount)
                                                          .replace(this.state.home_currency_symbol, '')
                                                      : 0}
                                                  </span>
                                                </td>
                                              );
                                            case 'balance':
                                              return (
                                                <td>
                                                  <span className=" ">
                                                    {!isNaN(entry.current_closing_balance) && entry.current_closing_balance
                                                      ? new Intl.NumberFormat(
                                                          this.state.language_code + '-' + this.state.country_sortname,
                                                          { style: 'currency', currency: this.state.home_currency },
                                                        )
                                                          .format(entry.current_closing_balance)
                                                          .replace(this.state.home_currency_symbol, '')
                                                      : 0}
                                                  </span>
                                                </td>
                                              );
                                            case 'open_balance_foreign_currency':
                                              return (
                                                <td>
                                                  <span className=" ">
                                                    {!isNaN(entry.total_open_foreign_balance) && entry.total_open_foreign_balance
                                                      ? new Intl.NumberFormat(
                                                          this.state.language_code + '-' + this.state.country_sortname,
                                                          { style: 'currency', currency: this.state.home_currency },
                                                        )
                                                          .format(entry.total_open_foreign_balance)
                                                          .replace(this.state.home_currency_symbol, '')
                                                      : 0}
                                                  </span>
                                                </td>
                                              );
                                            case 'foreign_debit':
                                              return (
                                                <td>
                                                  <span className=" ">
                                                    {!isNaN(entry.total_foreign_debit) && entry.total_foreign_debit
                                                      ? new Intl.NumberFormat(
                                                          this.state.language_code + '-' + this.state.country_sortname,
                                                          { style: 'currency', currency: this.state.home_currency },
                                                        )
                                                          .format(entry.total_foreign_debit)
                                                          .replace(this.state.home_currency_symbol, '')
                                                      : 0}
                                                  </span>
                                                </td>
                                              );
                                            case 'foreign_credit':
                                              return (
                                                <td>
                                                  <span className=" ">
                                                    {!isNaN(entry.total_foreign_credit) && entry.total_foreign_credit
                                                      ? new Intl.NumberFormat(
                                                          this.state.language_code + '-' + this.state.country_sortname,
                                                          { style: 'currency', currency: this.state.home_currency },
                                                        )
                                                          .format(entry.total_foreign_credit)
                                                          .replace(this.state.home_currency_symbol, '')
                                                      : 0}
                                                  </span>
                                                </td>
                                              );
                                            case 'foreign_amount':
                                              return (
                                                <td>
                                                  <span className=" ">
                                                    {!isNaN(entry.total_foreign_amount) && entry.total_foreign_amount
                                                      ? new Intl.NumberFormat(
                                                          this.state.language_code + '-' + this.state.country_sortname,
                                                          { style: 'currency', currency: this.state.home_currency },
                                                        )
                                                          .format(entry.total_foreign_amount)
                                                          .replace(this.state.home_currency_symbol, '')
                                                      : 0}
                                                  </span>
                                                </td>
                                              );
                                            case 'foreign_balance':
                                              return (
                                                <td>
                                                  <span className=" ">
                                                    {!isNaN(entry.current_closing_foreign_balance) &&
                                                    entry.current_closing_foreign_balance
                                                      ? new Intl.NumberFormat(
                                                          this.state.language_code + '-' + this.state.country_sortname,
                                                          { style: 'currency', currency: this.state.home_currency },
                                                        )
                                                          .format(entry.current_closing_foreign_balance)
                                                          .replace(this.state.home_currency_symbol, '')
                                                      : 0}
                                                  </span>
                                                </td>
                                              );
                                            case 'current_customer_id':
                                              return (
                                                <td className="hide-table-column">
                                                  <span>{entry.current_customer_id}</span>
                                                </td>
                                              );
                                            case 'current_job_id':
                                            case 'current_currency_code':
                                              return (
                                                <td className="hide-table-column">
                                                  <span></span>
                                                </td>
                                              );

                                            default:
                                              return (
                                                <td>
                                                  <span className="text-right"></span>
                                                </td>
                                              );
                                          }
                                        })}
                                      </tr>
                                    )}
                                  </React.Fragment>
                                );
                              })}

                            {/* final Total */}

                            {this.state.fullResponse != {} ? (
                              <tr className="item-step1 title1 bdr-no" style={{ backgroundColor: 'lightgrey' }}>
                                <td>
                                  <span>Total </span>
                                </td>
                                {/* <td>
                                  <span className=""></span>
                                </td>
                                <td>
                                  <span className=""></span>
                                </td>
                                <td>
                                  <span className=""></span>
                                </td>{" "}
                                <td>
                                  <span className=""></span>
                                </td>{" "}
                                <td>
                                  <span className=""></span>
                                </td>{" "}
                                <td>
                                  <span className=""></span>
                                </td>{" "}
                                <td>
                                  <span className=""></span>
                                </td>{" "}
                                <td>
                                  <span className=""></span>
                                </td>{" "}
                                <td>
                                  <span className=""></span>
                                </td>{" "}
                                <td>
                                  <span className="">
                                    {!isNaN(this.state.fullResponse.overall_total_home_open_balance) && this.state.fullResponse.overall_total_home_open_balance?(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                      { style: 'currency', currency: this.state.home_currency }).format(this.state.fullResponse.overall_total_home_open_balance)).replace(this.state.home_currency_symbol, ''):0}
                                  </span>
                                </td>
                                <td>
                                  <span className="">
                                    {!isNaN(this.state.fullResponse.overall_total_home_debit) && this.state.fullResponse.overall_total_home_debit?(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                      { style: 'currency', currency: this.state.home_currency }).format(this.state.fullResponse.overall_total_home_debit)).replace(this.state.home_currency_symbol, ''):0}
                                  </span>
                                </td>
                                <td>
                                  <span className="">
                                    {!isNaN(this.state.fullResponse.overall_total_home_credit) && this.state.fullResponse.overall_total_home_credit?(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                      { style: 'currency', currency: this.state.home_currency }).format(this.state.fullResponse.overall_total_home_credit)).replace(this.state.home_currency_symbol, ''):0}
                                  </span>
                                </td>
                                <td>
                                  <span className="">
                                    {!isNaN(this.state.fullResponse.overall_total_home_amount) && this.state.fullResponse.overall_total_home_amount?(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                      { style: 'currency', currency: this.state.home_currency }).format(this.state.fullResponse.overall_total_home_amount)).replace(this.state.home_currency_symbol, ''):0}
                                  </span>
                                </td>
                                <td>
                                  <span className="">
                                    {!isNaN(this.state.fullResponse.overall_total_home_balance) && this.state.fullResponse.overall_total_home_balance ?(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                      { style: 'currency', currency: this.state.home_currency }).format(this.state.fullResponse.overall_total_home_balance)).replace(this.state.home_currency_symbol, ''):0}
                                  </span>
                                </td>
                                <td>
                                  <span className=""></span>
                                </td>{" "}
                                <td>
                                  <span className=""></span>
                                </td>
                                <td>
                                  <span className="">
                                    {!isNaN(this.state.fullResponse.overall_total_foreign_open_balance) && this.state.fullResponse.overall_total_foreign_open_balance?(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                      { style: 'currency', currency: this.state.home_currency }).format(this.state.fullResponse.overall_total_foreign_open_balance)).replace(this.state.home_currency_symbol, ''):0}
                                  </span>
                                </td>{" "}
                                <td>
                                  <span className="">
                                    {!isNaN(this.state.fullResponse.overall_total_foreign_debit) && this.state.fullResponse.overall_total_foreign_debit ?(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                      { style: 'currency', currency: this.state.home_currency }).format(this.state.fullResponse.overall_total_foreign_debit)).replace(this.state.home_currency_symbol, ''):0}
                                  </span>
                                </td>{" "}
                                <td>
                                  <span className="">
                                    {!isNaN(this.state.fullResponse.overall_total_foreign_credit) && this.state.fullResponse.overall_total_foreign_credit ?(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                      { style: 'currency', currency: this.state.home_currency }).format(this.state.fullResponse.overall_total_foreign_credit)).replace(this.state.home_currency_symbol, ''):0}
                                  </span>
                                </td>{" "}
                                <td>
                                  <span className="">
                                    {!isNaN(this.state.fullResponse.overall_total_foreign_amount) && this.state.fullResponse.overall_total_foreign_amount ?(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                      { style: 'currency', currency: this.state.home_currency }).format(this.state.fullResponse.overall_total_foreign_amount)).replace(this.state.home_currency_symbol, ''):0}
                                  </span>
                                </td>{" "}
                                <td>
                                  <span className="">
                                    {!isNaN(this.state.fullResponse.overall_total_foreign_balance) && this.state.fullResponse.overall_total_foreign_balance ?(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                      { style: 'currency', currency: this.state.home_currency }).format(this.state.fullResponse.overall_total_foreign_balance)).replace(this.state.home_currency_symbol, ''):0}
                                  </span>
                                </td>
                                <td>
                                  <span></span>
                                </td> */}

                                {/* excel export formula - use -2 for grand total*/}
                                {/* <td className="hide-table-column">
                                <span className=''>-2</span>
                              </td> 
                              <td className="hide-table-column">
                                <span className=''>-2</span>
                              </td> 
                              <td className="hide-table-column">
                                <span className=''>-2</span>
                              </td>  */}
                                {/* excel export formula end */}

                                {this.state.coulmns_head.map((x, y) => {
                                  switch (x.clsname) {
                                    case 'open_balance_home_currency':
                                      return (
                                        <td>
                                          <span className="">
                                            {!isNaN(this.state.fullResponse.overall_total_home_open_balance) &&
                                            this.state.fullResponse.overall_total_home_open_balance
                                              ? new Intl.NumberFormat(
                                                  this.state.language_code + '-' + this.state.country_sortname,
                                                  { style: 'currency', currency: this.state.home_currency },
                                                )
                                                  .format(this.state.fullResponse.overall_total_home_open_balance)
                                                  .replace(this.state.home_currency_symbol, '')
                                              : 0}
                                          </span>
                                        </td>
                                      );
                                    case 'debit':
                                      return (
                                        <td>
                                          <span className="">
                                            {!isNaN(this.state.fullResponse.overall_total_home_debit) &&
                                            this.state.fullResponse.overall_total_home_debit
                                              ? new Intl.NumberFormat(
                                                  this.state.language_code + '-' + this.state.country_sortname,
                                                  { style: 'currency', currency: this.state.home_currency },
                                                )
                                                  .format(this.state.fullResponse.overall_total_home_debit)
                                                  .replace(this.state.home_currency_symbol, '')
                                              : 0}
                                          </span>
                                        </td>
                                      );
                                    case 'credit':
                                      return (
                                        <td>
                                          <span className="">
                                            {!isNaN(this.state.fullResponse.overall_total_home_credit) &&
                                            this.state.fullResponse.overall_total_home_credit
                                              ? new Intl.NumberFormat(
                                                  this.state.language_code + '-' + this.state.country_sortname,
                                                  { style: 'currency', currency: this.state.home_currency },
                                                )
                                                  .format(this.state.fullResponse.overall_total_home_credit)
                                                  .replace(this.state.home_currency_symbol, '')
                                              : 0}
                                          </span>
                                        </td>
                                      );
                                    case 'amount':
                                      return (
                                        <td>
                                          <span className="">
                                            {!isNaN(this.state.fullResponse.overall_total_home_amount) &&
                                            this.state.fullResponse.overall_total_home_amount
                                              ? new Intl.NumberFormat(
                                                  this.state.language_code + '-' + this.state.country_sortname,
                                                  { style: 'currency', currency: this.state.home_currency },
                                                )
                                                  .format(this.state.fullResponse.overall_total_home_amount)
                                                  .replace(this.state.home_currency_symbol, '')
                                              : 0}
                                          </span>
                                        </td>
                                      );
                                    case 'balance':
                                      return (
                                        <td>
                                          <span className="">
                                            {!isNaN(this.state.fullResponse.overall_total_home_balance) &&
                                            this.state.fullResponse.overall_total_home_balance
                                              ? new Intl.NumberFormat(
                                                  this.state.language_code + '-' + this.state.country_sortname,
                                                  { style: 'currency', currency: this.state.home_currency },
                                                )
                                                  .format(this.state.fullResponse.overall_total_home_balance)
                                                  .replace(this.state.home_currency_symbol, '')
                                              : 0}
                                          </span>
                                        </td>
                                      );
                                    case 'open_balance_foreign_currency':
                                      return (
                                        <td>
                                          <span className="">
                                            {!isNaN(this.state.fullResponse.overall_total_foreign_open_balance) &&
                                            this.state.fullResponse.overall_total_foreign_open_balance
                                              ? new Intl.NumberFormat(
                                                  this.state.language_code + '-' + this.state.country_sortname,
                                                  { style: 'currency', currency: this.state.home_currency },
                                                )
                                                  .format(this.state.fullResponse.overall_total_foreign_open_balance)
                                                  .replace(this.state.home_currency_symbol, '')
                                              : 0}
                                          </span>
                                        </td>
                                      );
                                    case 'foreign_debit':
                                      return (
                                        <td>
                                          <span className="">
                                            {!isNaN(this.state.fullResponse.overall_total_foreign_debit) &&
                                            this.state.fullResponse.overall_total_foreign_debit
                                              ? new Intl.NumberFormat(
                                                  this.state.language_code + '-' + this.state.country_sortname,
                                                  { style: 'currency', currency: this.state.home_currency },
                                                )
                                                  .format(this.state.fullResponse.overall_total_foreign_debit)
                                                  .replace(this.state.home_currency_symbol, '')
                                              : 0}
                                          </span>
                                        </td>
                                      );
                                    case 'foreign_credit':
                                      return (
                                        <td>
                                          <span className="">
                                            {!isNaN(this.state.fullResponse.overall_total_foreign_credit) &&
                                            this.state.fullResponse.overall_total_foreign_credit
                                              ? new Intl.NumberFormat(
                                                  this.state.language_code + '-' + this.state.country_sortname,
                                                  { style: 'currency', currency: this.state.home_currency },
                                                )
                                                  .format(this.state.fullResponse.overall_total_foreign_credit)
                                                  .replace(this.state.home_currency_symbol, '')
                                              : 0}
                                          </span>
                                        </td>
                                      );
                                    case 'foreign_amount':
                                      return (
                                        <td>
                                          <span className="">
                                            {!isNaN(this.state.fullResponse.overall_total_foreign_amount) &&
                                            this.state.fullResponse.overall_total_foreign_amount
                                              ? new Intl.NumberFormat(
                                                  this.state.language_code + '-' + this.state.country_sortname,
                                                  { style: 'currency', currency: this.state.home_currency },
                                                )
                                                  .format(this.state.fullResponse.overall_total_foreign_amount)
                                                  .replace(this.state.home_currency_symbol, '')
                                              : 0}
                                          </span>
                                        </td>
                                      );
                                    case 'foreign_balance':
                                      return (
                                        <td>
                                          <span className="">
                                            {!isNaN(this.state.fullResponse.overall_total_foreign_balance) &&
                                            this.state.fullResponse.overall_total_foreign_balance
                                              ? new Intl.NumberFormat(
                                                  this.state.language_code + '-' + this.state.country_sortname,
                                                  { style: 'currency', currency: this.state.home_currency },
                                                )
                                                  .format(this.state.fullResponse.overall_total_foreign_balance)
                                                  .replace(this.state.home_currency_symbol, '')
                                              : 0}
                                          </span>
                                        </td>
                                      );
                                    case 'current_customer_id':
                                    case 'current_job_id':
                                    case 'current_currency_code':
                                      return (
                                        <td className="hide-table-column">
                                          <span className="">-2</span>
                                        </td>
                                      );

                                    default:
                                      return (
                                        <td>
                                          <span className=""></span>
                                        </td>
                                      );
                                  }
                                })}
                              </tr>
                            ) : (
                              ''
                            )}
                            {/* final total ends */}
                          </tbody>
                        </table>
                      )}

                      {/* excel export with formula - starts */}
                      <div style={containerStyle}>
                        <div className="container">
                          <div className="grid-wrapper">
                            <div style={gridStyle} className="ag-theme-alpine">
                              <AgGridReact
                                ref={this.gridRef}
                                rowData={this.state.export_formula_row_data}
                                columnDefs={this.state.export_formula_column_defs}
                                defaultColDef={this.state.defaultColDef}
                                defaultExcelExportParams={this.state.defaultExcelExportParams}
                              ></AgGridReact>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* excel export with formula - end */}
                    </div>
                  </div>
                </div>
              </div>

              {/* Main Content Ends here */}
            </div>
            {/* MainContent Wrapper Ends here */}
          </div>
        </div>
        {/* Main Wrapper Ends here */}
        {/* footer Starts here */}
        <footer className="container-fluid">
          <p>© Copyrights 2019, Genie. All Rights Reserved.</p>
        </footer>
      </div>
    );
  }
}
export default open_invoice;
